import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, TextField } from "@mui/material";
import { useAdviceRequests, useTranslations } from "domains";
import { ScanAdviceRequestFormValues } from "../useScanForm";
import { useScanAdviceRequestForm } from "./useScanAdviceRequestForm";

interface ScanAdviceRequestFormProps {
	enabled: boolean;
	initialValues: ScanAdviceRequestFormValues;
}

export const ScanAdviceRequestForm: UIComponent<ScanAdviceRequestFormProps> = ({ enabled, initialValues }) => {
	const { t } = useTranslations();
	const { save } = useAdviceRequests();
	const { register, handleFormSubmit } = useScanAdviceRequestForm({ initialValues, submit: save });

	if (!enabled) {
		return (
			<div>
				<h4>{t("scanForm.step.2")}</h4>
				<p style={{ opacity: 0.8 }}>{t("scanForm.disabled.description")}</p>
			</div>
		);
	}

	return (
		<form onSubmit={handleFormSubmit}>
			<div>
				<h4>{t("scanForm.step.2")}</h4>
				<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
					<TextField disabled={!enabled} {...register("internalComment")} multiline rows={4} fullWidth name="internalComment" id="internal_feedback" label={t("feedback.internal")} variant="outlined" />
				</FormControl>
				<p>{t("scanAdviceRequest.disclaimer")}</p>
				<Button disabled={!enabled} variant="contained" type="submit">{t("saveAdviceRequest")}</Button>
			</div>
		</form>
	);
};
