import { Database } from "mocks/db";
import { LanguageDTO } from "models/schema";
import faker from "faker";

export const populateFoodCollection = (db: Database) => {
	const createTitles = () => ([
		{ language: LanguageDTO.EnDTO, text: faker.lorem.words() },
		{ language: LanguageDTO.NlDTO, text: faker.lorem.words() },
		{ language: LanguageDTO.FrDTO, text: faker.lorem.words() },
	]);
	const createDescriptions = () => ([
		{ language: LanguageDTO.EnDTO, text: faker.lorem.paragraph() },
		{ language: LanguageDTO.NlDTO, text: faker.lorem.paragraph() },
		{ language: LanguageDTO.FrDTO, text: faker.lorem.paragraph() },
	]);

	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
	db.food.create({ titles: createTitles(), descriptions: createDescriptions() });
};
