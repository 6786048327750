import { UILogic } from "@aptus/frontend-core";
import { Chip, IconButton } from "@mui/material";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { useRoutes, useTranslations } from "domains";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PaginationResult, usePagination } from "hooks/table/pagination/usePagination";
import { SortResult, useSort } from "hooks/table/sort/useSort";

interface Result extends PaginationResult, SortResult {
	columns: GridColDef[];
}

interface Props {
	remove: (id: string) => void;
}

export const useUsersTable: UILogic<Props, Result> = ({ remove }) => {
	const pagination = usePagination({});
	const sort = useSort({});

	const { updateQueryString } = useRoutes();

	const { t } = useTranslations();

	const editUser = (id: string) => {
		updateQueryString({ selected: id });
	};

	const removeUser = (id: string) => {
		remove(id);
	};

	const renderActionsCell = (params: GridRenderEditCellParams) => (
		<div style={{ display: "flex" }}>
			<IconButton>
				<EditIcon onClick={() => editUser(params.value)} />
			</IconButton>
			<IconButton>
				<DeleteIcon onClick={() => removeUser(params.value)} />
			</IconButton>
		</div>
	);

	const renderRolesCell = (params: GridRenderEditCellParams) => (
		<div style={{ width: 400, display: "flex", flexWrap: "wrap" }}>
			{(params.value || []).map((v: string) => <Chip sx={{ mb: 0.5, mr: 0.5 }} label={t(`userForm.role.${v}` as "userForm.role.userAdmin")} />)}
		</div>
	);

	const columns = [
		{ field: "givenName", headerName: "First name", flex: 1 },
		{ field: "familyName", headerName: "Last name", flex: 1 },
		{ field: "email", headerName: "E-mail", flex: 1 },
		{ field: "roles", headerName: "Roles", width: 400, renderCell: renderRolesCell },
		{ field: "id", headerName: "actions", width: 100, renderCell: renderActionsCell, sortable: false },
	];

	return {
		...pagination,
		...sort,
		columns,
	};
};
