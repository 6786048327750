import { ApolloError } from "@apollo/client";
import { UILogic } from "@aptus/frontend-core";
import { useAdviceRequests, useAocs, useDomains, useWines } from "domains";
import event from "event";
import {
	AdviceRequestStatusDTO,
	AdviceRequestTypeDTO,
} from "models/schema";
import { UseFormTypes } from "pages/databasePage/components/databaseContent/recordForm/forms/useFormTypes";
import { useForm } from "react-hook-form";

import { CreateDomainInput } from "hooks/domains/models/domainInput";
import { Domain } from "hooks/domains/models/domain";
import { useEffect, useState } from "react";
import { useGetAocById } from "pages/databasePage/components/databaseContent/recordForm/forms/domainForm/models/getAocById";
import { LabelDataFormValues, SuggestedTemperatures } from "../useScanForm";
import { useSuggestedTemperatures } from "./useSuggestedTemperatures";

/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/no-unused-vars */
export interface LabelDataFormProps {
	adviceRequestId: string;
	initialValues: LabelDataFormValues;
	scanId: string;
	idealTemp?: number;
	isScanLabel?: boolean;
}

interface Result extends UseFormTypes<LabelDataFormValues> {
	selectedCountry?: string;
	selectedAoc?: string;
	selectedRegion?: string;
	domainNotFound?: boolean;
	overwriteTemperature?: boolean;
	suggestedTemperatures?: SuggestedTemperatures;
	isDisabled?: boolean;
	onRetrySuggestionSubmit: () => void;
}

export const useLabelDataForm: UILogic<LabelDataFormProps, Result> = ({ initialValues, scanId, adviceRequestId, isScanLabel }) => {
	const { save: saveWine } = useWines();
	const { save: saveAdviceRequest } = useAdviceRequests();
	const { save: saveDomain } = useDomains();
	const [aocId, setAocId] = useState(initialValues?.aoc);
	const { aoc } = useAocs(useGetAocById({ variables: { id: aocId || "" }, skip: !aocId }));

	const { register, control, watch, handleSubmit, setError, getValues, setValue, reset, formState: { errors } } = useForm({ defaultValues: initialValues });

	const domainNotFound = watch("domainNotFound");
	const overwriteTemperature = watch("overwriteTemperature");
	const selectedGrape = watch("grape");
	const selectedRegion = watch("region");
	const selectedCountry = watch("country");
	const selectedAoc = watch("aoc");
	const selectedWineType = watch("wineType");

	const { suggestedTemperatures } = useSuggestedTemperatures({
		isScanLabel,
		selectedAoc,
		selectedCountry,
		selectedGrape,
		selectedRegion,
		selectedWineType,
	});

	const onSuggestionSubmit = async (): Promise<Domain | undefined> => {
		const customDomain = getValues("customDomain");

		let domain;

		if (customDomain) {
			try {
				domain = await saveDomain({
					name: customDomain,
					country: selectedCountry,
					aoc: selectedAoc,
					region: selectedRegion,
				} as CreateDomainInput);
				/* When suggestions are re-introduced */
				// await saveSuggestion({
				// 	type: SuggestionTypeDTO.DomainDTO,
				// 	title: customDomain,
				// 	source: {
				// 		type: SuggestionSourceTypeDTO.AdviceRequestDTO,
				// 		typeId: adviceRequestId,
				// 	},
				// } as SaveSuggestionInput);
			} catch {
				setError(
					"customDomain",
					{ message: "Something went wrong while trying to add this suggestion." },
					{ shouldFocus: true },
				);
			}
		}

		return domain;
	};

	const handleFormSubmit = async (values: LabelDataFormValues) => {
		const { domainNotFound: submitSuggestion, overwriteTemperature: shouldOverwriteTemperature, domain, ...rest } = values;

		try {
			let newDomain;

			if (submitSuggestion) {
				newDomain = await onSuggestionSubmit();
			}

			const result = await saveWine({
				...rest,
				idealTemp: shouldOverwriteTemperature ? Number(rest.idealTemp) : suggestedTemperatures?.suggestedTemperature,
				year: rest.year ? Number(rest.year) : undefined,
				scanId,
				feedback: "",
				domain: newDomain ? newDomain.id : domain,
			});

			if (result && result.id) {
				await saveAdviceRequest({ id: adviceRequestId, wineResult: result.id, type: isScanLabel ? AdviceRequestTypeDTO.ScanDTO : AdviceRequestTypeDTO.BuyingDTO, status: AdviceRequestStatusDTO.FinishedDTO });
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	useEffect(() => {
		setValue("region", undefined);
		if (!aoc) setValue("aoc", undefined);
	}, [watch("country")]);

	useEffect(() => {
		if (watch("aoc") && watch("region") !== aoc.region) console.log(`region is now ${aoc.region}`);
		if (watch("aoc") && watch("region") !== aoc.region) setValue("region", aoc.region);
		if (watch("aoc") && watch("country") !== aoc.country) setValue("country", aoc.country || "");
	}, [aoc]);

	useEffect(() => {
		if (watch("aoc") !== aocId) {
			setAocId(watch("aoc"));
		}

		// if (!watch("aoc")) {
		// 	setValue("region", undefined);
		// 	setValue("country", "");
		// }
	}, [watch("aoc")]);

	useEffect(() => {
		reset(initialValues);
	}, [initialValues]);

	return {
		register,
		errors,
		control,
		domainNotFound,
		overwriteTemperature,
		selectedAoc,
		selectedRegion,
		selectedCountry,
		selectedGrape,
		selectedWineType,
		suggestedTemperatures,
		onRetrySuggestionSubmit: onSuggestionSubmit,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		isDisabled: !!watch("aoc"),
	};
};
