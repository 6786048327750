import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Aoc } from "./models/aoc";
import { CreateAocInput, DeleteAocInput, SaveAocInput, UpdateAocInput } from "./models/aocInput";

export type AocsAPI = InternalAPI<Aoc[]>;

export type UpdateAocMutation = Mutation<UpdateAocInput, Aoc>;
export type CreateAocMutation = Mutation<CreateAocInput, Aoc>;
export type DeleteAocMutation = Mutation<DeleteAocInput, Success>;

interface Props {
	API: AocsAPI;
	defaultAoc: Aoc;
	updateMutation: UpdateAocMutation;
	createMutation: CreateAocMutation;
	deleteMutation: DeleteAocMutation;
}
interface Result {
	aoc: Aoc;
	aocs: Aoc[];
	isLoading: boolean;
	save: (input: SaveAocInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useAocsUseCases: UseCases<Props, Result> = ({ API, defaultAoc, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveAocInput): input is UpdateAocInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("aocHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("aocHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("aocHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		aoc: API.data[0] || defaultAoc,
		aocs: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
