import { UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import { Country } from "hooks/countries/models/country";
import { Region } from "hooks/regions/models/region";
import { SaveRegionInput } from "hooks/regions/models/regionInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UseFormTypes } from "../useFormTypes";

interface Props {
	initialValues?: Region;
	submit: (values: SaveRegionInput) => void;
	remove: (id: string) => void;
}

interface Result extends UseFormTypes<Region> {
	handleDelete: (id: string) => void;
	selectedCountry?: Country["id"];
}

export const useRegionForm: UILogic<Props, Result> = ({ initialValues, remove, submit }) => {
	const { currentQueryString: { selected, create }, updateQueryString } = useRoutes();

	const defaultValues = {
		...initialValues,
		id: selected,
	};

	const { register, handleSubmit, control, reset, watch, formState: { errors } } = useForm<Region>({ defaultValues });

	const selectedCountry = watch("country");

	useEffect(() => {
		reset(defaultValues);
	}, [create, initialValues]);

	const handleFormSubmit = (values: SaveRegionInput): void => {
		submit({
			...values,
			temperatures: values.temperatures.map((temp) => ({ ...temp, temperature: temp.temperature ? Number(temp.temperature) : undefined })),
		});
	};

	const handleDelete = (id: string) => {
		remove(id);
		updateQueryString({ selected: null, create: true });
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		handleDelete,
		selectedCountry,
		errors,
	};
};
