/* eslint-disable */
import * as Types from '../../../../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDomainVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetDomainDTO = (
  { __typename?: 'Query' }
  & { domain: (
    { __typename?: 'Domain' }
    & Pick<Types.DomainDTO, 'id' | 'name'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Types.CountryDTO, 'id'>
    ), region?: Types.Maybe<(
      { __typename?: 'Region' }
      & Pick<Types.RegionDTO, 'id'>
    )>, aoc?: Types.Maybe<(
      { __typename?: 'Aoc' }
      & Pick<Types.AocDTO, 'id'>
    )> }
  ) }
);


export const GetDomainDocumentDTO = gql`
    query getDomain($id: String!) {
  domain(id: $id) {
    id
    name
    country {
      id
    }
    region {
      id
    }
    aoc {
      id
    }
  }
}
    `;

/**
 * __useGetDomain__
 *
 * To run a query within a React component, call `useGetDomain` and pass it any options that fit your needs.
 * When your component renders, `useGetDomain` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomain({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDomain(baseOptions: Apollo.QueryHookOptions<GetDomainDTO, GetDomainVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDomainDTO, GetDomainVariablesDTO>(GetDomainDocumentDTO, options);
      }
export function useGetDomainLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetDomainDTO, GetDomainVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDomainDTO, GetDomainVariablesDTO>(GetDomainDocumentDTO, options);
        }
export type GetDomainHookResult = ReturnType<typeof useGetDomain>;
export type GetDomainLazyQueryDTOHookResult = ReturnType<typeof useGetDomainLazyQueryDTO>;
export type GetDomainQueryResultDTO = Apollo.QueryResult<GetDomainDTO, GetDomainVariablesDTO>;