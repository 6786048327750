/* eslint-disable import/prefer-default-export */
import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "components/loader/loader";
import { useClassifications, useRoutes, useTranslations } from "domains";
import { Classification } from "hooks/classifications/models/classification";
import { SaveClassificationInput } from "hooks/domains/models/classificationInput";
import BasePage from "pages/basePage/basePage";
import { Controller } from "react-hook-form";
import { useGetClassification } from "./models/getClassification";
import { useClassificationForm } from "./useClassificationForm";

interface Props {
	classification: Classification;
	save: (input: SaveClassificationInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const ClassificationForm: UIComponent<Props> = ({ classification, save, remove }) => {
	const { handleFormSubmit, handleDelete, register, control, errors } = useClassificationForm({ initialValues: classification, submit: save, remove });
	const { t } = useTranslations();
	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`classification.${classification.id ? "edit" : "add"}`)}</h2>
			<FormControl {...register("name", { required: { value: true, message: "This is required." } })} fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					error={!!errors?.name}
					helperText={errors?.name?.message}
					name="name"
					fullWidth
					id="name"
					label={t("name")}
					variant="outlined"
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }} error={!!errors?.type}>
				<InputLabel id="type">{t("type")}</InputLabel>
				<Controller
					name="type"
					control={control}
					rules={{ required: { value: true, message: "This is required." } }}
					render={({ field: { onChange, value } }) => (
						<Select
							name="type"
							value={value}
							onChange={(e) => onChange(e.target.value)}
							labelId="type"
							error={!!errors?.type}
							id="type"
							label={t("type")}
						>
							<MenuItem value="still">{t("type.still")}</MenuItem>
							<MenuItem value="sparkling">{t("type.sparkling")}</MenuItem>
						</Select>
					)}
				/>
				{errors?.type?.message ? <FormHelperText>{errors?.name?.message}</FormHelperText> : null}
			</FormControl>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{classification.id ? <Button onClick={() => handleDelete(classification.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteClassification")}</Button> : undefined}
				<Button type="submit" variant="contained">{classification.id ? t("updateClassification") : t("createClassification")}</Button>
			</Box>
		</form>
	);
};

export const ClassificationPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, classification, isLoading } = useClassifications(useGetClassification({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <ClassificationForm save={save} remove={remove} classification={classification} />;
};
