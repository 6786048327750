/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRegionsCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetRegionsCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'regionsCount'>
);


export const GetRegionsCountDocumentDTO = gql`
    query getRegionsCount($search: String) {
  regionsCount(filter: {archived: {equals: false}, name: {contains: $search}})
}
    `;

/**
 * __useGetRegionsCount__
 *
 * To run a query within a React component, call `useGetRegionsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetRegionsCount(baseOptions?: Apollo.QueryHookOptions<GetRegionsCountDTO, GetRegionsCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionsCountDTO, GetRegionsCountVariablesDTO>(GetRegionsCountDocumentDTO, options);
      }
export function useGetRegionsCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsCountDTO, GetRegionsCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionsCountDTO, GetRegionsCountVariablesDTO>(GetRegionsCountDocumentDTO, options);
        }
export type GetRegionsCountHookResult = ReturnType<typeof useGetRegionsCount>;
export type GetRegionsCountLazyQueryDTOHookResult = ReturnType<typeof useGetRegionsCountLazyQueryDTO>;
export type GetRegionsCountQueryResultDTO = Apollo.QueryResult<GetRegionsCountDTO, GetRegionsCountVariablesDTO>;