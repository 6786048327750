/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUsersCountVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.UserFilterDTO>;
}>;


export type GetUsersCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'usersCount'>
);


export const GetUsersCountDocumentDTO = gql`
    query getUsersCount($filter: UserFilter) {
  usersCount(filter: $filter)
}
    `;

/**
 * __useGetUsersCount__
 *
 * To run a query within a React component, call `useGetUsersCount` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersCount({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUsersCount(baseOptions?: Apollo.QueryHookOptions<GetUsersCountDTO, GetUsersCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersCountDTO, GetUsersCountVariablesDTO>(GetUsersCountDocumentDTO, options);
      }
export function useGetUsersCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersCountDTO, GetUsersCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersCountDTO, GetUsersCountVariablesDTO>(GetUsersCountDocumentDTO, options);
        }
export type GetUsersCountHookResult = ReturnType<typeof useGetUsersCount>;
export type GetUsersCountLazyQueryDTOHookResult = ReturnType<typeof useGetUsersCountLazyQueryDTO>;
export type GetUsersCountQueryResultDTO = Apollo.QueryResult<GetUsersCountDTO, GetUsersCountVariablesDTO>;