/* eslint-disable */
import * as Types from '../../../models/schema';

import { CorrectionFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateCorrectionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.CorrectionUpdateInputDTO>;
}>;


export type UpdateCorrectionDTO = (
  { __typename?: 'Mutation' }
  & { updateOneCorrection: (
    { __typename?: 'Correction' }
    & CorrectionFragmentDTO
  ) }
);


export const UpdateCorrectionDocumentDTO = gql`
    mutation updateCorrection($id: String!, $data: CorrectionUpdateInput) {
  updateOneCorrection(id: $id, data: $data) {
    ...correctionFragment
  }
}
    ${CorrectionFragmentDTO}`;
export type UpdateCorrectionMutationFnDTO = Apollo.MutationFunction<UpdateCorrectionDTO, UpdateCorrectionVariablesDTO>;

/**
 * __useUpdateCorrection__
 *
 * To run a mutation, you first call `useUpdateCorrection` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCorrection` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCorrection, { data, loading, error }] = useUpdateCorrection({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCorrection(baseOptions?: Apollo.MutationHookOptions<UpdateCorrectionDTO, UpdateCorrectionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCorrectionDTO, UpdateCorrectionVariablesDTO>(UpdateCorrectionDocumentDTO, options);
      }
export type UpdateCorrectionHookResult = ReturnType<typeof useUpdateCorrection>;
export type UpdateCorrectionMutationResultDTO = Apollo.MutationResult<UpdateCorrectionDTO>;
export type UpdateCorrectionMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateCorrectionDTO, UpdateCorrectionVariablesDTO>;