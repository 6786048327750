import { manyOf, oneOf, primaryKey } from "@mswjs/data";
import { ConversationSuggestionSourceDTO, AdviceRequestSuggestionSourceDTO, MainGrapeDTO, AdviceRequestTypeDTO, AocDTO, BuyingAdviceRequestDTO, ClassificationDTO, CorrectionDTO, CountryDTO, DomainDTO, ExceptionDTO, FaqDTO, FoodCourseDTO, FoodDTO, FoodIngredientsDTO, FoodKitchenDTO, GrapeDTO, RegionDTO, ScanAdviceDTO, ScanAdviceRequestDTO, ScanDTO, UserDTO, WineDTO, WineStyleDTO, WineDescriptionDTO, SuggestionTypeDTO, SuggestionStatusDTO, SuggestionSourceTypeDTO, DomainSuggestionDTO, FaqSuggestionDTO, ConversationDTO, ConversationStatusDTO } from "models/schema";
import faker from "faker";
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary";

const defineEntity = <Entity>(
	generator: () => { [Key in keyof Entity]: ModelDefinitionValue },
) => generator();

const conversation = defineEntity<ConversationDTO>(() => ({
	__typename: () => "Conversation",
	id: primaryKey(faker.datatype.uuid),
	owner: oneOf("user"),
	status: () => faker.random.arrayElement(Object.values(ConversationStatusDTO)),
	messages: () => [],
}));

const adviceRequestSuggestionSource = defineEntity<AdviceRequestSuggestionSourceDTO>(() => ({
	__typename: () => "AdviceRequestSuggestionSource",
	id: primaryKey(faker.datatype.uuid),
	type: () => SuggestionSourceTypeDTO.AdviceRequestDTO,
	adviceRequest: oneOf("scanAdviceRequest"),
}));

const conversationSuggestionSource = defineEntity<ConversationSuggestionSourceDTO>(() => ({
	__typename: () => "ConversationSuggestionSource",
	id: primaryKey(faker.datatype.uuid),
	type: () => SuggestionSourceTypeDTO.ConversationDTO,
	conversation: oneOf("conversation"),
}));

const domainSuggestion = defineEntity<DomainSuggestionDTO>(() => ({
	__typename: () => "DomainSuggestion",
	id: primaryKey(faker.datatype.uuid),
	createdAt: faker.datatype.datetime,
	type: () => SuggestionTypeDTO.DomainDTO,
	status: () => faker.random.arrayElement(Object.values(SuggestionStatusDTO)),
	owner: oneOf("user"),
	title: faker.lorem.words,
	source: oneOf("adviceRequestSuggestionSource"),
}));

const faqSuggestion = defineEntity<FaqSuggestionDTO>(() => ({
	__typename: () => "FaqSuggestion",
	id: primaryKey(faker.datatype.uuid),
	createdAt: faker.datatype.datetime,
	type: () => SuggestionTypeDTO.FaqDTO,
	status: () => faker.random.arrayElement(Object.values(SuggestionStatusDTO)),
	owner: oneOf("user"),
	title: faker.lorem.words,
	description: faker.lorem.sentence,
	source: oneOf("conversationSuggestionSource"),
}));

const country = defineEntity<CountryDTO>(() => ({
	__typename: () => "Country",
	id: primaryKey(faker.datatype.uuid),
	name: faker.address.country,
	temperatures: () => [],
	archived: faker.datatype.boolean,
}));

const aoc = defineEntity<AocDTO>(() => ({
	__typename: () => "Aoc",
	id: primaryKey(faker.datatype.uuid),
	name: faker.address.state,
	region: oneOf("region", { nullable: true }),
	country: oneOf("country"),
	temperatures: () => [],
	archived: faker.datatype.boolean,
}));

const region = defineEntity<RegionDTO>(() => ({
	__typename: () => "Region",
	id: primaryKey(faker.datatype.uuid),
	name: faker.address.county,
	country: oneOf("country"),
	temperatures: () => [],
	archived: faker.datatype.boolean,
}));

const domain = defineEntity<DomainDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "Domain",
	name: faker.address.city,
	country: oneOf("country"),
	aoc: oneOf("aoc", { nullable: true }),
	region: oneOf("region", { nullable: true }),
	archived: faker.datatype.boolean,
}));

const classification = defineEntity<ClassificationDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "Classification",
	name: faker.lorem.word,
	type: () => faker.random.arrayElement(["still", "sparkling"]),
	archived: faker.datatype.boolean,
}));

const grape = defineEntity<GrapeDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "Grape",
	name: faker.name.lastName,
	temperatures: () => [],
	mainGrape: oneOf("mainGrape", { nullable: true }),
	archived: faker.datatype.boolean,
}));

const mainGrape = defineEntity<MainGrapeDTO>(() => ({
	__typename: () => "MainGrape",
	id: primaryKey(faker.datatype.uuid),
	name: faker.name.lastName,
	wineStyle: () => faker.random.arrayElement(Object.values(WineStyleDTO)),
	archived: faker.datatype.boolean,
	temperatureDescription: faker.lorem.sentence,
	temperatureDescriptions: () => [],
	foodDescription: faker.lorem.sentence,
	foodDescriptions: () => [],
}));

const correction = defineEntity<CorrectionDTO>(() => ({
	id: primaryKey(faker.datatype.uuid),
	__typename: () => "Correction",
	originalText: faker.lorem.word,
	replacement: faker.lorem.word,
}));

const exception = defineEntity<ExceptionDTO>(() => ({
	__typename: () => "Exception",
	id: primaryKey(faker.datatype.uuid),
	text: faker.name.lastName,
	aoc: oneOf("aoc", { nullable: true }),
	country: oneOf("country", { nullable: true }),
	region: oneOf("region", { nullable: true }),
	grape: oneOf("grape", { nullable: true }),
	domain: oneOf("domain", { nullable: true }),
	temperatures: () => [],
	archived: faker.datatype.boolean,
}));

const user = defineEntity<UserDTO>(() => ({
	__typename: () => "User",
	id: primaryKey(faker.datatype.uuid),
	email: faker.internet.email,
	language: faker.random.locale,
	givenName: faker.name.firstName,
	familyName: faker.name.lastName,
	company: faker.company.companyName,
	business: faker.company.catchPhraseDescriptor,
	country: faker.address.country,
	tempUnit: () => "F" || "C",
	agreedToTermsAndConditions: faker.datatype.boolean,
	roles: () => ["admin"] || [],
}));

const wineDescription = defineEntity<WineDescriptionDTO>(() => ({
	__typename: () => "WineDescription",
	id: primaryKey(faker.datatype.uuid),
	grape: oneOf("grape", { nullable: true }),
	aoc: oneOf("aoc", { nullable: true }),
	region: oneOf("region", { nullable: true }),
	country: oneOf("country", { nullable: true }),
	domain: oneOf("domain", { nullable: true }),
	classification: oneOf("classification", { nullable: true }),
}));

const scanAdvice = defineEntity<ScanAdviceDTO>(() => ({
	__typename: () => "ScanAdvice",
	id: primaryKey(faker.datatype.uuid),
	year: faker.datatype.number,
	description: oneOf("wineDescription", { nullable: true }),
	idealTemp: faker.datatype.number,
}));

const scan = defineEntity<ScanDTO>(() => ({
	__typename: () => "Scan",
	id: primaryKey(faker.datatype.uuid),
	image: faker.image.food,
	wineType: () => "white" || "red" || "pink" || "sparkling",
	advices: manyOf("scanAdvice", { nullable: true }),
	used: faker.datatype.boolean,
	owner: oneOf("user"),
}));

const wine = defineEntity<WineDTO>(() => ({
	__typename: () => "Wine",
	id: primaryKey(faker.datatype.uuid),
	type: () => "white" || "red" || "pink" || "sparkling",
	description: oneOf("wineDescription"),
	year: faker.datatype.number,
	idealTemp: faker.datatype.number,
	customTemp: faker.datatype.number,
	archived: faker.datatype.boolean,
	amount: faker.datatype.number,
	image: faker.image.food,
	rating: faker.datatype.number,
	comment: faker.lorem.sentence,
	owner: oneOf("user"),
	scan: oneOf("scan"),
}));

const scanAdviceRequest = defineEntity<ScanAdviceRequestDTO>(() => ({
	__typename: () => "ScanAdviceRequest",
	id: primaryKey(faker.datatype.uuid),
	status: () => "new" || "finished" || "deleted",
	comment: faker.lorem.sentence,
	createdAt: faker.datatype.datetime,
	scan: oneOf("scan"),
	wineResult: oneOf("wine", { nullable: true }),
	owner: oneOf("user"),
	archived: faker.datatype.boolean,
	internalComment: faker.lorem.sentence,
	type: () => AdviceRequestTypeDTO.ScanDTO,
}));

const buyingAdviceRequest = defineEntity<BuyingAdviceRequestDTO>(() => ({
	__typename: () => "BuyingAdviceRequest",
	id: primaryKey(faker.datatype.uuid),
	status: () => "new" || "finished" || "deleted",
	createdAt: faker.datatype.datetime,
	wine: oneOf("wine", { nullable: true }),
	owner: oneOf("user"),
	archived: faker.datatype.boolean,
	type: () => AdviceRequestTypeDTO.BuyingDTO,
	deliveryAddress: {
		__typename: () => "DeliveryAddress",
		givenName: faker.name.firstName,
		familyName: faker.name.lastName,
		address: {
			__typename: () => "Address",
			street: faker.address.streetName,
			houseNumber: faker.datatype.number,
			postalCode: faker.address.zipCode,
			city: faker.address.city,
			country: faker.address.country,
		},
		phoneNumber: faker.phone.phoneNumber,
		email: faker.internet.email,
	},
}));

const faq = defineEntity<FaqDTO>(() => ({
	__typename: () => "Faq",
	id: primaryKey(faker.datatype.uuid),
	createdAt: faker.datatype.datetime,
	title: faker.lorem.words,
	titles: () => [],
	description: faker.lorem.sentence,
	descriptions: () => [],
}));

const food = defineEntity<FoodDTO>(() => ({
	__typename: () => "Food",
	id: primaryKey(faker.datatype.uuid),
	course: () => faker.random.objectElement(FoodCourseDTO),
	kitchen: () => faker.random.objectElement(FoodKitchenDTO),
	ingredients: () => faker.random.objectElement(FoodIngredientsDTO),
	wineStyle: () => faker.random.objectElement(WineStyleDTO),
	title: faker.lorem.words,
	titles: () => [],
	description: faker.lorem.paragraph,
	descriptions: () => [],
}));

export const dictionary = {
	domain,
	region,
	aoc,
	country,
	classification,
	grape,
	correction,
	exception,
	user,
	scanAdvice,
	scanAdviceRequest,
	buyingAdviceRequest,
	scan,
	wine,
	faq,
	food,
	mainGrape,
	wineDescription,
	domainSuggestion,
	faqSuggestion,
	conversation,
	adviceRequestSuggestionSource,
	conversationSuggestionSource,
};
