import { Query, UILogic } from "@aptus/frontend-core";
import { AutoCompleteSelectProps, getDropdownOptions } from "components/form/autoCompleteSelect/autoCompleteSelect";
import { useClassifications } from "domains";
import { ClassificationsAPIDTO } from "hooks/classifications/classificationMapper";
import { Classification } from "hooks/classifications/models/classification";
import { ClassificationFilterDTO } from "models/schema";
import { useGetClassifications } from "./models/getClassifications";

export interface ClassificationSelectProps extends Omit<AutoCompleteSelectProps, "dropdownProps"> {
	defaultValue?: string;
}

type Result = AutoCompleteSelectProps;

interface QueryProps {
	id?: Classification["id"];
}

const useGetFilteredClassifications: Query<QueryProps, ClassificationsAPIDTO> = ({ id }) => {
	const filter: ClassificationFilterDTO = {
		OR: [
			{ archived: { equals: false } },
			...id ? [{ id: { equals: id } }] : [],
		],
	};

	return useGetClassifications({ variables: { filter } });
};

export const useClassificationSelect: UILogic<ClassificationSelectProps, Result> = (props) => {
	const { classifications, isLoading } = useClassifications(useGetFilteredClassifications({ id: props.defaultValue }));

	const options = {
		loading: isLoading,
		options: getDropdownOptions(classifications),
	};

	return {
		...props,
		dropdownProps: options,
	};
};
