/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageComponent } from "@aptus/frontend-core";
import { useProfile } from "domains";
import { PasswordLoginInputDTO } from "models/schema";
import LoginForm from "pages/loginPage/loginForm/loginForm";
import ResetPasswordAnonymouslyForm from "pages/loginPage/resetPasswordAnonymously/resetPasswordAnonymouslyForm";
import { useState } from "react";

import LoginPageStyle from "./LoginPageStyle";

const LoginPage: PageComponent = () => {
	const [showPasswordReset, setshowPasswordReset] = useState(false);

	const { login, requestPasswordResetMail } = useProfile();

	const initialPasswordLoginValues: PasswordLoginInputDTO = {
		email: "",
		password: "",
	};

	const initialPasswordResetValues = {
		email: "",
	};

	const getToggleButtonText = () => {
		if (!showPasswordReset) {
			return "Forgot your password?";
		}

		return "Back to login";
	};

	const toggleShowPasswordResetButton = () => {
		setshowPasswordReset(!showPasswordReset);
	};

	return (
		<LoginPageStyle>
			{!showPasswordReset && <LoginForm initialValues={initialPasswordLoginValues} submit={login} />}
			{showPasswordReset && <ResetPasswordAnonymouslyForm initialValues={initialPasswordResetValues} submit={(values) => requestPasswordResetMail(values.email)} />}

			<a role="button" style={{ textAlign: "center" }} onClick={toggleShowPasswordResetButton}>{getToggleButtonText()}</a>
		</LoginPageStyle>
	);
};

export default LoginPage;
