import { drop, factory } from "@mswjs/data";
import faker from "faker";
import { populateClassificationCollection } from "./collections/classification";
import { populateDomainCollection } from "./collections/domain";
import { populateRegionCollection } from "./collections/region";
import { populateCountryCollection } from "./collections/country";
import { dictionary } from "./dictionary";
import { populateAocCollection } from "./collections/aoc";
import { populateGrapeCollection } from "./collections/grape";
import { populateCorrectionCollection } from "./collections/correction";
import { populateExceptionCollection } from "./collections/exception";
import { populateAdviceRequestsCollection } from "./collections/adviceRequest";
import { populateFaqCollection } from "./collections/faq";
import { populateFoodCollection } from "./collections/food";
import { populateSuggestionsCollection } from "./collections/suggestion";
import { pupulateConversationsCollection } from "./collections/conversation";

export const db = factory(dictionary);
export type Database = typeof db;

export const rollbackDatabase = () => {
	drop(db);
	faker.seed(123);

	populateGrapeCollection(db);
	populateCountryCollection(db);
	populateDomainCollection(db);
	populateClassificationCollection(db);
	populateRegionCollection(db);
	populateAocCollection(db);
	populateCorrectionCollection(db);
	populateExceptionCollection(db);
	populateAdviceRequestsCollection(db);
	populateFaqCollection(db);
	populateFoodCollection(db);
	populateSuggestionsCollection(db);
	pupulateConversationsCollection(db);
};

rollbackDatabase();
