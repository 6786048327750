import { PageComponent } from "@aptus/frontend-core";
import { PageHeader } from "components/pageHeader/pageHeader";
import { ContentStyle, HeaderContainer } from "components/styles";
import BasePage from "pages/basePage/basePage";
import { ConversationsContent } from "./components/conversationsContent/conversationsContent";
import { ConversationsFilterBar } from "./components/conversationsFilterBar/conversationsFilterBar";

export const ConversationsPage: PageComponent = () => (
	<BasePage>
		<HeaderContainer>
			<PageHeader title="conversations.page.title" />
			<ConversationsFilterBar />
		</HeaderContainer>
		<ContentStyle>
			<ConversationsContent />
		</ContentStyle>
	</BasePage>
);
