/* eslint-disable */
import * as Types from '../../../models/schema';

import { FaqFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateFaqVariablesDTO = Types.Exact<{
  data: Types.FaqCreateInputDTO;
}>;


export type CreateFaqDTO = (
  { __typename?: 'Mutation' }
  & { createOneFaq: (
    { __typename?: 'Faq' }
    & FaqFragmentDTO
  ) }
);


export const CreateFaqDocumentDTO = gql`
    mutation createFaq($data: FaqCreateInput!) {
  createOneFaq(data: $data) {
    ...faqFragment
  }
}
    ${FaqFragmentDTO}`;
export type CreateFaqMutationFnDTO = Apollo.MutationFunction<CreateFaqDTO, CreateFaqVariablesDTO>;

/**
 * __useCreateFaq__
 *
 * To run a mutation, you first call `useCreateFaq` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaq` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaq, { data, loading, error }] = useCreateFaq({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFaq(baseOptions?: Apollo.MutationHookOptions<CreateFaqDTO, CreateFaqVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFaqDTO, CreateFaqVariablesDTO>(CreateFaqDocumentDTO, options);
      }
export type CreateFaqHookResult = ReturnType<typeof useCreateFaq>;
export type CreateFaqMutationResultDTO = Apollo.MutationResult<CreateFaqDTO>;
export type CreateFaqMutationOptionsDTO = Apollo.BaseMutationOptions<CreateFaqDTO, CreateFaqVariablesDTO>;