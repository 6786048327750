import { UIComponent } from "@aptus/frontend-core";
import { endOfDay, startOfDay } from "date-fns";
import { useAdviceRequests, useRoutes, useTranslations } from "domains";
import { AdviceRequestStatusDTO, AdviceRequestTypeDTO } from "models/schema";
import { useGetAdviceRequests } from "pages/homePage/models/getAdviceRequests";
import React from "react";
import { RequestCard } from "../requestCard/requestCard";
import { RequestsContentStyle, RequestsGridStyle } from "./requestsContentStyle";

export const RequestsContent: UIComponent = () => {
	const { currentQueryString: { type, search, language, date, status } } = useRoutes();
	const { t } = useTranslations();
	const { adviceRequests } = useAdviceRequests(useGetAdviceRequests({ variables: {
		filter: {
			type: type ? { in: (Array.isArray(type) ? type : [type]) as AdviceRequestTypeDTO[] || [] } : undefined,
			status: status ? { equals: status as AdviceRequestStatusDTO } : undefined,
			createdAt: date
				? { gte: startOfDay(new Date(date)).toISOString(), lte: endOfDay(new Date(date)).toISOString() }
				: undefined,
			owner: (language || search) ? {
				language: language ? { equals: language } : undefined,
				OR: search ? [
					{ givenName: { contains: search } },
					{ familyName: { contains: search } },
				] : undefined,
			} : undefined,
		},
	} }));

	const newRequests = React.useMemo(() => adviceRequests.filter((ar) => ar.status === AdviceRequestStatusDTO.NewDTO), [adviceRequests]);
	const finishedRequests = React.useMemo(() => adviceRequests.filter((ar) => ar.status === AdviceRequestStatusDTO.FinishedDTO), [adviceRequests]);

	return (
		<RequestsContentStyle>
			<h3>{t("requests.open")}</h3>
			{!newRequests.length && <p>{t("requests.noOpen")}</p>}
			<RequestsGridStyle>
				{newRequests.map((ar) => <RequestCard {...ar} />)}
			</RequestsGridStyle>
			<h3>{t("requests.handled")}</h3>
			{!finishedRequests.length && <p>{t("requests.noFinished")}</p>}
			<RequestsGridStyle>
				{finishedRequests.map((ar) => <RequestCard {...ar} />)}
			</RequestsGridStyle>
		</RequestsContentStyle>
	);
};
