import { Database } from "mocks/db";

export const populateRegionCollection = (db: Database): void => {
	const countries = db.country.findMany({});
	db.region.create({ country: countries[0], id: "region-id" });
	db.region.create({ country: countries[1] });
	db.region.create({ country: countries[2] });
	db.region.create({});
	db.region.create({});
	db.region.create({});
	db.region.create({});
	db.region.create({});
	db.region.create({});
};
