import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { User } from "./models/user";
import { CreateUserInput, DeleteUserInput, SaveUserInput, UpdateUserInput } from "./models/userInput";

export type UsersAPI = InternalAPI<User[] | number>;

export type UpdateUserMutation = Mutation<UpdateUserInput, User>;
export type CreateUserMutation = Mutation<CreateUserInput, User>;
export type DeleteUserMutation = Mutation<DeleteUserInput, Success>;

interface Props {
	API: UsersAPI;
	defaultUser: User;
	updateMutation: UpdateUserMutation;
	createMutation: CreateUserMutation;
	deleteMutation: DeleteUserMutation;
}

interface Result {
	user: User;
	users: User[];
	count: number;
	isLoading: boolean;
	error?: Error;
	save: (input: SaveUserInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useUsersUseCases: UseCases<Props, Result> = ({ API, defaultUser, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveUserInput): input is UpdateUserInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("userHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("userHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("userHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		user: Array.isArray(API.data) && API.data.length ? API.data[0] : defaultUser,
		users: Array.isArray(API.data) ? API.data : [],
		isLoading: API.isLoading || isLoading,
		count: !Array.isArray(API.data) ? API.data : 0,
		save,
		remove,
	};
};
