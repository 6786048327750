import { UILogic } from "@aptus/frontend-core";
import { useAocs, useRoutes } from "domains";
import { Aoc } from "hooks/aocs/models/aoc";
import { Country } from "hooks/countries/models/country";
import { Domain } from "hooks/domains/models/domain";
import { Exception } from "hooks/exceptions/models/exception";
import { SaveExceptionInput } from "hooks/exceptions/models/exceptionInput";
import { Region } from "hooks/regions/models/region";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGetAocById } from "../domainForm/models/getAocById";
import { UseFormTypes } from "../useFormTypes";

interface Props {
	initialValues?: Exception;
	submit: (values: SaveExceptionInput) => void;
	remove: (id: string) => void;
}

interface Result extends UseFormTypes<Exception> {
	handleDelete: (id: string) => void;
	selectedCountry?: Country["id"];
	selectedRegion?: Region["id"];
	selectedAoc?: Aoc["id"];
	selectedDomain?: Domain["id"];
	isDisabled?: boolean;
}

export const useExceptionForm: UILogic<Props, Result> = ({ initialValues, submit, remove }) => {
	const { currentQueryString: { selected, create }, updateQueryString } = useRoutes();
	const [aocId, setAocId] = useState(initialValues?.aoc);

	const defaultValues = {
		...initialValues,
		id: selected,
	};

	const { register, handleSubmit, control, reset, watch, setValue, formState: { errors } } = useForm<Exception>({ defaultValues });

	const country = watch("country");
	const region = watch("region");
	const domain = watch("domain");

	const { aoc } = useAocs(useGetAocById({ variables: { id: aocId || "" }, skip: !aocId }));

	useEffect(() => {
		reset(defaultValues);
	}, [create, initialValues]);

	const handleFormSubmit = (values: Exception): void => {
		submit({
			...values,
			temperatures: values.temperatures.map((temp) => ({ ...temp, temperature: temp.temperature ? Number(temp.temperature) : undefined })),
		});
	};

	const handleDelete = (id: string) => {
		remove(id);
		updateQueryString({ selected: null, create: true });
	};

	useEffect(() => {
		setValue("region", undefined);
		if (!aoc) setValue("aoc", undefined);
	}, [country]);

	useEffect(() => {
		if (watch("aoc") && watch("region") !== aoc.region) setValue("region", aoc.region);
		if (watch("aoc") && watch("country") !== aoc.region) setValue("country", aoc.country || "");
	}, [aoc]);

	useEffect(() => {
		if (watch("aoc") !== aocId) {
			setAocId(watch("aoc"));
		}
	}, [watch("aoc")]);

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		handleDelete,
		selectedCountry: country,
		selectedRegion: region,
		selectedAoc: aoc.id,
		selectedDomain: domain,
		errors,
		isDisabled: !!watch("aoc"),
	};
};
