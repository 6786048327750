import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { DomainDTO, QueryDTO } from "models/schema";
import { CreateDomainMutationFnDTO } from "./models/createDomain";
import { DeleteDomainMutationFnDTO } from "./models/deleteDomain";
import { Domain } from "./models/domain";
import { UpdateDomainMutationFnDTO } from "./models/updateDomain";
import { CreateDomainMutation, DeleteDomainMutation, DomainsAPI, UpdateDomainMutation } from "./useDomainsUseCases";

export type DomainsAPIDTO = ApolloAPI<QueryDTO, "domains" | "domain">;

interface Mapper {
	toAPI: ToAPI<DomainsAPIDTO, DomainsAPI>;
	toUpdateMutation: ToInternal<UpdateDomainMutationFnDTO, UpdateDomainMutation>;
	toCreateMutation: ToInternal<CreateDomainMutationFnDTO, CreateDomainMutation>;
	toDeleteMutation: ToInternal<DeleteDomainMutationFnDTO, DeleteDomainMutation>;
	toDomain: ToInternal<DeepPartial<DomainDTO>, Domain>;
}

export class DomainMapper implements Mapper {
	public toDomain: Mapper["toDomain"] = (domain) => ({
		id: domain.id || "",
		country: domain.country?.id || "",
		region: domain.region?.id,
		aoc: domain.aoc?.id,
		name: domain.name || "",
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: DomainsAPIDTO["data"]): DeepPartial<DomainDTO[]> => {
			if (data?.domain) {
				return [data.domain];
			}

			if (data?.domains) {
				return data.domains;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toDomain),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateDomainMutationFnDTO, UpdateDomainMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						name: input.name,
						country: input.country,
						aoc: input.aoc,
						region: input.region,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneDomain ? this.toDomain(output.data.updateOneDomain) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateDomainMutationFnDTO, CreateDomainMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						name: input.name,
						country: input.country,
						aoc: input.aoc,
						region: input.region,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneDomain ? this.toDomain(output.data.createOneDomain) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteDomainMutationFnDTO, DeleteDomainMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getDomains"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: !!output?.data?.updateOneDomain.id }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
