import { PageComponent } from "@aptus/frontend-core";
import { PageHeader } from "components/pageHeader/pageHeader";
import BasePage from "pages/basePage/basePage";
import { DatabaseContent } from "./components/databaseContent/databaseContent";
import { DatabaseFilterBar } from "./components/databaseFilterBar/databaseFilterBar";
import { HeaderContainer } from "./databasePageStyle";

const DatabasePage: PageComponent = () => (
	<BasePage>
		<HeaderContainer>
			<PageHeader title="database.page.title" />
			<DatabaseFilterBar />
		</HeaderContainer>
		<DatabaseContent />
	</BasePage>
);

export default DatabasePage;
