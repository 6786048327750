/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCountriesCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetCountriesCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'countriesCount'>
);


export const GetCountriesCountDocumentDTO = gql`
    query getCountriesCount($search: String) {
  countriesCount(filter: {archived: {equals: false}, name: {contains: $search}})
}
    `;

/**
 * __useGetCountriesCount__
 *
 * To run a query within a React component, call `useGetCountriesCount` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCountriesCount(baseOptions?: Apollo.QueryHookOptions<GetCountriesCountDTO, GetCountriesCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesCountDTO, GetCountriesCountVariablesDTO>(GetCountriesCountDocumentDTO, options);
      }
export function useGetCountriesCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesCountDTO, GetCountriesCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesCountDTO, GetCountriesCountVariablesDTO>(GetCountriesCountDocumentDTO, options);
        }
export type GetCountriesCountHookResult = ReturnType<typeof useGetCountriesCount>;
export type GetCountriesCountLazyQueryDTOHookResult = ReturnType<typeof useGetCountriesCountLazyQueryDTO>;
export type GetCountriesCountQueryResultDTO = Apollo.QueryResult<GetCountriesCountDTO, GetCountriesCountVariablesDTO>;