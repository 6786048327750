/* eslint-disable no-tabs */
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import result from "models/schema";

const cache = new InMemoryCache(result);

/**
 * The application is deployed using a "build-once-deploy-many" strategy.
 * This disallows us from setting the live environment variables in the traditional dotenv way
 * as those variables are set at build time and then used at runtime. Instead, we use the window
 * object in the browser itself to set the environment variables. This however does interfere with
 * our tests as the tests don't run in a browser environment and therefor cannot access the window object.
 * For this reason we use the traditional build time environment variables locally, but fallback to the
 * window object in the live environment.
 */
const httpLink = new HttpLink({
	uri: process.env.REACT_APP_API_URL || window?.appEnvironment?.API_URL,
	fetch: (...args) => fetch(...args),
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("token");
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const client = new ApolloClient({ cache, link: authLink.concat(httpLink) });

// All 3 properties must be set in order for the defaultOptions to work.
// https://github.com/apollographql/apollo-client/issues/2555#issuecomment-449871538
// client.defaultOptions = {
// 	watchQuery: {
// 		fetchPolicy: process.env.REACT_APP_ENVIRONMENT === "local" ? "network-only" : "cache-and-network",
// 		errorPolicy: "all",
// 	},
// 	query: {
// 		fetchPolicy: process.env.REACT_APP_ENVIRONMENT === "local" ? "network-only" : "cache-first",
// 		errorPolicy: "all",
// 	},
// 	mutate: {
// 		errorPolicy: "all",
// 	},
// };

export default client;
