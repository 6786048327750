import { UIComponent } from "@aptus/frontend-core";
import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "components/table/customPagination";
import { useConversations, useRoutes } from "domains";
import { useGetConversations } from "./models/getConversations";
import { useGetConversationsCount } from "./models/getConversationsCount";
import { useConversationsTable } from "./useConversationsTable";

export const ConversationsContent: UIComponent = () => {
	const { currentQueryString: { search }, goToConversationDetail } = useRoutes();

	const { count } = useConversations(useGetConversationsCount({
		variables: {
			filter: search
				? {
					OR: [
						{ owner: { familyName: { contains: search } } },
						{ owner: { givenName: { contains: search } } },
						{ owner: { email: { contains: search } } },
					],
				}
				: undefined,
		},
	}));

	const { columns, skip, pageSize, page, onChangePage, onChangeSort, sort } = useConversationsTable({});

	const { conversations, isLoading } = useConversations(useGetConversations({ variables: {
		filter: search ? {
			OR: [
				{ owner: { familyName: { contains: search } } },
				{ owner: { givenName: { contains: search } } },
				{ owner: { email: { contains: search } } },
			],
		} : undefined,
		paging: {
			offset: skip,
			limit: pageSize,
		},
		sort,
	} }));

	return (
		<div style={{ width: "100%" }}>
			<DataGrid
				style={{ backgroundColor: "white" }}
				loading={isLoading}
				onSortModelChange={onChangeSort}
				onPageChange={onChangePage}
				pageSize={pageSize}
				page={page}
				disableColumnSelector
				disableColumnFilter
				autoHeight
				paginationMode="server"
				rowCount={count}
				rowHeight={80}
				columns={columns}
				rows={conversations}
				onRowClick={(params) => goToConversationDetail(params.id as string)}
				components={{
					Pagination: CustomPagination,
				}}
			/>
		</div>
	);
};
