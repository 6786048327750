/* eslint-disable */
import * as Types from '../../../models/schema';

import { CountryFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateCountryVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.CountryUpdateInputDTO>;
}>;


export type UpdateCountryDTO = (
  { __typename?: 'Mutation' }
  & { updateOneCountry: (
    { __typename?: 'Country' }
    & CountryFragmentDTO
  ) }
);


export const UpdateCountryDocumentDTO = gql`
    mutation updateCountry($id: String!, $data: CountryUpdateInput) {
  updateOneCountry(id: $id, data: $data) {
    ...countryFragment
  }
}
    ${CountryFragmentDTO}`;
export type UpdateCountryMutationFnDTO = Apollo.MutationFunction<UpdateCountryDTO, UpdateCountryVariablesDTO>;

/**
 * __useUpdateCountry__
 *
 * To run a mutation, you first call `useUpdateCountry` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCountry` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCountry, { data, loading, error }] = useUpdateCountry({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCountry(baseOptions?: Apollo.MutationHookOptions<UpdateCountryDTO, UpdateCountryVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCountryDTO, UpdateCountryVariablesDTO>(UpdateCountryDocumentDTO, options);
      }
export type UpdateCountryHookResult = ReturnType<typeof useUpdateCountry>;
export type UpdateCountryMutationResultDTO = Apollo.MutationResult<UpdateCountryDTO>;
export type UpdateCountryMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateCountryDTO, UpdateCountryVariablesDTO>;