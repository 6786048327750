import { UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import { Grape } from "hooks/grapes/models/grape";
import { SaveGrapeInput } from "hooks/grapes/models/grapeInput";
import { MainGrape } from "hooks/mainGrapes/models/mainGrape";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UseFormTypes } from "../useFormTypes";

interface Props {
	initialValues?: Grape;
	submit: (values: SaveGrapeInput) => void;
	remove: (id: string) => void;
}

interface Result extends UseFormTypes<Grape> {
	handleDelete: (id: string) => void;
	selectedMainGrape?: MainGrape["id"];
}

export const useGrapeForm: UILogic<Props, Result> = ({ initialValues, submit, remove }) => {
	const { currentQueryString: { selected, create }, updateQueryString } = useRoutes();

	const defaultValues = {
		...initialValues,
		id: selected,
	};

	const { register, handleSubmit, control, reset, watch, formState: { errors } } = useForm<Grape>({ defaultValues });

	const selectedMainGrape = watch("mainGrape");

	useEffect(() => {
		reset(defaultValues);
	}, [create, initialValues]);

	const handleFormSubmit = (values: Grape): void => {
		submit({
			...values,
			temperatures: values.temperatures.map((temp) => ({ ...temp, temperature: temp.temperature ? Number(temp.temperature) : undefined })),
		});
	};

	const handleDelete = (id: string) => {
		remove(id);
		updateQueryString({ selected: null, create: true });
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		handleDelete,
		selectedMainGrape,
		errors,
	};
};
