import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Spinner from "react-loader-spinner";

import { useTranslations } from "domains";
import LoaderStyle from "./loaderStyle";

const Loader: React.FunctionComponent = () => {
	const { t } = useTranslations();

	return (
		<LoaderStyle>
			<Spinner
				type="Rings"
				color="#111"
				height={50}
				width={50}
			/>
			<h3>{t("pleaseWait")}</h3>
		</LoaderStyle>
	);
};

export default Loader;
