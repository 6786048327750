import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { CreateClassificationInput, DeleteClassificationInput, SaveClassificationInput, UpdateClassificationInput } from "hooks/domains/models/classificationInput";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Classification } from "./models/classification";

export type ClassificationsAPI = InternalAPI<Classification[]>;

export type UpdateClassificationMutation = Mutation<UpdateClassificationInput, Classification>;
export type CreateClassificationMutation = Mutation<CreateClassificationInput, Classification>;
export type DeleteClassificationMutation = Mutation<DeleteClassificationInput, Success>;

interface Props {
	API: ClassificationsAPI;
	defaultClassification: Classification;
	updateMutation: UpdateClassificationMutation;
	createMutation: CreateClassificationMutation;
	deleteMutation: DeleteClassificationMutation;
}

interface Result {
	classification: Classification;
	classifications: Classification[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveClassificationInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useClassificationsUseCases: UseCases<Props, Result> = ({ API, defaultClassification, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveClassificationInput): input is UpdateClassificationInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("classificationHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("classificationHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("classificationHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		classification: API.data[0] || defaultClassification,
		classifications: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
