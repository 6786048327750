import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useProfile, useRoutes, useTranslations } from "domains";
import { Routes } from "hooks/routes/models/Routes";
import { TranslationKey } from "hooks/translations/models/translate";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

/* Update to new design and split into components */

const drawerWidth = 240;

const BasePage: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
	const { t } = useTranslations();
	const { routes, routeNames } = useRoutes();
	const { logout } = useProfile();

	const menuItems = Object.keys(routes).filter((key) => ["home", "conversations", "database", "users", "profile"].includes(key));

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
					<Box>
						<Typography variant="h6" noWrap>
							QelviQ-HuB
						</Typography>
						<Typography variant="body2" noWrap>
							Proud to serve our wine community
						</Typography>
					</Box>
					<Button onClick={logout} variant="text" sx={{ color: "#fff" }}>
						Logout
					</Button>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
				}}
			>
				<Toolbar />
				<Box sx={{ overflow: "auto" }}>
					<List>
						{menuItems.map((key, index) => (
							<Link
								style={{
									display: "flex", textDecoration: "none", color: "black", height: 70,
								}}
								key={key}
								to={routes[key as keyof Routes]}
							>
								<ListItem button>
									<ListItemIcon>
										{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
									</ListItemIcon>
									<ListItemText primary={t(routeNames[key as keyof Routes] as TranslationKey)} />
								</ListItem>
							</Link>
						))}
					</List>
				</Box>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, height: "100vh", backgroundColor: "#F5F5F5" }}>
				<Toolbar />
				{children}
			</Box>
		</Box>
	);
};

export default BasePage;
