/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteFaqVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteFaqDTO = (
  { __typename?: 'Mutation' }
  & { deleteOneFaq: (
    { __typename?: 'Success' }
    & Pick<Types.SuccessDTO, 'success'>
  ) }
);


export const DeleteFaqDocumentDTO = gql`
    mutation deleteFaq($id: String!) {
  deleteOneFaq(id: $id) {
    success
  }
}
    `;
export type DeleteFaqMutationFnDTO = Apollo.MutationFunction<DeleteFaqDTO, DeleteFaqVariablesDTO>;

/**
 * __useDeleteFaq__
 *
 * To run a mutation, you first call `useDeleteFaq` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaq` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaq, { data, loading, error }] = useDeleteFaq({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFaq(baseOptions?: Apollo.MutationHookOptions<DeleteFaqDTO, DeleteFaqVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFaqDTO, DeleteFaqVariablesDTO>(DeleteFaqDocumentDTO, options);
      }
export type DeleteFaqHookResult = ReturnType<typeof useDeleteFaq>;
export type DeleteFaqMutationResultDTO = Apollo.MutationResult<DeleteFaqDTO>;
export type DeleteFaqMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteFaqDTO, DeleteFaqVariablesDTO>;