/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteExceptionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteExceptionDTO = (
  { __typename?: 'Mutation' }
  & { deleteOneException: (
    { __typename?: 'Success' }
    & Pick<Types.SuccessDTO, 'success'>
  ) }
);


export const DeleteExceptionDocumentDTO = gql`
    mutation deleteException($id: String!) {
  deleteOneException(id: $id) {
    success
  }
}
    `;
export type DeleteExceptionMutationFnDTO = Apollo.MutationFunction<DeleteExceptionDTO, DeleteExceptionVariablesDTO>;

/**
 * __useDeleteException__
 *
 * To run a mutation, you first call `useDeleteException` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteException` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteException, { data, loading, error }] = useDeleteException({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteException(baseOptions?: Apollo.MutationHookOptions<DeleteExceptionDTO, DeleteExceptionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExceptionDTO, DeleteExceptionVariablesDTO>(DeleteExceptionDocumentDTO, options);
      }
export type DeleteExceptionHookResult = ReturnType<typeof useDeleteException>;
export type DeleteExceptionMutationResultDTO = Apollo.MutationResult<DeleteExceptionDTO>;
export type DeleteExceptionMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteExceptionDTO, DeleteExceptionVariablesDTO>;