/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteUserVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteUserDTO = (
  { __typename?: 'Mutation' }
  & { deleteOneUser: (
    { __typename?: 'Success' }
    & Pick<Types.SuccessDTO, 'success'>
  ) }
);


export const DeleteUserDocumentDTO = gql`
    mutation deleteUser($id: String!) {
  deleteOneUser(id: $id) {
    success
  }
}
    `;
export type DeleteUserMutationFnDTO = Apollo.MutationFunction<DeleteUserDTO, DeleteUserVariablesDTO>;

/**
 * __useDeleteUser__
 *
 * To run a mutation, you first call `useDeleteUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUser, { data, loading, error }] = useDeleteUser({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUser(baseOptions?: Apollo.MutationHookOptions<DeleteUserDTO, DeleteUserVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserDTO, DeleteUserVariablesDTO>(DeleteUserDocumentDTO, options);
      }
export type DeleteUserHookResult = ReturnType<typeof useDeleteUser>;
export type DeleteUserMutationResultDTO = Apollo.MutationResult<DeleteUserDTO>;
export type DeleteUserMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteUserDTO, DeleteUserVariablesDTO>;