/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetClassificationsVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.ClassificationFilterDTO>;
}>;


export type GetClassificationsDTO = (
  { __typename?: 'Query' }
  & { classifications: Array<(
    { __typename?: 'Classification' }
    & Pick<Types.ClassificationDTO, 'id' | 'name'>
  )> }
);


export const GetClassificationsDocumentDTO = gql`
    query getClassifications($filter: ClassificationFilter) {
  classifications(filter: $filter, sort: [{field: "name", order: ASC}]) {
    id
    name
  }
}
    `;

/**
 * __useGetClassifications__
 *
 * To run a query within a React component, call `useGetClassifications` and pass it any options that fit your needs.
 * When your component renders, `useGetClassifications` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassifications({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetClassifications(baseOptions?: Apollo.QueryHookOptions<GetClassificationsDTO, GetClassificationsVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassificationsDTO, GetClassificationsVariablesDTO>(GetClassificationsDocumentDTO, options);
      }
export function useGetClassificationsLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetClassificationsDTO, GetClassificationsVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassificationsDTO, GetClassificationsVariablesDTO>(GetClassificationsDocumentDTO, options);
        }
export type GetClassificationsHookResult = ReturnType<typeof useGetClassifications>;
export type GetClassificationsLazyQueryDTOHookResult = ReturnType<typeof useGetClassificationsLazyQueryDTO>;
export type GetClassificationsQueryResultDTO = Apollo.QueryResult<GetClassificationsDTO, GetClassificationsVariablesDTO>;