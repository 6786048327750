import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { AocDTO, LanguageDTO, QueryDTO, WineTypeDTO } from "models/schema";
import { toAPIMultiLanguage, toFormMultiLanguage } from "utils/MLtoFMLI";
import { Aoc } from "./models/aoc";
import { CreateAocMutationFnDTO } from "./models/createAoc";
import { DeleteAocMutationFnDTO } from "./models/deleteAoc";
import { UpdateAocMutationFnDTO } from "./models/updateAoc";
import { AocsAPI, CreateAocMutation, DeleteAocMutation, UpdateAocMutation } from "./useAocsUseCases";

export type AocsAPIDTO = ApolloAPI<QueryDTO, "aocs" | "aoc">;

interface Mapper {
	toAPI: ToAPI<AocsAPIDTO, AocsAPI>;
	toUpdateMutation: ToInternal<UpdateAocMutationFnDTO, UpdateAocMutation>;
	toCreateMutation: ToInternal<CreateAocMutationFnDTO, CreateAocMutation>;
	toDeleteMutation: ToInternal<DeleteAocMutationFnDTO, DeleteAocMutation>;
	toAoc: ToInternal<DeepPartial<AocDTO>, Aoc>;
}

export class AocMapper implements Mapper {
	public toAoc: Mapper["toAoc"] = (aoc) => ({
		id: aoc.id || "",
		name: aoc.name || "",
		region: aoc.region?.id,
		country: aoc.country?.id || "",
		temperatures: (aoc.temperatures || []).map((temp) => (
			{
				descriptions: toFormMultiLanguage(temp.temperatureDescriptions?.map((td) => ({ language: td.language || LanguageDTO.EnDTO, text: td.text || "" })) || []),
				type: temp.type || WineTypeDTO.RedDTO,
				mainGrape: temp.mainGrape?.id || "",
				temperature: temp.temperature ? Number(temp.temperature) : undefined,
			})),
		archived: aoc.archived || false,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: AocsAPIDTO["data"]): DeepPartial<AocDTO[]> => {
			if (data?.aoc) {
				return [data.aoc];
			}

			if (data?.aocs) {
				return data.aocs;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toAoc),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateAocMutationFnDTO, UpdateAocMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						name: input.name,
						region: input.region,
						country: input.country,
						temperatures: input.temperatures.map((temp) => (
							{
								type: temp.type,
								temperature: temp.temperature,
								mainGrape: temp.mainGrape,
								temperatureDescriptions: toAPIMultiLanguage(temp.descriptions || {}),
							}
						)),
						archived: input.archived,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneAoc ? this.toAoc(output.data.updateOneAoc) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateAocMutationFnDTO, CreateAocMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						name: input.name,
						region: input.region,
						country: input.country,
						temperatures: input.temperatures.map((temp) => (
							{
								type: temp.type,
								temperature: temp.temperature,
								mainGrape: temp.mainGrape,
								temperatureDescriptions: toAPIMultiLanguage(temp.descriptions || {}),
							}
						)),
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneAoc ? this.toAoc(output.data.createOneAoc) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteAocMutationFnDTO, DeleteAocMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getAocs"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: !!output?.data?.updateOneAoc?.id }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
