/* eslint-disable */
import * as Types from '../../../models/schema';

import { WineFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateWineVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.WineUpdateInputDTO;
}>;


export type UpdateWineDTO = (
  { __typename?: 'Mutation' }
  & { updateOneWine: (
    { __typename?: 'Wine' }
    & WineFragmentDTO
  ) }
);


export const UpdateWineDocumentDTO = gql`
    mutation updateWine($id: String!, $data: WineUpdateInput!) {
  updateOneWine(id: $id, data: $data) {
    ...wineFragment
  }
}
    ${WineFragmentDTO}`;
export type UpdateWineMutationFnDTO = Apollo.MutationFunction<UpdateWineDTO, UpdateWineVariablesDTO>;

/**
 * __useUpdateWine__
 *
 * To run a mutation, you first call `useUpdateWine` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWine` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWine, { data, loading, error }] = useUpdateWine({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWine(baseOptions?: Apollo.MutationHookOptions<UpdateWineDTO, UpdateWineVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWineDTO, UpdateWineVariablesDTO>(UpdateWineDocumentDTO, options);
      }
export type UpdateWineHookResult = ReturnType<typeof useUpdateWine>;
export type UpdateWineMutationResultDTO = Apollo.MutationResult<UpdateWineDTO>;
export type UpdateWineMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateWineDTO, UpdateWineVariablesDTO>;