/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRegionsVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.RegionFilterDTO>;
}>;


export type GetRegionsDTO = (
  { __typename?: 'Query' }
  & { regions: Array<(
    { __typename?: 'Region' }
    & Pick<Types.RegionDTO, 'id' | 'name'>
  )> }
);


export const GetRegionsDocumentDTO = gql`
    query getRegions($filter: RegionFilter) {
  regions(filter: $filter, sort: [{field: "name", order: ASC}]) {
    id
    name
  }
}
    `;

/**
 * __useGetRegions__
 *
 * To run a query within a React component, call `useGetRegions` and pass it any options that fit your needs.
 * When your component renders, `useGetRegions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegions({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRegions(baseOptions?: Apollo.QueryHookOptions<GetRegionsDTO, GetRegionsVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionsDTO, GetRegionsVariablesDTO>(GetRegionsDocumentDTO, options);
      }
export function useGetRegionsLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsDTO, GetRegionsVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionsDTO, GetRegionsVariablesDTO>(GetRegionsDocumentDTO, options);
        }
export type GetRegionsHookResult = ReturnType<typeof useGetRegions>;
export type GetRegionsLazyQueryDTOHookResult = ReturnType<typeof useGetRegionsLazyQueryDTO>;
export type GetRegionsQueryResultDTO = Apollo.QueryResult<GetRegionsDTO, GetRegionsVariablesDTO>;