import { WineTypeDTO } from "models/schema";

export const getDefaultTemperatureForWineType = (wineType: WineTypeDTO) => {
	if (wineType === WineTypeDTO.WhiteDTO) {
		return 10;
	}

	if (wineType === WineTypeDTO.PinkDTO) {
		return 9;
	}

	if (wineType === WineTypeDTO.SparklingDTO) {
		return 8;
	}

	return 16;
};
