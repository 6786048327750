import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#0a5b76",
		},
		secondary: {
			main: "#f50057",
		},
	},
});

export default theme;
