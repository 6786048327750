import { UILogic } from "@aptus/frontend-core";
import { GridSortModel } from "@mui/x-data-grid";
import { useRoutes } from "domains";
import { SortDTO, SortOrderDTO } from "models/schema";

export interface SortResult {
	sort?: SortDTO | SortDTO[];
	onChangeSort: (sort: GridSortModel) => void;
}

type Props = {
	defaultSort?: SortDTO | SortDTO[];
};

export const useSort: UILogic<Props, SortResult> = ({ defaultSort }) => {
	const { updateQueryString, currentQueryString: { sort, sortField } } = useRoutes();

	const onChangeSort: SortResult["onChangeSort"] = (s) => {
		if (s[0]) {
			let qsSort: SortOrderDTO | null = null;
			if (s[0].sort === "asc") {
				qsSort = SortOrderDTO.AscDTO;
			}

			if (s[0].sort === "desc") {
				qsSort = SortOrderDTO.DescDTO;
			}

			updateQueryString({
				sortField: s[0].field,
				sort: qsSort,
			});
		}
	};

	return {
		sort: sortField && sort ? { order: sort, field: sortField } : defaultSort,
		onChangeSort,
	};
};
