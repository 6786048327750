import { UIComponent } from "@aptus/frontend-core";
import Search from "@mui/icons-material/Search";
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { FilterBarStyle } from "components/filterbar/filterBarStyle";
import { useRoutes, useTranslations } from "domains";

export const UsersFilterBar: UIComponent = () => {
	const { currentQueryString: { search }, updateQueryString } = useRoutes();
	const { t } = useTranslations();
	return (
		<FilterBarStyle>
			<FormControl style={{ flex: 3, marginRight: 20 }} variant="outlined">
				<InputLabel htmlFor="search">{t("users.filter.search")}</InputLabel>
				<OutlinedInput
					defaultValue={search}
					onChange={(e) => updateQueryString({ search: e.currentTarget.value })}
					id="search"
					endAdornment={(
						<InputAdornment position="end">
							<IconButton
								edge="end"
							>
								<Search />
							</IconButton>
						</InputAdornment>
					)}
					label={t("users.filter.search")}
				/>
			</FormControl>
			<Button onClick={() => updateQueryString({ create: true })} variant="contained">
				{t("users.filter.addNew")}
			</Button>
		</FilterBarStyle>
	);
};
