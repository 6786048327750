/* eslint-disable */
import * as Types from '../../../models/schema';

import { RegionFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateRegionVariablesDTO = Types.Exact<{
  data: Types.RegionCreateInputDTO;
}>;


export type CreateRegionDTO = (
  { __typename?: 'Mutation' }
  & { createOneRegion: (
    { __typename?: 'Region' }
    & RegionFragmentDTO
  ) }
);


export const CreateRegionDocumentDTO = gql`
    mutation createRegion($data: RegionCreateInput!) {
  createOneRegion(data: $data) {
    ...regionFragment
  }
}
    ${RegionFragmentDTO}`;
export type CreateRegionMutationFnDTO = Apollo.MutationFunction<CreateRegionDTO, CreateRegionVariablesDTO>;

/**
 * __useCreateRegion__
 *
 * To run a mutation, you first call `useCreateRegion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegion, { data, loading, error }] = useCreateRegion({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRegion(baseOptions?: Apollo.MutationHookOptions<CreateRegionDTO, CreateRegionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegionDTO, CreateRegionVariablesDTO>(CreateRegionDocumentDTO, options);
      }
export type CreateRegionHookResult = ReturnType<typeof useCreateRegion>;
export type CreateRegionMutationResultDTO = Apollo.MutationResult<CreateRegionDTO>;
export type CreateRegionMutationOptionsDTO = Apollo.BaseMutationOptions<CreateRegionDTO, CreateRegionVariablesDTO>;