import { Database } from "mocks/db";
import { LanguageDTO } from "models/schema";

export const populateFaqCollection = (db: Database) => {
	db.faq.create({
		title: "Is pink wine allowed?",
		titles: [
			{
				language: LanguageDTO.EnDTO,
				text: "Is pink wine allowed?",
			},
			{
				language: LanguageDTO.NlDTO,
				text: "Mag roze wijn?",
			},
		],
		description: "Yes, it's allowed!",
		descriptions: [
			{
				language: LanguageDTO.EnDTO,
				text: "Yes, it's allowed",
			},
			{
				language: LanguageDTO.NlDTO,
				text: "Ja, dat mag.",
			},
		],
	});

	db.faq.create({});
	db.faq.create({});
	db.faq.create({});
	db.faq.create({});
	db.faq.create({});
	db.faq.create({});
	db.faq.create({});
};
