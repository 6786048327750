/* eslint-disable */
import * as Types from '../../../models/schema';

import { DomainFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateDomainVariablesDTO = Types.Exact<{
  data: Types.DomainCreateInputDTO;
}>;


export type CreateDomainDTO = (
  { __typename?: 'Mutation' }
  & { createOneDomain: (
    { __typename?: 'Domain' }
    & DomainFragmentDTO
  ) }
);


export const CreateDomainDocumentDTO = gql`
    mutation createDomain($data: DomainCreateInput!) {
  createOneDomain(data: $data) {
    ...domainFragment
  }
}
    ${DomainFragmentDTO}`;
export type CreateDomainMutationFnDTO = Apollo.MutationFunction<CreateDomainDTO, CreateDomainVariablesDTO>;

/**
 * __useCreateDomain__
 *
 * To run a mutation, you first call `useCreateDomain` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDomain` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDomain, { data, loading, error }] = useCreateDomain({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDomain(baseOptions?: Apollo.MutationHookOptions<CreateDomainDTO, CreateDomainVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDomainDTO, CreateDomainVariablesDTO>(CreateDomainDocumentDTO, options);
      }
export type CreateDomainHookResult = ReturnType<typeof useCreateDomain>;
export type CreateDomainMutationResultDTO = Apollo.MutationResult<CreateDomainDTO>;
export type CreateDomainMutationOptionsDTO = Apollo.BaseMutationOptions<CreateDomainDTO, CreateDomainVariablesDTO>;