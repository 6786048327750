import { UIComponent } from "@aptus/frontend-core";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, FormControl } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useTranslations } from "domains";
import { FormProps } from "models/formProps";
import { PasswordLoginInputDTO } from "models/schema";
import { useForm } from "react-hook-form";

import sx from "./loginFormStyle";

type Props = FormProps<PasswordLoginInputDTO>;

/* Translate */

const LoginForm: UIComponent<Props> = ({ submit, initialValues }) => {
	const { register, handleSubmit } = useForm({ defaultValues: initialValues });
	const { t } = useTranslations();

	return (
		<Container component="main" maxWidth="xs">
			{/* <Snackbar open={showSnackBar} autoHideDuration={6000} onClose={handleClose}>
				{alert}
			</Snackbar> */}

			<CssBaseline />
			<Box sx={sx.paper}>
				<Avatar sx={sx.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Welcome back!
				</Typography>
				<form onSubmit={handleSubmit(submit)}>
					<FormControl {...register("email")} fullWidth sx={{ mb: 2 }} variant="filled">
						<TextField autoComplete="current-email" required type="email" variant="outlined" name="email" label={t("email")} />
					</FormControl>
					<FormControl {...register("password")} fullWidth sx={{ mb: 2 }} variant="filled">
						<TextField autoComplete="current-password" required variant="outlined" name="password" type="password" label={t("password")} />
					</FormControl>
					<Button type="submit" fullWidth variant="contained" color="primary" sx={sx.submit}>
						Login
					</Button>
				</form>
			</Box>
		</Container>
	);
};

export default LoginForm;
