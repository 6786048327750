/* eslint-disable react/destructuring-assignment */
import { UIComponent } from "@aptus/frontend-core";
import { Button, Checkbox, FormControl, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
import { AocSelect } from "components/form/items/AOCSelect/aocSelect";
import { ClassificationSelect } from "components/form/items/classificationSelect/classificationSelect";
import { CountrySelect } from "components/form/items/countrySelect/countrySelect";
import { DomainSelect } from "components/form/items/domainSelect/domainSelect";
import { GrapeSelect } from "components/form/items/grapeSelect/grapeSelect";
import { RegionSelect } from "components/form/items/regionSelect/regionSelect";
import { useTranslations } from "domains";
import { WineTypeDTO } from "models/schema";
import { Controller } from "react-hook-form";

import { LabelDataFormProps, useLabelDataForm } from "./useLabelDataForm";

export const LabelDataForm: UIComponent<LabelDataFormProps> = (props) => {
	const { isScanLabel } = props;
	const { t } = useTranslations();
	const {
		control,
		// domainNotFound,
		overwriteTemperature,
		selectedAoc,
		selectedRegion,
		selectedCountry,
		errors,
		suggestedTemperatures,
		handleFormSubmit,
		register,
		isDisabled,
	} = useLabelDataForm(props);

	return (
		<form onSubmit={handleFormSubmit}>
			<h4>{t("scanForm.step.1")}</h4>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<AocSelect
					error={!!errors?.aoc}
					helperText={errors?.aoc?.message}
					region={selectedRegion}
					country={selectedCountry}
					control={control}
					label={t("aoc")}
					name="aoc"
				/>
			</FormControl>
			<div className="flex">
				<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
					<CountrySelect
						error={!!errors?.country}
						helperText={errors?.country?.message}
						label={t("country")}
						name="country"
						control={control}
						disabled={isDisabled}
					/>
				</FormControl>
				<FormControl fullWidth sx={{ mb: 2 }}>
					<RegionSelect
						error={!!errors?.region}
						helperText={errors?.region?.message}
						country={selectedCountry}
						label={t("region")}
						control={control}
						name="region"
						disabled={isDisabled}
					/>
				</FormControl>
			</div>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<GrapeSelect
					error={!!errors?.grape}
					helperText={errors?.grape?.message}
					region={selectedRegion}
					control={control}
					name="grape"
					label={t("grape")}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<DomainSelect
					error={!!errors?.domain}
					helperText={errors?.domain?.message}
					country={selectedCountry}
					region={selectedRegion}
					aoc={selectedAoc}
					control={control}
					name="domain"
					label={t("domain")}
				/>
			</FormControl>
			{/*
				This functionality will come back eventually,
				form logic stays so just bringing back the form items will restore functionality
			*/}
			{/* <FormControlLabel
				control={(
					<Controller
						name="domainNotFound"
						control={control}
						render={({ field }) => (
							<Checkbox
								{...field}
								checked={field.value}
								onChange={(e) => field.onChange(e.target.checked)}
							/>
						)}
					/>
				)}
				label={t("notFoundInDatabase")}
			/>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					error={!!errors?.customDomain}
					disabled={!domainNotFound}
					{...register("customDomain", { required: !!domainNotFound })}
					fullWidth
					name="customDomain"
					id="suggested_domain"
					label="Suggested domain"
					variant="outlined"
				/>
				{!!errors?.customDomain && (
					<FormHelperText error style={{ display: "flex", justifyContent: "space-between", margin: "10px 0" }}>
						<span style={{ width: "100%" }}>{errors?.customDomain?.message}</span>
						<Button style={{ whiteSpace: "nowrap", minWidth: "max-content", marginLeft: 10 }} variant="outlined" color="error">
							<ReplayIcon />
							{" "}
							Try again
						</Button>
					</FormHelperText>
				)}
			</FormControl> */}
			<div className="flex">
				<ClassificationSelect control={control} label="Classification" name="classification" />
				<FormControl fullWidth sx={{ mb: 2, ml: 2 }}>
					<TextField error={!!errors?.year} helperText={errors?.year?.message} {...register("year")} type="number" fullWidth name="year" id="year" label="Year" variant="outlined" />
				</FormControl>
			</div>
			<FormControl fullWidth sx={{ mb: 2 }} variant="filled">
				<Controller
					name="wineType"
					control={control}
					render={({ field: { onChange, value } }) => (
						<Select error={!!errors?.country} value={value} onChange={(e) => onChange(e.target.value)} variant="outlined" name="wineType">
							{Object.values(WineTypeDTO).map((wt) => <MenuItem value={wt}>{t(wt)}</MenuItem>)}
						</Select>
					)}
				/>
			</FormControl>
			{isScanLabel && suggestedTemperatures && (
				<>
					{suggestedTemperatures.grapeTemperature ? <p className="temperature">{`Based on grape: ${suggestedTemperatures.grapeTemperature}˚C`}</p> : null}
					{suggestedTemperatures.aocTemperature ? <p className="temperature">{`Based on AOC: ${suggestedTemperatures.aocTemperature}˚C`}</p> : null}
					{suggestedTemperatures.regionTemperature ? <p className="temperature">{`Based on region: ${suggestedTemperatures.regionTemperature}˚C`}</p> : null}
					{suggestedTemperatures.countryTemperature ? <p className="temperature">{`Based on country: ${suggestedTemperatures.countryTemperature}˚C`}</p> : null}

					{suggestedTemperatures.suggestedTemperature ? <p style={{ marginTop: 10 }} className="mainTemperature">{`Suggested temperature: ${suggestedTemperatures.suggestedTemperature}˚C`}</p> : null}
				</>
			)}

			{isScanLabel && (
				<>
					<FormControlLabel
						control={(
							<Controller
								name="overwriteTemperature"
								control={control}
								render={({ field }) => (
									<Checkbox
										{...field}
										checked={field.value}
										onChange={(e) => field.onChange(e.target.checked)}
									/>
								)}
							/>
						)}
						label={t("overwrite")}
					/>
					<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
						<TextField error={!!errors?.idealTemp} helperText={errors?.idealTemp?.message} disabled={!overwriteTemperature} {...register("idealTemp", { required: !!overwriteTemperature })} type="number" fullWidth name="idealTemp" id="temperature" label="Overwrite temperature" variant="outlined" />
					</FormControl>
				</>
			)}
			<Button variant="contained" type="submit">{isScanLabel ? t("sendClientFeedback") : t("sendToSales")}</Button>
		</form>
	);
};
