import { UIComponent } from "@aptus/frontend-core";
import { Autocomplete, Button, Chip, Drawer, FormControl, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "components/loader/loader";
import { useRoutes, useTranslations, useUsers } from "domains";
import { User } from "hooks/users/models/user";
import { SaveUserInput } from "hooks/users/models/userInput";
import { RoleDTO } from "models/schema";
import BasePage from "pages/basePage/basePage";
import { Controller } from "react-hook-form";
import { useGetUser } from "./models/getUser";
import { useUserForm } from "./useUserForm";

interface UserFormProps {
	user: User;
	save: (input: SaveUserInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const UserForm: UIComponent<UserFormProps> = ({ user, save, remove }) => {
	const { handleFormSubmit, errors, register, control, handleDelete, cancel } = useUserForm({ initialValues: user, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form style={{ width: 700, marginTop: 100, padding: 20 }} onSubmit={handleFormSubmit}>
			<h2 style={{ marginBottom: 20 }}>{t(`user.${user.id ? "edit" : "add"}`)}</h2>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					{...register("email", { required: { value: true, message: "This is required." } })}
					error={!!errors?.email}
					helperText={errors?.email?.message}
					fullWidth
					name="email"
					id="email"
					label={t("userForm.email")}
					variant="outlined"
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2 }} style={{ display: "flex", flexDirection: "row" }}>
				<TextField
					sx={{ mr: 2 }}
					{...register("givenName", { required: { value: true, message: "This is required." } })}
					error={!!errors?.givenName}
					helperText={errors?.givenName?.message}
					fullWidth
					name="givenName"
					id="givenName"
					label={t("userForm.firstName")}
					variant="outlined"
				/>
				<TextField
					{...register("familyName", { required: { value: true, message: "This is required." } })}
					error={!!errors?.familyName}
					helperText={errors?.familyName?.message}
					name="familyName"
					fullWidth
					id="familyName"
					label={t("userForm.lastName")}
					variant="outlined"
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2 }}>
				<Controller
					control={control}
					name="roles"
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							onChange={(e, data) => onChange(data.filter((option) => Object.values(RoleDTO).includes(option as unknown as RoleDTO)))}
							disablePortal
							multiple
							freeSolo
							fullWidth
							options={Object.values(RoleDTO)}
							getOptionLabel={(v) => t(`userForm.role.${v}` as "userForm.role.userAdmin")}
							value={value || []}
							renderTags={(selected: string[], getTagProps) => (
								selected.map((v: string, index) => (
									<Chip label={t(`userForm.role.${v}` as "userForm.role.userAdmin")} {...getTagProps({ index })} />
								))
							)}
							renderInput={(params) => (
								<TextField
									label={t("userForm.roles")}
									{...params}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					)}
				/>
			</FormControl>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button onClick={cancel} variant="outlined" sx={{ marginRight: 2 }}>{t("cancel")}</Button>
				{user.id ? <Button onClick={() => handleDelete(user.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteUser")}</Button> : undefined}
				<Button type="submit" variant="contained">{user.id ? t("updateUser") : t("createUser")}</Button>
			</Box>
		</form>
	);
};

export const UserPage: UIComponent = () => {
	const { updateQueryString, currentQueryString } = useRoutes();
	const { user, isLoading, save, remove } = useUsers(useGetUser({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	let component = null;

	if (isLoading) {
		component = (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	if (!isLoading && user) {
		component = (
			<UserForm user={user} save={save} remove={remove} />
		);
	}

	return (
		<Drawer
			anchor="right"
			open={!!currentQueryString.create || !!currentQueryString.selected}
			onClose={() => updateQueryString({ create: null, selected: null })}
		>
			{component}
		</Drawer>
	);
};
