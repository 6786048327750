/* eslint-disable */
import * as Types from '../../../models/schema';

import { UserFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateUserVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.UserUpdateInputDTO>;
}>;


export type UpdateUserDTO = (
  { __typename?: 'Mutation' }
  & { updateOneUser: (
    { __typename?: 'User' }
    & UserFragmentDTO
  ) }
);


export const UpdateUserDocumentDTO = gql`
    mutation updateUser($id: String!, $data: UserUpdateInput) {
  updateOneUser(id: $id, data: $data) {
    ...userFragment
  }
}
    ${UserFragmentDTO}`;
export type UpdateUserMutationFnDTO = Apollo.MutationFunction<UpdateUserDTO, UpdateUserVariablesDTO>;

/**
 * __useUpdateUser__
 *
 * To run a mutation, you first call `useUpdateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUser, { data, loading, error }] = useUpdateUser({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUser(baseOptions?: Apollo.MutationHookOptions<UpdateUserDTO, UpdateUserVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserDTO, UpdateUserVariablesDTO>(UpdateUserDocumentDTO, options);
      }
export type UpdateUserHookResult = ReturnType<typeof useUpdateUser>;
export type UpdateUserMutationResultDTO = Apollo.MutationResult<UpdateUserDTO>;
export type UpdateUserMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateUserDTO, UpdateUserVariablesDTO>;