import { ApolloError } from "@apollo/client";
import { ProfileEvents } from "hooks/profile/useProfileUseCases";
import { StrictEventEmitter } from "strict-event-emitter";

// This is a strictly typed version of the default NodeJS EventEmitter.
export interface BaseEvents {
	mutationSucceeded: (message: string) => void;
	mutationFailed: (error: ApolloError) => void;
}

type Events = BaseEvents
& ProfileEvents;

/**
 * Allows to emit events and listen to them. This allows us to chain different Interaction Domains
 * together without explicitly depending on each other.
 */
const event = new StrictEventEmitter<Events>();

export default event;
