/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConversationsCountVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.ConversationFilterDTO>;
}>;


export type GetConversationsCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'conversationsCount'>
);


export const GetConversationsCountDocumentDTO = gql`
    query getConversationsCount($filter: ConversationFilter) {
  conversationsCount(filter: $filter)
}
    `;

/**
 * __useGetConversationsCount__
 *
 * To run a query within a React component, call `useGetConversationsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsCount({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetConversationsCount(baseOptions?: Apollo.QueryHookOptions<GetConversationsCountDTO, GetConversationsCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationsCountDTO, GetConversationsCountVariablesDTO>(GetConversationsCountDocumentDTO, options);
      }
export function useGetConversationsCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationsCountDTO, GetConversationsCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationsCountDTO, GetConversationsCountVariablesDTO>(GetConversationsCountDocumentDTO, options);
        }
export type GetConversationsCountHookResult = ReturnType<typeof useGetConversationsCount>;
export type GetConversationsCountLazyQueryDTOHookResult = ReturnType<typeof useGetConversationsCountLazyQueryDTO>;
export type GetConversationsCountQueryResultDTO = Apollo.QueryResult<GetConversationsCountDTO, GetConversationsCountVariablesDTO>;