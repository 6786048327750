import { UIComponent } from "@aptus/frontend-core";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslations } from "domains";
import { usePasswordReset } from "./usePasswordReset";
import { useResetPasswordForm } from "./useResetPasswordForm";

interface ResetPasswordFormProps {
	id: string;
}

export const ResetPasswordForm: UIComponent<ResetPasswordFormProps> = ({ id }) => {
	const { save } = usePasswordReset({});
	const { handleFormSubmit, register, errors, newPassword } = useResetPasswordForm({ submit: save, id });
	const { t } = useTranslations();
	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t("resetPassword")}</h2>
			<TextField
				type="password"
				sx={{ mb: 2 }}
				{...register("oldPassword", { required: { value: true, message: "This is required." } })}
				error={!!errors?.oldPassword}
				helperText={errors?.oldPassword?.message}
				fullWidth
				name="oldPassword"
				id="oldPassword"
				label={t("userForm.oldPassword")}
				variant="outlined"
			/>
			<TextField
				type="password"
				sx={{ mb: 2 }}
				{...register("newPassword", { required: { value: true, message: "This is required." } })}
				error={!!errors?.newPassword || !!errors?.passwordRepeat}
				helperText={errors?.newPassword?.message}
				fullWidth
				name="newPassword"
				id="newPassword"
				label={t("userForm.newPassword")}
				variant="outlined"
			/>
			<TextField
				type="password"
				sx={{ mb: 2 }}
				{...register("passwordRepeat", { validate: { value: (value) => (value !== newPassword ? "Passwords do not match." : undefined) }, required: { value: true, message: "This is required." } })}
				error={!!errors?.passwordRepeat}
				helperText={errors?.passwordRepeat?.message}
				fullWidth
				name="passwordRepeat"
				id="passwordRepeat"
				label={t("userForm.passwordRepeat")}
				variant="outlined"
			/>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button type="submit" variant="contained">{t("updatePassword")}</Button>
			</Box>
		</form>
	);
};
