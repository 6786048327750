import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { QueryDTO, RegionDTO, WineTypeDTO } from "models/schema";
import { CreateRegionMutationFnDTO } from "./models/createRegion";
import { DeleteRegionMutationFnDTO } from "./models/deleteRegion";
import { Region } from "./models/region";
import { UpdateRegionMutationFnDTO } from "./models/updateRegion";
import { CreateRegionMutation, DeleteRegionMutation, RegionsAPI, UpdateRegionMutation } from "./useRegionsUseCases";

export type RegionsAPIDTO = ApolloAPI<QueryDTO, "region" | "regions">;

interface Mapper {
	toAPI: ToAPI<RegionsAPIDTO, RegionsAPI>;
	toUpdateMutation: ToInternal<UpdateRegionMutationFnDTO, UpdateRegionMutation>;
	toCreateMutation: ToInternal<CreateRegionMutationFnDTO, CreateRegionMutation>;
	toDeleteMutation: ToInternal<DeleteRegionMutationFnDTO, DeleteRegionMutation>;
	toRegion: ToInternal<DeepPartial<RegionDTO>, Region>;
}

export class RegionMapper implements Mapper {
	public toRegion: Mapper["toRegion"] = (region) => ({
		id: region.id || "",
		name: region.name || "",
		country: region.country?.id || "",
		temperatures: (region.temperatures || []).map((temp) => ({ type: temp.type || WineTypeDTO.RedDTO, temperature: temp.temperature })),
		archived: region.archived || false,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: RegionsAPIDTO["data"]): DeepPartial<RegionDTO[]> => {
			if (data?.region) {
				return [data.region];
			}

			if (data?.regions) {
				return data.regions;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toRegion),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateRegionMutationFnDTO, UpdateRegionMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						name: input.name,
						country: input.country,
						temperatures: input.temperatures,
						archived: input.archived || false,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneRegion ? this.toRegion(output.data.updateOneRegion) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateRegionMutationFnDTO, CreateRegionMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						name: input.name,
						country: input.country,
						temperatures: input.temperatures,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneRegion ? this.toRegion(output.data.createOneRegion) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteRegionMutationFnDTO, DeleteRegionMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getRegions"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: !!output?.data?.updateOneRegion.id }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
