import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { CountrySelect } from "components/form/items/countrySelect/countrySelect";
import { RegionSelect } from "components/form/items/regionSelect/regionSelect";
import { WineAndTemperatureSelect } from "components/form/items/wineAndTemperatureSelect/wineAndTemperatureSelect";
import { MultiLanguage } from "components/form/multilanguage/MultiLanguage";
import Loader from "components/loader/loader";
import { useAocs, useRoutes, useTranslations } from "domains";
import { Aoc } from "hooks/aocs/models/aoc";
import { SaveAocInput } from "hooks/aocs/models/aocInput";
import BasePage from "pages/basePage/basePage";
import { useGetAoc } from "./models/getAoc";
import { useAocForm } from "./useAocForm";

interface AocFormProps {
	aoc: Aoc;
	save: (input: SaveAocInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const AocForm: UIComponent<AocFormProps> = ({ aoc, save, remove }) => {
	const { control, register, handleFormSubmit, selectedCountry, selectedRegion, handleDelete, errors, temperatures } = useAocForm({ initialValues: aoc, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`AOC.${aoc.id ? "edit" : "add"}`)}</h2>
			<FormControl {...register("name", { required: { value: true, message: "This is required." } })} fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					error={!!errors?.name}
					helperText={errors?.name?.message}
					fullWidth
					name="name"
					id="name"
					label={t("aocForm.name")}
					variant="outlined"
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<CountrySelect
					rules={{ required: { value: true, message: "This is required." } }}
					error={!!errors?.country}
					helperText={errors?.country?.message}
					control={control}
					name="country"
					label={t("country")}
					defaultValue={selectedCountry}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<RegionSelect
					country={selectedCountry || aoc.country}
					control={control}
					name="region"
					label={t("region")}
					defaultValue={selectedRegion}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<WineAndTemperatureSelect
					showMainGrapeField
					name="temperatures"
					control={control}
					register={register}
				/>
			</FormControl>
			{temperatures?.length ? (
				<MultiLanguage register={register} languages={["nl", "en", "fr"]}>
					{temperatures.map((temp, index) => (
						<TextField
							sx={{ mb: 2 }}
							fullWidth
							rows={10}
							multiline
							id={`temperatures.${index}.descriptions`}
							label={`Temperature description (${temp.type})`}
							variant="outlined"
						/>
					))}
				</MultiLanguage>
			) : null}
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{aoc.id ? <Button onClick={() => handleDelete(aoc.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteAoc")}</Button> : undefined}
				<Button type="submit" variant="contained">{aoc.id ? t("updateAoc") : t("createAoc")}</Button>
			</Box>
		</form>
	);
};

export const AocPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, aoc, isLoading } = useAocs(useGetAoc({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <AocForm save={save} remove={remove} aoc={aoc} />;
};
