/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCorrectionsCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetCorrectionsCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'correctionsCount'>
);


export const GetCorrectionsCountDocumentDTO = gql`
    query getCorrectionsCount($search: String) {
  correctionsCount(
    filter: {OR: [{originalText: {contains: $search}}, {replacement: {contains: $search}}]}
  )
}
    `;

/**
 * __useGetCorrectionsCount__
 *
 * To run a query within a React component, call `useGetCorrectionsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetCorrectionsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorrectionsCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCorrectionsCount(baseOptions?: Apollo.QueryHookOptions<GetCorrectionsCountDTO, GetCorrectionsCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCorrectionsCountDTO, GetCorrectionsCountVariablesDTO>(GetCorrectionsCountDocumentDTO, options);
      }
export function useGetCorrectionsCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetCorrectionsCountDTO, GetCorrectionsCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCorrectionsCountDTO, GetCorrectionsCountVariablesDTO>(GetCorrectionsCountDocumentDTO, options);
        }
export type GetCorrectionsCountHookResult = ReturnType<typeof useGetCorrectionsCount>;
export type GetCorrectionsCountLazyQueryDTOHookResult = ReturnType<typeof useGetCorrectionsCountLazyQueryDTO>;
export type GetCorrectionsCountQueryResultDTO = Apollo.QueryResult<GetCorrectionsCountDTO, GetCorrectionsCountVariablesDTO>;