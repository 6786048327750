import { UIComponent } from "@aptus/frontend-core";
import { Card, CardContent, CardMedia, Chip } from "@mui/material";
import { AdviceRequest } from "hooks/adviceRequests/models/adviceRequest";
import { useRoutes, useTranslations } from "domains";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import { RequestCardLabel } from "./requestCardStyle";

type RequestCardProps = AdviceRequest;

export const RequestCard: UIComponent<RequestCardProps> = ({ id, owner, createdAt, status, type, image }) => {
	const { routes } = useRoutes();
	const { t } = useTranslations();

	return (
		<Link style={{ textDecoration: "none" }} to={routes.requestDetail.replace(":id", id).replace(":type", type)}>
			<Card style={{ opacity: status === "finished" ? "0.5" : "1" }}>
				<CardMedia
					component="img"
					height="200"
					image={image}
					alt="wine"
				/>
				<Chip style={{ marginTop: "-25px", marginLeft: 10 }} label={t(`request.type.${type}` as "request.type.scan")} color="primary" />
				<CardContent style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						<p>
							{owner.givenName}
							{" "}
							{owner.familyName}
						</p>
						<RequestCardLabel>{createdAt && formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}</RequestCardLabel>
					</div>
					<div>
						<p>{owner.language}</p>
						<RequestCardLabel>{status}</RequestCardLabel>
					</div>
				</CardContent>
			</Card>
		</Link>
	);
};
