/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdatePasswordVariablesDTO = Types.Exact<{
  data: Types.PasswordUpdateInputDTO;
}>;


export type UpdatePasswordDTO = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'Success' }
    & Pick<Types.SuccessDTO, 'success'>
  ) }
);


export const UpdatePasswordDocumentDTO = gql`
    mutation updatePassword($data: PasswordUpdateInput!) {
  updatePassword(data: $data) {
    success
  }
}
    `;
export type UpdatePasswordMutationFnDTO = Apollo.MutationFunction<UpdatePasswordDTO, UpdatePasswordVariablesDTO>;

/**
 * __useUpdatePassword__
 *
 * To run a mutation, you first call `useUpdatePassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassword, { data, loading, error }] = useUpdatePassword({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePassword(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordDTO, UpdatePasswordVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordDTO, UpdatePasswordVariablesDTO>(UpdatePasswordDocumentDTO, options);
      }
export type UpdatePasswordHookResult = ReturnType<typeof useUpdatePassword>;
export type UpdatePasswordMutationResultDTO = Apollo.MutationResult<UpdatePasswordDTO>;
export type UpdatePasswordMutationOptionsDTO = Apollo.BaseMutationOptions<UpdatePasswordDTO, UpdatePasswordVariablesDTO>;