/* eslint-disable */
import * as Types from '../../../models/schema';

import { UserFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProfileVariablesDTO = Types.Exact<{ [key: string]: never; }>;


export type GetProfileDTO = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { user: (
      { __typename?: 'User' }
      & UserFragmentDTO
    ) }
  ) }
);


export const GetProfileDocumentDTO = gql`
    query getProfile {
  profile {
    user {
      ...userFragment
    }
  }
}
    ${UserFragmentDTO}`;

/**
 * __useGetProfile__
 *
 * To run a query within a React component, call `useGetProfile` and pass it any options that fit your needs.
 * When your component renders, `useGetProfile` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfile({
 *   variables: {
 *   },
 * });
 */
export function useGetProfile(baseOptions?: Apollo.QueryHookOptions<GetProfileDTO, GetProfileVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileDTO, GetProfileVariablesDTO>(GetProfileDocumentDTO, options);
      }
export function useGetProfileLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileDTO, GetProfileVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileDTO, GetProfileVariablesDTO>(GetProfileDocumentDTO, options);
        }
export type GetProfileHookResult = ReturnType<typeof useGetProfile>;
export type GetProfileLazyQueryDTOHookResult = ReturnType<typeof useGetProfileLazyQueryDTO>;
export type GetProfileQueryResultDTO = Apollo.QueryResult<GetProfileDTO, GetProfileVariablesDTO>;