/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteGrapeVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteGrapeDTO = (
  { __typename?: 'Mutation' }
  & { updateOneGrape: (
    { __typename?: 'Grape' }
    & Pick<Types.GrapeDTO, 'id'>
  ) }
);


export const DeleteGrapeDocumentDTO = gql`
    mutation deleteGrape($id: String!) {
  updateOneGrape(id: $id, data: {archived: true}) {
    id
  }
}
    `;
export type DeleteGrapeMutationFnDTO = Apollo.MutationFunction<DeleteGrapeDTO, DeleteGrapeVariablesDTO>;

/**
 * __useDeleteGrape__
 *
 * To run a mutation, you first call `useDeleteGrape` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGrape` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGrape, { data, loading, error }] = useDeleteGrape({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGrape(baseOptions?: Apollo.MutationHookOptions<DeleteGrapeDTO, DeleteGrapeVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGrapeDTO, DeleteGrapeVariablesDTO>(DeleteGrapeDocumentDTO, options);
      }
export type DeleteGrapeHookResult = ReturnType<typeof useDeleteGrape>;
export type DeleteGrapeMutationResultDTO = Apollo.MutationResult<DeleteGrapeDTO>;
export type DeleteGrapeMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteGrapeDTO, DeleteGrapeVariablesDTO>;