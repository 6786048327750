import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { AdviceRequestStatusDTO, AdviceRequestTypeDTO } from "models/schema";
import { useState } from "react";
import { AdviceRequest } from "./models/adviceRequest";
import { ScanAdviceRequestUpdateInput, UpdateAdviceRequestInput } from "./models/adviceRequestInput";

const isScanUpdate = (input: any): input is ScanAdviceRequestUpdateInput => input.type === AdviceRequestTypeDTO.ScanDTO;

interface Result {
	adviceRequest?: AdviceRequest;
	adviceRequests: AdviceRequest[];
	isLoading: boolean;
	error?: Error;
	save: (adviceRequestInput: Partial<UpdateAdviceRequestInput>) => Promise<void>;
}

export type AdviceRequestsAPI = InternalAPI<AdviceRequest[]>;

export type UpdateAdviceRequestMutation = Mutation<UpdateAdviceRequestInput, AdviceRequest>;

interface Props {
	API: AdviceRequestsAPI;
	defaultAdviceRequest: AdviceRequest;
	updateMutation: UpdateAdviceRequestMutation;
}

export const useAdviceRequestsUseCases: UseCases<Props, Result> = ({ API, defaultAdviceRequest, updateMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);

	const save: Result["save"] = async (adviceRequestInput) => {
		setIsLoading(true);
		try {
			const data: UpdateAdviceRequestInput = {
				id: adviceRequestInput.id || "",
				comment: isScanUpdate(adviceRequestInput) ? adviceRequestInput.comment : "",
				internalComment: isScanUpdate(adviceRequestInput) ? adviceRequestInput.internalComment : "",
				wineResult: (isScanUpdate(adviceRequestInput) && adviceRequestInput.wineResult) || undefined,
				type: adviceRequestInput.type || AdviceRequestTypeDTO.ScanDTO,
				status: adviceRequestInput.status || AdviceRequestStatusDTO.FinishedDTO,
			};

			await updateMutation(data);
			event.emit("mutationSucceeded", t("adviceRequestHasBeenSuccessfullyUpdated"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	return {
		...API,
		adviceRequest: API.data[0] || defaultAdviceRequest,
		adviceRequests: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
	};
};
