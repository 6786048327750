/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGrapesCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetGrapesCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'grapesCount'>
);


export const GetGrapesCountDocumentDTO = gql`
    query getGrapesCount($search: String) {
  grapesCount(filter: {archived: {equals: false}, name: {contains: $search}})
}
    `;

/**
 * __useGetGrapesCount__
 *
 * To run a query within a React component, call `useGetGrapesCount` and pass it any options that fit your needs.
 * When your component renders, `useGetGrapesCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrapesCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetGrapesCount(baseOptions?: Apollo.QueryHookOptions<GetGrapesCountDTO, GetGrapesCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGrapesCountDTO, GetGrapesCountVariablesDTO>(GetGrapesCountDocumentDTO, options);
      }
export function useGetGrapesCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetGrapesCountDTO, GetGrapesCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGrapesCountDTO, GetGrapesCountVariablesDTO>(GetGrapesCountDocumentDTO, options);
        }
export type GetGrapesCountHookResult = ReturnType<typeof useGetGrapesCount>;
export type GetGrapesCountLazyQueryDTOHookResult = ReturnType<typeof useGetGrapesCountLazyQueryDTO>;
export type GetGrapesCountQueryResultDTO = Apollo.QueryResult<GetGrapesCountDTO, GetGrapesCountVariablesDTO>;