/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import { UIComponent } from "@aptus/frontend-core";
import { CircularProgress, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useRoutes, useTranslations } from "domains";

import InfiniteScroll from "react-infinite-scroll-component";

import AddIcon from "@mui/icons-material/Add";

interface RecordListProps {
	data?: {
		name?: string | null;
		id: string;
	}[];
	count: number;
	loadMore: () => void;
}

export const RecordList: UIComponent<RecordListProps> = ({ data = [], loadMore, count }) => {
	const { currentQueryString: { selected, create, table }, updateQueryString } = useRoutes();
	const { t } = useTranslations();

	return (
		<InfiniteScroll
			data-testid="record-list"
			dataLength={data.length + 1}
			next={loadMore}
			hasMore={data.length < count}
			loader={(
				<ListItem>
					<CircularProgress style={{ marginRight: 20 }} color="inherit" size={20} />
					<ListItemText primary={t("loading")} />
				</ListItem>
			)}
			height="70vh"
		>
			{table ? (
				<ListItem selected={create} disablePadding>
					<ListItemButton onClick={() => {
						updateQueryString({ selected: null, create: true });
					}}
					>
						<AddIcon style={{ marginRight: 10 }} />
						<ListItemText primary={t("new")} />
					</ListItemButton>
				</ListItem>
			) : null }
			{(data || []).map((d, i) => (
				<ListItem className={`${d.id}-${i}`} selected={selected === d.id} key={`${d.id}-${i}`} disablePadding>
					<ListItemButton onClick={() => updateQueryString({ selected: d.id, create: null })}>
						<ListItemText primary={d.name || t("noName")} />
					</ListItemButton>
				</ListItem>
			))}
		</InfiniteScroll>
	);
};
