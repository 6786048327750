import { Database } from "mocks/db";

export const populateClassificationCollection = (db: Database): void => {
	db.classification.create({
		id: "classification-id",
		name: "Dry Sec",
		type: "sparkling",
	});
	db.classification.create({
		name: "Grand Cru",
		type: "still",
	});
	db.classification.create({
		name: "Reserva",
	});
};
