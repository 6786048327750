import { UIComponent } from "@aptus/frontend-core";
import { Alert, AlertTitle } from "@mui/material";
import { Notification as NotificationType } from "hooks/notifications/models/notification";

import NotificationStyle from "./notificationStyle";

type Props = NotificationType;

const Notification: UIComponent<Props> = ({
	title, error, type,
}) => (
	<NotificationStyle>
		<Alert severity={type}>
			<AlertTitle>{title}</AlertTitle>
			{error && <p>{error.message}</p>}
		</Alert>
	</NotificationStyle>
);

export default Notification;
