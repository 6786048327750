import { UIComponent } from "@aptus/frontend-core";
import { Box, Button, FormControl, TextField } from "@mui/material";
import { WineAndTemperatureSelect } from "components/form/items/wineAndTemperatureSelect/wineAndTemperatureSelect";
import { useTranslations, useCountries, useRoutes } from "domains";
import { SaveCountryInput } from "hooks/countries/models/countryInput";
import { Country } from "hooks/countries/models/country";
import BasePage from "pages/basePage/basePage";
import Loader from "components/loader/loader";
import { useCountryForm } from "./useCountryForm";
import { useGetCountry } from "./models/getCountry";

interface CountryFormProps {
	country: Country;
	save: (input: SaveCountryInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const CountryForm: UIComponent<CountryFormProps> = ({ country, save, remove }) => {
	const { register, handleFormSubmit, control, handleDelete, errors } = useCountryForm({ initialValues: country, submit: save, remove });
	const { t } = useTranslations();
	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`country.${country.id ? "edit" : "add"}`)}</h2>
			<FormControl {...register("name", { required: { value: true, message: "This is required." } })} fullWidth sx={{ mb: 2, mr: 2 }} variant="filled">
				<TextField
					error={!!errors?.name}
					helperText={errors?.name?.message}
					fullWidth
					name="name"
					id="name"
					label={t("countryForm.name")}
					variant="outlined"
				/>
			</FormControl>
			<WineAndTemperatureSelect register={register} control={control} name="temperatures" />
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{country.id ? <Button onClick={() => handleDelete(country.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteCountry")}</Button> : undefined}
				<Button type="submit" variant="contained">{country.id ? t("updateCountry") : t("createCountry")}</Button>
			</Box>
		</form>
	);
};

export const CountryPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, country, isLoading } = useCountries(useGetCountry({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <CountryForm save={save} remove={remove} country={country} />;
};
