import { UILogic } from "@aptus/frontend-core";
import { UseFormTypes } from "pages/databasePage/components/databaseContent/recordForm/forms/useFormTypes";
import { useForm } from "react-hook-form";

interface Props {
	submit: (values: { email: string }) => void;
}

interface FormValues {
	email: string;
}

type Result = UseFormTypes<FormValues> & {
};

export const useResetPasswordAnonymouslyForm: UILogic<Props, Result> = ({ submit }) => {
	const { register, handleSubmit, resetField, control, formState: { errors } } = useForm<FormValues>();

	const handleFormSubmit = async (values: FormValues) => {
		submit(values);
		resetField("email");
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		errors,
	};
};
