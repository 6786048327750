import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Search from "@mui/icons-material/Search";
import { UIComponent } from "@aptus/frontend-core";
import { useTranslations } from "domains";
import { FilterBarStyle } from "components/filterbar/filterBarStyle";
import { useDatabaseFilterBar } from "./useDatabaseFilterBar";

export const DatabaseFilterBar: UIComponent<{}> = () => {
	const { onSelectTable, onSearch, onCreateNew, selectedTable, tables, search } = useDatabaseFilterBar({});
	const { t } = useTranslations();
	return (
		<FilterBarStyle>
			<FormControl style={{ flex: 2, marginRight: 20 }} sx={{ minWidth: 300 }}>
				<InputLabel id="table">{t("database.filter.table")}</InputLabel>
				<Select
					value={selectedTable}
					onChange={(e) => onSelectTable(e.target.value as string)}
					labelId="table"
					id="table"
					label={t("database.filter.table")}
				>
					{tables.map((table: any) => <MenuItem value={table.value}>{table.label}</MenuItem>)}
				</Select>
			</FormControl>
			<FormControl style={{ flex: 3, marginRight: 20 }} variant="outlined">
				<InputLabel htmlFor="search">{t("database.filter.search")}</InputLabel>
				<OutlinedInput
					defaultValue={search}
					onChange={(e) => onSearch(e.target.value)}
					id="search"
					endAdornment={(
						<InputAdornment position="end">
							<IconButton
								edge="end"
							>
								<Search />
							</IconButton>
						</InputAdornment>
					)}
					label={t("database.filter.search")}
				/>
			</FormControl>
			{selectedTable && (
				<Button variant="contained" onClick={onCreateNew}>
					{t(`database.filter.addNew.${selectedTable}` as "database.filter.addNew.domain")}
				</Button>
			)}
		</FilterBarStyle>
	);
};
