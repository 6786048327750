import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { QueryDTO, UserDTO } from "models/schema";
import { CreateUserMutationFnDTO } from "./models/createUser";
import { DeleteUserMutationFnDTO } from "./models/deleteUser";
import { User } from "./models/user";
import { UpdateUserMutationFnDTO } from "./models/updateUser";
import { CreateUserMutation, DeleteUserMutation, UsersAPI, UpdateUserMutation } from "./useUsersUseCases";

export type UsersAPIDTO = ApolloAPI<QueryDTO, "user" | "users" | "usersCount" | "profile">;

interface Mapper {
	toAPI: ToAPI<UsersAPIDTO, UsersAPI>;
	toUpdateMutation: ToInternal<UpdateUserMutationFnDTO, UpdateUserMutation>;
	toCreateMutation: ToInternal<CreateUserMutationFnDTO, CreateUserMutation>;
	toDeleteMutation: ToInternal<DeleteUserMutationFnDTO, DeleteUserMutation>;
	toUser: ToInternal<DeepPartial<UserDTO>, User>;
}

export class UserMapper implements Mapper {
	public toUser: Mapper["toUser"] = (user) => ({
		id: user.id || "",
		email: user.email || "",
		roles: user.roles || [],
		givenName: user.givenName || "",
		familyName: user.familyName || "",
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: UsersAPIDTO["data"]): DeepPartial<UserDTO[] | number> => {
			if (data?.user) {
				return [data.user];
			}

			if (data?.users) {
				return data.users;
			}

			if (data?.usersCount) {
				return data.usersCount || 0;
			}

			if (data?.profile?.user) {
				return [data.profile.user];
			}

			return [];
		};

		const data = extractDTOs(api?.data);

		return {
			...api,
			isLoading: api?.loading || false,
			count: api?.data?.usersCount,
			data: Array.isArray(data) ? data.map(this.toUser) : data,
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateUserMutationFnDTO, UpdateUserMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						givenName: input.givenName,
						familyName: input.familyName,
						email: input.email,
						roles: input.roles,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneUser ? this.toUser(output.data.updateOneUser) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateUserMutationFnDTO, CreateUserMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						givenName: input.givenName,
						familyName: input.familyName,
						email: input.email,
						roles: input.roles,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneUser ? this.toUser(output.data.createOneUser) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteUserMutationFnDTO, DeleteUserMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getUsers"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: output?.data?.deleteOneUser.success || false }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
