import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Domain } from "./models/domain";
import { CreateDomainInput, DeleteDomainInput, SaveDomainInput, UpdateDomainInput } from "./models/domainInput";

export type DomainsAPI = InternalAPI<Domain[]>;

export type UpdateDomainMutation = Mutation<UpdateDomainInput, Domain>;
export type CreateDomainMutation = Mutation<CreateDomainInput, Domain>;
export type DeleteDomainMutation = Mutation<DeleteDomainInput, Success>;

interface Props {
	API: DomainsAPI;
	defaultDomain: Domain;
	updateMutation: UpdateDomainMutation;
	createMutation: CreateDomainMutation;
	deleteMutation: DeleteDomainMutation;
}

interface Result {
	domain: Domain;
	domains: Domain[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveDomainInput) => Promise<Domain | undefined>;
	remove: (id: string) => Promise<void>;
}

export const useDomainsUseCases: UseCases<Props, Result> = ({ API, defaultDomain, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveDomainInput): input is UpdateDomainInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		let domain;
		try {
			if (isUpdate(input)) {
				domain = await updateMutation(input);
				event.emit("mutationSucceeded", t("domainHasBeenSuccessfullyUpdated"));
			} else {
				domain = await createMutation(input);
				event.emit("mutationSucceeded", t("domainHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
		return domain;
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("domainHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		domain: API.data[0] || defaultDomain,
		domains: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
