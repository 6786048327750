/* eslint-disable */
import * as Types from '../../../models/schema';

import { GrapeFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateGrapeVariablesDTO = Types.Exact<{
  data: Types.GrapeCreateInputDTO;
}>;


export type CreateGrapeDTO = (
  { __typename?: 'Mutation' }
  & { createOneGrape: (
    { __typename?: 'Grape' }
    & GrapeFragmentDTO
  ) }
);


export const CreateGrapeDocumentDTO = gql`
    mutation createGrape($data: GrapeCreateInput!) {
  createOneGrape(data: $data) {
    ...grapeFragment
  }
}
    ${GrapeFragmentDTO}`;
export type CreateGrapeMutationFnDTO = Apollo.MutationFunction<CreateGrapeDTO, CreateGrapeVariablesDTO>;

/**
 * __useCreateGrape__
 *
 * To run a mutation, you first call `useCreateGrape` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGrape` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGrape, { data, loading, error }] = useCreateGrape({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGrape(baseOptions?: Apollo.MutationHookOptions<CreateGrapeDTO, CreateGrapeVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGrapeDTO, CreateGrapeVariablesDTO>(CreateGrapeDocumentDTO, options);
      }
export type CreateGrapeHookResult = ReturnType<typeof useCreateGrape>;
export type CreateGrapeMutationResultDTO = Apollo.MutationResult<CreateGrapeDTO>;
export type CreateGrapeMutationOptionsDTO = Apollo.BaseMutationOptions<CreateGrapeDTO, CreateGrapeVariablesDTO>;