import styled from "styled-components";

export const FilterBarStyle = styled.div`
	display: flex;
	padding: 20px 0;
`;

export const FilterBarHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;
