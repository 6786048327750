/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteSuggestionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteSuggestionDTO = (
  { __typename?: 'Mutation' }
  & { updateOneSuggestion: (
    { __typename?: 'DomainSuggestion' }
    & Pick<Types.DomainSuggestionDTO, 'id'>
  ) | (
    { __typename?: 'FaqSuggestion' }
    & Pick<Types.FaqSuggestionDTO, 'id'>
  ) }
);


export const DeleteSuggestionDocumentDTO = gql`
    mutation deleteSuggestion($id: String!) {
  updateOneSuggestion(id: $id, data: {archived: true}) {
    id
  }
}
    `;
export type DeleteSuggestionMutationFnDTO = Apollo.MutationFunction<DeleteSuggestionDTO, DeleteSuggestionVariablesDTO>;

/**
 * __useDeleteSuggestion__
 *
 * To run a mutation, you first call `useDeleteSuggestion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSuggestion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSuggestion, { data, loading, error }] = useDeleteSuggestion({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSuggestion(baseOptions?: Apollo.MutationHookOptions<DeleteSuggestionDTO, DeleteSuggestionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSuggestionDTO, DeleteSuggestionVariablesDTO>(DeleteSuggestionDocumentDTO, options);
      }
export type DeleteSuggestionHookResult = ReturnType<typeof useDeleteSuggestion>;
export type DeleteSuggestionMutationResultDTO = Apollo.MutationResult<DeleteSuggestionDTO>;
export type DeleteSuggestionMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteSuggestionDTO, DeleteSuggestionVariablesDTO>;