import event from "event";
import decodeJWT from "jwt-decode";
import { useEffect } from "react";
import { Profile, Role } from "hooks/profile/models/profile";
import { PasswordLoginInputDTO } from "models/schema";
import { usePasswordLogin } from "pages/loginPage/models/login";
import { ApolloError } from "@apollo/client";
import { UseCases } from "@aptus/frontend-core";
import { useResetPasswordAnonymously } from "./models/resetPasswordAnonymously";

type WithIsExpired<T> = T & { isExpired: boolean };

interface JWTObject {
	sub: string;
	iss: string;
	exp: number;
	roles: Role[];
}

export interface ProfileEvents {
	jwtTokenReceived: (token: string) => void;
	userLoggedIn: () => void;
	userLoggedOut: () => void;
	passwordResetMailSent: () => void;
	passwordResetFailed: () => void;
}

interface Result {
	profile?: Profile;
	isLoggedIn: boolean;
	login: (values: PasswordLoginInputDTO) => void;
	logout: () => void;
	requestPasswordResetMail: (email: string) => void;
}

export const useProfileUseCases: UseCases<undefined, Result> = () => {
	const [passwordLogin, { error: passwordLoginError }] = usePasswordLogin();
	const [resetPasswordAnonymously] = useResetPasswordAnonymously();

	const jwtToProfile = (jwtObject: JWTObject): WithIsExpired<Profile> => ({
		isExpired: jwtObject.exp < Date.now() / 1000,
		roles: jwtObject.roles,
	});

	const logout: Result["logout"] = () => {
		localStorage.removeItem("token");
		event.emit("userLoggedOut");
	};

	const getProfile = (): Result["profile"] => {
		const token = localStorage.getItem("token");
		const profile = token ? jwtToProfile(decodeJWT(token)) : undefined;

		if (profile?.isExpired) {
			logout();
			return undefined;
		}

		return profile;
	};

	const updateToken: ProfileEvents["jwtTokenReceived"] = (token) => {
		localStorage.setItem("token", token);
		event.emit("userLoggedIn");
	};

	const login = async (values: PasswordLoginInputDTO) => {
		try {
			const result = await passwordLogin({ variables: { data: values } });

			if (result && result.data && result.data.passwordLogin.token) {
				event.emit("jwtTokenReceived", result.data.passwordLogin.token);
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	const requestPasswordResetMail = async (email: string) => {
		try {
			const result = await resetPasswordAnonymously({ variables: { data: { email } } });

			if (result && result.data && result.data.resetPassword.success) {
				event.emit("passwordResetMailSent");
			} else {
				event.emit("passwordResetFailed");
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	const profile: Result["profile"] = getProfile();

	useEffect(() => {
		event.on("jwtTokenReceived", updateToken);

		return () => {
			event.removeListener("jwtTokenReceived", updateToken);
		};
	}, []);

	useEffect(() => {
		if (passwordLoginError) {
			event.emit("mutationFailed", passwordLoginError);
		}
	}, [passwordLoginError]);

	return {
		profile,
		roles: Object.values(Role),
		isLoggedIn: !!profile,
		login,
		logout,
		requestPasswordResetMail,
	};
};
