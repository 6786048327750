/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMainGrapeListVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  paging?: Types.Maybe<Types.PagingDTO>;
}>;


export type GetMainGrapeListDTO = (
  { __typename?: 'Query' }
  & { mainGrapes: Array<(
    { __typename?: 'MainGrape' }
    & Pick<Types.MainGrapeDTO, 'id' | 'name'>
  )> }
);


export const GetMainGrapeListDocumentDTO = gql`
    query getMainGrapeList($search: String, $paging: Paging) {
  mainGrapes(
    filter: {archived: {equals: false}, name: {contains: $search}}
    paging: $paging
    sort: [{field: "name", order: ASC}]
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetMainGrapeList__
 *
 * To run a query within a React component, call `useGetMainGrapeList` and pass it any options that fit your needs.
 * When your component renders, `useGetMainGrapeList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainGrapeList({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetMainGrapeList(baseOptions?: Apollo.QueryHookOptions<GetMainGrapeListDTO, GetMainGrapeListVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMainGrapeListDTO, GetMainGrapeListVariablesDTO>(GetMainGrapeListDocumentDTO, options);
      }
export function useGetMainGrapeListLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetMainGrapeListDTO, GetMainGrapeListVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMainGrapeListDTO, GetMainGrapeListVariablesDTO>(GetMainGrapeListDocumentDTO, options);
        }
export type GetMainGrapeListHookResult = ReturnType<typeof useGetMainGrapeList>;
export type GetMainGrapeListLazyQueryDTOHookResult = ReturnType<typeof useGetMainGrapeListLazyQueryDTO>;
export type GetMainGrapeListQueryResultDTO = Apollo.QueryResult<GetMainGrapeListDTO, GetMainGrapeListVariablesDTO>;