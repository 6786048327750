/* eslint-disable */
import * as Types from '../../../models/schema';

import { CountryFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateCountryVariablesDTO = Types.Exact<{
  data: Types.CountryCreateInputDTO;
}>;


export type CreateCountryDTO = (
  { __typename?: 'Mutation' }
  & { createOneCountry: (
    { __typename?: 'Country' }
    & CountryFragmentDTO
  ) }
);


export const CreateCountryDocumentDTO = gql`
    mutation createCountry($data: CountryCreateInput!) {
  createOneCountry(data: $data) {
    ...countryFragment
  }
}
    ${CountryFragmentDTO}`;
export type CreateCountryMutationFnDTO = Apollo.MutationFunction<CreateCountryDTO, CreateCountryVariablesDTO>;

/**
 * __useCreateCountry__
 *
 * To run a mutation, you first call `useCreateCountry` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountry` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountry, { data, loading, error }] = useCreateCountry({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCountry(baseOptions?: Apollo.MutationHookOptions<CreateCountryDTO, CreateCountryVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCountryDTO, CreateCountryVariablesDTO>(CreateCountryDocumentDTO, options);
      }
export type CreateCountryHookResult = ReturnType<typeof useCreateCountry>;
export type CreateCountryMutationResultDTO = Apollo.MutationResult<CreateCountryDTO>;
export type CreateCountryMutationOptionsDTO = Apollo.BaseMutationOptions<CreateCountryDTO, CreateCountryVariablesDTO>;