/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteMainGrapeVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteMainGrapeDTO = (
  { __typename?: 'Mutation' }
  & { updateOneMainGrape: (
    { __typename?: 'MainGrape' }
    & Pick<Types.MainGrapeDTO, 'id'>
  ) }
);


export const DeleteMainGrapeDocumentDTO = gql`
    mutation deleteMainGrape($id: String!) {
  updateOneMainGrape(id: $id, data: {archived: true}) {
    id
  }
}
    `;
export type DeleteMainGrapeMutationFnDTO = Apollo.MutationFunction<DeleteMainGrapeDTO, DeleteMainGrapeVariablesDTO>;

/**
 * __useDeleteMainGrape__
 *
 * To run a mutation, you first call `useDeleteMainGrape` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMainGrape` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMainGrape, { data, loading, error }] = useDeleteMainGrape({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMainGrape(baseOptions?: Apollo.MutationHookOptions<DeleteMainGrapeDTO, DeleteMainGrapeVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMainGrapeDTO, DeleteMainGrapeVariablesDTO>(DeleteMainGrapeDocumentDTO, options);
      }
export type DeleteMainGrapeHookResult = ReturnType<typeof useDeleteMainGrape>;
export type DeleteMainGrapeMutationResultDTO = Apollo.MutationResult<DeleteMainGrapeDTO>;
export type DeleteMainGrapeMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteMainGrapeDTO, DeleteMainGrapeVariablesDTO>;