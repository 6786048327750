import { PageComponent } from "@aptus/frontend-core";
import Loader from "components/loader/loader";
import { useUsers } from "domains";
import BasePage from "pages/basePage/basePage";
import { EmailForm } from "./components/emailForm/emailForm";
import { ProfileForm } from "./components/profileForm/profileForm";
import { ResetPasswordForm } from "./components/resetPasswordForm/resetPasswordForm";
import { useGetProfile } from "./models/getProfile";
import { ProfilePageStyle } from "./profilePageStyle";

export const ProfilePage: PageComponent = () => {
	const { user, isLoading } = useUsers(useGetProfile());

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return (
		<BasePage>
			<ProfilePageStyle>
				<div>
					<ProfileForm formValues={{ id: user.id, givenName: user.givenName, familyName: user.familyName }} />
					{/* TODO: Avatar form should come here but is not yet supported by API */}
				</div>
				<div>
					<EmailForm formValues={{ id: user.id, email: user.email }} />
					<ResetPasswordForm id={user.id} />
				</div>
			</ProfilePageStyle>
		</BasePage>
	);
};
