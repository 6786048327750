import { Query, UILogic } from "@aptus/frontend-core";
import { AutoCompleteSelectProps, getDropdownOptions } from "components/form/autoCompleteSelect/autoCompleteSelect";
import { useMainGrapes } from "domains";
import { MainGrapesAPIDTO } from "hooks/mainGrapes/mainGrapeMapper";
import { MainGrape } from "hooks/mainGrapes/models/mainGrape";
import { MainGrapeFilterDTO } from "models/schema";
import { useGetMainGrapes } from "./models/getMainGrapes";

export interface MainGrapeSelectProps extends Omit<AutoCompleteSelectProps, "dropdownProps"> {
	defaultValue?: string;
}

type Result = AutoCompleteSelectProps;

interface QueryProps {
	id?: MainGrape["id"];
}

const useGetFilteredMainGrapes: Query<QueryProps, MainGrapesAPIDTO> = ({ id }) => {
	const filter: MainGrapeFilterDTO = {
		OR: [
			{ archived: { equals: false } },
			...id ? [{ id: { equals: id } }] : [],
		],
	};

	return useGetMainGrapes({ variables: { filter } });
};

export const useMainGrapeSelect: UILogic<MainGrapeSelectProps, Result> = (props) => {
	const { mainGrapes, isLoading } = useMainGrapes(useGetFilteredMainGrapes({
		id: props.defaultValue,
	}));

	const options = {
		loading: isLoading,
		options: getDropdownOptions(mainGrapes),
	};

	return {
		...props,
		dropdownProps: options,
	};
};
