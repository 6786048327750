/* eslint-disable no-restricted-globals */
import { UILogic } from "@aptus/frontend-core";
import { debounce } from "utils/Debounce";
import { useRoutes, useTranslations } from "domains";

interface Result {
	selectedTable?: string;
	search?: string;
	tables: TableItem[];
	onSelectTable: (table: string) => void;
	onSearch: (value: string) => void;
	onCreateNew: () => void;
}

interface TableItem {
	value: Tables;
	label: string;
}

export enum Tables {
	Domain = "domain",
	Classification = "classification",
	Exception = "exception",
	Region = "region",
	FoodPairing = "food_pairing",
	FAQ = "faq",
	Country = "country",
	AOC = "aoc",
	Grape = "grape",
	MainGrape = "main_grape",
	Correction = "correction",
}

export const useDatabaseFilterBar: UILogic<{}, Result> = () => {
	const { currentQueryString, updateQueryString } = useRoutes();
	const { t } = useTranslations();

	const onSelectTable: Result["onSelectTable"] = (table) => {
		updateQueryString({ table, selected: null, create: null });
	};

	const onSearch: Result["onSearch"] = (value) => {
		updateQueryString({ search: value });
	};

	const tables: Result["tables"] = [
		{ label: t("database.filter.domains"), value: Tables.Domain },
		{ label: t("database.filter.classifications"), value: Tables.Classification },
		{ label: t("database.filter.regions"), value: Tables.Region },
		{ label: t("database.filter.foodPairing"), value: Tables.FoodPairing },
		{ label: t("database.filter.FAQ"), value: Tables.FAQ },
		{ label: t("database.filter.countries"), value: Tables.Country },
		{ label: t("database.filter.AOC"), value: Tables.AOC },
		{ label: t("database.filter.grapes"), value: Tables.Grape },
		{ label: t("database.filter.mainGrapes"), value: Tables.MainGrape },
		{ label: t("database.filter.corrections"), value: Tables.Correction },
		{ label: t("database.filter.exceptions"), value: Tables.Exception },
	];

	const onCreateNew = () => {
		updateQueryString({
			selected: null,
			create: true,
		});
	};

	return {
		onSelectTable,
		onSearch: debounce(onSearch, 1000),
		onCreateNew,
		selectedTable: currentQueryString.table,
		search: currentQueryString.search,
		tables,
	};
};
