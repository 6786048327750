/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetClassificationsCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetClassificationsCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'classificationsCount'>
);


export const GetClassificationsCountDocumentDTO = gql`
    query getClassificationsCount($search: String) {
  classificationsCount(
    filter: {archived: {equals: false}, name: {contains: $search}}
  )
}
    `;

/**
 * __useGetClassificationsCount__
 *
 * To run a query within a React component, call `useGetClassificationsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationsCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetClassificationsCount(baseOptions?: Apollo.QueryHookOptions<GetClassificationsCountDTO, GetClassificationsCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassificationsCountDTO, GetClassificationsCountVariablesDTO>(GetClassificationsCountDocumentDTO, options);
      }
export function useGetClassificationsCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetClassificationsCountDTO, GetClassificationsCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassificationsCountDTO, GetClassificationsCountVariablesDTO>(GetClassificationsCountDocumentDTO, options);
        }
export type GetClassificationsCountHookResult = ReturnType<typeof useGetClassificationsCount>;
export type GetClassificationsCountLazyQueryDTOHookResult = ReturnType<typeof useGetClassificationsCountLazyQueryDTO>;
export type GetClassificationsCountQueryResultDTO = Apollo.QueryResult<GetClassificationsCountDTO, GetClassificationsCountVariablesDTO>;