/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFaqListVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  paging?: Types.Maybe<Types.PagingDTO>;
  sort?: Types.Maybe<Array<Types.Maybe<Types.SortDTO>> | Types.Maybe<Types.SortDTO>>;
}>;


export type GetFaqListDTO = (
  { __typename?: 'Query' }
  & { faqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Types.FaqDTO, 'id'>
    & { name: Types.FaqDTO['titleInLanguage'] }
  )> }
);


export const GetFaqListDocumentDTO = gql`
    query getFaqList($search: String, $paging: Paging, $sort: [Sort]) {
  faqs(
    filter: {titleInLanguage: {language: en, text: {contains: $search}}}
    paging: $paging
    sort: $sort
  ) {
    id
    name: titleInLanguage(language: en)
  }
}
    `;

/**
 * __useGetFaqList__
 *
 * To run a query within a React component, call `useGetFaqList` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqList({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetFaqList(baseOptions?: Apollo.QueryHookOptions<GetFaqListDTO, GetFaqListVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaqListDTO, GetFaqListVariablesDTO>(GetFaqListDocumentDTO, options);
      }
export function useGetFaqListLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetFaqListDTO, GetFaqListVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaqListDTO, GetFaqListVariablesDTO>(GetFaqListDocumentDTO, options);
        }
export type GetFaqListHookResult = ReturnType<typeof useGetFaqList>;
export type GetFaqListLazyQueryDTOHookResult = ReturnType<typeof useGetFaqListLazyQueryDTO>;
export type GetFaqListQueryResultDTO = Apollo.QueryResult<GetFaqListDTO, GetFaqListVariablesDTO>;