import { UILogic } from "@aptus/frontend-core";
import { PasswordUpdateInputDTO } from "models/schema";
import { UseFormTypes } from "pages/databasePage/components/databaseContent/recordForm/forms/useFormTypes";
import { useForm } from "react-hook-form";

interface Props {
	id: string;
	submit: (input: PasswordUpdateInputDTO) => Promise<void>;
}

interface FormValues {
	oldPassword: string;
	newPassword: string;
	passwordRepeat: string;
}

interface Result extends UseFormTypes<FormValues> {
	newPassword: string;
}

export const useResetPasswordForm: UILogic<Props, Result> = ({ submit, id }) => {
	const { register, handleSubmit, watch, control, formState: { errors } } = useForm<FormValues>();

	const newPassword = watch("newPassword");

	const handleFormSubmit = (values: FormValues): void => {
		submit({
			id,
			password: values.newPassword,
			oldPassword: values.oldPassword,
		});
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		errors,
		newPassword,
	};
};
