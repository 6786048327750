import { GetAocsDTO, GetAocsVariablesDTO } from "components/form/items/AOCSelect/models/getAOCs";
import { GetClassificationsDTO, GetClassificationsVariablesDTO } from "components/form/items/classificationSelect/models/getClassifications";
import { GetCountriesDTO, GetCountriesVariablesDTO } from "components/form/items/countrySelect/models/getCountries";
import { GetDomainsDTO, GetDomainsVariablesDTO } from "components/form/items/domainSelect/models/getDomains";
import { GetGrapesDTO, GetGrapesVariablesDTO } from "components/form/items/grapeSelect/models/getGrapes";
import { GetMainGrapesDTO, GetMainGrapesVariablesDTO } from "components/form/items/mainGrapeSelect/models/getMainGrapes";
import { GetRegionsDTO, GetRegionsVariablesDTO } from "components/form/items/regionSelect/models/getRegions";
import { ResetPasswordAnonymouslyDTO, ResetPasswordAnonymouslyVariablesDTO } from "hooks/profile/models/resetPasswordAnonymously";
import { graphql, RequestHandler } from "msw";
import { GetConversationDTO, GetConversationVariablesDTO } from "pages/conversationDetailPage/models/getConversation";
import { GetConversationsDTO, GetConversationsVariablesDTO } from "pages/conversationsPage/components/conversationsContent/models/getConversations";
import { GetAocListDTO, GetAocListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getAocs";
import { GetClassificationListDTO, GetClassificationListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getClassifications";
import { GetCorrectionListDTO, GetCorrectionListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getCorrections";
import { GetCountryListDTO, GetCountryListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getCountries";
import { GetDomainListDTO, GetDomainListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getDomains";
import { GetExceptionListDTO, GetExceptionListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getExceptions";
import { GetFaqListDTO, GetFaqListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getFaqs";
import { GetFoodListDTO, GetFoodListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getFoods";
import { GetGrapeListDTO, GetGrapeListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getGrapes";
import { GetMainGrapeListDTO, GetMainGrapeListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getMainGrapes";
import { GetRegionListDTO, GetRegionListVariablesDTO } from "pages/databasePage/components/databaseContent/models/getRegions";
import { GetAocDTO, GetAocVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/aocForm/models/getAoc";
import { GetClassificationDTO, GetClassificationVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/classificationForm/models/getClassification";
import { GetCorrectionDTO, GetCorrectionVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/correctionForm/models/getCorrection";
import { GetCountryDTO, GetCountryVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/countryForm/models/getCountry";
import { GetDomainDTO, GetDomainVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/domainForm/models/getDomain";
import { GetExceptionDTO, GetExceptionVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/exceptionForm/models/getException";
import { GetFaqDTO, GetFaqVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/faqForm/models/getFaq";
import { GetFoodDTO, GetFoodVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/foodPairingForm/models/getFood";
import { GetGrapeDTO, GetGrapeVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/grapeForm/models/getGrape";
import { GetMainGrapeDTO, GetMainGrapeVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/mainGrapeForm/models/getMainGrape";
import { GetRegionDTO, GetRegionVariablesDTO } from "pages/databasePage/components/databaseContent/recordForm/forms/regionForm/models/getRegion";
import { GetAdviceRequestsDTO, GetAdviceRequestsVariablesDTO } from "pages/homePage/models/getAdviceRequests";
import { PasswordLoginDTO, PasswordLoginVariablesDTO } from "pages/loginPage/models/login";
import { GetAdviceRequestDTO, GetAdviceRequestVariablesDTO } from "pages/requestDetailPage/models/getAdviceRequest";
import { GetSuggestionDTO, GetSuggestionVariablesDTO } from "pages/sommelierSuggestionsPage/components/suggestionForm/models/getSuggestion";
import { GetSuggestionsDTO, GetSuggestionsVariablesDTO } from "pages/sommelierSuggestionsPage/components/suggestionsContent/models/useGetSuggestions";
import { db } from "./db";

/**
 * Here we define what API request returns in our mock.
 *
 * Example: `[
 *    graphql.query('getAlbums', (req, res, ctx) => res(ctx.data({
 *       todos: MOCKED_TODOS
 *    })))
 * ]`
 */

/**
 * 'ctx.delay()' without a specific value imitates a realistic network delay.
 * Useful for manual testing, not so much for integration tests.
 */
const delay = process.env.NODE_ENV === "test" ? 0 : undefined;

/*
	If token expires in 2035,
	I hope this website is still there to create a new one
	http://jwtbuilder.jamiekurtz.com/
	Good luck from the past.
*/
const JWT_TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJRZWx2aVEiLCJpYXQiOjE2NjUxMzE3NzYsImV4cCI6MjA3NTM1ODk3NiwiYXVkIjoiIiwic3ViIjoiNWY0ZGZhZjMxMmRkMWY1ZGM0MDk4YzBhIiwicm9sZXMiOlsiYWRtaW4iLCJzZWNvbmQiXX0.F_nCiHy6FDPPCq_guu0PgPrN1OZL41MLWR7BB7Iv9N0";

const handlers: RequestHandler[] = [
	graphql.mutation<PasswordLoginDTO, PasswordLoginVariablesDTO>("passwordLogin", (req, res, ctx) => {
		if (req.variables.data.email === "test@test.be" && req.variables.data.password === "correct") {
			return res(
				ctx.delay(delay),
				ctx.data({
					passwordLogin: {
						user: {
							id: "userId",
						},
						token: JWT_TOKEN,
					},
				}),
			);
		}

		return res(
			ctx.delay(delay),
			ctx.errors([
				{ message: "Invalid details" },
			]),
		);
	}),
	graphql.mutation<ResetPasswordAnonymouslyDTO, ResetPasswordAnonymouslyVariablesDTO>("resetPasswordAnonymously", (req, res, ctx) => {
		if (!req.variables.data.email) {
			return res(
				ctx.delay(delay),
				ctx.errors([
					{ message: "Missing email" },
				]),
			);
		}

		if (req.variables.data.email === "test@test.be") {
			return res(
				ctx.delay(delay),
				ctx.data({
					resetPassword: {
						success: true,
					},
				}),
			);
		}

		return res(
			ctx.delay(delay),
			ctx.data({
				resetPassword: {
					success: false,
				},
			}),
		);
	}),
	graphql.query<GetDomainListDTO, GetDomainListVariablesDTO>("getDomainList", (req, res, ctx) => {
		const domains: any = db.domain.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				domains,
			}),
		);
	}),
	graphql.query<GetDomainDTO, GetDomainVariablesDTO>("getDomain", (req, res, ctx) => {
		const domain: any = db.domain.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				domain,
			}),
		);
	}),
	graphql.query<GetDomainsDTO, GetDomainsVariablesDTO>("getDomains", (req, res, ctx) => {
		const domains: any = db.domain.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				domains,
			}),
		);
	}),
	graphql.query<GetCountriesDTO, GetCountriesVariablesDTO>("getCountries", (req, res, ctx) => {
		const countries: any = db.country.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				countries,
			}),
		);
	}),
	graphql.query<GetRegionsDTO, GetRegionsVariablesDTO>("getRegions", (req, res, ctx) => {
		const regions: any = db.region.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				regions,
			}),
		);
	}),
	graphql.query<GetAocsDTO, GetAocsVariablesDTO>("getAocs", (req, res, ctx) => {
		const aocs: any = db.aoc.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				aocs,
			}),
		);
	}),
	graphql.query<GetClassificationListDTO, GetClassificationListVariablesDTO>("getClassificationList", (req, res, ctx) => {
		const classifications: any = db.classification.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				classifications,
			}),
		);
	}),
	graphql.query<GetClassificationDTO, GetClassificationVariablesDTO>("getClassification", (req, res, ctx) => {
		const classification: any = db.classification.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				classification,
			}),
		);
	}),
	graphql.query<GetRegionListDTO, GetRegionListVariablesDTO>("getRegionList", (req, res, ctx) => {
		const regions: any = db.region.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				regions,
			}),
		);
	}),
	graphql.query<GetRegionDTO, GetRegionVariablesDTO>("getRegion", (req, res, ctx) => {
		const region: any = db.region.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				region,
			}),
		);
	}),
	graphql.query<GetCountryListDTO, GetCountryListVariablesDTO>("getCountryList", (req, res, ctx) => {
		const countries: any = db.country.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				countries,
			}),
		);
	}),
	graphql.query<GetCountryDTO, GetCountryVariablesDTO>("getCountry", (req, res, ctx) => {
		const country: any = db.country.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				country,
			}),
		);
	}),
	graphql.query<GetAocListDTO, GetAocListVariablesDTO>("getAocList", (req, res, ctx) => {
		const aocs: any = db.aoc.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				aocs,
			}),
		);
	}),
	graphql.query<GetAocDTO, GetAocVariablesDTO>("getAoc", (req, res, ctx) => {
		const aoc: any = db.aoc.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				aoc,
			}),
		);
	}),
	graphql.query<GetGrapeListDTO, GetGrapeListVariablesDTO>("getGrapeList", (req, res, ctx) => {
		const grapes: any = db.grape.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				grapes,
			}),
		);
	}),
	graphql.query<GetGrapeDTO, GetGrapeVariablesDTO>("getGrape", (req, res, ctx) => {
		const grape: any = db.grape.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				grape,
			}),
		);
	}),
	graphql.query<GetMainGrapeListDTO, GetMainGrapeListVariablesDTO>("getMainGrapeList", (req, res, ctx) => {
		const mainGrapes: any = db.mainGrape.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				mainGrapes,
			}),
		);
	}),
	graphql.query<GetMainGrapesDTO, GetMainGrapesVariablesDTO>("getMainGrapes", (req, res, ctx) => {
		const mainGrapes: any = db.mainGrape.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				mainGrapes,
			}),
		);
	}),
	graphql.query<GetMainGrapeDTO, GetMainGrapeVariablesDTO>("getMainGrape", (req, res, ctx) => {
		const mainGrape: any = db.mainGrape.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				mainGrape,
			}),
		);
	}),
	graphql.query<GetGrapesDTO, GetGrapesVariablesDTO>("getGrapes", (req, res, ctx) => {
		const grapes: any = db.grape.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				grapes,
			}),
		);
	}),
	graphql.query<GetCorrectionListDTO, GetCorrectionListVariablesDTO>("getCorrectionList", (req, res, ctx) => {
		const corrections: any = db.correction.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				corrections: corrections.map((c: any) => ({ ...c, name: c.originalText })),
			}),
		);
	}),
	graphql.query<GetCorrectionDTO, GetCorrectionVariablesDTO>("getCorrection", (req, res, ctx) => {
		const correction: any = db.correction.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				correction,
			}),
		);
	}),
	graphql.query<GetExceptionListDTO, GetExceptionListVariablesDTO>("getExceptionList", (req, res, ctx) => {
		const exceptions: any = db.exception.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				exceptions: exceptions.map((e: any) => ({ ...e, name: e.text })),
			}),
		);
	}),
	graphql.query<GetExceptionDTO, GetExceptionVariablesDTO>("getException", (req, res, ctx) => {
		const exception: any = db.exception.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				exception,
			}),
		);
	}),
	graphql.query<GetAdviceRequestsDTO, GetAdviceRequestsVariablesDTO>("getAdviceRequests", (req, res, ctx) => {
		const scanAdviceRequests: any = db.scanAdviceRequest.findMany({});
		// const buyingAdviceRequests: any = db.buyingAdviceRequest.findMany({});

		return res(
			ctx.delay(delay),
			ctx.data({
				adviceRequests: [...scanAdviceRequests],
			}),
		);
	}),
	graphql.query<GetAdviceRequestDTO, GetAdviceRequestVariablesDTO>("getAdviceRequest", (req, res, ctx) => {
		const scanAdviceRequest: any = db.scanAdviceRequest.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		const buyingAdviceRequest: any = db.buyingAdviceRequest.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				adviceRequest: scanAdviceRequest || buyingAdviceRequest,
			}),
		);
	}),
	graphql.query<GetFaqListDTO, GetFaqListVariablesDTO>("getFaqList", (req, res, ctx) => {
		const faqs: any = db.faq.getAll();

		return res(
			ctx.delay(delay),
			ctx.data({
				faqs: faqs.map((f: any) => ({ ...f, name: f.title })),
			}),
		);
	}),
	graphql.query<GetFaqDTO, GetFaqVariablesDTO>("getFaq", (req, res, ctx) => {
		const faq: any = db.faq.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				faq,
			}),
		);
	}),
	graphql.query<GetFoodListDTO, GetFoodListVariablesDTO>("getFoodList", (req, res, ctx) => {
		const foods: any = db.food.getAll();

		return res(
			ctx.delay(delay),
			ctx.data({
				foods: foods.map((f: any) => ({ ...f, name: f.title })),
			}),
		);
	}),
	graphql.query<GetFoodDTO, GetFoodVariablesDTO>("getFood", (req, res, ctx) => {
		const food: any = db.food.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				food,
			}),
		);
	}),
	graphql.query<GetClassificationsDTO, GetClassificationsVariablesDTO>("getClassifications", (req, res, ctx) => {
		const classifications: any = db.classification.getAll();

		return res(
			ctx.delay(delay),
			ctx.data({
				classifications,
			}),
		);
	}),
	graphql.query<GetSuggestionsDTO, GetSuggestionsVariablesDTO>("getSuggestions", (req, res, ctx) => {
		const suggestions: any = db.domainSuggestion.getAll();

		return res(
			ctx.delay(delay),
			ctx.data({
				suggestions,
			}),
		);
	}),
	graphql.query<GetSuggestionDTO, GetSuggestionVariablesDTO>("getSuggestion", (req, res, ctx) => {
		const suggestion: any = db.domainSuggestion.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				suggestion,
			}),
		);
	}),
	graphql.query<GetConversationsDTO, GetConversationsVariablesDTO>("getConversations", (req, res, ctx) => {
		const conversations: any = db.conversation.getAll();

		return res(
			ctx.delay(delay),
			ctx.data({
				conversations,
			}),
		);
	}),
	graphql.query<GetConversationDTO, GetConversationVariablesDTO>("getConversation", (req, res, ctx) => {
		const conversation: any = db.conversation.findFirst({
			where: { id: { equals: req.variables.id } },
		});

		return res(
			ctx.delay(delay),
			ctx.data({
				conversation,
			}),
		);
	}),
];

export default handlers;
