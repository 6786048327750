import { Box, Typography } from "@mui/material";
import { useTranslations } from "domains";
import { TranslationKey } from "hooks/translations/models/translate";
import { PropsWithChildren } from "react";

interface Props {
	title: TranslationKey;
}

export const PageHeader: React.FunctionComponent<PropsWithChildren<Props>> = ({ title, children }) => {
	const { t } = useTranslations();

	return (
		<Box>
			<Typography variant="h5">{t(title)}</Typography>
			{children}
		</Box>
	);
};
