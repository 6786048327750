import { UIComponent } from "@aptus/frontend-core";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslations, useUsers } from "domains";
import { EmailFormProps, useEmailForm } from "./useEmailForm";

export const EmailForm: UIComponent<EmailFormProps> = ({ formValues }) => {
	const { save } = useUsers();
	const { handleFormSubmit, register, errors } = useEmailForm({ initialValues: formValues, submit: save });
	const { t } = useTranslations();
	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t("emailSettings")}</h2>
			<TextField
				sx={{ mb: 2 }}
				{...register("email", { required: { value: true, message: "This is required." } })}
				error={!!errors?.email}
				helperText={errors?.email?.message}
				fullWidth
				name="email"
				id="email"
				label={t("userForm.email")}
				variant="outlined"
			/>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button type="submit" variant="contained">{t("updateEmail")}</Button>
			</Box>
		</form>
	);
};
