/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDomainListVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  paging?: Types.Maybe<Types.PagingDTO>;
}>;


export type GetDomainListDTO = (
  { __typename?: 'Query' }
  & { domains: Array<(
    { __typename?: 'Domain' }
    & Pick<Types.DomainDTO, 'id' | 'name'>
  )> }
);


export const GetDomainListDocumentDTO = gql`
    query getDomainList($search: String, $paging: Paging) {
  domains(
    filter: {archived: {equals: false}, name: {contains: $search}}
    paging: $paging
    sort: [{field: "name", order: ASC}]
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetDomainList__
 *
 * To run a query within a React component, call `useGetDomainList` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainList({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetDomainList(baseOptions?: Apollo.QueryHookOptions<GetDomainListDTO, GetDomainListVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDomainListDTO, GetDomainListVariablesDTO>(GetDomainListDocumentDTO, options);
      }
export function useGetDomainListLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetDomainListDTO, GetDomainListVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDomainListDTO, GetDomainListVariablesDTO>(GetDomainListDocumentDTO, options);
        }
export type GetDomainListHookResult = ReturnType<typeof useGetDomainList>;
export type GetDomainListLazyQueryDTOHookResult = ReturnType<typeof useGetDomainListLazyQueryDTO>;
export type GetDomainListQueryResultDTO = Apollo.QueryResult<GetDomainListDTO, GetDomainListVariablesDTO>;