/* eslint-disable no-nested-ternary */
import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { AdviceRequestDTO, AdviceRequestStatusDTO, AdviceRequestTypeDTO, AocTypeTemperatureDTO, BuyingAdviceRequestDTO, QueryDTO, ScanAdviceRequestDTO, ScanDTO, TypeTemperatureDTO, WineDTO, WineTypeDTO } from "models/schema";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { AdviceRequest, ShortenedWine } from "./models/adviceRequest";
import { UpdateAdviceRequestMutationFnDTO } from "./models/updateAdviceRequest";
import { ScanAdviceRequestUpdateInput } from "./models/adviceRequestInput";
import { AdviceRequestsAPI, UpdateAdviceRequestMutation } from "./useAdviceRequestsUseCases";

interface Mapper {
	toAPI: ToAPI<AdviceRequestsAPIDTO, AdviceRequestsAPI>;
	toUpdateMutation: ToInternal<UpdateAdviceRequestMutationFnDTO, UpdateAdviceRequestMutation>;
	toAdviceRequest: ToInternal<DeepPartial<AdviceRequestDTO>, AdviceRequest>;
}

export type AdviceRequestsAPIDTO = ApolloAPI<QueryDTO, "adviceRequests" | "adviceRequest">;

export const isScanRequest = (request: any): request is ScanAdviceRequestDTO => request?.type === AdviceRequestTypeDTO.ScanDTO;
const isBuyRequest = (request: any): request is BuyingAdviceRequestDTO => request?.type === AdviceRequestTypeDTO.BuyingDTO;

const isScanUpdate = (input: any): input is ScanAdviceRequestUpdateInput => input?.type === AdviceRequestTypeDTO.ScanDTO;
const isScan = (data: any): data is ScanDTO => !!data.advices;
const isWine = (data: any): data is WineDTO => !!data.type;

class AdviceRequestMapper implements Mapper {
	public toAdviceRequest: Mapper["toAdviceRequest"] = (adviceRequest) => {
		const mapToTemperatures = (temperatures: DeepPartial<TypeTemperatureDTO | AocTypeTemperatureDTO>[] | null | undefined) => (
			(temperatures || []).map((temp) => ({ type: temp.type || WineTypeDTO.RedDTO, temperature: temp.temperature || 0 }))
		);
		const mapToShortenedWine = (wine: DeepPartial<WineDTO> | DeepPartial<ScanDTO>): ShortenedWine | undefined => {
			if (isScan(wine)) {
				const advice = wine.advices?.[0];
				return {
					year: advice?.year || undefined,
					type: wine.wineType || WineTypeDTO.RedDTO,
					descriptions: {
						grape: (advice?.description?.grape && {
							id: advice.description?.grape.id || "",
							temperatures: mapToTemperatures(advice.description.grape.temperatures),
						}) || undefined,
						aoc: (advice?.description?.aoc && {
							id: advice.description?.aoc.id || "",
							temperatures: mapToTemperatures(advice.description.aoc.temperatures),
						}) || undefined,
						region: (advice?.description?.region && {
							id: advice.description?.region.id || "",
							temperatures: mapToTemperatures(advice.description.region.temperatures),
						}) || undefined,
						country: (advice?.description?.country && {
							id: advice.description?.country.id || "",
							temperatures: mapToTemperatures(advice.description.country.temperatures),
						}) || undefined,
						domainId: advice?.description?.domain?.id,
						customDomain: advice?.description?.domainText || "",
						classificationId: advice?.description?.classification?.id,
					},
				};
			}

			if (isWine(wine)) {
				return {
					id: wine.id || "",
					year: wine.year || undefined,
					type: wine.type || WineTypeDTO.RedDTO,
					descriptions: {
						grape: (wine.description?.grape && {
							id: wine.description?.grape.id || "",
							temperatures: mapToTemperatures(wine.description.grape.temperatures),
						}) || undefined,
						aoc: (wine.description?.aoc && {
							id: wine.description?.aoc.id || "",
							temperatures: mapToTemperatures(wine.description.aoc.temperatures),
						}) || undefined,
						region: (wine.description?.region && {
							id: wine.description?.region.id || "",
							temperatures: mapToTemperatures(wine.description.region.temperatures),
						}) || undefined,
						country: (wine.description?.country && {
							id: wine.description?.country.id || "",
							temperatures: mapToTemperatures(wine.description.country.temperatures),
						}) || undefined,
						domainId: wine.description?.domain?.id,
						customDomain: wine.description?.domainText || "",
						classificationId: wine.description?.classification?.id,
					},
					customTemperature: wine.customTemp || 0,
					idealTemperature: wine.idealTemp || 0,
				};
			}

			return undefined;
		};

		return ({
			id: adviceRequest.id || "",
			status: adviceRequest.status || AdviceRequestStatusDTO.NewDTO,
			createdAt: adviceRequest.createdAt,
			scanId: isScanRequest(adviceRequest) && adviceRequest.scan.id,
			type: adviceRequest.type || (isScanRequest(adviceRequest) ? AdviceRequestTypeDTO.ScanDTO : AdviceRequestTypeDTO.BuyingDTO),
			owner: {
				id: (adviceRequest.owner && adviceRequest.owner.id) || "",
				givenName: (adviceRequest.owner && adviceRequest.owner.givenName) || "",
				familyName: (adviceRequest.owner && adviceRequest.owner.familyName) || "",
				email: (adviceRequest.owner && adviceRequest.owner.email) || "",
				language: (adviceRequest.owner && adviceRequest.owner.language) || "",
			},
			image: isScanRequest(adviceRequest) ? adviceRequest.scan.image : (isBuyRequest(adviceRequest) ? adviceRequest.wine.image : ""),
			...(isScanRequest(adviceRequest) && {
				wine: mapToShortenedWine(adviceRequest.wineResult || adviceRequest.scan),
				comment: adviceRequest.comment,
				internalComment: adviceRequest.internalComment,
			}),
			...(isBuyRequest(adviceRequest) && {
				wine: adviceRequest.wine && mapToShortenedWine(adviceRequest.wine),
				deliveryAddress: adviceRequest.deliveryAddress,
			}),
		});
	};

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: AdviceRequestsAPIDTO["data"]): DeepPartial<AdviceRequestDTO[]> => {
			if (data?.adviceRequest) {
				return [data.adviceRequest];
			}

			if (data?.adviceRequests) {
				return data.adviceRequests;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toAdviceRequest),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateAdviceRequestMutationFnDTO, UpdateAdviceRequestMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						internalComment: isScanUpdate(input) ? input.internalComment : undefined,
						comment: isScanUpdate(input) ? input.comment : undefined,
						wineResult: isScanUpdate(input) ? input.wineResult : undefined,
						status: input.status,
						type: input.type,
					},
				},
			}),
			toOutput: (output) => ((output?.data?.updateOneAdviceRequest ? this.toAdviceRequest(output.data.updateOneAdviceRequest) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}

export default AdviceRequestMapper;
