/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConversationsVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.ConversationFilterDTO>;
  paging?: Types.Maybe<Types.PagingDTO>;
  sort?: Types.Maybe<Array<Types.Maybe<Types.SortDTO>> | Types.Maybe<Types.SortDTO>>;
}>;


export type GetConversationsDTO = (
  { __typename?: 'Query' }
  & { conversations: Array<(
    { __typename?: 'Conversation' }
    & Pick<Types.ConversationDTO, 'id' | 'status'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<Types.UserDTO, 'id' | 'givenName' | 'familyName' | 'language'>
    ), messages: Array<(
      { __typename?: 'ConversationMessage' }
      & Pick<Types.ConversationMessageDTO, 'content' | 'createdAt'>
      & { owner: (
        { __typename?: 'User' }
        & Pick<Types.UserDTO, 'id' | 'givenName' | 'familyName'>
      ) }
    )> }
  )> }
);


export const GetConversationsDocumentDTO = gql`
    query getConversations($filter: ConversationFilter, $paging: Paging, $sort: [Sort]) {
  conversations(filter: $filter, paging: $paging, sort: $sort) {
    id
    owner {
      id
      givenName
      familyName
      language
    }
    status
    messages {
      content
      createdAt
      owner {
        id
        givenName
        familyName
      }
    }
  }
}
    `;

/**
 * __useGetConversations__
 *
 * To run a query within a React component, call `useGetConversations` and pass it any options that fit your needs.
 * When your component renders, `useGetConversations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversations({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetConversations(baseOptions?: Apollo.QueryHookOptions<GetConversationsDTO, GetConversationsVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationsDTO, GetConversationsVariablesDTO>(GetConversationsDocumentDTO, options);
      }
export function useGetConversationsLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationsDTO, GetConversationsVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationsDTO, GetConversationsVariablesDTO>(GetConversationsDocumentDTO, options);
        }
export type GetConversationsHookResult = ReturnType<typeof useGetConversations>;
export type GetConversationsLazyQueryDTOHookResult = ReturnType<typeof useGetConversationsLazyQueryDTO>;
export type GetConversationsQueryResultDTO = Apollo.QueryResult<GetConversationsDTO, GetConversationsVariablesDTO>;