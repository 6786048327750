/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateConversationMessageVariablesDTO = Types.Exact<{
  data: Types.ConversationMessageCreateInputDTO;
}>;


export type CreateConversationMessageDTO = (
  { __typename?: 'Mutation' }
  & { createOneConversationMessage: (
    { __typename?: 'Conversation' }
    & Pick<Types.ConversationDTO, 'id' | 'status'>
    & { messages: Array<(
      { __typename?: 'ConversationMessage' }
      & Pick<Types.ConversationMessageDTO, 'content' | 'createdAt'>
      & { owner: (
        { __typename?: 'User' }
        & Pick<Types.UserDTO, 'id' | 'givenName' | 'familyName'>
      ) }
    )> }
  ) }
);


export const CreateConversationMessageDocumentDTO = gql`
    mutation createConversationMessage($data: ConversationMessageCreateInput!) {
  createOneConversationMessage(data: $data) {
    id
    status
    messages {
      content
      createdAt
      owner {
        id
        givenName
        familyName
      }
    }
  }
}
    `;
export type CreateConversationMessageMutationFnDTO = Apollo.MutationFunction<CreateConversationMessageDTO, CreateConversationMessageVariablesDTO>;

/**
 * __useCreateConversationMessage__
 *
 * To run a mutation, you first call `useCreateConversationMessage` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMessage` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMessage, { data, loading, error }] = useCreateConversationMessage({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateConversationMessage(baseOptions?: Apollo.MutationHookOptions<CreateConversationMessageDTO, CreateConversationMessageVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationMessageDTO, CreateConversationMessageVariablesDTO>(CreateConversationMessageDocumentDTO, options);
      }
export type CreateConversationMessageHookResult = ReturnType<typeof useCreateConversationMessage>;
export type CreateConversationMessageMutationResultDTO = Apollo.MutationResult<CreateConversationMessageDTO>;
export type CreateConversationMessageMutationOptionsDTO = Apollo.BaseMutationOptions<CreateConversationMessageDTO, CreateConversationMessageVariablesDTO>;