/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { UserFragmentDTO } from '../../../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUsersVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.UserFilterDTO>;
  paging?: Types.Maybe<Types.PagingDTO>;
  sort?: Types.Maybe<Array<Types.Maybe<Types.SortDTO>> | Types.Maybe<Types.SortDTO>>;
}>;


export type GetUsersDTO = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & UserFragmentDTO
  )> }
);


export const GetUsersDocumentDTO = gql`
    query getUsers($filter: UserFilter, $paging: Paging, $sort: [Sort]) {
  users(filter: $filter, paging: $paging, sort: $sort) {
    ...userFragment
  }
}
    ${UserFragmentDTO}`;

/**
 * __useGetUsers__
 *
 * To run a query within a React component, call `useGetUsers` and pass it any options that fit your needs.
 * When your component renders, `useGetUsers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsers({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetUsers(baseOptions?: Apollo.QueryHookOptions<GetUsersDTO, GetUsersVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersDTO, GetUsersVariablesDTO>(GetUsersDocumentDTO, options);
      }
export function useGetUsersLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersDTO, GetUsersVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersDTO, GetUsersVariablesDTO>(GetUsersDocumentDTO, options);
        }
export type GetUsersHookResult = ReturnType<typeof useGetUsers>;
export type GetUsersLazyQueryDTOHookResult = ReturnType<typeof useGetUsersLazyQueryDTO>;
export type GetUsersQueryResultDTO = Apollo.QueryResult<GetUsersDTO, GetUsersVariablesDTO>;