/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateConversationVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.ConversationUpdateInputDTO>;
}>;


export type UpdateConversationDTO = (
  { __typename?: 'Mutation' }
  & { updateOneConversation: (
    { __typename?: 'Conversation' }
    & Pick<Types.ConversationDTO, 'id' | 'status'>
  ) }
);


export const UpdateConversationDocumentDTO = gql`
    mutation updateConversation($id: String!, $data: ConversationUpdateInput) {
  updateOneConversation(id: $id, data: $data) {
    id
    status
  }
}
    `;
export type UpdateConversationMutationFnDTO = Apollo.MutationFunction<UpdateConversationDTO, UpdateConversationVariablesDTO>;

/**
 * __useUpdateConversation__
 *
 * To run a mutation, you first call `useUpdateConversation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversation, { data, loading, error }] = useUpdateConversation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateConversation(baseOptions?: Apollo.MutationHookOptions<UpdateConversationDTO, UpdateConversationVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConversationDTO, UpdateConversationVariablesDTO>(UpdateConversationDocumentDTO, options);
      }
export type UpdateConversationHookResult = ReturnType<typeof useUpdateConversation>;
export type UpdateConversationMutationResultDTO = Apollo.MutationResult<UpdateConversationDTO>;
export type UpdateConversationMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateConversationDTO, UpdateConversationVariablesDTO>;