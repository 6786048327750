import styled from "styled-components";

export const RequestsContentStyle = styled.div`
	padding: 30px;
	background-color: #F5F5F5;

	p {
		margin-top: 10px;
		opacity: 0.5;
	}
`;

export const RequestsGridStyle = styled.div`
	margin: 20px 0;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(5, 1fr);
`;
