/* eslint-disable */
import * as Types from '../../../models/schema';

import { CorrectionFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateCorrectionVariablesDTO = Types.Exact<{
  data: Types.CorrectionCreateInputDTO;
}>;


export type CreateCorrectionDTO = (
  { __typename?: 'Mutation' }
  & { createOneCorrection: (
    { __typename?: 'Correction' }
    & CorrectionFragmentDTO
  ) }
);


export const CreateCorrectionDocumentDTO = gql`
    mutation createCorrection($data: CorrectionCreateInput!) {
  createOneCorrection(data: $data) {
    ...correctionFragment
  }
}
    ${CorrectionFragmentDTO}`;
export type CreateCorrectionMutationFnDTO = Apollo.MutationFunction<CreateCorrectionDTO, CreateCorrectionVariablesDTO>;

/**
 * __useCreateCorrection__
 *
 * To run a mutation, you first call `useCreateCorrection` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCorrection` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCorrection, { data, loading, error }] = useCreateCorrection({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCorrection(baseOptions?: Apollo.MutationHookOptions<CreateCorrectionDTO, CreateCorrectionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCorrectionDTO, CreateCorrectionVariablesDTO>(CreateCorrectionDocumentDTO, options);
      }
export type CreateCorrectionHookResult = ReturnType<typeof useCreateCorrection>;
export type CreateCorrectionMutationResultDTO = Apollo.MutationResult<CreateCorrectionDTO>;
export type CreateCorrectionMutationOptionsDTO = Apollo.BaseMutationOptions<CreateCorrectionDTO, CreateCorrectionVariablesDTO>;