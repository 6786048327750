/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRegionListVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  paging?: Types.Maybe<Types.PagingDTO>;
}>;


export type GetRegionListDTO = (
  { __typename?: 'Query' }
  & { regions: Array<(
    { __typename?: 'Region' }
    & Pick<Types.RegionDTO, 'id' | 'name'>
  )> }
);


export const GetRegionListDocumentDTO = gql`
    query getRegionList($search: String, $paging: Paging) {
  regions(
    filter: {archived: {equals: false}, name: {contains: $search}}
    paging: $paging
    sort: [{field: "name", order: ASC}]
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetRegionList__
 *
 * To run a query within a React component, call `useGetRegionList` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionList({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetRegionList(baseOptions?: Apollo.QueryHookOptions<GetRegionListDTO, GetRegionListVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionListDTO, GetRegionListVariablesDTO>(GetRegionListDocumentDTO, options);
      }
export function useGetRegionListLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionListDTO, GetRegionListVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionListDTO, GetRegionListVariablesDTO>(GetRegionListDocumentDTO, options);
        }
export type GetRegionListHookResult = ReturnType<typeof useGetRegionList>;
export type GetRegionListLazyQueryDTOHookResult = ReturnType<typeof useGetRegionListLazyQueryDTO>;
export type GetRegionListQueryResultDTO = Apollo.QueryResult<GetRegionListDTO, GetRegionListVariablesDTO>;