/* eslint-disable */
import * as Types from '../../../models/schema';

import { AocFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateAocVariablesDTO = Types.Exact<{
  data: Types.AocCreateInputDTO;
}>;


export type CreateAocDTO = (
  { __typename?: 'Mutation' }
  & { createOneAoc: (
    { __typename?: 'Aoc' }
    & AocFragmentDTO
  ) }
);


export const CreateAocDocumentDTO = gql`
    mutation createAoc($data: AocCreateInput!) {
  createOneAoc(data: $data) {
    ...aocFragment
  }
}
    ${AocFragmentDTO}`;
export type CreateAocMutationFnDTO = Apollo.MutationFunction<CreateAocDTO, CreateAocVariablesDTO>;

/**
 * __useCreateAoc__
 *
 * To run a mutation, you first call `useCreateAoc` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAoc` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAoc, { data, loading, error }] = useCreateAoc({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAoc(baseOptions?: Apollo.MutationHookOptions<CreateAocDTO, CreateAocVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAocDTO, CreateAocVariablesDTO>(CreateAocDocumentDTO, options);
      }
export type CreateAocHookResult = ReturnType<typeof useCreateAoc>;
export type CreateAocMutationResultDTO = Apollo.MutationResult<CreateAocDTO>;
export type CreateAocMutationOptionsDTO = Apollo.BaseMutationOptions<CreateAocDTO, CreateAocVariablesDTO>;