import { UILogic } from "@aptus/frontend-core";
import { AdviceRequest, ScanAdviceRequest } from "hooks/adviceRequests/models/adviceRequest";
import { WineTypeDTO } from "models/schema";

export interface LabelDataFormValues {
	year?: number;
	wineType: WineTypeDTO;
	overwriteTemperature?: boolean;
	idealTemp?: number;
	customTemp?: number;
	grape?: string;
	aoc?: string;
	region?: string;
	country?: string;
	domain?: string;
	id?: string;
	domainNotFound?: boolean;
	customDomain?: string;
	classification?: string;
}

export interface ScanAdviceRequestFormValues {
	id: string;
	comment: string;
	internalComment: string;
}

export interface SuggestedTemperatures {
	grapeTemperature?: number;
	aocTemperature?: number;
	regionTemperature?: number;
	countryTemperature?: number;
	suggestedTemperature: number;
}

interface DataValues {
	owner?: AdviceRequest["owner"];
	createdAt?: Date;
	id?: string;
	status?: AdviceRequest["status"];
	type?: AdviceRequest["type"];
	image?: AdviceRequest["image"];
}

export interface ScanFormProps {
	scanAdviceRequest: AdviceRequest & ScanAdviceRequest;
}

interface Result {
	initialLabelDataFormValues: LabelDataFormValues;
	initialScanAdviceRequestFormValues: ScanAdviceRequestFormValues;
	dataValues: DataValues;
}

export const useScanForm: UILogic<ScanFormProps, Result> = ({ scanAdviceRequest: initialValues }) => {
	const initialLabelDataFormValues: LabelDataFormValues = {
		id: initialValues.wine?.id,
		year: initialValues?.wine?.year,
		wineType: initialValues?.wine?.type || WineTypeDTO.RedDTO,
		overwriteTemperature: !!initialValues?.wine?.customTemperature,
		idealTemp: initialValues.wine?.idealTemperature,
		customTemp: initialValues?.wine?.customTemperature,
		grape: initialValues?.wine?.descriptions.grape?.id,
		aoc: initialValues?.wine?.descriptions.aoc?.id,
		region: initialValues?.wine?.descriptions.region?.id,
		country: initialValues?.wine?.descriptions.country?.id,
		domain: initialValues?.wine?.descriptions.domainId,
		domainNotFound: !!initialValues?.wine?.descriptions.customDomain,
		customDomain: initialValues?.wine?.descriptions.customDomain,
		classification: initialValues?.wine?.descriptions.classificationId,
	};

	const initialScanAdviceRequestFormValues: ScanAdviceRequestFormValues = {
		id: initialValues.id,
		comment: initialValues.comment,
		internalComment: initialValues.internalComment,
	};

	const createDataValues = (): DataValues => ({
		owner: initialValues?.owner,
		createdAt: initialValues?.createdAt ? new Date(initialValues?.createdAt) : undefined,
		id: initialValues?.id,
		status: initialValues?.status,
		type: initialValues?.type,
		image: initialValues?.image,
	});

	return {
		initialLabelDataFormValues,
		initialScanAdviceRequestFormValues,
		dataValues: createDataValues(),
	};
};
