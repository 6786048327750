import { UIComponent } from "@aptus/frontend-core";
import { Chip } from "@mui/material";
import { formatDistance } from "date-fns";
import { useAdviceRequests, useTranslations } from "domains";
import { AdviceRequest, BuyingAdviceRequest } from "hooks/adviceRequests/models/adviceRequest";
import { RequestDetailContent, TitleInfoBar } from "pages/requestDetailPage/requestDetailPageStyle";

import { LabelDataForm } from "../scanForm/forms/labelDataForm";
import { AddressItem } from "./buyingFormStyle";
import { useBuyingForm } from "./useBuyingForm";

interface BuyingFormProps {
	data: AdviceRequest & BuyingAdviceRequest;
}

export const BuyingForm: UIComponent<BuyingFormProps> = ({ data }) => {
	const { save } = useAdviceRequests();
	const { dataValues, labelDataFormValues } = useBuyingForm({ buyingAdviceRequest: data, save });
	const { t } = useTranslations();

	return (
		<>
			<TitleInfoBar>
				<h3>
					{dataValues.owner?.givenName}
					{" "}
					{dataValues.owner?.familyName}
				</h3>
				<Chip style={{ marginBottom: 10 }} label="Buy" color="primary" />
				<p>
					{`${t("createdBy")} ${dataValues.owner?.givenName} ${dataValues.owner?.familyName}, ${dataValues.createdAt
						? formatDistance(dataValues.createdAt, new Date(), { addSuffix: true })
						: null} | ${dataValues.owner?.email}`}
				</p>
			</TitleInfoBar>
			<RequestDetailContent>
				<div>
					<LabelDataForm
						adviceRequestId={data.id}
						initialValues={labelDataFormValues}
						scanId="" // TODO: what do we do?
					/>
					<div style={{ width: "100%" }}>
						<h4>Delivery data</h4>
						<div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
							<AddressItem>
								<p>First name</p>
								<p>{dataValues.address.firstName}</p>
							</AddressItem>
							<AddressItem>
								<p>Last name</p>
								<p>{dataValues.address.lastName}</p>
							</AddressItem>
						</div>
						<AddressItem>
							<p>Address</p>
							<p>{dataValues.address.address}</p>
						</AddressItem>
						<AddressItem>
							<p>Phone number</p>
							<p>{dataValues.address.phoneNumber}</p>
						</AddressItem>
						<AddressItem>
							<p>E-mail address</p>
							<p>{dataValues.address.email}</p>
						</AddressItem>
					</div>
				</div>
				<div>
					<h4>{t("OCR.data")}</h4>
					<img width="100%" src={dataValues.image} alt="wine" />
				</div>
			</RequestDetailContent>
		</>
	);
};
