/* eslint-disable */
import * as Types from '../../../../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCountryVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetCountryDTO = (
  { __typename?: 'Query' }
  & { country: (
    { __typename?: 'Country' }
    & Pick<Types.CountryDTO, 'id' | 'name'>
    & { temperatures?: Types.Maybe<Array<(
      { __typename?: 'TypeTemperature' }
      & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  ) }
);


export const GetCountryDocumentDTO = gql`
    query getCountry($id: String!) {
  country(id: $id) {
    id
    name
    temperatures {
      type
      temperature
    }
  }
}
    `;

/**
 * __useGetCountry__
 *
 * To run a query within a React component, call `useGetCountry` and pass it any options that fit your needs.
 * When your component renders, `useGetCountry` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountry({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCountry(baseOptions: Apollo.QueryHookOptions<GetCountryDTO, GetCountryVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryDTO, GetCountryVariablesDTO>(GetCountryDocumentDTO, options);
      }
export function useGetCountryLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryDTO, GetCountryVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryDTO, GetCountryVariablesDTO>(GetCountryDocumentDTO, options);
        }
export type GetCountryHookResult = ReturnType<typeof useGetCountry>;
export type GetCountryLazyQueryDTOHookResult = ReturnType<typeof useGetCountryLazyQueryDTO>;
export type GetCountryQueryResultDTO = Apollo.QueryResult<GetCountryDTO, GetCountryVariablesDTO>;