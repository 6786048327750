import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { QueryDTO, WineDTO } from "models/schema";
import { CreateWineMutationFnDTO } from "./models/createWine";
import { UpdateWineMutationFnDTO } from "./models/updateWine";
import { CreateWineMutation, UpdateWineMutation, WinesAPI } from "./useWinesUseCases";
import { Wine } from "./models/wine";

export type WinesAPIDTO = ApolloAPI<QueryDTO, "wines" | "wine">;

interface Mapper {
	toAPI: ToAPI<WinesAPIDTO, WinesAPI>;
	toUpdateMutation: ToInternal<UpdateWineMutationFnDTO, UpdateWineMutation>;
	toCreateMutation: ToInternal<CreateWineMutationFnDTO, CreateWineMutation>;
	toWine: ToInternal<DeepPartial<WineDTO>, Wine>;
}

export class WineMapper implements Mapper {
	public toWine: Mapper["toWine"] = (wine) => ({
		id: wine.id || "",
		type: wine.type,
		description: wine.description,
		year: wine.year || undefined,
		idealTemp: wine.idealTemp || undefined,
		customTemp: wine.customTemp || undefined,
		archived: wine.archived,
		amount: wine.amount || 0,
		image: wine.image || "",
		rating: wine.rating || undefined,
		comment: wine.comment || "",
		owner: {
			id: wine.owner?.id || "",
			email: wine.owner?.email || "",
			language: wine.owner?.language || "",
			fullName: wine.owner?.givenName && wine.owner.givenName ? `${wine.owner.givenName} ${wine.owner.familyName}` : "",
		},
		scan: {
			id: wine.scan?.id || "",
			image: wine.scan?.image || "",
			wineType: wine.scan?.wineType,
			advices: (wine.scan?.advices || []).map((advice) => ({ year: advice?.year || undefined, idealTemp: advice?.idealTemp || undefined })),
			used: wine.scan?.used,
		},
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: WinesAPIDTO["data"]): DeepPartial<WineDTO[]> => {
			if (data?.wine) {
				return [data.wine];
			}

			if (data?.wines) {
				return data.wines;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toWine),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateWineMutationFnDTO, UpdateWineMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						idealTemp: input.idealTemp,
						customTemp: input.customTemp,
						type: input.wineType,
						description: {
							country: input.country,
							region: input.region,
							classification: input.classification,
							grape: input.grape,
							domain: input.domain,
							aoc: input.aoc,
						},
						year: input.year,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneWine ? this.toWine(output.data.updateOneWine) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateWineMutationFnDTO, CreateWineMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						idealTemp: input.idealTemp,
						customTemp: input.customTemp,
						type: input.wineType,
						description: {
							country: input.country,
							region: input.region,
							classification: input.classification,
							grape: input.grape,
							domain: input.domain,
							aoc: input.aoc,
						},
						archived: false,
						amount: 1,
						scan: input.scanId,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneWine ? this.toWine(output.data.createOneWine) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
