/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AdviceRequestCreateInput: any;
  AdviceRequestUpdateInput: any;
  AlgorithmConfigTypeSpecificsInput: any;
  Date: string;
  /** DateTime custom scalar type */
  DateTime: string;
  JSON: any;
  SuggestionCreateInput: any;
  SuggestionUpdateInput: any;
  Time: any;
  Upload: any;
};


export type AddressDTO = {
  __typename?: 'Address';
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
};

export type AddressInputDTO = {
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
};

export type AdviceRequestDTO = {
  id: Scalars['String'];
  owner: UserDTO;
  createdAt: Scalars['DateTime'];
  archived: Scalars['Boolean'];
  status: AdviceRequestStatusDTO;
  type: AdviceRequestTypeDTO;
};


export type AdviceRequestFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  type?: Maybe<AdviceRequestTypeFilterDTO>;
  status?: Maybe<AdviceRequestStatusFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  owner?: Maybe<UserFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<AdviceRequestFilterDTO>>;
  OR?: Maybe<Array<AdviceRequestFilterDTO>>;
};

export enum AdviceRequestStatusDTO {
  NewDTO = 'new',
  FinishedDTO = 'finished',
  DeletedDTO = 'deleted'
}

export type AdviceRequestStatusFilterDTO = {
  equals?: Maybe<AdviceRequestStatusDTO>;
  not?: Maybe<AdviceRequestStatusDTO>;
  in?: Maybe<Array<Maybe<AdviceRequestStatusDTO>>>;
  notIn?: Maybe<Array<Maybe<AdviceRequestStatusDTO>>>;
};

export type AdviceRequestSuggestionSourceDTO = SuggestionSourceDTO & {
  __typename?: 'AdviceRequestSuggestionSource';
  type: SuggestionSourceTypeDTO;
  adviceRequest: AdviceRequestDTO;
};

export enum AdviceRequestTypeDTO {
  ScanDTO = 'scan',
  BuyingDTO = 'buying'
}

export type AdviceRequestTypeFilterDTO = {
  equals?: Maybe<AdviceRequestTypeDTO>;
  not?: Maybe<AdviceRequestTypeDTO>;
  in?: Maybe<Array<Maybe<AdviceRequestTypeDTO>>>;
  notIn?: Maybe<Array<Maybe<AdviceRequestTypeDTO>>>;
};


export type AlgorithmConfigDTO = {
  __typename?: 'AlgorithmConfig';
  id: Scalars['String'];
  correction: AlgorithmConfigScoreConfigDTO;
  classification: AlgorithmConfigScoreConfigDTO;
  algorithms: Array<AlgorithmConfigTypeSpecificsDTO>;
};

export type AlgorithmConfigCreateInputDTO = {
  correction: AlgorithmConfigScoreConfigInputDTO;
  classification: AlgorithmConfigScoreConfigInputDTO;
  algorithms: Array<Scalars['AlgorithmConfigTypeSpecificsInput']>;
};

export type AlgorithmConfigFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<AlgorithmConfigFilterDTO>>;
  OR?: Maybe<Array<AlgorithmConfigFilterDTO>>;
};

export type AlgorithmConfigScoreConfigDTO = {
  __typename?: 'AlgorithmConfigScoreConfig';
  costs: AlgorithmConfigScoreConfigCostsDTO;
  scoreThresholds: Array<AlgorithmConfigScoreConfigScoreThresholdDTO>;
};

export type AlgorithmConfigScoreConfigCostsDTO = {
  __typename?: 'AlgorithmConfigScoreConfigCosts';
  insertion: Scalars['Int'];
  deletion: Scalars['Int'];
  substitution: Scalars['Int'];
  transposition: Scalars['Int'];
};

export type AlgorithmConfigScoreConfigCostsInputDTO = {
  insertion: Scalars['Int'];
  deletion: Scalars['Int'];
  substitution: Scalars['Int'];
  transposition: Scalars['Int'];
};

export type AlgorithmConfigScoreConfigInputDTO = {
  costs: AlgorithmConfigScoreConfigCostsInputDTO;
  scoreThresholds: Array<AlgorithmConfigScoreConfigScoreThresholdInputDTO>;
};

export type AlgorithmConfigScoreConfigScoreThresholdDTO = {
  __typename?: 'AlgorithmConfigScoreConfigScoreThreshold';
  textLength: Scalars['Int'];
  threshold: Scalars['Float'];
};

export type AlgorithmConfigScoreConfigScoreThresholdInputDTO = {
  textLength: Scalars['Int'];
  threshold: Scalars['Float'];
};

export enum AlgorithmConfigTypeDTO {
  ExceptionDTO = 'exception',
  StepDTO = 'step'
}

export type AlgorithmConfigTypeSpecificsDTO = {
  type: AlgorithmConfigTypeDTO;
  name: Scalars['String'];
  priority: Scalars['Int'];
};

export type AlgorithmConfigTypeSpecificsForExceptionDTO = AlgorithmConfigTypeSpecificsDTO & {
  __typename?: 'AlgorithmConfigTypeSpecificsForException';
  type: AlgorithmConfigTypeDTO;
  name: Scalars['String'];
  priority: Scalars['Int'];
  exception: AlgorithmConfigScoreConfigDTO;
};

export type AlgorithmConfigTypeSpecificsForExceptionInputDTO = {
  type: AlgorithmConfigTypeDTO;
  name: Scalars['String'];
  priority: Scalars['Int'];
  exception: AlgorithmConfigScoreConfigInputDTO;
};

export type AlgorithmConfigTypeSpecificsForStepDTO = AlgorithmConfigTypeSpecificsDTO & {
  __typename?: 'AlgorithmConfigTypeSpecificsForStep';
  type: AlgorithmConfigTypeDTO;
  name: Scalars['String'];
  priority: Scalars['Int'];
  country?: Maybe<AlgorithmConfigScoreConfigDTO>;
  region?: Maybe<AlgorithmConfigScoreConfigDTO>;
  aoc?: Maybe<AlgorithmConfigScoreConfigDTO>;
  grape?: Maybe<AlgorithmConfigScoreConfigDTO>;
  domain?: Maybe<AlgorithmConfigScoreConfigDTO>;
  steps: Array<AlgorithmConfigTypeSpecificsForStepTypeDTO>;
};

export type AlgorithmConfigTypeSpecificsForStepInputDTO = {
  type: AlgorithmConfigTypeDTO;
  name: Scalars['String'];
  priority: Scalars['Int'];
  country?: Maybe<AlgorithmConfigScoreConfigInputDTO>;
  region?: Maybe<AlgorithmConfigScoreConfigInputDTO>;
  aoc?: Maybe<AlgorithmConfigScoreConfigInputDTO>;
  grape?: Maybe<AlgorithmConfigScoreConfigInputDTO>;
  domain?: Maybe<AlgorithmConfigScoreConfigInputDTO>;
  steps: Array<AlgorithmConfigTypeSpecificsForStepTypeDTO>;
};

export enum AlgorithmConfigTypeSpecificsForStepTypeDTO {
  CountryDTO = 'country',
  RegionDTO = 'region',
  RegionWithRelationsDTO = 'regionWithRelations',
  AocDTO = 'aoc',
  AocWithRelationsDTO = 'aocWithRelations',
  GrapeDTO = 'grape',
  DomainDTO = 'domain'
}


export type AlgorithmConfigUpdateInputDTO = {
  correction?: Maybe<AlgorithmConfigScoreConfigInputDTO>;
  classification?: Maybe<AlgorithmConfigScoreConfigInputDTO>;
  algorithms?: Maybe<Array<Scalars['AlgorithmConfigTypeSpecificsInput']>>;
};

export type AlgorithmConfigWithoutIdDTO = {
  __typename?: 'AlgorithmConfigWithoutId';
  correction: AlgorithmConfigScoreConfigDTO;
  classification: AlgorithmConfigScoreConfigDTO;
  algorithms: Array<AlgorithmConfigTypeSpecificsDTO>;
};

export type AocDTO = {
  __typename?: 'Aoc';
  id: Scalars['String'];
  name: Scalars['String'];
  region?: Maybe<RegionDTO>;
  country: CountryDTO;
  temperatures?: Maybe<Array<AocTypeTemperatureDTO>>;
  archived: Scalars['Boolean'];
};

export type AocCreateInputDTO = {
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  temperatures?: Maybe<Array<Maybe<AocTypeTemperatureCreateInputDTO>>>;
};

export type AocFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  region?: Maybe<RegionFilterDTO>;
  country?: Maybe<CountryFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<AocFilterDTO>>;
  OR?: Maybe<Array<AocFilterDTO>>;
};

export type AocTypeTemperatureDTO = {
  __typename?: 'AocTypeTemperature';
  type: WineTypeDTO;
  temperature?: Maybe<Scalars['Int']>;
  mainGrape?: Maybe<MainGrapeDTO>;
  temperatureDescription?: Maybe<Scalars['String']>;
  temperatureDescriptions?: Maybe<Array<MultiLanguageTextDTO>>;
};

export type AocTypeTemperatureCreateInputDTO = {
  type: WineTypeDTO;
  temperature?: Maybe<Scalars['Int']>;
  mainGrape?: Maybe<Scalars['String']>;
  temperatureDescriptions: Array<MultiLanguageTextInputDTO>;
};

export type AocUpdateInputDTO = {
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<AocTypeTemperatureCreateInputDTO>>>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type BasicStringArrayFilterDTO = {
  containsSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsAll?: Maybe<Array<Maybe<Scalars['String']>>>;
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BasicStringFilterDTO = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BooleanFilterDTO = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<Scalars['Boolean']>;
};

export type BuyingAdviceRequestDTO = AdviceRequestDTO & {
  __typename?: 'BuyingAdviceRequest';
  id: Scalars['String'];
  owner: UserDTO;
  createdAt: Scalars['DateTime'];
  archived: Scalars['Boolean'];
  status: AdviceRequestStatusDTO;
  type: AdviceRequestTypeDTO;
  wine: WineDTO;
  deliveryAddress: DeliveryAddressDTO;
};

export type BuyingAdviceRequestCreateInputDTO = {
  type: AdviceRequestTypeDTO;
  wine: Scalars['String'];
  deliveryAddress: DeliveryAddressInputDTO;
};

export type BuyingAdviceRequestUpdateInputDTO = {
  type: AdviceRequestTypeDTO;
  status?: Maybe<AdviceRequestStatusDTO>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type ClassificationDTO = {
  __typename?: 'Classification';
  id: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
};

export type ClassificationCreateInputDTO = {
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ClassificationFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<ClassificationFilterDTO>>;
  OR?: Maybe<Array<ClassificationFilterDTO>>;
};

export type ClassificationUpdateInputDTO = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type ConversationDTO = {
  __typename?: 'Conversation';
  id: Scalars['String'];
  owner: UserDTO;
  status: ConversationStatusDTO;
  messages: Array<ConversationMessageDTO>;
};

export type ConversationCreateInputDTO = {
  content: Scalars['String'];
};

export type ConversationFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  owner?: Maybe<UserFilterDTO>;
  status?: Maybe<ConversationStatusFilterDTO>;
  AND?: Maybe<Array<ConversationFilterDTO>>;
  OR?: Maybe<Array<ConversationFilterDTO>>;
};

export type ConversationMessageDTO = {
  __typename?: 'ConversationMessage';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  owner: UserDTO;
};

export type ConversationMessageCreateInputDTO = {
  content: Scalars['String'];
  conversation: Scalars['String'];
};

export enum ConversationStatusDTO {
  NewDTO = 'new',
  OwnerRespondedDTO = 'ownerResponded',
  HandledDTO = 'handled'
}

export type ConversationStatusFilterDTO = {
  equals?: Maybe<ConversationStatusDTO>;
  not?: Maybe<ConversationStatusDTO>;
  in?: Maybe<Array<Maybe<ConversationStatusDTO>>>;
  notIn?: Maybe<Array<Maybe<ConversationStatusDTO>>>;
};

export type ConversationSuggestionSourceDTO = SuggestionSourceDTO & {
  __typename?: 'ConversationSuggestionSource';
  type: SuggestionSourceTypeDTO;
  conversation: ConversationDTO;
};

export type ConversationUpdateInputDTO = {
  status?: Maybe<ConversationStatusDTO>;
};

export type CorrectionDTO = {
  __typename?: 'Correction';
  id: Scalars['String'];
  originalText: Scalars['String'];
  replacement: Scalars['String'];
};

export type CorrectionCreateInputDTO = {
  originalText: Scalars['String'];
  replacement: Scalars['String'];
};

export type CorrectionFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  originalText?: Maybe<StringFilterDTO>;
  replacement?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<CorrectionFilterDTO>>;
  OR?: Maybe<Array<CorrectionFilterDTO>>;
};

export type CorrectionUpdateInputDTO = {
  originalText?: Maybe<Scalars['String']>;
  replacement?: Maybe<Scalars['String']>;
};

export type CountryDTO = {
  __typename?: 'Country';
  id: Scalars['String'];
  name: Scalars['String'];
  temperatures?: Maybe<Array<TypeTemperatureDTO>>;
  archived: Scalars['Boolean'];
};

export type CountryCreateInputDTO = {
  name: Scalars['String'];
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInputDTO>>>;
};

export type CountryFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<CountryFilterDTO>>;
  OR?: Maybe<Array<CountryFilterDTO>>;
};

export type CountryUpdateInputDTO = {
  name?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInputDTO>>>;
  archived?: Maybe<Scalars['Boolean']>;
};



export type DateTimeFilterDTO = {
  equals?: Maybe<Scalars['DateTime']>;
  not?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type DeliveryAddressDTO = {
  __typename?: 'DeliveryAddress';
  givenName: Scalars['String'];
  familyName: Scalars['String'];
  address: AddressDTO;
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
};

export type DeliveryAddressInputDTO = {
  givenName: Scalars['String'];
  familyName: Scalars['String'];
  address: AddressInputDTO;
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
};

export type DomainDTO = {
  __typename?: 'Domain';
  id: Scalars['String'];
  name: Scalars['String'];
  aoc?: Maybe<AocDTO>;
  region?: Maybe<RegionDTO>;
  country: CountryDTO;
  archived: Scalars['Boolean'];
};

export type DomainCreateInputDTO = {
  name: Scalars['String'];
  aoc?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country: Scalars['String'];
};

export type DomainFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  aoc?: Maybe<AocFilterDTO>;
  region?: Maybe<RegionFilterDTO>;
  country?: Maybe<CountryFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<DomainFilterDTO>>;
  OR?: Maybe<Array<DomainFilterDTO>>;
};

export type DomainSuggestionDTO = SuggestionDTO & {
  __typename?: 'DomainSuggestion';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  type: SuggestionTypeDTO;
  status: SuggestionStatusDTO;
  owner: UserDTO;
  source: SuggestionSourceDTO;
  title: Scalars['String'];
};

export type DomainSuggestionCreateInputDTO = {
  type: SuggestionTypeDTO;
  title: Scalars['String'];
  source: SuggestionSourceInputDTO;
};

export type DomainSuggestionUpdateInputDTO = {
  type: SuggestionTypeDTO;
  status?: Maybe<SuggestionStatusDTO>;
  title?: Maybe<Scalars['String']>;
};

export type DomainUpdateInputDTO = {
  name?: Maybe<Scalars['String']>;
  aoc?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type ExceptionDTO = {
  __typename?: 'Exception';
  id: Scalars['String'];
  text: Scalars['String'];
  aoc?: Maybe<AocDTO>;
  country?: Maybe<CountryDTO>;
  region?: Maybe<RegionDTO>;
  grape?: Maybe<GrapeDTO>;
  domain?: Maybe<DomainDTO>;
  temperatures?: Maybe<Array<TypeTemperatureDTO>>;
  archived: Scalars['Boolean'];
};

export type ExceptionCreateInputDTO = {
  text: Scalars['String'];
  aoc?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  grape?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInputDTO>>>;
};

export type ExceptionFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  text?: Maybe<StringFilterDTO>;
  aoc?: Maybe<AocFilterDTO>;
  country?: Maybe<CountryFilterDTO>;
  region?: Maybe<RegionFilterDTO>;
  grape?: Maybe<GrapeFilterDTO>;
  domain?: Maybe<DomainFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<ExceptionFilterDTO>>;
  OR?: Maybe<Array<ExceptionFilterDTO>>;
};

export type ExceptionUpdateInputDTO = {
  text?: Maybe<Scalars['String']>;
  aoc?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  grape?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInputDTO>>>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type FaqDTO = {
  __typename?: 'Faq';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  titleInLanguage?: Maybe<Scalars['String']>;
  titles?: Maybe<Array<MultiLanguageTextDTO>>;
  description?: Maybe<Scalars['String']>;
  descriptions?: Maybe<Array<MultiLanguageTextDTO>>;
};


export type FaqTitleInLanguageArgsDTO = {
  language: LanguageDTO;
};

export type FaqCreateInputDTO = {
  titles: Array<MultiLanguageTextInputDTO>;
  descriptions: Array<MultiLanguageTextInputDTO>;
};

export type FaqFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  titleInLanguage?: Maybe<MultiLanguageTextFilterDTO>;
  description?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<FaqFilterDTO>>;
  OR?: Maybe<Array<FaqFilterDTO>>;
};

export type FaqSuggestionDTO = SuggestionDTO & {
  __typename?: 'FaqSuggestion';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  type: SuggestionTypeDTO;
  status: SuggestionStatusDTO;
  owner: UserDTO;
  source: SuggestionSourceDTO;
  title: Scalars['String'];
  description: Scalars['String'];
};

export type FaqSuggestionCreateInputDTO = {
  type: SuggestionTypeDTO;
  title: Scalars['String'];
  description: Scalars['String'];
  source: SuggestionSourceInputDTO;
};

export type FaqSuggestionUpdateInputDTO = {
  type: SuggestionTypeDTO;
  status?: Maybe<SuggestionStatusDTO>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type FaqUpdateInputDTO = {
  titles?: Maybe<Array<MultiLanguageTextInputDTO>>;
  descriptions?: Maybe<Array<MultiLanguageTextInputDTO>>;
};

export type FirmwareDTO = {
  __typename?: 'Firmware';
  id: Scalars['String'];
  version: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type FirmwareCreateInputDTO = {
  version: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type FirmwareFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  version?: Maybe<StringFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<FirmwareFilterDTO>>;
  OR?: Maybe<Array<FirmwareFilterDTO>>;
};

export type FirmwareUpdateInputDTO = {
  version?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FoodDTO = {
  __typename?: 'Food';
  id: Scalars['String'];
  course: FoodCourseDTO;
  kitchen: FoodKitchenDTO;
  ingredients: FoodIngredientsDTO;
  wineStyle: WineStyleDTO;
  title?: Maybe<Scalars['String']>;
  titleInLanguage?: Maybe<Scalars['String']>;
  titles?: Maybe<Array<MultiLanguageTextDTO>>;
  description?: Maybe<Scalars['String']>;
  descriptions?: Maybe<Array<MultiLanguageTextDTO>>;
};


export type FoodTitleInLanguageArgsDTO = {
  language: LanguageDTO;
};

export enum FoodCourseDTO {
  AperoDTO = 'apero',
  StarterDTO = 'starter',
  DinnerDTO = 'dinner',
  DessertDTO = 'dessert',
  CheeseDTO = 'cheese'
}

export type FoodCourseFilterDTO = {
  equals?: Maybe<FoodCourseDTO>;
  not?: Maybe<FoodCourseDTO>;
  in?: Maybe<Array<Maybe<FoodCourseDTO>>>;
  notIn?: Maybe<Array<Maybe<FoodCourseDTO>>>;
};

export type FoodCreateInputDTO = {
  course: FoodCourseDTO;
  kitchen: FoodKitchenDTO;
  ingredients: FoodIngredientsDTO;
  wineStyle: WineStyleDTO;
  titles: Array<MultiLanguageTextInputDTO>;
  descriptions: Array<MultiLanguageTextInputDTO>;
};

export type FoodFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  course?: Maybe<FoodCourseFilterDTO>;
  kitchen?: Maybe<FoodKitchenFilterDTO>;
  ingredients?: Maybe<FoodIngredientsFilterDTO>;
  wineStyle?: Maybe<WineStyleFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  titleInLanguage?: Maybe<MultiLanguageTextFilterDTO>;
  description?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<FoodFilterDTO>>;
  OR?: Maybe<Array<FoodFilterDTO>>;
};

export enum FoodIngredientsDTO {
  VegetarianDTO = 'vegetarian',
  FishDTO = 'fish',
  MeatDTO = 'meat'
}

export type FoodIngredientsFilterDTO = {
  equals?: Maybe<FoodIngredientsDTO>;
  not?: Maybe<FoodIngredientsDTO>;
  in?: Maybe<Array<Maybe<FoodIngredientsDTO>>>;
  notIn?: Maybe<Array<Maybe<FoodIngredientsDTO>>>;
};

export enum FoodKitchenDTO {
  AmericanDTO = 'american',
  BelgianDTO = 'belgian',
  CarribianDTO = 'carribian',
  ChineseDTO = 'chinese',
  FrenchDTO = 'french',
  IndianDTO = 'indian',
  InternationalDTO = 'international',
  ItalianDTO = 'italian',
  JapaneseDTO = 'japanese',
  LatinAmericanDTO = 'latinAmerican',
  SpanishDTO = 'spanish',
  ThaiDTO = 'thai',
  VietnameseDTO = 'vietnamese'
}

export type FoodKitchenFilterDTO = {
  equals?: Maybe<FoodKitchenDTO>;
  not?: Maybe<FoodKitchenDTO>;
  in?: Maybe<Array<Maybe<FoodKitchenDTO>>>;
  notIn?: Maybe<Array<Maybe<FoodKitchenDTO>>>;
};

export type FoodUpdateInputDTO = {
  course?: Maybe<FoodCourseDTO>;
  kitchen?: Maybe<FoodKitchenDTO>;
  ingredients?: Maybe<FoodIngredientsDTO>;
  wineStyle?: Maybe<WineStyleDTO>;
  titles?: Maybe<Array<MultiLanguageTextInputDTO>>;
  descriptions?: Maybe<Array<MultiLanguageTextInputDTO>>;
};

export type GrapeDTO = {
  __typename?: 'Grape';
  id: Scalars['String'];
  name: Scalars['String'];
  temperatures?: Maybe<Array<TypeTemperatureDTO>>;
  mainGrape?: Maybe<MainGrapeDTO>;
  archived: Scalars['Boolean'];
};

export type GrapeCreateInputDTO = {
  name: Scalars['String'];
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInputDTO>>>;
  mainGrape: Scalars['String'];
};

export type GrapeFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  mainGrape?: Maybe<MainGrapeFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<GrapeFilterDTO>>;
  OR?: Maybe<Array<GrapeFilterDTO>>;
};

export type GrapeUpdateInputDTO = {
  name?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInputDTO>>>;
  archived?: Maybe<Scalars['Boolean']>;
  mainGrape?: Maybe<Scalars['String']>;
};

export type IntFilterDTO = {
  equals?: Maybe<Scalars['Int']>;
  not?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
};


export enum LanguageDTO {
  NlDTO = 'nl',
  FrDTO = 'fr',
  EnDTO = 'en'
}

export type LoginResultDTO = {
  __typename?: 'LoginResult';
  token: Scalars['String'];
  user: UserDTO;
};

export type MainGrapeDTO = {
  __typename?: 'MainGrape';
  id: Scalars['String'];
  name: Scalars['String'];
  wineStyle?: Maybe<WineStyleDTO>;
  archived: Scalars['Boolean'];
  temperatureDescription?: Maybe<Scalars['String']>;
  temperatureDescriptions?: Maybe<Array<MultiLanguageTextDTO>>;
  foodDescription?: Maybe<Scalars['String']>;
  foodDescriptions?: Maybe<Array<MultiLanguageTextDTO>>;
};

export type MainGrapeCreateInputDTO = {
  name: Scalars['String'];
  wineStyle?: Maybe<WineStyleDTO>;
  temperatureDescriptions: Array<MultiLanguageTextInputDTO>;
  foodDescriptions: Array<MultiLanguageTextInputDTO>;
};

export type MainGrapeFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  wineStyle?: Maybe<WineStyleFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  temperatureDescription?: Maybe<StringFilterDTO>;
  foodDescription?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<MainGrapeFilterDTO>>;
  OR?: Maybe<Array<MainGrapeFilterDTO>>;
};

export type MainGrapeUpdateInputDTO = {
  name?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  wineStyle?: Maybe<WineStyleDTO>;
  temperatureDescriptions?: Maybe<Array<MultiLanguageTextInputDTO>>;
  foodDescriptions?: Maybe<Array<MultiLanguageTextInputDTO>>;
};

export type MultiLanguageTextDTO = {
  __typename?: 'MultiLanguageText';
  language: LanguageDTO;
  text: Scalars['String'];
};

export type MultiLanguageTextFilterDTO = {
  language: LanguageDTO;
  text: StringFilterDTO;
};

export type MultiLanguageTextInputDTO = {
  language: LanguageDTO;
  text: Scalars['String'];
};

export type MutationDTO = {
  __typename?: 'Mutation';
  createOneAdviceRequest: AdviceRequestDTO;
  updateOneAdviceRequest: AdviceRequestDTO;
  deleteOneAdviceRequest: SuccessDTO;
  updateOneAlgorithmConfig: AlgorithmConfigDTO;
  createOneAoc: AocDTO;
  updateOneAoc: AocDTO;
  deleteOneAoc: SuccessDTO;
  createOneClassification: ClassificationDTO;
  updateOneClassification: ClassificationDTO;
  deleteOneClassification: SuccessDTO;
  createOneConversation: ConversationDTO;
  updateOneConversation: ConversationDTO;
  deleteOneConversation: SuccessDTO;
  createOneConversationMessage: ConversationDTO;
  createOneCorrection: CorrectionDTO;
  updateOneCorrection: CorrectionDTO;
  deleteOneCorrection: SuccessDTO;
  createOneCountry: CountryDTO;
  updateOneCountry: CountryDTO;
  deleteOneCountry: SuccessDTO;
  createOneDomain: DomainDTO;
  updateOneDomain: DomainDTO;
  deleteOneDomain: SuccessDTO;
  createOneException: ExceptionDTO;
  updateOneException: ExceptionDTO;
  deleteOneException: SuccessDTO;
  createOneFaq: FaqDTO;
  updateOneFaq: FaqDTO;
  deleteOneFaq: SuccessDTO;
  createOneFirmware: FirmwareDTO;
  updateOneFirmware: FirmwareDTO;
  deleteOneFirmware: SuccessDTO;
  createOneFood: FoodDTO;
  updateOneFood: FoodDTO;
  deleteOneFood: SuccessDTO;
  createOneGrape: GrapeDTO;
  updateOneGrape: GrapeDTO;
  deleteOneGrape: SuccessDTO;
  passwordLogin: LoginResultDTO;
  socialLogin: LoginResultDTO;
  passwordRegister: LoginResultDTO;
  updatePassword: SuccessDTO;
  updatePasswordAnonymous: SuccessDTO;
  resetPassword: SuccessDTO;
  createOneMainGrape: MainGrapeDTO;
  updateOneMainGrape: MainGrapeDTO;
  deleteOneMainGrape: SuccessDTO;
  createOneRegion: RegionDTO;
  updateOneRegion: RegionDTO;
  deleteOneRegion: SuccessDTO;
  createOneScan: ScanDTO;
  createOneSuggestion: SuggestionDTO;
  updateOneSuggestion: SuggestionDTO;
  deleteOneSuggestion: SuccessDTO;
  createOneUser: UserDTO;
  updateOneUser: UserDTO;
  deleteOneUser: SuccessDTO;
  createOneValidationItem: ValidationItemDTO;
  updateOneValidationItem: ValidationItemDTO;
  deleteOneValidationItem: SuccessDTO;
  createOneValidationRun: ValidationRunDTO;
  createOneWine: WineDTO;
  updateOneWine: WineDTO;
  deleteOneWine: SuccessDTO;
};


export type MutationCreateOneAdviceRequestArgsDTO = {
  data: Scalars['AdviceRequestCreateInput'];
};


export type MutationUpdateOneAdviceRequestArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<Scalars['AdviceRequestUpdateInput']>;
};


export type MutationDeleteOneAdviceRequestArgsDTO = {
  id: Scalars['String'];
};


export type MutationUpdateOneAlgorithmConfigArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<AlgorithmConfigUpdateInputDTO>;
};


export type MutationCreateOneAocArgsDTO = {
  data: AocCreateInputDTO;
};


export type MutationUpdateOneAocArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<AocUpdateInputDTO>;
};


export type MutationDeleteOneAocArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneClassificationArgsDTO = {
  data: ClassificationCreateInputDTO;
};


export type MutationUpdateOneClassificationArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<ClassificationUpdateInputDTO>;
};


export type MutationDeleteOneClassificationArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneConversationArgsDTO = {
  data: ConversationCreateInputDTO;
};


export type MutationUpdateOneConversationArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<ConversationUpdateInputDTO>;
};


export type MutationDeleteOneConversationArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneConversationMessageArgsDTO = {
  data: ConversationMessageCreateInputDTO;
};


export type MutationCreateOneCorrectionArgsDTO = {
  data: CorrectionCreateInputDTO;
};


export type MutationUpdateOneCorrectionArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<CorrectionUpdateInputDTO>;
};


export type MutationDeleteOneCorrectionArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneCountryArgsDTO = {
  data: CountryCreateInputDTO;
};


export type MutationUpdateOneCountryArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<CountryUpdateInputDTO>;
};


export type MutationDeleteOneCountryArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneDomainArgsDTO = {
  data: DomainCreateInputDTO;
};


export type MutationUpdateOneDomainArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<DomainUpdateInputDTO>;
};


export type MutationDeleteOneDomainArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneExceptionArgsDTO = {
  data: ExceptionCreateInputDTO;
};


export type MutationUpdateOneExceptionArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<ExceptionUpdateInputDTO>;
};


export type MutationDeleteOneExceptionArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneFaqArgsDTO = {
  data: FaqCreateInputDTO;
};


export type MutationUpdateOneFaqArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<FaqUpdateInputDTO>;
};


export type MutationDeleteOneFaqArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneFirmwareArgsDTO = {
  data: FirmwareCreateInputDTO;
};


export type MutationUpdateOneFirmwareArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<FirmwareUpdateInputDTO>;
};


export type MutationDeleteOneFirmwareArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneFoodArgsDTO = {
  data: FoodCreateInputDTO;
};


export type MutationUpdateOneFoodArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<FoodUpdateInputDTO>;
};


export type MutationDeleteOneFoodArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneGrapeArgsDTO = {
  data: GrapeCreateInputDTO;
};


export type MutationUpdateOneGrapeArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<GrapeUpdateInputDTO>;
};


export type MutationDeleteOneGrapeArgsDTO = {
  id: Scalars['String'];
};


export type MutationPasswordLoginArgsDTO = {
  data: PasswordLoginInputDTO;
};


export type MutationSocialLoginArgsDTO = {
  data: SocialLoginInputDTO;
};


export type MutationPasswordRegisterArgsDTO = {
  data: PasswordRegisterInputDTO;
};


export type MutationUpdatePasswordArgsDTO = {
  data: PasswordUpdateInputDTO;
};


export type MutationUpdatePasswordAnonymousArgsDTO = {
  data: PasswordAnonymousUpdateInputDTO;
};


export type MutationResetPasswordArgsDTO = {
  data: PasswordResetInputDTO;
};


export type MutationCreateOneMainGrapeArgsDTO = {
  data: MainGrapeCreateInputDTO;
};


export type MutationUpdateOneMainGrapeArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<MainGrapeUpdateInputDTO>;
};


export type MutationDeleteOneMainGrapeArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneRegionArgsDTO = {
  data: RegionCreateInputDTO;
};


export type MutationUpdateOneRegionArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<RegionUpdateInputDTO>;
};


export type MutationDeleteOneRegionArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneScanArgsDTO = {
  data: ScanCreateInputDTO;
};


export type MutationCreateOneSuggestionArgsDTO = {
  data: Scalars['SuggestionCreateInput'];
};


export type MutationUpdateOneSuggestionArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<Scalars['SuggestionUpdateInput']>;
};


export type MutationDeleteOneSuggestionArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneUserArgsDTO = {
  data: UserCreateInputDTO;
};


export type MutationUpdateOneUserArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<UserUpdateInputDTO>;
};


export type MutationDeleteOneUserArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneValidationItemArgsDTO = {
  data: ValidationItemCreateInputDTO;
};


export type MutationUpdateOneValidationItemArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<ValidationItemUpdateInputDTO>;
};


export type MutationDeleteOneValidationItemArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneValidationRunArgsDTO = {
  data: ValidationRunCreateInputDTO;
};


export type MutationCreateOneWineArgsDTO = {
  data: WineCreateInputDTO;
};


export type MutationUpdateOneWineArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<WineUpdateInputDTO>;
};


export type MutationDeleteOneWineArgsDTO = {
  id: Scalars['String'];
};

export type PagingDTO = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PasswordAnonymousUpdateInputDTO = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordLoginInputDTO = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type PasswordRegisterInputDTO = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type PasswordResetInputDTO = {
  email: Scalars['String'];
};

export type PasswordUpdateInputDTO = {
  id: Scalars['String'];
  password: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ProfileDTO = {
  __typename?: 'Profile';
  user: UserDTO;
};

export type QueryDTO = {
  __typename?: 'Query';
  adviceRequests: Array<AdviceRequestDTO>;
  adviceRequestsCount?: Maybe<Scalars['Int']>;
  adviceRequest: AdviceRequestDTO;
  algorithmConfigs: Array<AlgorithmConfigDTO>;
  algorithmConfigsCount?: Maybe<Scalars['Int']>;
  algorithmConfig: AlgorithmConfigDTO;
  aocs: Array<AocDTO>;
  aocsCount?: Maybe<Scalars['Int']>;
  aoc: AocDTO;
  classifications: Array<ClassificationDTO>;
  classificationsCount?: Maybe<Scalars['Int']>;
  classification: ClassificationDTO;
  conversations: Array<ConversationDTO>;
  conversationsCount?: Maybe<Scalars['Int']>;
  conversation: ConversationDTO;
  corrections: Array<CorrectionDTO>;
  correctionsCount?: Maybe<Scalars['Int']>;
  correction: CorrectionDTO;
  countries: Array<CountryDTO>;
  countriesCount?: Maybe<Scalars['Int']>;
  country: CountryDTO;
  domains: Array<DomainDTO>;
  domainsCount?: Maybe<Scalars['Int']>;
  domain: DomainDTO;
  exceptions: Array<ExceptionDTO>;
  exceptionsCount?: Maybe<Scalars['Int']>;
  exception: ExceptionDTO;
  faqs: Array<FaqDTO>;
  faqsCount?: Maybe<Scalars['Int']>;
  faq: FaqDTO;
  firmwares: Array<FirmwareDTO>;
  firmwaresCount?: Maybe<Scalars['Int']>;
  firmware: FirmwareDTO;
  foods: Array<FoodDTO>;
  foodsCount?: Maybe<Scalars['Int']>;
  food: FoodDTO;
  grapes: Array<GrapeDTO>;
  grapesCount?: Maybe<Scalars['Int']>;
  grape: GrapeDTO;
  mainGrapes: Array<MainGrapeDTO>;
  mainGrapesCount?: Maybe<Scalars['Int']>;
  mainGrape: MainGrapeDTO;
  profile: ProfileDTO;
  regions: Array<RegionDTO>;
  regionsCount?: Maybe<Scalars['Int']>;
  region: RegionDTO;
  scans: Array<ScanDTO>;
  scansCount?: Maybe<Scalars['Int']>;
  scan: ScanDTO;
  suggestions: Array<SuggestionDTO>;
  suggestionsCount?: Maybe<Scalars['Int']>;
  suggestion: SuggestionDTO;
  temperature: TemperatureDTO;
  users: Array<UserDTO>;
  usersCount?: Maybe<Scalars['Int']>;
  user: UserDTO;
  validationItems: Array<ValidationItemDTO>;
  validationItemsCount?: Maybe<Scalars['Int']>;
  validationItem: ValidationItemDTO;
  validationRuns: Array<ValidationRunDTO>;
  validationRunsCount?: Maybe<Scalars['Int']>;
  validationRun: ValidationRunDTO;
  wines: Array<WineDTO>;
  winesCount?: Maybe<Scalars['Int']>;
  wine: WineDTO;
};


export type QueryAdviceRequestsArgsDTO = {
  filter?: Maybe<AdviceRequestFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryAdviceRequestsCountArgsDTO = {
  filter?: Maybe<AdviceRequestFilterDTO>;
};


export type QueryAdviceRequestArgsDTO = {
  id: Scalars['String'];
};


export type QueryAlgorithmConfigsArgsDTO = {
  filter?: Maybe<AlgorithmConfigFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryAlgorithmConfigsCountArgsDTO = {
  filter?: Maybe<AlgorithmConfigFilterDTO>;
};


export type QueryAlgorithmConfigArgsDTO = {
  id: Scalars['String'];
};


export type QueryAocsArgsDTO = {
  filter?: Maybe<AocFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryAocsCountArgsDTO = {
  filter?: Maybe<AocFilterDTO>;
};


export type QueryAocArgsDTO = {
  id: Scalars['String'];
};


export type QueryClassificationsArgsDTO = {
  filter?: Maybe<ClassificationFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryClassificationsCountArgsDTO = {
  filter?: Maybe<ClassificationFilterDTO>;
};


export type QueryClassificationArgsDTO = {
  id: Scalars['String'];
};


export type QueryConversationsArgsDTO = {
  filter?: Maybe<ConversationFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryConversationsCountArgsDTO = {
  filter?: Maybe<ConversationFilterDTO>;
};


export type QueryConversationArgsDTO = {
  id: Scalars['String'];
};


export type QueryCorrectionsArgsDTO = {
  filter?: Maybe<CorrectionFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryCorrectionsCountArgsDTO = {
  filter?: Maybe<CorrectionFilterDTO>;
};


export type QueryCorrectionArgsDTO = {
  id: Scalars['String'];
};


export type QueryCountriesArgsDTO = {
  filter?: Maybe<CountryFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryCountriesCountArgsDTO = {
  filter?: Maybe<CountryFilterDTO>;
};


export type QueryCountryArgsDTO = {
  id: Scalars['String'];
};


export type QueryDomainsArgsDTO = {
  filter?: Maybe<DomainFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryDomainsCountArgsDTO = {
  filter?: Maybe<DomainFilterDTO>;
};


export type QueryDomainArgsDTO = {
  id: Scalars['String'];
};


export type QueryExceptionsArgsDTO = {
  filter?: Maybe<ExceptionFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryExceptionsCountArgsDTO = {
  filter?: Maybe<ExceptionFilterDTO>;
};


export type QueryExceptionArgsDTO = {
  id: Scalars['String'];
};


export type QueryFaqsArgsDTO = {
  filter?: Maybe<FaqFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryFaqsCountArgsDTO = {
  filter?: Maybe<FaqFilterDTO>;
};


export type QueryFaqArgsDTO = {
  id: Scalars['String'];
};


export type QueryFirmwaresArgsDTO = {
  filter?: Maybe<FirmwareFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryFirmwaresCountArgsDTO = {
  filter?: Maybe<FirmwareFilterDTO>;
};


export type QueryFirmwareArgsDTO = {
  id: Scalars['String'];
};


export type QueryFoodsArgsDTO = {
  filter?: Maybe<FoodFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryFoodsCountArgsDTO = {
  filter?: Maybe<FoodFilterDTO>;
};


export type QueryFoodArgsDTO = {
  id: Scalars['String'];
};


export type QueryGrapesArgsDTO = {
  filter?: Maybe<GrapeFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryGrapesCountArgsDTO = {
  filter?: Maybe<GrapeFilterDTO>;
};


export type QueryGrapeArgsDTO = {
  id: Scalars['String'];
};


export type QueryMainGrapesArgsDTO = {
  filter?: Maybe<MainGrapeFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryMainGrapesCountArgsDTO = {
  filter?: Maybe<MainGrapeFilterDTO>;
};


export type QueryMainGrapeArgsDTO = {
  id: Scalars['String'];
};


export type QueryRegionsArgsDTO = {
  filter?: Maybe<RegionFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryRegionsCountArgsDTO = {
  filter?: Maybe<RegionFilterDTO>;
};


export type QueryRegionArgsDTO = {
  id: Scalars['String'];
};


export type QueryScansArgsDTO = {
  filter?: Maybe<ScanFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryScansCountArgsDTO = {
  filter?: Maybe<ScanFilterDTO>;
};


export type QueryScanArgsDTO = {
  id: Scalars['String'];
};


export type QuerySuggestionsArgsDTO = {
  filter?: Maybe<SuggestionFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QuerySuggestionsCountArgsDTO = {
  filter?: Maybe<SuggestionFilterDTO>;
};


export type QuerySuggestionArgsDTO = {
  id: Scalars['String'];
};


export type QueryTemperatureArgsDTO = {
  filter: TemperatureFilterDTO;
};


export type QueryUsersArgsDTO = {
  filter?: Maybe<UserFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryUsersCountArgsDTO = {
  filter?: Maybe<UserFilterDTO>;
};


export type QueryUserArgsDTO = {
  id: Scalars['String'];
};


export type QueryValidationItemsArgsDTO = {
  filter?: Maybe<ValidationItemFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryValidationItemsCountArgsDTO = {
  filter?: Maybe<ValidationItemFilterDTO>;
};


export type QueryValidationItemArgsDTO = {
  id: Scalars['String'];
};


export type QueryValidationRunsArgsDTO = {
  filter?: Maybe<ValidationRunFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryValidationRunsCountArgsDTO = {
  filter?: Maybe<ValidationRunFilterDTO>;
};


export type QueryValidationRunArgsDTO = {
  id: Scalars['String'];
};


export type QueryWinesArgsDTO = {
  filter?: Maybe<WineFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryWinesCountArgsDTO = {
  filter?: Maybe<WineFilterDTO>;
};


export type QueryWineArgsDTO = {
  id: Scalars['String'];
};

export type RegionDTO = {
  __typename?: 'Region';
  id: Scalars['String'];
  name: Scalars['String'];
  country: CountryDTO;
  temperatures?: Maybe<Array<TypeTemperatureDTO>>;
  archived: Scalars['Boolean'];
};

export type RegionCreateInputDTO = {
  name: Scalars['String'];
  country: Scalars['String'];
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInputDTO>>>;
};

export type RegionFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  country?: Maybe<CountryFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<RegionFilterDTO>>;
  OR?: Maybe<Array<RegionFilterDTO>>;
};

export type RegionUpdateInputDTO = {
  name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  temperatures?: Maybe<Array<Maybe<TypeTemperatureCreateInputDTO>>>;
  archived?: Maybe<Scalars['Boolean']>;
};

export enum RoleDTO {
  UserAdminDTO = 'userAdmin',
  AdviceRequestAdminDTO = 'adviceRequestAdmin',
  DatabaseAdminDTO = 'databaseAdmin'
}

export type RoleArrayFilterDTO = {
  containsSome?: Maybe<Array<Maybe<RoleDTO>>>;
  containsAll?: Maybe<Array<Maybe<RoleDTO>>>;
  equals?: Maybe<Array<Maybe<RoleDTO>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type ScanDTO = {
  __typename?: 'Scan';
  id: Scalars['String'];
  image: Scalars['String'];
  wineType: WineTypeDTO;
  advices?: Maybe<Array<Maybe<ScanAdviceDTO>>>;
  used: Scalars['Boolean'];
  owner: UserDTO;
};

export type ScanAdviceDTO = {
  __typename?: 'ScanAdvice';
  year?: Maybe<Scalars['Int']>;
  description?: Maybe<WineDescriptionDTO>;
  idealTemp: Scalars['Int'];
};

export type ScanAdviceRequestDTO = AdviceRequestDTO & {
  __typename?: 'ScanAdviceRequest';
  id: Scalars['String'];
  owner: UserDTO;
  createdAt: Scalars['DateTime'];
  archived: Scalars['Boolean'];
  status: AdviceRequestStatusDTO;
  type: AdviceRequestTypeDTO;
  scan: ScanDTO;
  wineResult?: Maybe<WineDTO>;
  comment?: Maybe<Scalars['String']>;
  internalComment?: Maybe<Scalars['String']>;
};

export type ScanAdviceRequestCreateInputDTO = {
  type: AdviceRequestTypeDTO;
  scan: Scalars['String'];
};

export type ScanAdviceRequestUpdateInputDTO = {
  type: AdviceRequestTypeDTO;
  status?: Maybe<AdviceRequestStatusDTO>;
  archived?: Maybe<Scalars['Boolean']>;
  wineResult?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  internalComment?: Maybe<Scalars['String']>;
};

export type ScanCreateInputDTO = {
  base64Image: Scalars['String'];
  wineType: WineTypeDTO;
};

export type ScanFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  wineType?: Maybe<WineTypeFilterDTO>;
  used?: Maybe<BooleanFilterDTO>;
  owner?: Maybe<UserFilterDTO>;
  AND?: Maybe<Array<ScanFilterDTO>>;
  OR?: Maybe<Array<ScanFilterDTO>>;
};

export type SocialLoginInputDTO = {
  socialType: SocialTypeDTO;
  accessToken: Scalars['String'];
};

export enum SocialTypeDTO {
  FacebookDTO = 'Facebook',
  GoogleDTO = 'Google',
  AppleDTO = 'Apple'
}

export type SortDTO = {
  field: Scalars['String'];
  order: SortOrderDTO;
};

export enum SortOrderDTO {
  AscDTO = 'ASC',
  DescDTO = 'DESC'
}

export type StringArrayFilterDTO = {
  containsSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsAll?: Maybe<Array<Maybe<Scalars['String']>>>;
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  someContains?: Maybe<Scalars['String']>;
  allContains?: Maybe<Scalars['String']>;
  someStartsWith?: Maybe<Scalars['String']>;
  allStartsWith?: Maybe<Scalars['String']>;
  someEndsWith?: Maybe<Scalars['String']>;
  allEndsWith?: Maybe<Scalars['String']>;
};

export type StringFilterDTO = {
  equals?: Maybe<Scalars['String']>;
  not?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionDTO = {
  __typename?: 'Subscription';
  adviceRequestCreated: AdviceRequestDTO;
  adviceRequestUpdated: AdviceRequestDTO;
  adviceRequestDeleted: Scalars['String'];
  algorithmConfigUpdated: AlgorithmConfigDTO;
  aocCreated: AocDTO;
  aocUpdated: AocDTO;
  aocDeleted: Scalars['String'];
  classificationCreated: ClassificationDTO;
  classificationUpdated: ClassificationDTO;
  classificationDeleted: Scalars['String'];
  conversationCreated: ConversationDTO;
  conversationUpdated: ConversationDTO;
  conversationDeleted: Scalars['String'];
  correctionCreated: CorrectionDTO;
  correctionUpdated: CorrectionDTO;
  correctionDeleted: Scalars['String'];
  countryCreated: CountryDTO;
  countryUpdated: CountryDTO;
  countryDeleted: Scalars['String'];
  domainCreated: DomainDTO;
  domainUpdated: DomainDTO;
  domainDeleted: Scalars['String'];
  exceptionCreated: ExceptionDTO;
  exceptionUpdated: ExceptionDTO;
  exceptionDeleted: Scalars['String'];
  faqCreated: FaqDTO;
  faqUpdated: FaqDTO;
  faqDeleted: Scalars['String'];
  firmwareCreated: FirmwareDTO;
  firmwareUpdated: FirmwareDTO;
  firmwareDeleted: Scalars['String'];
  foodCreated: FoodDTO;
  foodUpdated: FoodDTO;
  foodDeleted: Scalars['String'];
  grapeCreated: GrapeDTO;
  grapeUpdated: GrapeDTO;
  grapeDeleted: Scalars['String'];
  mainGrapeCreated: MainGrapeDTO;
  mainGrapeUpdated: MainGrapeDTO;
  mainGrapeDeleted: Scalars['String'];
  regionCreated: RegionDTO;
  regionUpdated: RegionDTO;
  regionDeleted: Scalars['String'];
  scanCreated: ScanDTO;
  scanUpdated: ScanDTO;
  suggestionCreated: SuggestionDTO;
  suggestionUpdated: SuggestionDTO;
  suggestionDeleted: Scalars['String'];
  userCreated: UserDTO;
  userUpdated: UserDTO;
  userDeleted: Scalars['String'];
  validationItemCreated: ValidationItemDTO;
  validationItemUpdated: ValidationItemDTO;
  validationItemDeleted: Scalars['String'];
  validationRunCreated: ValidationRunDTO;
  validationRunUpdated: ValidationRunDTO;
  wineCreated: WineDTO;
  wineUpdated: WineDTO;
  wineDeleted: Scalars['String'];
};

export type SuccessDTO = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type SuggestionDTO = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  type: SuggestionTypeDTO;
  status: SuggestionStatusDTO;
  owner: UserDTO;
  source: SuggestionSourceDTO;
};


export type SuggestionFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  type?: Maybe<SuggestionTypeFilterDTO>;
  status?: Maybe<SuggestionStatusFilterDTO>;
  owner?: Maybe<UserFilterDTO>;
  AND?: Maybe<Array<SuggestionFilterDTO>>;
  OR?: Maybe<Array<SuggestionFilterDTO>>;
};

export type SuggestionSourceDTO = {
  type: SuggestionSourceTypeDTO;
};

export type SuggestionSourceInputDTO = {
  type: SuggestionSourceTypeDTO;
  typeId: Scalars['String'];
};

export enum SuggestionSourceTypeDTO {
  ConversationDTO = 'conversation',
  AdviceRequestDTO = 'adviceRequest'
}

export enum SuggestionStatusDTO {
  NewDTO = 'new',
  FinishedDTO = 'finished',
  DeletedDTO = 'deleted'
}

export type SuggestionStatusFilterDTO = {
  equals?: Maybe<SuggestionStatusDTO>;
  not?: Maybe<SuggestionStatusDTO>;
  in?: Maybe<Array<Maybe<SuggestionStatusDTO>>>;
  notIn?: Maybe<Array<Maybe<SuggestionStatusDTO>>>;
};

export enum SuggestionTypeDTO {
  FaqDTO = 'faq',
  DomainDTO = 'domain'
}

export type SuggestionTypeFilterDTO = {
  equals?: Maybe<SuggestionTypeDTO>;
  not?: Maybe<SuggestionTypeDTO>;
  in?: Maybe<Array<Maybe<SuggestionTypeDTO>>>;
  notIn?: Maybe<Array<Maybe<SuggestionTypeDTO>>>;
};


export type TemperatureDTO = {
  __typename?: 'Temperature';
  value: Scalars['Int'];
};

export type TemperatureFilterDTO = {
  type: WineTypeDTO;
  relation?: Maybe<TemperatureRelationFilterDTO>;
};

export type TemperatureRelationFilterDTO = {
  relation: TemperatureRelationTypeDTO;
  relationId: Scalars['String'];
};

export enum TemperatureRelationTypeDTO {
  GrapeDTO = 'Grape',
  AocDTO = 'Aoc',
  CountryDTO = 'Country',
  RegionDTO = 'Region'
}

export enum TemperatureUnitDTO {
  FDTO = 'F',
  CDTO = 'C'
}


export type TypeTemperatureDTO = {
  __typename?: 'TypeTemperature';
  type: WineTypeDTO;
  temperature?: Maybe<Scalars['Int']>;
};

export type TypeTemperatureCreateInputDTO = {
  type: WineTypeDTO;
  temperature?: Maybe<Scalars['Int']>;
};


export type UserDTO = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  business?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  tempUnit?: Maybe<TemperatureUnitDTO>;
  agreedToTermsAndConditions: Scalars['Boolean'];
  /** This can only be set by the admin */
  roles?: Maybe<Array<RoleDTO>>;
};

export type UserCreateInputDTO = {
  email: Scalars['String'];
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  roles: Array<RoleDTO>;
};

export type UserFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  email?: Maybe<StringFilterDTO>;
  language?: Maybe<StringFilterDTO>;
  givenName?: Maybe<StringFilterDTO>;
  familyName?: Maybe<StringFilterDTO>;
  company?: Maybe<StringFilterDTO>;
  business?: Maybe<StringFilterDTO>;
  country?: Maybe<StringFilterDTO>;
  role?: Maybe<RoleArrayFilterDTO>;
  AND?: Maybe<Array<UserFilterDTO>>;
  OR?: Maybe<Array<UserFilterDTO>>;
};

export type UserUpdateInputDTO = {
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  business?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  tempUnit?: Maybe<TemperatureUnitDTO>;
  agreedToTermsAndConditions?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<RoleDTO>>;
};

export type ValidationItemDTO = {
  __typename?: 'ValidationItem';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  wineType: WineTypeDTO;
  image: Scalars['String'];
  expected: ValidationItemDefinitionDTO;
};

export type ValidationItemCreateInputDTO = {
  name: Scalars['String'];
  wineType?: Maybe<WineTypeDTO>;
  base64Image?: Maybe<Scalars['String']>;
  scan?: Maybe<Scalars['String']>;
  expected: ValidationItemDefinitionInputDTO;
};

export type ValidationItemDefinitionDTO = {
  __typename?: 'ValidationItemDefinition';
  grape?: Maybe<GrapeDTO>;
  aoc?: Maybe<AocDTO>;
  region?: Maybe<RegionDTO>;
  country?: Maybe<CountryDTO>;
  domain?: Maybe<DomainDTO>;
  classification?: Maybe<ClassificationDTO>;
  year?: Maybe<Scalars['Int']>;
};

export type ValidationItemDefinitionInputDTO = {
  grape?: Maybe<Scalars['String']>;
  aoc?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type ValidationItemFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  wineType?: Maybe<WineTypeFilterDTO>;
  AND?: Maybe<Array<ValidationItemFilterDTO>>;
  OR?: Maybe<Array<ValidationItemFilterDTO>>;
};

export type ValidationItemUpdateInputDTO = {
  name?: Maybe<Scalars['String']>;
  wineType?: Maybe<WineTypeDTO>;
  expected?: Maybe<ValidationItemDefinitionInputDTO>;
};

export type ValidationRunDTO = {
  __typename?: 'ValidationRun';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  algorithmConfig: AlgorithmConfigWithoutIdDTO;
  items: Array<ValidationRunItemDTO>;
  itemsCount: Scalars['Int'];
  completed: Scalars['Boolean'];
  completedItems: Scalars['Int'];
  correct: ValidationRunCorrectnessDTO;
};

export type ValidationRunCorrectnessDTO = {
  __typename?: 'ValidationRunCorrectness';
  grape: Scalars['Int'];
  aoc: Scalars['Int'];
  region: Scalars['Int'];
  country: Scalars['Int'];
  domain: Scalars['Int'];
  classification: Scalars['Int'];
  year: Scalars['Int'];
  total: Scalars['Int'];
};

export type ValidationRunCreateInputDTO = {
  validationItems: Array<Scalars['String']>;
  /** If not specified, the algorithm config will be taken from the database. */
  algorithmConfig?: Maybe<AlgorithmConfigCreateInputDTO>;
};

export type ValidationRunFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  completed?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<ValidationRunFilterDTO>>;
  OR?: Maybe<Array<ValidationRunFilterDTO>>;
};

export type ValidationRunItemDTO = {
  __typename?: 'ValidationRunItem';
  wineType: WineTypeDTO;
  image: Scalars['String'];
  expected: ValidationItemDefinitionDTO;
  status: ValidationRunItemStatusDTO;
  actual?: Maybe<ValidationItemDefinitionDTO>;
  correct?: Maybe<ValidationRunItemCorrectnessDTO>;
};

export type ValidationRunItemCorrectnessDTO = {
  __typename?: 'ValidationRunItemCorrectness';
  grape: Scalars['Boolean'];
  aoc: Scalars['Boolean'];
  region: Scalars['Boolean'];
  country: Scalars['Boolean'];
  domain: Scalars['Boolean'];
  classification: Scalars['Boolean'];
  year: Scalars['Boolean'];
  total: Scalars['Boolean'];
};

export enum ValidationRunItemStatusDTO {
  PendingDTO = 'pending',
  CompletedDTO = 'completed',
  FailedDTO = 'failed'
}

export type WineDTO = {
  __typename?: 'Wine';
  id: Scalars['String'];
  type: WineTypeDTO;
  name?: Maybe<Scalars['String']>;
  description: WineDescriptionDTO;
  year?: Maybe<Scalars['Int']>;
  idealTemp?: Maybe<Scalars['Int']>;
  customTemp?: Maybe<Scalars['Int']>;
  archived: Scalars['Boolean'];
  amount: Scalars['Int'];
  image: Scalars['String'];
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  owner: UserDTO;
  scan: ScanDTO;
};

export type WineCreateInputDTO = {
  type: WineTypeDTO;
  name?: Maybe<Scalars['String']>;
  description: WineDescriptionInputDTO;
  year?: Maybe<Scalars['Int']>;
  idealTemp?: Maybe<Scalars['Int']>;
  customTemp?: Maybe<Scalars['Int']>;
  archived: Scalars['Boolean'];
  amount: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  scan: Scalars['String'];
};

export type WineDescriptionDTO = {
  __typename?: 'WineDescription';
  grapeText?: Maybe<Scalars['String']>;
  grape?: Maybe<GrapeDTO>;
  aocText?: Maybe<Scalars['String']>;
  aoc?: Maybe<AocDTO>;
  regionText?: Maybe<Scalars['String']>;
  region?: Maybe<RegionDTO>;
  countryText?: Maybe<Scalars['String']>;
  country?: Maybe<CountryDTO>;
  domainText?: Maybe<Scalars['String']>;
  domain?: Maybe<DomainDTO>;
  classificationText?: Maybe<Scalars['String']>;
  classification?: Maybe<ClassificationDTO>;
};

export type WineDescriptionFilterDTO = {
  grapeText?: Maybe<StringFilterDTO>;
  grape?: Maybe<GrapeFilterDTO>;
  aocText?: Maybe<StringFilterDTO>;
  aoc?: Maybe<AocFilterDTO>;
  regionText?: Maybe<StringFilterDTO>;
  region?: Maybe<RegionFilterDTO>;
  countryText?: Maybe<StringFilterDTO>;
  country?: Maybe<CountryFilterDTO>;
  domainText?: Maybe<StringFilterDTO>;
  domain?: Maybe<DomainFilterDTO>;
  classificationText?: Maybe<StringFilterDTO>;
  classification?: Maybe<ClassificationFilterDTO>;
  AND?: Maybe<Array<WineDescriptionFilterDTO>>;
  OR?: Maybe<Array<WineDescriptionFilterDTO>>;
};

export type WineDescriptionInputDTO = {
  grapeText?: Maybe<Scalars['String']>;
  grape?: Maybe<Scalars['String']>;
  aocText?: Maybe<Scalars['String']>;
  aoc?: Maybe<Scalars['String']>;
  regionText?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  countryText?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  domainText?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  classificationText?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
};

export type WineFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  type?: Maybe<WineTypeFilterDTO>;
  name?: Maybe<StringFilterDTO>;
  description?: Maybe<WineDescriptionFilterDTO>;
  year?: Maybe<IntFilterDTO>;
  idealTemp?: Maybe<IntFilterDTO>;
  customTemp?: Maybe<IntFilterDTO>;
  archived?: Maybe<BooleanFilterDTO>;
  amount?: Maybe<IntFilterDTO>;
  rating?: Maybe<IntFilterDTO>;
  owner?: Maybe<UserFilterDTO>;
  AND?: Maybe<Array<WineFilterDTO>>;
  OR?: Maybe<Array<WineFilterDTO>>;
};

export enum WineStyleDTO {
  SparklingDTO = 'sparkling',
  MediumRedDTO = 'mediumRed',
  DryWhiteDTO = 'dryWhite',
  LightRedDTO = 'lightRed',
  SweetWhiteDTO = 'sweetWhite',
  BoldRedDTO = 'boldRed',
  RichWhiteDTO = 'richWhite',
  RoseDTO = 'rose',
  DessertDTO = 'dessert'
}

export type WineStyleFilterDTO = {
  equals?: Maybe<WineStyleDTO>;
  not?: Maybe<WineStyleDTO>;
  in?: Maybe<Array<Maybe<WineStyleDTO>>>;
  notIn?: Maybe<Array<Maybe<WineStyleDTO>>>;
};

export enum WineTypeDTO {
  WhiteDTO = 'white',
  RedDTO = 'red',
  PinkDTO = 'pink',
  SparklingDTO = 'sparkling'
}

export type WineTypeFilterDTO = {
  equals?: Maybe<WineTypeDTO>;
  not?: Maybe<WineTypeDTO>;
  in?: Maybe<Array<Maybe<WineTypeDTO>>>;
  notIn?: Maybe<Array<Maybe<WineTypeDTO>>>;
};

export type WineUpdateInputDTO = {
  type?: Maybe<WineTypeDTO>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<WineDescriptionInputDTO>;
  year?: Maybe<Scalars['Int']>;
  idealTemp?: Maybe<Scalars['Int']>;
  customTemp?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AdviceRequest": [
      "BuyingAdviceRequest",
      "ScanAdviceRequest"
    ],
    "AlgorithmConfigTypeSpecifics": [
      "AlgorithmConfigTypeSpecificsForException",
      "AlgorithmConfigTypeSpecificsForStep"
    ],
    "Suggestion": [
      "DomainSuggestion",
      "FaqSuggestion"
    ],
    "SuggestionSource": [
      "AdviceRequestSuggestionSource",
      "ConversationSuggestionSource"
    ]
  }
};
      export default result;
    