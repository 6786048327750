/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFoodsCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetFoodsCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'foodsCount'>
);


export const GetFoodsCountDocumentDTO = gql`
    query getFoodsCount($search: String) {
  foodsCount(filter: {titleInLanguage: {language: en, text: {contains: $search}}})
}
    `;

/**
 * __useGetFoodsCount__
 *
 * To run a query within a React component, call `useGetFoodsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetFoodsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoodsCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetFoodsCount(baseOptions?: Apollo.QueryHookOptions<GetFoodsCountDTO, GetFoodsCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFoodsCountDTO, GetFoodsCountVariablesDTO>(GetFoodsCountDocumentDTO, options);
      }
export function useGetFoodsCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetFoodsCountDTO, GetFoodsCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFoodsCountDTO, GetFoodsCountVariablesDTO>(GetFoodsCountDocumentDTO, options);
        }
export type GetFoodsCountHookResult = ReturnType<typeof useGetFoodsCount>;
export type GetFoodsCountLazyQueryDTOHookResult = ReturnType<typeof useGetFoodsCountLazyQueryDTO>;
export type GetFoodsCountQueryResultDTO = Apollo.QueryResult<GetFoodsCountDTO, GetFoodsCountVariablesDTO>;