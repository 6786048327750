import { UILogic } from "@aptus/frontend-core";
import { Chip } from "@mui/material";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { formatDistance } from "date-fns";
import { useTranslations } from "domains";
import { Conversation } from "hooks/conversations/models/conversation";
import { PaginationResult, usePagination } from "hooks/table/pagination/usePagination";
import { SortResult, useSort } from "hooks/table/sort/useSort";
import { ConversationStatusDTO, SortDTO, SortOrderDTO } from "models/schema";
import { ClientCell } from "./conversationsContentStyle";

interface Result extends PaginationResult, SortResult {
	columns: GridColDef[];
}

type Props = {};

export const useConversationsTable: UILogic<Props, Result> = () => {
	const pagination = usePagination({});
	const sort = useSort({ defaultSort: { field: "messages.createdAt", order: SortOrderDTO.DescDTO } });

	const { t } = useTranslations();

	const renderFullStatusCell = (params: GridRenderEditCellParams) => {
		if (params.value === ConversationStatusDTO.HandledDTO) {
			return null;
		}

		let color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined = "success";
		if (params.value === ConversationStatusDTO.OwnerRespondedDTO) {
			color = "primary";
		}
		return (
			<Chip label={t(params.value)} color={color} />
		);
	};

	const renderClientCell = (params: GridRenderEditCellParams<Conversation>) => (
		<ClientCell new={params.row.new}>
			{params.row.client}
		</ClientCell>
	);

	const renderDateCell = (params: GridRenderEditCellParams) => formatDistance(new Date(params.value), new Date(), { addSuffix: true });

	const columns = [
		{ field: "client", headerName: "Client", renderCell: renderClientCell, flex: 1 },
		{ field: "status", renderCell: renderFullStatusCell, width: 200 },
		{ field: "language", headerName: "Language", width: 75 },
		{ field: "lastMessage", headerName: "Last message", width: 200, renderCell: renderDateCell },
	];

	const getCustomSort = (s: SortDTO | SortDTO[] | undefined): SortDTO | SortDTO[] | undefined => {
		if (!s) {
			return undefined;
		}

		if (!Array.isArray(s)) {
			if (s.field === "client") {
				return [
					{ field: "owner.givenName", order: s.order },
					{ field: "owner.familyName", order: s.order },
				];
			}

			if (s.field === "lastMessage") {
				return {
					field: "messages.createdAt", order: s.order,
				};
			}
		}

		return s;
	};

	return {
		...pagination,
		...sort,
		sort: getCustomSort(sort.sort),
		columns,
	};
};
