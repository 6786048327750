import { UILogic } from "@aptus/frontend-core";
import { useAocs, useCountries, useGrapes, useRegions } from "domains";
import {
	TypeTemperatureDTO,
	WineTypeDTO,
} from "models/schema";
import React from "react";

import { getDefaultTemperatureForWineType } from "utils/TemperatureUtils";
import {
	useGetAocTemperatures,
	useGetCountryTemperatures,
	useGetGrapeTemperatures,
	useGetRegionTemperatures,
} from "../../../models/getSuggestedTemperatures";

import { SuggestedTemperatures } from "../useScanForm";

interface Props {
	selectedGrape?: string;
	selectedWineType?: WineTypeDTO;
	selectedCountry?: string;
	selectedAoc?: string;
	selectedRegion?: string;
	isScanLabel?: boolean;
}

interface Result {
	suggestedTemperatures?: SuggestedTemperatures;
}

export const useSuggestedTemperatures: UILogic<Props, Result> = ({ isScanLabel, selectedAoc, selectedCountry, selectedRegion, selectedGrape, selectedWineType = WineTypeDTO.RedDTO }) => {
	const { grape } = useGrapes(useGetGrapeTemperatures({ variables: { id: selectedGrape! }, skip: !selectedGrape || !isScanLabel }));
	const { aoc } = useAocs(useGetAocTemperatures({ variables: { id: selectedAoc! }, skip: !selectedAoc || !isScanLabel }));
	const { region } = useRegions(useGetRegionTemperatures({ variables: { id: selectedRegion! }, skip: !selectedRegion || !isScanLabel }));
	const { country } = useCountries(useGetCountryTemperatures({ variables: { id: selectedCountry! }, skip: !selectedCountry || !isScanLabel }));

	const getSuggestedTemperatureByType = (type: WineTypeDTO, temperatures?: TypeTemperatureDTO[]) => (temperatures || []).find((temp) => temp.type === type)?.temperature || undefined;

	const getSuggestedTemperatures = () => {
		const grapeTemperature = grape?.temperatures && getSuggestedTemperatureByType(selectedWineType, grape.temperatures);
		const aocTemperature = aoc?.temperatures && getSuggestedTemperatureByType(selectedWineType, aoc.temperatures);
		const regionTemperature = region?.temperatures && getSuggestedTemperatureByType(selectedWineType, region.temperatures);
		const countryTemperature = country?.temperatures && getSuggestedTemperatureByType(selectedWineType, country.temperatures);

		return {
			grapeTemperature: selectedGrape ? grapeTemperature : undefined,
			aocTemperature: selectedAoc ? aocTemperature : undefined,
			regionTemperature: selectedRegion ? regionTemperature : undefined,
			countryTemperature: selectedCountry ? countryTemperature : undefined,
			suggestedTemperature: grapeTemperature || aocTemperature || regionTemperature || countryTemperature || getDefaultTemperatureForWineType(selectedWineType),
		};
	};

	const suggestedTemperatures = React.useMemo(() => getSuggestedTemperatures(), [grape, aoc, region, country, selectedWineType]);

	return {
		suggestedTemperatures,
	};
};
