/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteRegionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteRegionDTO = (
  { __typename?: 'Mutation' }
  & { updateOneRegion: (
    { __typename?: 'Region' }
    & Pick<Types.RegionDTO, 'id'>
  ) }
);


export const DeleteRegionDocumentDTO = gql`
    mutation deleteRegion($id: String!) {
  updateOneRegion(id: $id, data: {archived: true}) {
    id
  }
}
    `;
export type DeleteRegionMutationFnDTO = Apollo.MutationFunction<DeleteRegionDTO, DeleteRegionVariablesDTO>;

/**
 * __useDeleteRegion__
 *
 * To run a mutation, you first call `useDeleteRegion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegion, { data, loading, error }] = useDeleteRegion({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRegion(baseOptions?: Apollo.MutationHookOptions<DeleteRegionDTO, DeleteRegionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegionDTO, DeleteRegionVariablesDTO>(DeleteRegionDocumentDTO, options);
      }
export type DeleteRegionHookResult = ReturnType<typeof useDeleteRegion>;
export type DeleteRegionMutationResultDTO = Apollo.MutationResult<DeleteRegionDTO>;
export type DeleteRegionMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteRegionDTO, DeleteRegionVariablesDTO>;