import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Correction } from "./models/correction";
import { CreateCorrectionInput, DeleteCorrectionInput, SaveCorrectionInput, UpdateCorrectionInput } from "./models/correctionInput";

export type CorrectionsAPI = InternalAPI<Correction[]>;

export type UpdateCorrectionMutation = Mutation<UpdateCorrectionInput, Correction>;
export type CreateCorrectionMutation = Mutation<CreateCorrectionInput, Correction>;
export type DeleteCorrectionMutation = Mutation<DeleteCorrectionInput, Success>;

interface Props {
	API: CorrectionsAPI;
	defaultCorrection: Correction;
	updateMutation: UpdateCorrectionMutation;
	createMutation: CreateCorrectionMutation;
	deleteMutation: DeleteCorrectionMutation;
}
interface Result {
	correction: Correction;
	corrections: Correction[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveCorrectionInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useCorrectionsUseCases: UseCases<Props, Result> = ({ API, defaultCorrection, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveCorrectionInput): input is UpdateCorrectionInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("correctionHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("correctionHasBeenSuccessfullyUpdated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("correctionHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		correction: API.data[0] || defaultCorrection,
		corrections: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
