import styled from "styled-components";

export const HeaderBar = styled.div`
	padding: 20px;
	display: flex;
	justify-content: space-between;
`;

export const ConversationContent = styled.div`
	padding: 20px;
	/* display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px; */
`;

export const ContentBox = styled.div`
	background-color: white;
	padding: 20px;
`;
