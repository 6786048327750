import { Query, UILogic } from "@aptus/frontend-core";
import { AutoCompleteSelectProps, getDropdownOptions } from "components/form/autoCompleteSelect/autoCompleteSelect";
import { useGrapes } from "domains";
import { GrapesAPIDTO } from "hooks/grapes/grapeMapper";
import { Grape } from "hooks/grapes/models/grape";
import { GrapeFilterDTO } from "models/schema";
import { useGetGrapes } from "./models/getGrapes";

export interface GrapeSelectProps extends Omit<AutoCompleteSelectProps, "dropdownProps"> {
	region?: string;
	defaultValue?: string;
}

type Result = AutoCompleteSelectProps;

interface QueryProps {
	id?: Grape["id"];
}

const useGetFilteredGrapes: Query<QueryProps, GrapesAPIDTO> = ({ id }) => {
	const filter: GrapeFilterDTO = {
		OR: [
			{ archived: { equals: false } },
			...id ? [{ id: { equals: id } }] : [],
		],
	};

	return useGetGrapes({ variables: { filter } });
};

export const useGrapeSelect: UILogic<GrapeSelectProps, Result> = (props) => {
	const { grapes, isLoading } = useGrapes(useGetFilteredGrapes({
		id: props.defaultValue,
	}));

	const options = {
		loading: isLoading,
		options: getDropdownOptions(grapes),
	};

	return {
		...props,
		dropdownProps: options,
	};
};
