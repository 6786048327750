import { Database } from "mocks/db";

export const populateCountryCollection = (db: Database): void => {
	db.country.create({
		id: "country-id",
	});
	db.country.create({});
	db.country.create({});
	db.country.create({});
	db.country.create({});
	db.country.create({});
	db.country.create({});
};
