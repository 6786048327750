/* eslint-disable */
import * as Types from '../../../../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetExceptionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetExceptionDTO = (
  { __typename?: 'Query' }
  & { exception: (
    { __typename?: 'Exception' }
    & Pick<Types.ExceptionDTO, 'id' | 'text' | 'archived'>
    & { aoc?: Types.Maybe<(
      { __typename?: 'Aoc' }
      & Pick<Types.AocDTO, 'id'>
    )>, country?: Types.Maybe<(
      { __typename?: 'Country' }
      & Pick<Types.CountryDTO, 'id'>
    )>, region?: Types.Maybe<(
      { __typename?: 'Region' }
      & Pick<Types.RegionDTO, 'id'>
    )>, grape?: Types.Maybe<(
      { __typename?: 'Grape' }
      & Pick<Types.GrapeDTO, 'id'>
    )>, domain?: Types.Maybe<(
      { __typename?: 'Domain' }
      & Pick<Types.DomainDTO, 'id'>
    )>, temperatures?: Types.Maybe<Array<(
      { __typename?: 'TypeTemperature' }
      & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  ) }
);


export const GetExceptionDocumentDTO = gql`
    query getException($id: String!) {
  exception(id: $id) {
    id
    text
    aoc {
      id
    }
    country {
      id
    }
    region {
      id
    }
    grape {
      id
    }
    domain {
      id
    }
    temperatures {
      type
      temperature
    }
    archived
  }
}
    `;

/**
 * __useGetException__
 *
 * To run a query within a React component, call `useGetException` and pass it any options that fit your needs.
 * When your component renders, `useGetException` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetException({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetException(baseOptions: Apollo.QueryHookOptions<GetExceptionDTO, GetExceptionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExceptionDTO, GetExceptionVariablesDTO>(GetExceptionDocumentDTO, options);
      }
export function useGetExceptionLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetExceptionDTO, GetExceptionVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExceptionDTO, GetExceptionVariablesDTO>(GetExceptionDocumentDTO, options);
        }
export type GetExceptionHookResult = ReturnType<typeof useGetException>;
export type GetExceptionLazyQueryDTOHookResult = ReturnType<typeof useGetExceptionLazyQueryDTO>;
export type GetExceptionQueryResultDTO = Apollo.QueryResult<GetExceptionDTO, GetExceptionVariablesDTO>;