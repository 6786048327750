/* eslint-disable jsx-a11y/anchor-is-valid */
import { UIComponent } from "@aptus/frontend-core";
import { Autocomplete, Button, Chip, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import Search from "@mui/icons-material/Search";
import { useTranslations } from "domains";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PageHeader } from "components/pageHeader/pageHeader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AdviceRequestStatusDTO, AdviceRequestTypeDTO, LanguageDTO } from "models/schema";
import CloseIcon from "@mui/icons-material/Close";
import { FilterBarHeader, RequestsFilterBarStyle, ToggleFilters, VisibleFilters } from "./requestsFilterBarStyle";
import { useRequestsFilterBar } from "./useRequestsFilterBar";

export const RequestsFilterBar: UIComponent = () => {
	const {
		search,
		language,
		onSelectLanguage,
		onSearch,
		status,
		onSelectStatus,
		createdOn,
		onSelectDate,
		requestType,
		onSelectType,
		showSecondFilterBar,
		toggleSecondFilterBar,
	} = useRequestsFilterBar({});
	const { t } = useTranslations();

	return (
		<RequestsFilterBarStyle>
			<FilterBarHeader>
				<PageHeader title="requests.page.title" />
				<Button
					onClick={toggleSecondFilterBar}
					variant="text"
				>
					{t(`requests.filter.${showSecondFilterBar ? "hide" : "show"}`)}
					{" "}
					{showSecondFilterBar ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
				</Button>
			</FilterBarHeader>
			<VisibleFilters>
				<FormControl style={{ marginRight: 20, flex: 2 }} variant="outlined">
					<InputLabel htmlFor="search">{t("requests.filter.search")}</InputLabel>
					<OutlinedInput
						defaultValue={search}
						onChange={(e) => onSearch(e.target.value)}
						id="search"
						endAdornment={(
							<InputAdornment position="end">
								<IconButton
									edge="end"
								>
									<Search />
								</IconButton>
							</InputAdornment>
						)}
						label={t("requests.filter.search")}
					/>
				</FormControl>
				<FormControl style={{ marginRight: 20, flex: 1 }} sx={{ minWidth: 300 }}>
					<InputLabel id="table">{t("requests.filter.language")}</InputLabel>
					<Select
						value={language}
						onChange={(e) => onSelectLanguage(e.target.value as string)}
						labelId="language"
						id="language"
						label={t("requests.filter.language")}
					>
						<MenuItem value="">{t("empty")}</MenuItem>
						{Object.values(LanguageDTO).map((l) => <MenuItem value={l}>{t(`language.${l}`)}</MenuItem>)}
					</Select>
				</FormControl>
				<FormControl style={{ flex: 1 }} sx={{ minWidth: 300 }}>
					<InputLabel id="table">{t("requests.filter.status")}</InputLabel>
					<Select
						value={status}
						onChange={(e) => onSelectStatus(e.target.value as string)}
						labelId="status"
						id="status"
						label={t("requests.filter.status")}
					>
						<MenuItem value="">{t("empty")}</MenuItem>
						{Object.values(AdviceRequestStatusDTO).map((s) => <MenuItem value={s}>{t(`status.${s}`)}</MenuItem>)}
					</Select>
				</FormControl>
			</VisibleFilters>
			<ToggleFilters visible={showSecondFilterBar}>
				<FormControl style={{ marginRight: 20 }} sx={{ minWidth: 300 }}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label={t("requests.filter.createdOn")}
							value={createdOn}
							onChange={(v) => onSelectDate(v)}
							renderInput={(params) => <TextField {...params} />}
							InputProps={{
								endAdornment: (
									<IconButton onClick={() => onSelectDate(null)}>
										<CloseIcon />
									</IconButton>
								),
							}}
							InputAdornmentProps={{
								position: "start",
							}}
						/>
					</LocalizationProvider>
				</FormControl>
				<FormControl sx={{ minWidth: 300 }}>
					<Autocomplete
						onChange={(e, data) => onSelectType(data)}
						disablePortal
						multiple
						freeSolo
						options={Object.values(AdviceRequestTypeDTO)}
						value={requestType || []}
						renderTags={(selected: string[], getTagProps) => (
							selected.map((value: string, index) => (
								<Chip label={value} {...getTagProps({ index })} />
							))
						)}
						renderInput={(params) => (
							<TextField
								label={t("requests.filter.type")}
								{...params}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{params.InputProps.endAdornment}
										</>
									),
								}}
							/>
						)}
					/>
				</FormControl>
			</ToggleFilters>
		</RequestsFilterBarStyle>
	);
};
