/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageComponent } from "@aptus/frontend-core";
import { Breadcrumbs, Chip, Link } from "@mui/material";
import Loader from "components/loader/loader";
import { useConversations, useRoutes, useTranslations } from "domains";
import { ConversationStatusDTO } from "models/schema";
import BasePage from "pages/basePage/basePage";
import { useHistory, useParams } from "react-router";
import { Chat } from "./components/chat/Chat";
// import { FAQSection } from "./components/faq/FAQSection/Faq";
// import { FAQSuggestionSection } from "./components/faq/form/FaqSuggestionSection";
import { ConversationContent, HeaderBar } from "./conversationDetailPageStyle";
import { useGetConversation } from "./models/getConversation";

interface Params {
	id: string;
}

export const ConversationDetailPage: PageComponent = () => {
	const { t } = useTranslations();
	const { routes } = useRoutes();
	const history = useHistory();
	const params = useParams<Params>();
	const { isLoading, conversation } = useConversations(useGetConversation({
		variables: { id: params.id },
		pollInterval: 5000,
		fetchPolicy: "cache-and-network",
	}));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	let color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined = "success";
	if (conversation.status === ConversationStatusDTO.OwnerRespondedDTO) {
		color = "primary";
	}

	return (
		<BasePage>
			<HeaderBar>
				<Breadcrumbs aria-label="breadcrumb">
					<Link onClick={() => history.push(routes.conversations)}>
						{t("allChats")}
					</Link>
					<p>
						{conversation.client}
					</p>
				</Breadcrumbs>
				<Chip label={t(`conversation.${conversation.status}`)} color={color} />
			</HeaderBar>
			<ConversationContent>
				<Chat conversation={conversation} />
				{/* <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
					<FAQSection keywords={[]} />
					<FAQSuggestionSection sourceId={conversation.id} />
				</div> */}
			</ConversationContent>
		</BasePage>
	);
};
