import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Exception } from "./models/exception";
import { CreateExceptionInput, DeleteExceptionInput, SaveExceptionInput, UpdateExceptionInput } from "./models/exceptionInput";

export type ExceptionsAPI = InternalAPI<Exception[]>;

export type UpdateExceptionMutation = Mutation<UpdateExceptionInput, Exception>;
export type CreateExceptionMutation = Mutation<CreateExceptionInput, Exception>;
export type DeleteExceptionMutation = Mutation<DeleteExceptionInput, Success>;

interface Props {
	API: ExceptionsAPI;
	defaultException: Exception;
	updateMutation: UpdateExceptionMutation;
	createMutation: CreateExceptionMutation;
	deleteMutation: DeleteExceptionMutation;
}
interface Result {
	exception: Exception;
	exceptions: Exception[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveExceptionInput
	) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useExceptionsUseCases: UseCases<Props, Result> = ({ API, defaultException, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveExceptionInput): input is UpdateExceptionInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("exceptionHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("exceptionHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("exceptionHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		exception: API.data[0] || defaultException,
		exceptions: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
