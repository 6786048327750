import { UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import { Classification } from "hooks/classifications/models/classification";
import { SaveClassificationInput } from "hooks/domains/models/classificationInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UseFormTypes } from "../useFormTypes";

interface Props {
	initialValues?: Classification;
	submit: (values: SaveClassificationInput) => void;
	remove: (id: string) => void;
}

interface Result extends UseFormTypes<Classification> {
	handleDelete: (id: string) => void;
}

export const useClassificationForm: UILogic<Props, Result> = ({ initialValues, remove, submit }) => {
	const { currentQueryString: { selected, create }, updateQueryString } = useRoutes();

	const defaultValues = {
		...initialValues,
		id: selected,
	};

	const { register, handleSubmit, control, reset, formState: { errors } } = useForm<Classification>({ defaultValues });

	useEffect(() => {
		reset(defaultValues);
	}, [create, initialValues]);

	const handleFormSubmit = (values: SaveClassificationInput): void => {
		submit(values);
	};

	const handleDelete = (id: string) => {
		remove(id);
		updateQueryString({ selected: null, create: true });
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		handleDelete,
		errors,
	};
};
