import { UILogic } from "@aptus/frontend-core";
import { UpdateAdviceRequestInput } from "hooks/adviceRequests/models/adviceRequestInput";
import { AdviceRequestTypeDTO } from "models/schema";
import { UseFormTypes } from "pages/databasePage/components/databaseContent/recordForm/forms/useFormTypes";
import React from "react";
import { useForm } from "react-hook-form";
import { ScanAdviceRequestFormValues } from "../useScanForm";

interface Props {
	initialValues: ScanAdviceRequestFormValues;
	submit: (values: Partial<UpdateAdviceRequestInput>) => Promise<void>;
}

type Result = UseFormTypes<ScanAdviceRequestFormValues>;

export const useScanAdviceRequestForm: UILogic<Props, Result> = ({ initialValues, submit }) => {
	const { register, control, handleSubmit, reset } = useForm({ defaultValues: initialValues });

	React.useEffect(() => {
		reset(initialValues);
	}, [initialValues]);

	const handleFormSubmit = async (values: ScanAdviceRequestFormValues) => {
		await submit({ ...values, type: AdviceRequestTypeDTO.ScanDTO });
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
	};
};
