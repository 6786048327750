import { Query } from "@aptus/frontend-core";
import { Tables } from "pages/databasePage/components/databaseFilterBar/useDatabaseFilterBar";
import { PagingDTO, SortOrderDTO } from "models/schema";
import { ApolloError } from "@apollo/client";
import { useRoutes } from "domains";
import { useEffect, useState } from "react";
import { useGetCountryList } from "./models/getCountries";
import { useGetClassificationList } from "./models/getClassifications";
import { useGetGrapeList } from "./models/getGrapes";
import { useGetAocList } from "./models/getAocs";
import { useGetRegionList } from "./models/getRegions";
import { useGetDomainList } from "./models/getDomains";
import { useGetCountriesCount } from "./models/getCountriesCount";
import { useGetClassificationsCount } from "./models/getClassificationsCount";
import { useGetGrapesCount } from "./models/getGrapesCount";
import { useGetAocsCount } from "./models/getAocsCount";
import { useGetRegionsCount } from "./models/getRegionsCount";
import { useGetDomainsCount } from "./models/getDomainsCount";
import { useGetCorrectionList } from "./models/getCorrections";
import { useGetCorrectionsCount } from "./models/getCorrectionsCount";
import { useGetExceptionList } from "./models/getExceptions";
import { useGetExceptionsCount } from "./models/getExceptionsCount";
import { useGetFaqList } from "./models/getFaqs";
import { useGetFaqsCount } from "./models/getFaqsCount";
import { useGetFoodList } from "./models/getFoods";
import { useGetFoodsCount } from "./models/getFoodsCount";
import { useGetMainGrapeList } from "./models/getMainGrapes";
import { useGetMainGrapesCount } from "./models/getMainGrapesCount";

interface APIDTO {
	data?: {
		name?: string | null;
		id: string;
	}[];
	isLoading: boolean;
	error?: ApolloError;
	count: number;
	loadMore: () => void;
}

export const useDatabaseContent: Query<{}, APIDTO> = () => {
	const { currentQueryString: { search, table } } = useRoutes();

	const [page, setPage] = useState(0);

	useEffect(() => {
		if (page !== 0) {
			setPage(0);
		}
	}, [table]);

	const paging: PagingDTO = { offset: 0, limit: 20 };

	const getVariables = (match: Tables, isCount?: boolean, sortProperty?: string) => ({
		variables: {
			search,
			sort: (!isCount && [{ field: sortProperty || "name", order: SortOrderDTO.AscDTO }]) || [],
			paging: (!isCount && paging) || undefined,
		},
		skip: table !== match });

	const { data: countriesData, loading: countriesLoading, error: countriesError, fetchMore: fetchMoreCountries } = useGetCountryList(getVariables(Tables.Country));
	const { data: countriesCount } = useGetCountriesCount(getVariables(Tables.Country, true));

	const { data: classificationsData, loading: classificationsLoading, error: classificationsError, fetchMore: fetchMoreClassifications } = useGetClassificationList(getVariables(Tables.Classification));
	const { data: classificationsCount } = useGetClassificationsCount(getVariables(Tables.Classification, true));

	const { data: grapesData, loading: grapesLoading, error: grapesError, fetchMore: fetchMoreGrapes } = useGetGrapeList(getVariables(Tables.Grape));
	const { data: grapesCount } = useGetGrapesCount(getVariables(Tables.Grape, true));

	const { data: aocData, loading: aocLoading, error: aocError, fetchMore: fetchMoreAocs } = useGetAocList(getVariables(Tables.AOC));
	const { data: aocCount } = useGetAocsCount(getVariables(Tables.AOC, true));

	const { data: regionsData, loading: regionsLoading, error: regionError, fetchMore: fetchMoreRegions } = useGetRegionList(getVariables(Tables.Region));
	const { data: regionsCount } = useGetRegionsCount(getVariables(Tables.Region, true));

	const { data: domainsData, loading: domainsLoading, error: domainError, fetchMore: fetchMoreDomains } = useGetDomainList(getVariables(Tables.Domain));
	const { data: domainsCount } = useGetDomainsCount(getVariables(Tables.Domain, true));

	const { data: correctionsData, loading: correctionsLoading, error: correctionError, fetchMore: fetchMoreCorrections } = useGetCorrectionList(getVariables(Tables.Correction));
	const { data: correctionsCount } = useGetCorrectionsCount(getVariables(Tables.Correction, true));

	const { data: exceptionsData, loading: exceptionsLoading, error: exceptionError, fetchMore: fetchMoreExceptions } = useGetExceptionList(getVariables(Tables.Exception, false, "text"));
	const { data: exceptionsCount } = useGetExceptionsCount(getVariables(Tables.Exception, true));

	const { data: faqsData, loading: faqsLoading, error: faqsError, fetchMore: fetchMoreFaqs } = useGetFaqList(getVariables(Tables.FAQ, false, "titles.en"));
	const { data: faqsCount } = useGetFaqsCount(getVariables(Tables.FAQ, true));

	const { data: foodsData, loading: foodsLoading, error: foodsError, fetchMore: fetchMoreFoods } = useGetFoodList(getVariables(Tables.FoodPairing, false, "titles.en"));
	const { data: foodsCount } = useGetFoodsCount(getVariables(Tables.FoodPairing, true));

	const { data: mainGrapesData, loading: mainGrapesLoading, error: mainGrapesError, fetchMore: fetchMoreMainGrapes } = useGetMainGrapeList(getVariables(Tables.MainGrape));
	const { data: mainGrapesCount } = useGetMainGrapesCount(getVariables(Tables.MainGrape, true));

	const getFetchMoreQuery = () => {
		switch (table) {
			case Tables.Country:
				return {
					fetchMore: fetchMoreCountries,
					key: "countries",
				};
			case Tables.Classification:
				return {
					fetchMore: fetchMoreClassifications,
					key: "classifications",
				};
			case Tables.Grape:
				return {
					fetchMore: fetchMoreGrapes,
					key: "grapes",
				};
			case Tables.AOC:
				return {
					fetchMore: fetchMoreAocs,
					key: "aocs",
				};
			case Tables.Region:
				return {
					fetchMore: fetchMoreRegions,
					key: "regions",
				};
			case Tables.Domain:
				return {
					fetchMore: fetchMoreDomains,
					key: "domains",
				};
			case Tables.Correction:
				return {
					fetchMore: fetchMoreCorrections,
					key: "corrections",
				};
			case Tables.Exception:
				return {
					fetchMore: fetchMoreExceptions,
					key: "exceptions",
				};
			case Tables.FAQ:
				return {
					fetchMore: fetchMoreFaqs,
					key: "faqs",
				};
			case Tables.FoodPairing:
				return {
					fetchMore: fetchMoreFoods,
					key: "foods",
				};
			case Tables.MainGrape:
				return {
					fetchMore: fetchMoreMainGrapes,
					key: "mainGrapes",
				};
			default:
				return undefined;
		}
	};

	const loadMore = () => {
		const data = getFetchMoreQuery();

		if (data) {
			data.fetchMore<any, any>({
				variables: { paging: { offset: (page + 1) * paging.limit, limit: paging.limit } },
				updateQuery: (previousResult, { fetchMoreResult }) => {
					const newEntries = ((fetchMoreResult as any || {})[data.key] as any) || [];
					const oldEntries = ((previousResult as any || {})[data.key] as any) || [];
					return {
						[data.key]: [...oldEntries, ...newEntries],
					};
				},
			});

			setPage(page + 1);
		}
	};

	return {
		data: mainGrapesData?.mainGrapes || foodsData?.foods || faqsData?.faqs || exceptionsData?.exceptions || correctionsData?.corrections || countriesData?.countries || classificationsData?.classifications || grapesData?.grapes || aocData?.aocs || regionsData?.regions || domainsData?.domains,
		isLoading: mainGrapesLoading || foodsLoading || faqsLoading || exceptionsLoading || correctionsLoading || countriesLoading || classificationsLoading || grapesLoading || aocLoading || regionsLoading || domainsLoading,
		error: mainGrapesError || foodsError || faqsError || exceptionError || correctionError || countriesError || classificationsError || grapesError || aocError || regionError || domainError,
		count: mainGrapesCount?.mainGrapesCount || foodsCount?.foodsCount || faqsCount?.faqsCount || exceptionsCount?.exceptionsCount || correctionsCount?.correctionsCount || countriesCount?.countriesCount || classificationsCount?.classificationsCount || grapesCount?.grapesCount || aocCount?.aocsCount || regionsCount?.regionsCount || domainsCount?.domainsCount || 0,
		loadMore,
	};
};
