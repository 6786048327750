import { UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import { Correction } from "hooks/corrections/models/correction";
import { SaveCorrectionInput } from "hooks/corrections/models/correctionInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UseFormTypes } from "../useFormTypes";

interface Props {
	initialValues?: Correction;
	submit: (values: SaveCorrectionInput) => void;
	remove: (id: string) => void;
}

interface Result extends UseFormTypes<Correction> {
	handleDelete: (id: string) => void;
}

export const useCorrectionForm: UILogic<Props, Result> = ({ initialValues, submit, remove }) => {
	const { currentQueryString: { selected, create }, updateQueryString } = useRoutes();

	const defaultValues = {
		...initialValues,
		id: selected,
	};

	const { register, handleSubmit, control, reset, formState: { errors } } = useForm<Correction>({ defaultValues });

	useEffect(() => {
		reset(defaultValues);
	}, [create, initialValues]);

	const handleFormSubmit = (values: SaveCorrectionInput): void => {
		submit(values);
	};

	const handleDelete = (id: string) => {
		remove(id);
		updateQueryString({ selected: null, create: true });
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		handleDelete,
		errors,
	};
};
