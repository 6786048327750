import { UseCases } from "@aptus/frontend-core";
import { useTranslations } from "domains";
import event, { BaseEvents } from "event";
import { useEffect, useState } from "react";
import { Notification } from "./models/notification";

interface Result {
	notification?: Notification;
	closeNotification: () => void;
}

export const useNotificationsUseCases: UseCases<undefined, Result> = () => {
	const [notification, setNotification] = useState<Result["notification"]>(undefined);
	const [autocloseDelay, setAutocloseDelay] = useState<number | undefined>(undefined);
	const { t } = useTranslations();

	const showRequestSucceeded: BaseEvents["mutationSucceeded"] = (message) => {
		setNotification({ title: t("successfullySaved"), type: "success", message });
		setAutocloseDelay(5000);
	};

	const showRequestFailed: BaseEvents["mutationFailed"] = (error) => {
		setNotification({ title: t("somethingWentWrong"), type: "warning", error });
		setAutocloseDelay(undefined);
	};

	const closeNotification: Result["closeNotification"] = (): void => {
		setNotification(undefined);
		setAutocloseDelay(undefined);
	};

	useEffect(() => {
		if (autocloseDelay) {
			setTimeout(() => {
				closeNotification();
			}, autocloseDelay);
		}
	}, [autocloseDelay]);

	useEffect(() => {
		event.on("mutationSucceeded", showRequestSucceeded);
		event.on("mutationFailed", showRequestFailed);

		return (): void => {
			event.removeListener("mutationSucceeded", showRequestSucceeded);
			event.removeListener("mutationFailed", showRequestFailed);
		};
	}, []);

	return {
		notification,
		closeNotification,
	};
};
