/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGrapeTemperaturesVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetGrapeTemperaturesDTO = (
  { __typename?: 'Query' }
  & { grape: (
    { __typename?: 'Grape' }
    & Pick<Types.GrapeDTO, 'id' | 'name'>
    & { temperatures?: Types.Maybe<Array<(
      { __typename?: 'TypeTemperature' }
      & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  ) }
);

export type GetAocTemperaturesVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetAocTemperaturesDTO = (
  { __typename?: 'Query' }
  & { aoc: (
    { __typename?: 'Aoc' }
    & Pick<Types.AocDTO, 'id' | 'name'>
    & { temperatures?: Types.Maybe<Array<(
      { __typename?: 'AocTypeTemperature' }
      & Pick<Types.AocTypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  ) }
);

export type GetRegionTemperaturesVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetRegionTemperaturesDTO = (
  { __typename?: 'Query' }
  & { region: (
    { __typename?: 'Region' }
    & Pick<Types.RegionDTO, 'id' | 'name'>
    & { temperatures?: Types.Maybe<Array<(
      { __typename?: 'TypeTemperature' }
      & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  ) }
);

export type GetCountryTemperaturesVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetCountryTemperaturesDTO = (
  { __typename?: 'Query' }
  & { country: (
    { __typename?: 'Country' }
    & Pick<Types.CountryDTO, 'id' | 'name'>
    & { temperatures?: Types.Maybe<Array<(
      { __typename?: 'TypeTemperature' }
      & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  ) }
);


export const GetGrapeTemperaturesDocumentDTO = gql`
    query getGrapeTemperatures($id: String!) {
  grape(id: $id) {
    id
    name
    temperatures {
      type
      temperature
    }
  }
}
    `;

/**
 * __useGetGrapeTemperatures__
 *
 * To run a query within a React component, call `useGetGrapeTemperatures` and pass it any options that fit your needs.
 * When your component renders, `useGetGrapeTemperatures` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrapeTemperatures({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGrapeTemperatures(baseOptions: Apollo.QueryHookOptions<GetGrapeTemperaturesDTO, GetGrapeTemperaturesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGrapeTemperaturesDTO, GetGrapeTemperaturesVariablesDTO>(GetGrapeTemperaturesDocumentDTO, options);
      }
export function useGetGrapeTemperaturesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetGrapeTemperaturesDTO, GetGrapeTemperaturesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGrapeTemperaturesDTO, GetGrapeTemperaturesVariablesDTO>(GetGrapeTemperaturesDocumentDTO, options);
        }
export type GetGrapeTemperaturesHookResult = ReturnType<typeof useGetGrapeTemperatures>;
export type GetGrapeTemperaturesLazyQueryDTOHookResult = ReturnType<typeof useGetGrapeTemperaturesLazyQueryDTO>;
export type GetGrapeTemperaturesQueryResultDTO = Apollo.QueryResult<GetGrapeTemperaturesDTO, GetGrapeTemperaturesVariablesDTO>;
export const GetAocTemperaturesDocumentDTO = gql`
    query getAocTemperatures($id: String!) {
  aoc(id: $id) {
    id
    name
    temperatures {
      type
      temperature
    }
  }
}
    `;

/**
 * __useGetAocTemperatures__
 *
 * To run a query within a React component, call `useGetAocTemperatures` and pass it any options that fit your needs.
 * When your component renders, `useGetAocTemperatures` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAocTemperatures({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAocTemperatures(baseOptions: Apollo.QueryHookOptions<GetAocTemperaturesDTO, GetAocTemperaturesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAocTemperaturesDTO, GetAocTemperaturesVariablesDTO>(GetAocTemperaturesDocumentDTO, options);
      }
export function useGetAocTemperaturesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetAocTemperaturesDTO, GetAocTemperaturesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAocTemperaturesDTO, GetAocTemperaturesVariablesDTO>(GetAocTemperaturesDocumentDTO, options);
        }
export type GetAocTemperaturesHookResult = ReturnType<typeof useGetAocTemperatures>;
export type GetAocTemperaturesLazyQueryDTOHookResult = ReturnType<typeof useGetAocTemperaturesLazyQueryDTO>;
export type GetAocTemperaturesQueryResultDTO = Apollo.QueryResult<GetAocTemperaturesDTO, GetAocTemperaturesVariablesDTO>;
export const GetRegionTemperaturesDocumentDTO = gql`
    query getRegionTemperatures($id: String!) {
  region(id: $id) {
    id
    name
    temperatures {
      type
      temperature
    }
  }
}
    `;

/**
 * __useGetRegionTemperatures__
 *
 * To run a query within a React component, call `useGetRegionTemperatures` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionTemperatures` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionTemperatures({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegionTemperatures(baseOptions: Apollo.QueryHookOptions<GetRegionTemperaturesDTO, GetRegionTemperaturesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionTemperaturesDTO, GetRegionTemperaturesVariablesDTO>(GetRegionTemperaturesDocumentDTO, options);
      }
export function useGetRegionTemperaturesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionTemperaturesDTO, GetRegionTemperaturesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionTemperaturesDTO, GetRegionTemperaturesVariablesDTO>(GetRegionTemperaturesDocumentDTO, options);
        }
export type GetRegionTemperaturesHookResult = ReturnType<typeof useGetRegionTemperatures>;
export type GetRegionTemperaturesLazyQueryDTOHookResult = ReturnType<typeof useGetRegionTemperaturesLazyQueryDTO>;
export type GetRegionTemperaturesQueryResultDTO = Apollo.QueryResult<GetRegionTemperaturesDTO, GetRegionTemperaturesVariablesDTO>;
export const GetCountryTemperaturesDocumentDTO = gql`
    query getCountryTemperatures($id: String!) {
  country(id: $id) {
    id
    name
    temperatures {
      type
      temperature
    }
  }
}
    `;

/**
 * __useGetCountryTemperatures__
 *
 * To run a query within a React component, call `useGetCountryTemperatures` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryTemperatures` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryTemperatures({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCountryTemperatures(baseOptions: Apollo.QueryHookOptions<GetCountryTemperaturesDTO, GetCountryTemperaturesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryTemperaturesDTO, GetCountryTemperaturesVariablesDTO>(GetCountryTemperaturesDocumentDTO, options);
      }
export function useGetCountryTemperaturesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryTemperaturesDTO, GetCountryTemperaturesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryTemperaturesDTO, GetCountryTemperaturesVariablesDTO>(GetCountryTemperaturesDocumentDTO, options);
        }
export type GetCountryTemperaturesHookResult = ReturnType<typeof useGetCountryTemperatures>;
export type GetCountryTemperaturesLazyQueryDTOHookResult = ReturnType<typeof useGetCountryTemperaturesLazyQueryDTO>;
export type GetCountryTemperaturesQueryResultDTO = Apollo.QueryResult<GetCountryTemperaturesDTO, GetCountryTemperaturesVariablesDTO>;