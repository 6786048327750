import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Conversation } from "./models/conversation";
import { ChatInput, UpdateConversationInput } from "./models/conversationInput";

export type ConversationsAPI = InternalAPI<Conversation[] | number>;

export type UpdateConversationMutation = Mutation<UpdateConversationInput, Conversation>;
export type ChatMutation = Mutation<ChatInput, Conversation>;

interface Props {
	API: ConversationsAPI;
	defaultConversation: Conversation;
	updateMutation: UpdateConversationMutation;
	chatMutation: ChatMutation;
}

interface Result {
	conversation: Conversation;
	conversations: Conversation[];
	count: number;
	isLoading: boolean;
	isSending: boolean;
	error?: Error;
	save: (input: UpdateConversationInput) => Promise<void>;
	sendMessage: (input: ChatInput) => Promise<void>;
}

export const useConversationsUseCases: UseCases<Props, Result> = ({ API, defaultConversation, chatMutation, updateMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const [isSending, setIsSending] = useState<Result["isLoading"]>(false);

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			await updateMutation(input);
			event.emit("mutationSucceeded", t("conversationHasBeenSuccessfullyUpdated"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const sendMessage: Result["sendMessage"] = async (input) => {
		setIsSending(true);
		try {
			await chatMutation(input);
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsSending(false);
	};

	return {
		conversation: Array.isArray(API.data) && API.data.length ? API.data[0] : defaultConversation,
		conversations: Array.isArray(API.data) ? API.data : [],
		isLoading: API.isLoading || isLoading,
		isSending,
		count: !Array.isArray(API.data) ? API.data : 0,
		save,
		sendMessage,
	};
};
