import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { CorrectionDTO, QueryDTO } from "models/schema";
import { Correction } from "./models/correction";
import { CreateCorrectionMutationFnDTO } from "./models/createCorrection";
import { DeleteCorrectionMutationFnDTO } from "./models/deleteCorrection";
import { UpdateCorrectionMutationFnDTO } from "./models/updateCorrection";
import { CorrectionsAPI, CreateCorrectionMutation, DeleteCorrectionMutation, UpdateCorrectionMutation } from "./useCorrectionsUseCases";

export type CorrectionsAPIDTO = ApolloAPI<QueryDTO, "corrections" | "correction">;

interface Mapper {
	toAPI: ToAPI<CorrectionsAPIDTO, CorrectionsAPI>;
	toUpdateMutation: ToInternal<UpdateCorrectionMutationFnDTO, UpdateCorrectionMutation>;
	toCreateMutation: ToInternal<CreateCorrectionMutationFnDTO, CreateCorrectionMutation>;
	toDeleteMutation: ToInternal<DeleteCorrectionMutationFnDTO, DeleteCorrectionMutation>
	toCorrection: ToInternal<DeepPartial<CorrectionDTO>, Correction>;
}

export class CorrectionMapper implements Mapper {
	public toCorrection: Mapper["toCorrection"] = (correction) => ({
		id: correction.id || "",
		originalText: correction.originalText || "",
		replacement: correction.replacement || "",
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: CorrectionsAPIDTO["data"]): DeepPartial<CorrectionDTO[]> => {
			if (data?.correction) {
				return [data.correction];
			}

			if (data?.corrections) {
				return data.corrections;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toCorrection),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateCorrectionMutationFnDTO, UpdateCorrectionMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						originalText: input.originalText,
						replacement: input.replacement,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneCorrection ? this.toCorrection(output.data.updateOneCorrection) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateCorrectionMutationFnDTO, CreateCorrectionMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						originalText: input.originalText,
						replacement: input.replacement,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneCorrection ? this.toCorrection(output.data.createOneCorrection) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteCorrectionMutationFnDTO, DeleteCorrectionMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getCorrections"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: output?.data?.deleteOneCorrection.success || false }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
