/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-tabs */
// ts:disable
import { Database } from "mocks/db";
import faker from "faker";

export const populateAdviceRequestsCollection = (db: Database): void => {
	const grape = db.grape.findFirst({ where: { id: { equals: "grape-id" } } });
	const aoc = db.aoc.findFirst({ where: { id: { equals: "aoc-id" } } });
	const region = db.region.findFirst({ where: { id: { equals: "region-id" } } });
	const country = db.country.findFirst({ where: { id: { equals: "country-id" } } });
	const domain = db.domain.findFirst({ where: { id: { equals: "domain-id" } } });
	const classification = db.classification.findFirst({ where: { id: { equals: "classification-id" } } });

	const user1 = db.user.create({});
	const user2 = db.user.create({});
	const user3 = db.user.create({});

	const wineDescription = db.wineDescription.create({
		grape,
		aoc,
		region,
		country,
		domain,
		classification,
	});

	const scanAdvice = db.scanAdvice.create({
		year: faker.datatype.number({ min: 1900, max: 2025 }),
		idealTemp: faker.datatype.number({ min: 11, max: 21 }),
		description: wineDescription,
	});

	const scan1 = db.scan.create({
		wineType: "sparkling",
		used: false,
		image: `http://loremflickr.com/640/480/winelabel?${faker.datatype.number(1000)}`,
		owner: user1,
		advices: [scanAdvice],
	});

	const scan2 = db.scan.create({
		owner: user2,
		advices: [scanAdvice],
		image: `http://loremflickr.com/640/480/winelabel?${faker.datatype.number(1000)}`,
	});
	const scan3 = db.scan.create({
		owner: user3,
		wineType: "red",
		image: `http://loremflickr.com/640/480/winelabel?${faker.datatype.number(1000)}`,
	});

	const wine1 = db.wine.create({
		type: "sparkling",
		year: faker.datatype.number({ min: 1900, max: 2025 }),
		idealTemp: faker.datatype.number({ min: 9, max: 22 }),
		customTemp: faker.datatype.number({ min: 9, max: 22 }),
		archived: false,
		amount: faker.datatype.number({ min: 1, max: 5 }),
		rating: faker.datatype.number({ min: 0, max: 10 }),
		description: wineDescription,
		owner: user1,
		scan: scan1,
	});

	const wine2 = db.wine.create({
		type: "red",
		year: faker.datatype.number({ min: 1900, max: 2025 }),
		idealTemp: faker.datatype.number({ min: 9, max: 22 }),
		customTemp: faker.datatype.number({ min: 9, max: 22 }),
		archived: false,
		amount: faker.datatype.number({ min: 1, max: 5 }),
		rating: faker.datatype.number({ min: 0, max: 10 }),
		description: wineDescription,
		owner: user3,
		scan: scan3,
	});

	/*
		Important to overwrite the __typename to represent the correct type in mocking data,
		if not, this will cause issues with the fragment-matcher
	*/

	db.buyingAdviceRequest.create({
		__typename: "BuyingAdviceRequest",
		owner: user1,
		createdAt: new Date("2022-04-02"),
		archived: false,
		wine: wine2,
	});

	db.scanAdviceRequest.create({
		__typename: "ScanAdviceRequest",
		id: "scan-advice-request-id",
		status: "new",
		createdAt: new Date("2022-01-01"),
		scan: scan1,
		wineResult: wine1,
		owner: user1,
		archived: false,
	});

	db.scanAdviceRequest.create({
		__typename: "ScanAdviceRequest",
		status: "finished",
		createdAt: new Date("2022-01-04"),
		scan: scan2,
		owner: user2,
		archived: false,
	});

	db.scanAdviceRequest.create({
		__typename: "ScanAdviceRequest",
		status: "new",
		createdAt: new Date("2022-01-12"),
		scan: scan3,
		owner: user3,
		archived: false,
	});
};
