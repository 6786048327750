import { ApolloError } from "@apollo/client";
import { UILogic } from "@aptus/frontend-core";
import event from "event";
import { t } from "i18next";
import { PasswordUpdateInputDTO } from "models/schema";
import { useState } from "react";
import { useUpdatePassword } from "./models/updatePassword";

type Props = {};
interface Result {
	isLoading: boolean;
	save: (input: PasswordUpdateInputDTO) => Promise<void>;
}

export const usePasswordReset: UILogic<Props, Result> = () => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const [updatePassword] = useUpdatePassword();

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			const data = await updatePassword({ variables: { data: input } });

			if (data.data?.updatePassword.success) {
				event.emit("mutationSucceeded", t("passwordHasBeenSuccessfullyUpdated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	return {
		isLoading,
		save,
	};
};
