import { Query, UILogic } from "@aptus/frontend-core";
import { AutoCompleteSelectProps, getDropdownOptions } from "components/form/autoCompleteSelect/autoCompleteSelect";
import { useAocs, useTranslations } from "domains";
import { AocsAPIDTO } from "hooks/aocs/aocMapper";
import { Aoc } from "hooks/aocs/models/aoc";
import { Country } from "hooks/countries/models/country";
import { Region } from "hooks/regions/models/region";
import { AocFilterDTO } from "models/schema";
import { useGetAocs } from "./models/getAOCs";

export interface AocSelectProps extends Omit<AutoCompleteSelectProps, "dropdownProps"> {
	country?: string;
	region?: string;
	defaultValue?: string;
}

type Result = AutoCompleteSelectProps;

interface QueryProps {
	countryId?: Country["id"];
	regionId?: Region["id"];
	aocId?: Aoc["id"];
}

const useGetFilteredAocs: Query<QueryProps, AocsAPIDTO> = ({ countryId, regionId, aocId }) => {
	const filter: AocFilterDTO = {
		OR: [
			{
				archived: { equals: false },
				...countryId ? { country: { id: { equals: countryId } } } : {},
				...regionId ? { region: { id: { equals: regionId } } } : {},
			},
			...aocId ? [{ id: { equals: aocId } }] : [],
		],
	};

	return useGetAocs({ variables: { filter } });
};

export const useAocSelect: UILogic<AocSelectProps, Result> = (props) => {
	const { t } = useTranslations();
	const { aocs, isLoading } = useAocs(useGetFilteredAocs({
		countryId: props.country,
		regionId: props.region,
		aocId: props.defaultValue,
	}));

	const options = {
		loading: isLoading,
		options: getDropdownOptions(aocs),
	};

	return {
		...props,
		dropdownProps: options,
		noOptionsText: t("noOptions"),
	};
};
