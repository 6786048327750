import { PageComponent } from "@aptus/frontend-core";
import BasePage from "pages/basePage/basePage";
import { RequestsContent } from "./components/requestsContent/requestsContent";
import { RequestsFilterBar } from "./components/requestsFilterBar/requestsFilterBar";

const HomePage: PageComponent = () => (
	<BasePage>
		<RequestsFilterBar />
		<RequestsContent />
	</BasePage>
);
export default HomePage;
