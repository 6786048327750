/* eslint-disable import/prefer-default-export */
import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "components/loader/loader";
import { useCorrections, useRoutes, useTranslations } from "domains";
import { Correction } from "hooks/corrections/models/correction";
import { SaveCorrectionInput } from "hooks/corrections/models/correctionInput";
import BasePage from "pages/basePage/basePage";
import { useGetCorrection } from "./models/getCorrection";
import { useCorrectionForm } from "./useCorrectionForm";

interface CorrectionFormProps {
	correction: Correction;
	save: (input: SaveCorrectionInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const CorrectionForm: UIComponent<CorrectionFormProps> = ({ correction, save, remove }) => {
	const { handleFormSubmit, register, handleDelete, errors } = useCorrectionForm({ initialValues: correction, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`correction.${correction.id ? "edit" : "add"}`)}</h2>
			<FormControl {...register("originalText", { required: { value: true, message: "This is required." } })} fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					error={!!errors?.originalText}
					helperText={errors?.originalText?.message}
					fullWidth
					name="originalText"
					id="text"
					label={t("text")}
					variant="outlined"
				/>
			</FormControl>
			<FormControl {...register("replacement", { required: { value: true, message: "This is required." } })} fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					error={!!errors?.replacement}
					helperText={errors?.replacement?.message}
					fullWidth
					name="replacement"
					id="replacement"
					label={t("replaceBy")}
					variant="outlined"
				/>
			</FormControl>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{correction.id ? <Button onClick={() => handleDelete(correction.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteCorrection")}</Button> : undefined}
				<Button type="submit" variant="contained">{correction.id ? t("updateCorrection") : t("createCorrection")}</Button>
			</Box>
		</form>
	);
};

export const CorrectionPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, correction, isLoading } = useCorrections(useGetCorrection({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <CorrectionForm save={save} remove={remove} correction={correction} />;
};
