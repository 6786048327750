import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Faq } from "./models/faq";
import { CreateFaqInput, DeleteFaqInput, SaveFaqInput, UpdateFaqInput } from "./models/faqInput";

export type FaqsAPI = InternalAPI<Faq[]>;

export type UpdateFaqMutation = Mutation<UpdateFaqInput, Faq>;
export type CreateFaqMutation = Mutation<CreateFaqInput, Faq>;
export type DeleteFaqMutation = Mutation<DeleteFaqInput, Success>;

interface Props {
	API: FaqsAPI;
	defaultFaq: Faq;
	updateMutation: UpdateFaqMutation;
	createMutation: CreateFaqMutation;
	deleteMutation: DeleteFaqMutation;
}

interface Result {
	faq: Faq;
	faqs: Faq[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveFaqInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useFaqsUseCases: UseCases<Props, Result> = ({ API, defaultFaq, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveFaqInput): input is UpdateFaqInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("faqHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("faqHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("faqHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		faq: API.data[0] || defaultFaq,
		faqs: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
