import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from "@mui/material";
import { Box } from "@mui/system";
import { MultiLanguage } from "components/form/multilanguage/MultiLanguage";
import Loader from "components/loader/loader";
import { useFoods, useRoutes, useTranslations } from "domains";
import { Food } from "hooks/foods/models/food";
import { SaveFoodInput } from "hooks/foods/models/foodInput";
import { FoodCourseDTO, FoodIngredientsDTO, FoodKitchenDTO, WineStyleDTO } from "models/schema";
import BasePage from "pages/basePage/basePage";
import { Controller } from "react-hook-form";
import { useGetFood } from "./models/getFood";
import { useFoodPairingForm } from "./useFoodPairingForm";

interface Props {
	food: Food;
	save: (input: SaveFoodInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const FoodPairingForm: UIComponent<Props> = ({ save, remove, food }) => {
	const { register, handleFormSubmit, handleDelete, control, errors } = useFoodPairingForm({ initialValues: food, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`foodPairing.add`)}</h2>
			<FormControl error={!!errors?.course} sx={{ mb: 2 }} fullWidth>
				<InputLabel id="category">{t("category")}</InputLabel>
				<Controller
					name="course"
					control={control}
					rules={{ required: { value: true, message: "This is required." } }}
					render={({ field: { onChange, value } }) => (
						<Select
							value={value}
							onChange={(e) => onChange(e.target.value)}
							error={!!errors?.course}
							labelId="category"
							id="category"
							label={t("category")}
						>
							{Object.values(FoodCourseDTO).map((key) => <MenuItem key={key} value={key}>{t(`category.${key}`)}</MenuItem>)}
						</Select>
					)}
				/>

				{errors?.course?.message ? <FormHelperText>{errors?.course?.message}</FormHelperText> : null}
			</FormControl>
			<FormControl error={!!errors?.ingredients} sx={{ mb: 2 }} fullWidth>
				<InputLabel id="ingredients">{t("ingredients")}</InputLabel>
				<Controller
					name="ingredients"
					control={control}
					rules={{ required: { value: true, message: "This is required." } }}
					render={({ field: { onChange, value } }) => (
						<Select
							value={value}
							onChange={(e) => onChange(e.target.value)}
							error={!!errors?.ingredients}
							labelId="ingredients"
							id="ingredients"
							label={t("ingredients")}
						>
							{Object.values(FoodIngredientsDTO).map((key) => <MenuItem key={key} value={key}>{t(`ingredient.${key}`)}</MenuItem>)}
						</Select>
					)}
				/>
				{errors?.ingredients?.message ? <FormHelperText>{errors?.ingredients?.message}</FormHelperText> : null}
			</FormControl>
			<FormControl error={!!errors?.kitchen} sx={{ mb: 2 }} fullWidth>
				<InputLabel id="kitchen">{t("kitchen")}</InputLabel>
				<Controller
					name="kitchen"
					control={control}
					rules={{ required: { value: true, message: "This is required." } }}
					render={({ field: { onChange, value } }) => (
						<Select
							value={value}
							onChange={(e) => onChange(e.target.value)}
							error={!!errors?.kitchen}
							labelId="kitchen"
							id="kitchen"
							label={t("kitchen")}
						>
							{Object.values(FoodKitchenDTO).map((key) => <MenuItem key={key} value={key}>{t(`kitchen.${key}`)}</MenuItem>)}
						</Select>
					)}
				/>

				{errors?.kitchen?.message ? <FormHelperText>{errors?.kitchen?.message}</FormHelperText> : null}
			</FormControl>
			<FormControl error={!!errors?.wineStyle} sx={{ mb: 2 }} fullWidth>
				<InputLabel id="wine_type">{t("wineType")}</InputLabel>
				<Controller
					name="wineStyle"
					control={control}
					rules={{ required: { value: true, message: "This is required." } }}
					render={({ field: { onChange, value } }) => (
						<Select
							value={value}
							onChange={(e) => onChange(e.target.value)}
							error={!!errors?.wineStyle}
							labelId="wine_type"
							label={t("wineType")}
							variant="outlined"
							name="wineStyle"
						>
							{Object.values(WineStyleDTO).map((key) => <MenuItem key={key} value={key}>{t(`wineStyle.${key}`)}</MenuItem>)}
						</Select>
					)}
				/>
				{errors?.wineStyle?.message ? <FormHelperText>{errors?.wineStyle?.message}</FormHelperText> : null}
			</FormControl>
			<MultiLanguage register={register} languages={["nl", "en", "fr"]}>
				<TextField sx={{ mb: 2 }} fullWidth id="titles" label="Dish name" variant="outlined" />
				<TextField sx={{ mb: 2 }} fullWidth rows={10} multiline id="descriptions" label="Dish description" variant="outlined" />
			</MultiLanguage>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{food.id ? <Button onClick={() => handleDelete(food.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteFoodPairing")}</Button> : undefined}
				<Button type="submit" variant="contained">{food.id ? t("updateFoodPairing") : t("createFoodPairing")}</Button>
			</Box>
		</form>
	);
};

export const FoodPairingPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, food, isLoading } = useFoods(useGetFood({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <FoodPairingForm save={save} remove={remove} food={food} />;
};
