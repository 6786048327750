import { UIComponent } from "@aptus/frontend-core";
import { FilterBarStyle } from "components/filterbar/filterBarStyle";
import { Select, MenuItem, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import Search from "@mui/icons-material/Search";
import { useRoutes, useTranslations } from "domains";
import { LanguageDTO } from "models/schema";

export const ConversationsFilterBar: UIComponent = () => {
	const { currentQueryString: { search, language }, updateQueryString } = useRoutes();
	const { t } = useTranslations();

	return (
		<FilterBarStyle>
			<FormControl style={{ flex: 3, marginRight: 20 }} variant="outlined">
				<InputLabel htmlFor="search">{t("users.filter.search")}</InputLabel>
				<OutlinedInput
					defaultValue={search}
					onChange={(e) => updateQueryString({ search: e.currentTarget.value })}
					id="search"
					endAdornment={(
						<InputAdornment position="end">
							<IconButton
								edge="end"
							>
								<Search />
							</IconButton>
						</InputAdornment>
					)}
					label={t("users.filter.search")}
				/>
			</FormControl>
			<FormControl style={{ marginRight: 20, flex: 1 }} sx={{ minWidth: 300 }}>
				<InputLabel id="table">{t("requests.filter.language")}</InputLabel>
				<Select
					value={language}
					onChange={(e) => updateQueryString({ language: e.target.value as string })}
					labelId="language"
					id="language"
					label={t("requests.filter.language")}
				>
					<MenuItem value="">{t("empty")}</MenuItem>
					{Object.values(LanguageDTO).map((l) => <MenuItem value={l}>{t(`language.${l}`)}</MenuItem>)}
				</Select>
			</FormControl>
		</FilterBarStyle>
	);
};
