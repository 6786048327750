import { Database } from "mocks/db";
import { LanguageDTO, WineTypeDTO } from "models/schema";
import faker from "faker";

export const populateGrapeCollection = (db: Database): void => {
	const createDescriptions = () => ([
		{ language: LanguageDTO.EnDTO, text: faker.lorem.paragraph() },
		{ language: LanguageDTO.NlDTO, text: faker.lorem.paragraph() },
		{ language: LanguageDTO.FrDTO, text: faker.lorem.paragraph() },
	]);

	const mainGrape1 = db.mainGrape.create({
		foodDescriptions: createDescriptions(),
		temperatureDescriptions: createDescriptions(),
	});

	const mainGrape2 = db.mainGrape.create({
		foodDescriptions: createDescriptions(),
		temperatureDescriptions: createDescriptions(),
	});

	const mainGrape3 = db.mainGrape.create({
		foodDescriptions: createDescriptions(),
		temperatureDescriptions: createDescriptions(),
	});

	db.mainGrape.create({
		foodDescriptions: createDescriptions(),
		temperatureDescriptions: createDescriptions(),
	});

	const createTemperatures = () => [
		{
			type: WineTypeDTO.RedDTO,
			temperature: faker.datatype.number(21),
		},
		{
			type: WineTypeDTO.SparklingDTO,
			temperature: faker.datatype.number(21),
		},
		{
			type: WineTypeDTO.WhiteDTO,
			temperature: faker.datatype.number(21),
		},
	];

	db.grape.create({
		mainGrape: mainGrape1,
		temperatures: createTemperatures(),
		id: "grape-id",
	});
	db.grape.create({
		mainGrape: mainGrape1,
		temperatures: createTemperatures(),
	});
	db.grape.create({
		mainGrape: mainGrape2,
		temperatures: createTemperatures(),
	});
	db.grape.create({
		mainGrape: mainGrape3,
	});
	db.grape.create({});
	db.grape.create({});
	db.grape.create({});
	db.grape.create({});
};
