import styled from "styled-components";

export const ClientCell = styled.div<{ new?: boolean }>`
	${(props) => props.new && `
		::before {
			position: absolute;
			content: '';
			left: 7px;
			margin-top: 5px;
			height: 10px;
			width: 10px;
			border-radius: 50%;
			background-color: red;
		}

		margin-left: 15px;
	`}
`;
