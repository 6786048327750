import { Database } from "mocks/db";
import { SuggestionStatusDTO } from "models/schema";

export const populateSuggestionsCollection = (db: Database): void => {
	const adviceRequest = db.scanAdviceRequest.findFirst({ where: { id: { equals: "scan-advice-request-id" } } });
	const users = db.user.getAll();

	if (adviceRequest) {
		const arSource = db.adviceRequestSuggestionSource.create({
			adviceRequest,
		});

		db.domainSuggestion.create({
			status: SuggestionStatusDTO.NewDTO,
			owner: adviceRequest?.owner,
			source: arSource,
		});
	}

	const conversation = db.conversation.create({
		owner: users[0],
	});

	const convSource = db.conversationSuggestionSource.create({
		conversation,
	});

	db.faqSuggestion.create({
		status: SuggestionStatusDTO.NewDTO,
		owner: users[1],
		source: convSource,
	});
};
