import { UIComponent } from "@aptus/frontend-core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import Loader from "components/loader/loader";
import { useAdviceRequests } from "domains";
import { AdviceRequest, BuyingAdviceRequest, ScanAdviceRequest } from "hooks/adviceRequests/models/adviceRequest";
import BasePage from "pages/basePage/basePage";
import { useHistory, useParams } from "react-router";

import { BuyingForm } from "./components/buyingForm/buyingForm";
import { ScanForm } from "./components/scanForm/scanForm";
import { useGetAdviceRequest } from "./models/getAdviceRequest";
import { ActionBar } from "./requestDetailPageStyle";

interface Params {
	id: string;
	type: string;
}

export const RequestDetailPage: UIComponent = () => {
	const params = useParams<Params>();
	const history = useHistory();
	const { isLoading, adviceRequest } = useAdviceRequests(useGetAdviceRequest({ variables: { id: params.id } }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return (
		<BasePage>
			<ActionBar>
				<Button onClick={history.goBack}>
					<ArrowBackIcon />
				</Button>
				<div data-portal="sup" />
			</ActionBar>
			{params.type === "scan" ? (
				<ScanForm
					scanAdviceRequest={adviceRequest as AdviceRequest & ScanAdviceRequest}
				/>
			) : <BuyingForm data={adviceRequest as AdviceRequest & BuyingAdviceRequest} />}
		</BasePage>
	);
};
