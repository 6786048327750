/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteClassificationVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteClassificationDTO = (
  { __typename?: 'Mutation' }
  & { updateOneClassification: (
    { __typename?: 'Classification' }
    & Pick<Types.ClassificationDTO, 'id'>
  ) }
);


export const DeleteClassificationDocumentDTO = gql`
    mutation deleteClassification($id: String!) {
  updateOneClassification(id: $id, data: {archived: true}) {
    id
  }
}
    `;
export type DeleteClassificationMutationFnDTO = Apollo.MutationFunction<DeleteClassificationDTO, DeleteClassificationVariablesDTO>;

/**
 * __useDeleteClassification__
 *
 * To run a mutation, you first call `useDeleteClassification` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassification` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassification, { data, loading, error }] = useDeleteClassification({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClassification(baseOptions?: Apollo.MutationHookOptions<DeleteClassificationDTO, DeleteClassificationVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassificationDTO, DeleteClassificationVariablesDTO>(DeleteClassificationDocumentDTO, options);
      }
export type DeleteClassificationHookResult = ReturnType<typeof useDeleteClassification>;
export type DeleteClassificationMutationResultDTO = Apollo.MutationResult<DeleteClassificationDTO>;
export type DeleteClassificationMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteClassificationDTO, DeleteClassificationVariablesDTO>;