import { UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import { Faq } from "hooks/faqs/models/faq";
import { SaveFaqInput } from "hooks/faqs/models/faqInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UseFormTypes } from "../useFormTypes";

interface Props {
	initialValues?: Faq;
	submit: (values: SaveFaqInput) => void;
	remove: (id: string) => void;
}

interface Result extends UseFormTypes<Faq> {
	handleDelete: (id: string) => void;
}

export const useFaqForm: UILogic<Props, Result> = ({ initialValues, submit, remove }) => {
	const { currentQueryString: { selected, create }, updateQueryString } = useRoutes();

	const defaultValues = {
		...initialValues,
		id: selected,
	};

	const { register, handleSubmit, control, reset, formState: { errors } } = useForm<Faq>({ defaultValues });

	useEffect(() => {
		reset(defaultValues);
	}, [create, initialValues]);

	const handleFormSubmit = (values: Faq): void => {
		submit(values);
	};

	const handleDelete = (id: string) => {
		remove(id);
		updateQueryString({ selected: null, create: true });
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		handleDelete,
		errors,
	};
};
