import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { LanguageDTO, MainGrapeDTO, QueryDTO } from "models/schema";
import { toAPIMultiLanguage, toFormMultiLanguage } from "utils/MLtoFMLI";
import { CreateMainGrapeMutationFnDTO } from "./models/createMainGrape";
import { DeleteMainGrapeMutationFnDTO } from "./models/deleteMainGrape";
import { MainGrape } from "./models/mainGrape";
import { UpdateMainGrapeMutationFnDTO } from "./models/updateMainGrape";
import { CreateMainGrapeMutation, DeleteMainGrapeMutation, MainGrapesAPI, UpdateMainGrapeMutation } from "./useMainGrapesUseCases";

export type MainGrapesAPIDTO = ApolloAPI<QueryDTO, "mainGrapes" | "mainGrape">;

interface Mapper {
	toAPI: ToAPI<MainGrapesAPIDTO, MainGrapesAPI>;
	toUpdateMutation: ToInternal<UpdateMainGrapeMutationFnDTO, UpdateMainGrapeMutation>;
	toCreateMutation: ToInternal<CreateMainGrapeMutationFnDTO, CreateMainGrapeMutation>;
	toDeleteMutation: ToInternal<DeleteMainGrapeMutationFnDTO, DeleteMainGrapeMutation>
	toMainGrape: ToInternal<DeepPartial<MainGrapeDTO>, MainGrape>;
}

export class MainGrapeMapper implements Mapper {
	public toMainGrape: Mapper["toMainGrape"] = (mainGrape) => ({
		id: mainGrape.id || "",
		name: mainGrape.name || "",
		wineStyle: mainGrape.wineStyle,
		temperatureDescriptions: toFormMultiLanguage((mainGrape.temperatureDescriptions || []).map((td) => ({ language: td.language || LanguageDTO.EnDTO, text: td.text || "" }))),
		foodDescriptions: toFormMultiLanguage((mainGrape.foodDescriptions || []).map((fd) => ({ language: fd.language || LanguageDTO.EnDTO, text: fd.text || "" }))),
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: MainGrapesAPIDTO["data"]): DeepPartial<MainGrapeDTO[]> => {
			if (data?.mainGrape) {
				return [data.mainGrape];
			}

			if (data?.mainGrapes) {
				return data.mainGrapes;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toMainGrape),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateMainGrapeMutationFnDTO, UpdateMainGrapeMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						name: input.name,
						wineStyle: input.wineStyle,
						foodDescriptions: toAPIMultiLanguage(input.foodDescriptions),
						temperatureDescriptions: toAPIMultiLanguage(input.temperatureDescriptions),
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneMainGrape ? this.toMainGrape(output.data.updateOneMainGrape) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateMainGrapeMutationFnDTO, CreateMainGrapeMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						name: input.name,
						wineStyle: input.wineStyle,
						foodDescriptions: toAPIMultiLanguage(input.foodDescriptions),
						temperatureDescriptions: toAPIMultiLanguage(input.temperatureDescriptions),
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneMainGrape ? this.toMainGrape(output.data.createOneMainGrape) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteMainGrapeMutationFnDTO, DeleteMainGrapeMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getMainGrapes"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: !!output?.data?.updateOneMainGrape.id }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
