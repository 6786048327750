import { UIComponent } from "@aptus/frontend-core";
import { Chip } from "@mui/material";
import { formatDistance } from "date-fns";
import { useTranslations } from "domains";
import { RequestDetailContent, TitleInfoBar } from "pages/requestDetailPage/requestDetailPageStyle";

import { LabelDataForm } from "./forms/labelDataForm";
import { ScanAdviceRequestForm } from "./forms/scanAdviceRequestForm";
import { ScanFormProps, useScanForm } from "./useScanForm";

export const ScanForm: UIComponent<ScanFormProps> = ({ scanAdviceRequest }) => {
	const { initialLabelDataFormValues, dataValues, initialScanAdviceRequestFormValues } = useScanForm({ scanAdviceRequest });
	const { t } = useTranslations();

	return (
		<>
			<TitleInfoBar>
				<h3>
					{dataValues.owner?.givenName}
					{" "}
					{dataValues.owner?.familyName}
				</h3>
				<Chip style={{ marginBottom: 10 }} label="Temperature" color="primary" />
				<p>
					{`${t("createdBy")} ${dataValues.owner?.givenName} ${dataValues.owner?.familyName}, ${dataValues.createdAt
						? formatDistance(dataValues.createdAt, new Date(), { addSuffix: true })
						: null} | ${dataValues.owner?.email}`}
				</p>
			</TitleInfoBar>
			<RequestDetailContent>
				<div>
					<LabelDataForm
						isScanLabel
						adviceRequestId={scanAdviceRequest.id}
						scanId={scanAdviceRequest.scanId}
						initialValues={initialLabelDataFormValues}
					/>
					<ScanAdviceRequestForm
						enabled={!!scanAdviceRequest.wine?.id}
						initialValues={initialScanAdviceRequestFormValues}
					/>
				</div>
				<div>
					<h4>{t("OCR.data")}</h4>
					<img width="100%" src={dataValues.image} alt="wine" />
				</div>
			</RequestDetailContent>
		</>
	);
};
