/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PasswordLoginVariablesDTO = Types.Exact<{
  data: Types.PasswordLoginInputDTO;
}>;


export type PasswordLoginDTO = (
  { __typename?: 'Mutation' }
  & { passwordLogin: (
    { __typename?: 'LoginResult' }
    & Pick<Types.LoginResultDTO, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<Types.UserDTO, 'id' | 'roles'>
    ) }
  ) }
);


export const PasswordLoginDocumentDTO = gql`
    mutation passwordLogin($data: PasswordLoginInput!) {
  passwordLogin(data: $data) {
    token
    user {
      id
      roles
    }
  }
}
    `;
export type PasswordLoginMutationFnDTO = Apollo.MutationFunction<PasswordLoginDTO, PasswordLoginVariablesDTO>;

/**
 * __usePasswordLogin__
 *
 * To run a mutation, you first call `usePasswordLogin` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordLogin` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordLogin, { data, loading, error }] = usePasswordLogin({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePasswordLogin(baseOptions?: Apollo.MutationHookOptions<PasswordLoginDTO, PasswordLoginVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordLoginDTO, PasswordLoginVariablesDTO>(PasswordLoginDocumentDTO, options);
      }
export type PasswordLoginHookResult = ReturnType<typeof usePasswordLogin>;
export type PasswordLoginMutationResultDTO = Apollo.MutationResult<PasswordLoginDTO>;
export type PasswordLoginMutationOptionsDTO = Apollo.BaseMutationOptions<PasswordLoginDTO, PasswordLoginVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    