/* eslint-disable import/prefer-default-export */
import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { AocSelect } from "components/form/items/AOCSelect/aocSelect";
import { CountrySelect } from "components/form/items/countrySelect/countrySelect";
import { RegionSelect } from "components/form/items/regionSelect/regionSelect";
import Loader from "components/loader/loader";
import { useDomains, useRoutes, useTranslations } from "domains";
import { Domain } from "hooks/domains/models/domain";
import { SaveDomainInput } from "hooks/domains/models/domainInput";
import BasePage from "pages/basePage/basePage";
import { useGetDomain } from "./models/getDomain";
import { useDomainForm } from "./useDomainForm";

export enum FormType {
	Update = "update",
	Create = "create",
}

interface DomainFormProps {
	domain: Domain;
	type: FormType;
	save: (input: SaveDomainInput) => Promise<Domain | undefined | void>;
	remove: (id: string) => Promise<void>;
	allowRemove?: boolean;
}

export const DomainForm: UIComponent<DomainFormProps> = ({ domain, save, remove, type, allowRemove }) => {
	const { handleFormSubmit, control, selectedCountry, selectedAoc, handleDelete, register, selectedRegion, errors, isDisabled } = useDomainForm({ initialValues: domain, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`domain.${domain.id ? "edit" : "add"}`)}</h2>
			<FormControl {...register("name", { required: { value: true, message: "This field is required." } })} fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					error={!!errors?.name}
					helperText={errors?.name?.message}
					fullWidth
					id="name"
					name="name"
					label="Name"
					variant="outlined"
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<AocSelect
					control={control}
					region={selectedRegion}
					country={selectedCountry}
					label={t("aoc")}
					name="aoc"
					defaultValue={selectedAoc}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<CountrySelect
					rules={{ required: { value: true, message: "This field is required." } }}
					error={!!errors?.country}
					helperText={errors?.country?.message}
					label={t("country")}
					control={control}
					name="country"
					disabled={isDisabled}
					defaultValue={selectedCountry}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<RegionSelect
					country={selectedCountry}
					label={t("region")}
					control={control}
					name="region"
					disabled={isDisabled}
					defaultValue={selectedRegion}
				/>
			</FormControl>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{type === FormType.Update || allowRemove ? <Button onClick={() => handleDelete(domain.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteDomain")}</Button> : undefined}
				<Button type="submit" variant="contained">{t(`${type}Domain`)}</Button>
			</Box>
		</form>
	);
};

export const DomainPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, domain, isLoading } = useDomains(useGetDomain({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <DomainForm type={domain.id ? FormType.Update : FormType.Create} save={save} remove={remove} domain={domain} />;
};
