import { FunctionComponent } from "react";
import { Switch } from "react-router";
import Route from "components/route/route";
import LoginPage from "pages/loginPage/LoginPage";
import DatabasePage from "pages/databasePage/databasePage";
import { useProfile, useRoutes } from "domains";
import { useRoutesEvents } from "hooks/routes/useRoutes";
import { RequestDetailPage } from "pages/requestDetailPage/requestDetailPage";
import HomePage from "pages/homePage/HomePage";
import { UsersPage } from "pages/usersPage/usersPage";
import { ProfilePage } from "pages/profilePage/profilePage";
// import { SommelierSuggestionsPage } from "pages/sommelierSuggestionsPage/sommelierSuggestionsPage";
import { ConversationsPage } from "pages/conversationsPage/conversationsPage";
import { ConversationDetailPage } from "pages/conversationDetailPage/conversationDetailPage";

/**
 * This is the router of the application.
 * It handles everything that is based on the "Router state" (URL).
 * This enforces consistency on how URLs are handled throughout the application.
 */

const Routes: FunctionComponent = () => {
	const { routes } = useRoutes();
	const { isLoggedIn } = useProfile();
	useRoutesEvents(useRoutes());

	return (
		<Switch>
			<Route exact path={routes.home} isAuthorized={isLoggedIn} redirectTo={routes.login} component={HomePage} />
			<Route exact path={routes.requestDetail} isAuthorized={isLoggedIn} redirectTo={routes.login} component={RequestDetailPage} />
			<Route exact path={routes.database} isAuthorized={isLoggedIn} redirectTo={routes.login} component={DatabasePage} />
			<Route exact path={routes.users} isAuthorized={isLoggedIn} redirectTo={routes.login} component={UsersPage} />
			<Route exact path={routes.profile} isAuthorized={isLoggedIn} redirectTo={routes.login} component={ProfilePage} />
			{/* <Route exact path={routes.sommelierSuggestions} isAuthorized={isLoggedIn} redirectTo={routes.login} component={SommelierSuggestionsPage} /> */}
			<Route exact path={routes.conversations} isAuthorized={isLoggedIn} redirectTo={routes.login} component={ConversationsPage} />
			<Route exact path={routes.conversationDetail} isAuthorized={isLoggedIn} redirectTo={routes.login} component={ConversationDetailPage} />
			<Route exact path={routes.login} component={LoginPage} />
		</Switch>
	);
};

export default Routes;
