/* eslint-disable import/prefer-default-export */
import { UIComponent } from "@aptus/frontend-core";
import { Button, TextField } from "@mui/material";
import { MultiLanguage } from "components/form/multilanguage/MultiLanguage";
import { Box } from "@mui/system";
import { useFaqs, useRoutes, useTranslations } from "domains";
import BasePage from "pages/basePage/basePage";
import Loader from "components/loader/loader";
import { Faq } from "hooks/faqs/models/faq";
import { SaveFaqInput } from "hooks/faqs/models/faqInput";
import { useFaqForm } from "./useFaqForm";
import { useGetFaq } from "./models/getFaq";
import { FormType } from "../domainForm/domainForm";

interface FaqFormProps {
	faq: Faq;
	type: FormType;
	save: (input: SaveFaqInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
	allowRemove?: boolean;
}

export const FaqForm: UIComponent<FaqFormProps> = ({ faq, save, remove, allowRemove, type }) => {
	const { handleFormSubmit, register, handleDelete } = useFaqForm({ initialValues: faq, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`FAQ.add`)}</h2>
			<MultiLanguage register={register} languages={["nl", "en", "fr"]}>
				<TextField sx={{ mb: 2 }} fullWidth id="titles" label="Title" variant="outlined" />
				<TextField sx={{ mb: 2 }} fullWidth rows={10} multiline id="descriptions" label="Description" variant="outlined" />
			</MultiLanguage>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{type === FormType.Update || allowRemove ? <Button onClick={() => handleDelete(faq.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteFaq")}</Button> : undefined}
				<Button type="submit" variant="contained">{t(`${type}Faq`)}</Button>
			</Box>
		</form>
	);
};

export const FaqPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, isLoading, faq } = useFaqs(useGetFaq({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <FaqForm type={faq.id ? FormType.Update : FormType.Create} save={save} remove={remove} faq={faq} />;
};
