import { UIComponent } from "@aptus/frontend-core";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslations, useUsers } from "domains";
import { ProfileFormProps, useProfileForm } from "./useProfileForm";

export const ProfileForm: UIComponent<ProfileFormProps> = ({ formValues }) => {
	const { save } = useUsers();
	const { handleFormSubmit, register, errors } = useProfileForm({ initialValues: formValues, submit: save });
	const { t } = useTranslations();
	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t("profileSettings")}</h2>
			<TextField
				sx={{ mb: 2 }}
				{...register("givenName", { required: { value: true, message: "This is required." } })}
				error={!!errors?.givenName}
				helperText={errors?.givenName?.message}
				fullWidth
				name="givenName"
				id="givenName"
				label={t("userForm.firstName")}
				variant="outlined"
			/>
			<TextField
				sx={{ mb: 2 }}
				{...register("familyName", { required: { value: true, message: "This is required." } })}
				error={!!errors?.familyName}
				helperText={errors?.familyName?.message}
				name="familyName"
				fullWidth
				id="familyName"
				label={t("userForm.lastName")}
				variant="outlined"
			/>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button type="submit" variant="contained">{t("updateProfile")}</Button>
			</Box>
		</form>
	);
};
