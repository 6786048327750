/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDomainsCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetDomainsCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'domainsCount'>
);


export const GetDomainsCountDocumentDTO = gql`
    query getDomainsCount($search: String) {
  domainsCount(filter: {archived: {equals: false}, name: {contains: $search}})
}
    `;

/**
 * __useGetDomainsCount__
 *
 * To run a query within a React component, call `useGetDomainsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainsCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetDomainsCount(baseOptions?: Apollo.QueryHookOptions<GetDomainsCountDTO, GetDomainsCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDomainsCountDTO, GetDomainsCountVariablesDTO>(GetDomainsCountDocumentDTO, options);
      }
export function useGetDomainsCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetDomainsCountDTO, GetDomainsCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDomainsCountDTO, GetDomainsCountVariablesDTO>(GetDomainsCountDocumentDTO, options);
        }
export type GetDomainsCountHookResult = ReturnType<typeof useGetDomainsCount>;
export type GetDomainsCountLazyQueryDTOHookResult = ReturnType<typeof useGetDomainsCountLazyQueryDTO>;
export type GetDomainsCountQueryResultDTO = Apollo.QueryResult<GetDomainsCountDTO, GetDomainsCountVariablesDTO>;