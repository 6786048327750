import { Database } from "mocks/db";

export const populateDomainCollection = (db: Database): void => {
	const country = db.country.create({
		name: "Belgium",
		archived: false,
	});

	const region = db.region.create({
		name: "West-Flanders",
		country,
		archived: false,
	});

	const aoc = db.aoc.create({
		name: "Regional",
		country,
		region,
		archived: false,
	});

	db.domain.create({
		name: "Aptus Fields",
		country,
		region,
		aoc,
		archived: false,
	});

	db.domain.create({
		id: "domain-id",
	});
	db.domain.create({});
	db.domain.create({});
	db.domain.create({});
	db.domain.create({});
};
