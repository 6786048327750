/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetExceptionsCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetExceptionsCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'exceptionsCount'>
);


export const GetExceptionsCountDocumentDTO = gql`
    query getExceptionsCount($search: String) {
  exceptionsCount(filter: {text: {contains: $search}})
}
    `;

/**
 * __useGetExceptionsCount__
 *
 * To run a query within a React component, call `useGetExceptionsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetExceptionsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExceptionsCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetExceptionsCount(baseOptions?: Apollo.QueryHookOptions<GetExceptionsCountDTO, GetExceptionsCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExceptionsCountDTO, GetExceptionsCountVariablesDTO>(GetExceptionsCountDocumentDTO, options);
      }
export function useGetExceptionsCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetExceptionsCountDTO, GetExceptionsCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExceptionsCountDTO, GetExceptionsCountVariablesDTO>(GetExceptionsCountDocumentDTO, options);
        }
export type GetExceptionsCountHookResult = ReturnType<typeof useGetExceptionsCount>;
export type GetExceptionsCountLazyQueryDTOHookResult = ReturnType<typeof useGetExceptionsCountLazyQueryDTO>;
export type GetExceptionsCountQueryResultDTO = Apollo.QueryResult<GetExceptionsCountDTO, GetExceptionsCountVariablesDTO>;