import { UIComponent } from "@aptus/frontend-core";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import { DropDownOption, DropDownProps } from "../items/models/types";

export const getDropdownOptions = (array?: { name: string, id: string }[]): DropDownOption[] => {
	const options: DropDownOption[] = [];

	if (!array) {
		return options;
	}

	array.forEach((i) => {
		if (options.find((o) => o.id === i.id) || options.find((o) => o.label === i.name)) {
			return;
		}

		options.push({ id: i.id, label: i.name });
	});

	return options;
};

export interface AutoCompleteSelectProps {
	name: string;
	label: string;
	control: Control<any, any>;
	noOptionsText?: string;
	dropdownProps: DropDownProps;
	error?: boolean;
	helperText?: string;
	rules?: UseControllerProps["rules"];
	disabled?: boolean;
	onChange?: (value: string) => void;
}

export const AutoCompleteSelect: UIComponent<AutoCompleteSelectProps> = ({ name, control, dropdownProps, label, noOptionsText, rules, error, helperText, disabled, onChange }) => (
	<Controller
		rules={rules}
		control={control}
		render={({ field, ...renderProps }) => (
			<Autocomplete
				{...renderProps}
				onChange={(e, data) => {
					field.onChange(data?.id || "");
					onChange?.(data?.id || "");
				}}
				loading={dropdownProps.loading}
				disablePortal
				disabled={disabled}
				fullWidth
				value={dropdownProps.options.find((option) => option.id === (field.value as string)) || null}
				id={name}
				options={dropdownProps.options}
				noOptionsText={noOptionsText}
				renderInput={(params) => (
					<TextField
						error={error}
						helperText={error && helperText}
						label={label}
						{...params}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{dropdownProps.loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>
		)}
		name={name}
	/>
);
