/* eslint-disable */
import * as Types from '../../../models/schema';

import { AdviceRequestFragment_BuyingAdviceRequest_DTO, AdviceRequestFragment_ScanAdviceRequest_DTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import { AdviceRequestFragmentDTO } from '../../../graphql/models/fragments';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAdviceRequestsVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.AdviceRequestFilterDTO>;
  paging?: Types.Maybe<Types.PagingDTO>;
  sort?: Types.Maybe<Array<Types.Maybe<Types.SortDTO>> | Types.Maybe<Types.SortDTO>>;
}>;


export type GetAdviceRequestsDTO = (
  { __typename?: 'Query' }
  & { adviceRequests: Array<(
    { __typename?: 'BuyingAdviceRequest' }
    & AdviceRequestFragment_BuyingAdviceRequest_DTO
  ) | (
    { __typename?: 'ScanAdviceRequest' }
    & AdviceRequestFragment_ScanAdviceRequest_DTO
  )> }
);


export const GetAdviceRequestsDocumentDTO = gql`
    query getAdviceRequests($filter: AdviceRequestFilter, $paging: Paging, $sort: [Sort]) {
  adviceRequests(filter: $filter, paging: $paging, sort: $sort) {
    ...adviceRequestFragment
  }
}
    ${AdviceRequestFragmentDTO}`;

/**
 * __useGetAdviceRequests__
 *
 * To run a query within a React component, call `useGetAdviceRequests` and pass it any options that fit your needs.
 * When your component renders, `useGetAdviceRequests` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdviceRequests({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAdviceRequests(baseOptions?: Apollo.QueryHookOptions<GetAdviceRequestsDTO, GetAdviceRequestsVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdviceRequestsDTO, GetAdviceRequestsVariablesDTO>(GetAdviceRequestsDocumentDTO, options);
      }
export function useGetAdviceRequestsLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetAdviceRequestsDTO, GetAdviceRequestsVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdviceRequestsDTO, GetAdviceRequestsVariablesDTO>(GetAdviceRequestsDocumentDTO, options);
        }
export type GetAdviceRequestsHookResult = ReturnType<typeof useGetAdviceRequests>;
export type GetAdviceRequestsLazyQueryDTOHookResult = ReturnType<typeof useGetAdviceRequestsLazyQueryDTO>;
export type GetAdviceRequestsQueryResultDTO = Apollo.QueryResult<GetAdviceRequestsDTO, GetAdviceRequestsVariablesDTO>;