import { Database } from "mocks/db";

export const populateCorrectionCollection = (db: Database): void => {
	db.correction.create({
		originalText: "Toon",
		replacement: "Authier",
	});
	db.correction.create({});
	db.correction.create({});
	db.correction.create({});
	db.correction.create({});
	db.correction.create({});
	db.correction.create({});
	db.correction.create({});
	db.correction.create({});
};
