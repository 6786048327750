/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGrapesVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.GrapeFilterDTO>;
}>;


export type GetGrapesDTO = (
  { __typename?: 'Query' }
  & { grapes: Array<(
    { __typename?: 'Grape' }
    & Pick<Types.GrapeDTO, 'id' | 'name'>
  )> }
);


export const GetGrapesDocumentDTO = gql`
    query getGrapes($filter: GrapeFilter) {
  grapes(filter: $filter, sort: [{field: "name", order: ASC}]) {
    id
    name
  }
}
    `;

/**
 * __useGetGrapes__
 *
 * To run a query within a React component, call `useGetGrapes` and pass it any options that fit your needs.
 * When your component renders, `useGetGrapes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrapes({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetGrapes(baseOptions?: Apollo.QueryHookOptions<GetGrapesDTO, GetGrapesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGrapesDTO, GetGrapesVariablesDTO>(GetGrapesDocumentDTO, options);
      }
export function useGetGrapesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetGrapesDTO, GetGrapesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGrapesDTO, GetGrapesVariablesDTO>(GetGrapesDocumentDTO, options);
        }
export type GetGrapesHookResult = ReturnType<typeof useGetGrapes>;
export type GetGrapesLazyQueryDTOHookResult = ReturnType<typeof useGetGrapesLazyQueryDTO>;
export type GetGrapesQueryResultDTO = Apollo.QueryResult<GetGrapesDTO, GetGrapesVariablesDTO>;