import { PageComponent } from "@aptus/frontend-core";
import { PageHeader } from "components/pageHeader/pageHeader";
import { ContentStyle, HeaderContainer } from "components/styles";
import BasePage from "../basePage/basePage";
import { UserPage } from "./components/userForm/userForm";
import { UsersContent } from "./components/usersContent/usersContent";
import { UsersFilterBar } from "./components/usersFilterBar/usersFilterBar";

export const UsersPage: PageComponent = () => (
	<BasePage>
		<HeaderContainer>
			<PageHeader title="users.page.title" />
			<UsersFilterBar />
		</HeaderContainer>
		<ContentStyle style={{ height: "100%" }}>
			<UsersContent />
		</ContentStyle>
		<UserPage />
	</BasePage>
);
