/* eslint-disable */
import * as Types from '../../../models/schema';

import { SuggestionFragment_DomainSuggestion_DTO, SuggestionFragment_FaqSuggestion_DTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import { SuggestionFragmentDTO } from '../../../graphql/models/fragments';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateSuggestionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.Scalars['SuggestionUpdateInput']>;
}>;


export type UpdateSuggestionDTO = (
  { __typename?: 'Mutation' }
  & { updateOneSuggestion: (
    { __typename?: 'DomainSuggestion' }
    & SuggestionFragment_DomainSuggestion_DTO
  ) | (
    { __typename?: 'FaqSuggestion' }
    & SuggestionFragment_FaqSuggestion_DTO
  ) }
);


export const UpdateSuggestionDocumentDTO = gql`
    mutation updateSuggestion($id: String!, $data: SuggestionUpdateInput) {
  updateOneSuggestion(id: $id, data: $data) {
    ...suggestionFragment
  }
}
    ${SuggestionFragmentDTO}`;
export type UpdateSuggestionMutationFnDTO = Apollo.MutationFunction<UpdateSuggestionDTO, UpdateSuggestionVariablesDTO>;

/**
 * __useUpdateSuggestion__
 *
 * To run a mutation, you first call `useUpdateSuggestion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuggestion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuggestion, { data, loading, error }] = useUpdateSuggestion({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSuggestion(baseOptions?: Apollo.MutationHookOptions<UpdateSuggestionDTO, UpdateSuggestionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSuggestionDTO, UpdateSuggestionVariablesDTO>(UpdateSuggestionDocumentDTO, options);
      }
export type UpdateSuggestionHookResult = ReturnType<typeof useUpdateSuggestion>;
export type UpdateSuggestionMutationResultDTO = Apollo.MutationResult<UpdateSuggestionDTO>;
export type UpdateSuggestionMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateSuggestionDTO, UpdateSuggestionVariablesDTO>;