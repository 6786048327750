/* eslint-disable */
import * as Types from '../../../models/schema';

import { FoodFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateFoodVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.FoodUpdateInputDTO>;
}>;


export type UpdateFoodDTO = (
  { __typename?: 'Mutation' }
  & { updateOneFood: (
    { __typename?: 'Food' }
    & FoodFragmentDTO
  ) }
);


export const UpdateFoodDocumentDTO = gql`
    mutation updateFood($id: String!, $data: FoodUpdateInput) {
  updateOneFood(id: $id, data: $data) {
    ...foodFragment
  }
}
    ${FoodFragmentDTO}`;
export type UpdateFoodMutationFnDTO = Apollo.MutationFunction<UpdateFoodDTO, UpdateFoodVariablesDTO>;

/**
 * __useUpdateFood__
 *
 * To run a mutation, you first call `useUpdateFood` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFood` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFood, { data, loading, error }] = useUpdateFood({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFood(baseOptions?: Apollo.MutationHookOptions<UpdateFoodDTO, UpdateFoodVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFoodDTO, UpdateFoodVariablesDTO>(UpdateFoodDocumentDTO, options);
      }
export type UpdateFoodHookResult = ReturnType<typeof useUpdateFood>;
export type UpdateFoodMutationResultDTO = Apollo.MutationResult<UpdateFoodDTO>;
export type UpdateFoodMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateFoodDTO, UpdateFoodVariablesDTO>;