/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteCorrectionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteCorrectionDTO = (
  { __typename?: 'Mutation' }
  & { deleteOneCorrection: (
    { __typename?: 'Success' }
    & Pick<Types.SuccessDTO, 'success'>
  ) }
);


export const DeleteCorrectionDocumentDTO = gql`
    mutation deleteCorrection($id: String!) {
  deleteOneCorrection(id: $id) {
    success
  }
}
    `;
export type DeleteCorrectionMutationFnDTO = Apollo.MutationFunction<DeleteCorrectionDTO, DeleteCorrectionVariablesDTO>;

/**
 * __useDeleteCorrection__
 *
 * To run a mutation, you first call `useDeleteCorrection` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCorrection` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCorrection, { data, loading, error }] = useDeleteCorrection({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCorrection(baseOptions?: Apollo.MutationHookOptions<DeleteCorrectionDTO, DeleteCorrectionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCorrectionDTO, DeleteCorrectionVariablesDTO>(DeleteCorrectionDocumentDTO, options);
      }
export type DeleteCorrectionHookResult = ReturnType<typeof useDeleteCorrection>;
export type DeleteCorrectionMutationResultDTO = Apollo.MutationResult<DeleteCorrectionDTO>;
export type DeleteCorrectionMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteCorrectionDTO, DeleteCorrectionVariablesDTO>;