/* eslint-disable */
import * as Types from '../../../models/schema';

import { FoodFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateFoodVariablesDTO = Types.Exact<{
  data: Types.FoodCreateInputDTO;
}>;


export type CreateFoodDTO = (
  { __typename?: 'Mutation' }
  & { createOneFood: (
    { __typename?: 'Food' }
    & FoodFragmentDTO
  ) }
);


export const CreateFoodDocumentDTO = gql`
    mutation createFood($data: FoodCreateInput!) {
  createOneFood(data: $data) {
    ...foodFragment
  }
}
    ${FoodFragmentDTO}`;
export type CreateFoodMutationFnDTO = Apollo.MutationFunction<CreateFoodDTO, CreateFoodVariablesDTO>;

/**
 * __useCreateFood__
 *
 * To run a mutation, you first call `useCreateFood` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFood` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFood, { data, loading, error }] = useCreateFood({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFood(baseOptions?: Apollo.MutationHookOptions<CreateFoodDTO, CreateFoodVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFoodDTO, CreateFoodVariablesDTO>(CreateFoodDocumentDTO, options);
      }
export type CreateFoodHookResult = ReturnType<typeof useCreateFood>;
export type CreateFoodMutationResultDTO = Apollo.MutationResult<CreateFoodDTO>;
export type CreateFoodMutationOptionsDTO = Apollo.BaseMutationOptions<CreateFoodDTO, CreateFoodVariablesDTO>;