/* eslint-disable */
import * as Types from '../../../models/schema';

import { ExceptionFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateExceptionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.ExceptionUpdateInputDTO>;
}>;


export type UpdateExceptionDTO = (
  { __typename?: 'Mutation' }
  & { updateOneException: (
    { __typename?: 'Exception' }
    & ExceptionFragmentDTO
  ) }
);


export const UpdateExceptionDocumentDTO = gql`
    mutation updateException($id: String!, $data: ExceptionUpdateInput) {
  updateOneException(id: $id, data: $data) {
    ...exceptionFragment
  }
}
    ${ExceptionFragmentDTO}`;
export type UpdateExceptionMutationFnDTO = Apollo.MutationFunction<UpdateExceptionDTO, UpdateExceptionVariablesDTO>;

/**
 * __useUpdateException__
 *
 * To run a mutation, you first call `useUpdateException` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateException` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateException, { data, loading, error }] = useUpdateException({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateException(baseOptions?: Apollo.MutationHookOptions<UpdateExceptionDTO, UpdateExceptionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExceptionDTO, UpdateExceptionVariablesDTO>(UpdateExceptionDocumentDTO, options);
      }
export type UpdateExceptionHookResult = ReturnType<typeof useUpdateException>;
export type UpdateExceptionMutationResultDTO = Apollo.MutationResult<UpdateExceptionDTO>;
export type UpdateExceptionMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateExceptionDTO, UpdateExceptionVariablesDTO>;