import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { FoodDTO, LanguageDTO, QueryDTO } from "models/schema";
import { toAPIMultiLanguage, toFormMultiLanguage } from "utils/MLtoFMLI";
import { CreateFoodMutationFnDTO } from "./models/createFood";
import { DeleteFoodMutationFnDTO } from "./models/deleteFood";
import { Food } from "./models/food";
import { UpdateFoodMutationFnDTO } from "./models/updateFood";
import { CreateFoodMutation, DeleteFoodMutation, FoodsAPI, UpdateFoodMutation } from "./useFoodsUseCases";

export type FoodsAPIDTO = ApolloAPI<QueryDTO, "foods" | "food">;

interface Mapper {
	toAPI: ToAPI<FoodsAPIDTO, FoodsAPI>;
	toUpdateMutation: ToInternal<UpdateFoodMutationFnDTO, UpdateFoodMutation>;
	toCreateMutation: ToInternal<CreateFoodMutationFnDTO, CreateFoodMutation>;
	toDeleteMutation: ToInternal<DeleteFoodMutationFnDTO, DeleteFoodMutation>
	toFood: ToInternal<DeepPartial<FoodDTO>, Food>;
}

export class FoodMapper implements Mapper {
	public toFood: Mapper["toFood"] = (food) => ({
		id: food.id || "",
		course: food.course,
		kitchen: food.kitchen,
		ingredients: food.ingredients,
		wineStyle: food.wineStyle,
		titles: toFormMultiLanguage((food.titles || []).map((t) => ({ language: t.language || LanguageDTO.EnDTO, text: t.text || "" }))),
		descriptions: toFormMultiLanguage((food.descriptions || []).map((d) => ({ language: d.language || LanguageDTO.EnDTO, text: d.text || "" }))),
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: FoodsAPIDTO["data"]): DeepPartial<FoodDTO[]> => {
			if (data?.food) {
				return [data.food];
			}

			if (data?.foods) {
				return data.foods;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toFood),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateFoodMutationFnDTO, UpdateFoodMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						course: input.course,
						kitchen: input.kitchen,
						ingredients: input.ingredients,
						wineStyle: input.wineStyle,
						titles: input.titles && toAPIMultiLanguage(input.titles),
						descriptions: input.descriptions && toAPIMultiLanguage(input.descriptions),
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneFood ? this.toFood(output.data.updateOneFood) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateFoodMutationFnDTO, CreateFoodMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						course: input.course,
						kitchen: input.kitchen,
						ingredients: input.ingredients,
						wineStyle: input.wineStyle,
						titles: toAPIMultiLanguage(input.titles),
						descriptions: toAPIMultiLanguage(input.descriptions),
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneFood ? this.toFood(output.data.createOneFood) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteFoodMutationFnDTO, DeleteFoodMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getFoods"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: output?.data?.deleteOneFood.success || false }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
