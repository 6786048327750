import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Food } from "./models/food";
import { CreateFoodInput, DeleteFoodInput, SaveFoodInput, UpdateFoodInput } from "./models/foodInput";

export type FoodsAPI = InternalAPI<Food[]>;

export type UpdateFoodMutation = Mutation<UpdateFoodInput, Food>;
export type CreateFoodMutation = Mutation<CreateFoodInput, Food>;
export type DeleteFoodMutation = Mutation<DeleteFoodInput, Success>;

interface Props {
	API: FoodsAPI;
	defaultFood: Food;
	updateMutation: UpdateFoodMutation;
	createMutation: CreateFoodMutation;
	deleteMutation: DeleteFoodMutation;
}
interface Result {
	food: Food;
	foods: Food[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveFoodInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useFoodsUseCases: UseCases<Props, Result> = ({ API, defaultFood, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveFoodInput): input is UpdateFoodInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("foodHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("foodHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("foodHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		food: API.data[0] || defaultFood,
		foods: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
