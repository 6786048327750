import styled from "styled-components";

export const ActionBar = styled.div`
	background-color: #F5F5F5;
	padding: 10px;
	display: flex;
	justify-content: space-between;
`;

export const TitleInfoBar = styled.div`
	padding: 30px;

	h3 {
		font-size: 3rem;
	}

`;

export const RequestDetailContent = styled.div`
	padding: 30px;
	width: 100%;
	display: flex;
	background-color: white;

	.temperature {
		font-size: 13px;
		opacity: 0.5;
	}

	h4 {
		margin-bottom: 20px;
	}

	> div {
		:nth-child(2) {
			margin-left: 20px;
		}
	}

	form {
		margin-bottom: 20px;

		.flex {
			display: flex;
		}
	}
`;
