import { Database } from "mocks/db";

export const populateAocCollection = (db: Database): void => {
	const regions = db.region.findMany({});
	const countries = db.country.findMany({});

	db.aoc.create({ region: regions[0], country: regions[0].country, id: "aoc-id" });
	db.aoc.create({ region: regions[1], country: regions[1].country });
	db.aoc.create({ country: countries[0] });
	db.aoc.create({ country: countries[0] });
	db.aoc.create({ country: countries[0] });
	db.aoc.create({ country: countries[0] });
	db.aoc.create({ country: countries[0] });
};
