import { UIComponent } from "@aptus/frontend-core";
import { RecordFormStyle } from "./recordFormStyle";
import { useRecordForm } from "./useRecordForm";

export const RecordForm: UIComponent = () => {
	const { component: Component } = useRecordForm({});
	return (
		<RecordFormStyle>
			{Component && <Component />}
		</RecordFormStyle>
	);
};
