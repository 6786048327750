import { SxProps, Theme } from "@mui/system";

const paper: SxProps<Theme> = {
	marginTop: 8,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
};

const avatar: SxProps<Theme> = {
	margin: 1,
	backgroundColor: "primary.main",
};

const form: SxProps<Theme> = {
	width: "100%", // Fix IE 11 issue.
	marginTop: 1,
};

const submit: SxProps<Theme> = {
	mt: 3,
	mb: 2,
};

const sx = {
	paper,
	avatar,
	form,
	submit,
};

export default sx;
