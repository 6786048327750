/* eslint-disable */
import * as Types from '../../../models/schema';

import { GrapeFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateGrapeVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.GrapeUpdateInputDTO>;
}>;


export type UpdateGrapeDTO = (
  { __typename?: 'Mutation' }
  & { updateOneGrape: (
    { __typename?: 'Grape' }
    & GrapeFragmentDTO
  ) }
);


export const UpdateGrapeDocumentDTO = gql`
    mutation updateGrape($id: String!, $data: GrapeUpdateInput) {
  updateOneGrape(id: $id, data: $data) {
    ...grapeFragment
  }
}
    ${GrapeFragmentDTO}`;
export type UpdateGrapeMutationFnDTO = Apollo.MutationFunction<UpdateGrapeDTO, UpdateGrapeVariablesDTO>;

/**
 * __useUpdateGrape__
 *
 * To run a mutation, you first call `useUpdateGrape` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrape` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrape, { data, loading, error }] = useUpdateGrape({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateGrape(baseOptions?: Apollo.MutationHookOptions<UpdateGrapeDTO, UpdateGrapeVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGrapeDTO, UpdateGrapeVariablesDTO>(UpdateGrapeDocumentDTO, options);
      }
export type UpdateGrapeHookResult = ReturnType<typeof useUpdateGrape>;
export type UpdateGrapeMutationResultDTO = Apollo.MutationResult<UpdateGrapeDTO>;
export type UpdateGrapeMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateGrapeDTO, UpdateGrapeVariablesDTO>;