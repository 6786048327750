import { LanguageDTO, MultiLanguageTextInputDTO } from "models/schema";

export type FormMultiLanguage = {
	[key: string]: string
};

export const toFormMultiLanguage = (ml: MultiLanguageTextInputDTO[]): FormMultiLanguage => {
	if (Array.from(new Set(ml.map((item) => item.language))).length < ml.length) {
		throw new Error("Duplicates found in API source!");
	}
	return ml.reduce((prev, curr) => ({ ...prev, [curr.language]: curr.text }), {});
};

export const toAPIMultiLanguage = (fmli: FormMultiLanguage): MultiLanguageTextInputDTO[] => Object.keys(fmli).map((k) => ({
	language: k as LanguageDTO,
	text: fmli[k],
}));
