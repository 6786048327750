import { UIComponent } from "@aptus/frontend-core";
import { Route as OriginalRoute } from "react-router";
import useRoute, { RouteProps } from "./useRoute";

const Route: UIComponent<RouteProps> = (props) => {
	const { component, render, children } = useRoute(props);

	return (
		<OriginalRoute {...props} component={component} render={render}>
			{children}
		</OriginalRoute>
	);
};

export default Route;
