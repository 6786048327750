import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { CountryDTO, QueryDTO, WineTypeDTO } from "models/schema";
import { Country } from "./models/country";
import { CreateCountryMutationFnDTO } from "./models/createCountry";
import { DeleteCountryMutationFnDTO } from "./models/deleteCountry";
import { UpdateCountryMutationFnDTO } from "./models/updateCountry";
import { CountriesAPI, CreateCountryMutation, DeleteCountryMutation, UpdateCountryMutation } from "./useCountriesUseCases";

export type CountriesAPIDTO = ApolloAPI<QueryDTO, "countries" | "country">;

interface Mapper {
	toAPI: ToAPI<CountriesAPIDTO, CountriesAPI>;
	toUpdateMutation: ToInternal<UpdateCountryMutationFnDTO, UpdateCountryMutation>;
	toCreateMutation: ToInternal<CreateCountryMutationFnDTO, CreateCountryMutation>;
	toDeleteMutation: ToInternal<DeleteCountryMutationFnDTO, DeleteCountryMutation>
	toCountry: ToInternal<DeepPartial<CountryDTO>, Country>;
}

export class CountryMapper implements Mapper {
	public toCountry: Mapper["toCountry"] = (country) => ({
		id: country.id || "",
		name: country.name || "",
		temperatures: (country.temperatures || []).map((item) => ({ type: item.type || WineTypeDTO.RedDTO, temperature: item.temperature })),
		archived: country.archived || false,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: CountriesAPIDTO["data"]): DeepPartial<CountryDTO[]> => {
			if (data?.country) {
				return [data.country];
			}

			if (data?.countries) {
				return data.countries;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toCountry),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateCountryMutationFnDTO, UpdateCountryMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						name: input.name,
						archived: input.archived,
						temperatures: input.temperatures,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneCountry ? this.toCountry(output.data.updateOneCountry) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateCountryMutationFnDTO, CreateCountryMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						name: input.name,
						temperatures: input.temperatures,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneCountry ? this.toCountry(output.data.createOneCountry) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteCountryMutationFnDTO, DeleteCountryMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getCountries"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: !!output?.data?.updateOneCountry.id }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
