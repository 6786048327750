/* eslint-disable import/prefer-default-export */
import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { AocSelect } from "components/form/items/AOCSelect/aocSelect";
import { CountrySelect } from "components/form/items/countrySelect/countrySelect";
import { DomainSelect } from "components/form/items/domainSelect/domainSelect";
import { GrapeSelect } from "components/form/items/grapeSelect/grapeSelect";
import { RegionSelect } from "components/form/items/regionSelect/regionSelect";
import { WineAndTemperatureSelect } from "components/form/items/wineAndTemperatureSelect/wineAndTemperatureSelect";
import Loader from "components/loader/loader";
import { useExceptions, useRoutes, useTranslations } from "domains";
import { Exception } from "hooks/exceptions/models/exception";
import { SaveExceptionInput } from "hooks/exceptions/models/exceptionInput";
import BasePage from "pages/basePage/basePage";
import { useGetException } from "./models/getException";
import { useExceptionForm } from "./useExceptionForm";

interface ExceptionFormProps {
	exception: Exception;
	save: (input: SaveExceptionInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const ExceptionForm: UIComponent<ExceptionFormProps> = ({ exception, save, remove }) => {
	const { register, handleFormSubmit, control, selectedCountry, selectedRegion, selectedAoc, selectedDomain, handleDelete, errors, isDisabled } = useExceptionForm({ initialValues: exception, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`exception.${exception.id ? "edit" : "add"}`)}</h2>
			<FormControl {...register("text", { required: { value: true, message: "This is required." } })} fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					error={!!errors?.text}
					helperText={errors?.text?.message}
					fullWidth
					name="text"
					id="text"
					label={t("text")}
					variant="outlined"
				/>
			</FormControl>
			<p>Map to</p>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<GrapeSelect
					control={control}
					name="grape"
					label={t("grape")}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<AocSelect
					control={control}
					name="aoc"
					label={t("aoc")}
					region={selectedRegion || exception.region}
					country={selectedCountry || exception.country}
					defaultValue={selectedAoc}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<CountrySelect
					control={control}
					name="country"
					label={t("country")}
					defaultValue={selectedCountry}
					disabled={isDisabled}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<RegionSelect
					control={control}
					name="region"
					label={t("region")}
					country={selectedCountry || exception.country}
					defaultValue={selectedRegion}
					disabled={isDisabled}
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
				<DomainSelect
					control={control}
					name="domain"
					label={t("domain")}
					aoc={selectedAoc || exception.aoc}
					region={selectedRegion || exception.region}
					country={selectedCountry || exception.country}
					defaultValue={selectedDomain}
				/>
			</FormControl>
			<WineAndTemperatureSelect register={register} control={control} name="temperatures" />
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{exception.id ? <Button onClick={() => handleDelete(exception.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteException")}</Button> : undefined}
				<Button type="submit" variant="contained">{exception.id ? t("updateException") : t("createException")}</Button>
			</Box>
		</form>
	);
};

export const ExceptionPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, exception, isLoading } = useExceptions(useGetException({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <ExceptionForm save={save} remove={remove} exception={exception} />;
};
