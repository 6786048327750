import { UIComponent } from "@aptus/frontend-core";
import { DataGrid } from "@mui/x-data-grid";
import { CustomPagination } from "components/table/customPagination";
import { useRoutes, useUsers } from "domains";
import { useGetUsers } from "./models/getUsers";
import { useGetUsersCount } from "./models/getUsersCount";
import { useUsersTable } from "./useUsersTable";

export const UsersContent: UIComponent = () => {
	const { currentQueryString: { search } } = useRoutes();
	const { count, remove } = useUsers(useGetUsersCount({
		variables: {
			filter: search ? {
				OR: [
					{ familyName: { contains: search } },
					{ givenName: { contains: search } },
					{ email: { contains: search } },
				],
			} : undefined,
		},
	}));
	const { columns, skip, pageSize, page, onChangePage, onChangeSort, sort } = useUsersTable({ remove });
	const { users, isLoading } = useUsers(useGetUsers({ variables: {
		filter: search ? {
			OR: [
				{ familyName: { contains: search } },
				{ givenName: { contains: search } },
				{ email: { contains: search } },
			],
		} : undefined,
		paging: {
			offset: skip,
			limit: pageSize,
		},
		sort,
	} }));
	return (
		<div style={{ width: "100%" }}>
			<DataGrid
				disableColumnSelector
				disableColumnFilter
				onSortModelChange={onChangeSort}
				rowCount={count}
				loading={isLoading}
				pageSize={pageSize}
				page={page}
				onPageChange={onChangePage}
				style={{ backgroundColor: "white" }}
				autoHeight
				paginationMode="server"
				rowHeight={80}
				rows={users}
				columns={columns}
				components={{
					Pagination: CustomPagination,
				}}
			/>
		</div>
	);
};
