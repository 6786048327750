import { UIComponent } from "@aptus/frontend-core";
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { UseFormRegister } from "react-hook-form";
import { useTranslations } from "domains";

interface MultiLanguageProps {
	register: UseFormRegister<any>;
	languages: ("en" | "nl" | "fr")[];
	children: JSX.Element[] | JSX.Element;
}

export const MultiLanguage: UIComponent<MultiLanguageProps> = ({ children, languages, register }) => {
	const [value, setValue] = React.useState<string>(languages[0]);
	const { t } = useTranslations();

	const handleChange = (_: any, newValue: string) => {
		setValue(newValue);
	};

	const fields = React.useMemo(() => languages
		.map((lang) => ({
			lang,
			children: (Array.isArray(children) ? children : [children]).map((child) => (React.cloneElement(child, { key: `${child.props.id}.${lang}`, ...register(`${child.props.id}.${lang}`) }))),
		})), [languages, children]);

	const toRender = React.useMemo(() => fields.map((field) => (
		<div key={field.lang} hidden={field.lang !== value} role="tabpanel">
			{field.children}
		</div>
	)), [languages, children, value]);

	return (
		<>
			<Box sx={{ mb: 2 }}>
				<Tabs onChange={handleChange} value={value}>
					{languages.map((lang) => (<Tab key={lang} label={t(`language.${lang}`)} value={lang} />))}
				</Tabs>
			</Box>
			{toRender}
		</>
	);
};
