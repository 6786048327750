/* eslint-disable import/prefer-default-export */
import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { CountrySelect } from "components/form/items/countrySelect/countrySelect";
import { WineAndTemperatureSelect } from "components/form/items/wineAndTemperatureSelect/wineAndTemperatureSelect";
import Loader from "components/loader/loader";
import { useRegions, useRoutes, useTranslations } from "domains";
import { Region } from "hooks/regions/models/region";
import { SaveRegionInput } from "hooks/regions/models/regionInput";
import BasePage from "pages/basePage/basePage";
import { useGetRegion } from "./models/getRegion";
import { useRegionForm } from "./useRegionForm";

interface RegionFormProps {
	region: Region;
	save: (input: SaveRegionInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const RegionForm: UIComponent<RegionFormProps> = ({ region, save, remove }) => {
	const { register, handleFormSubmit, control, handleDelete, errors, selectedCountry } = useRegionForm({ initialValues: region, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`region.${region.id ? "edit" : "add"}`)}</h2>
			<FormControl {...register("name", { required: { value: true, message: "This is required." } })} sx={{ mb: 2, mr: 2 }} fullWidth>
				<TextField
					error={!!errors?.name}
					helperText={errors?.name?.message}
					fullWidth
					name="name"
					id="name"
					label="Name"
					variant="outlined"
				/>
			</FormControl>
			<FormControl sx={{ mb: 2, mr: 2 }} fullWidth>
				<CountrySelect
					rules={{ required: { value: true, message: "This is required." } }}
					error={!!errors?.country}
					helperText={errors?.country?.message}
					control={control}
					name="country"
					label={t("country")}
					defaultValue={selectedCountry}
				/>
			</FormControl>
			<WineAndTemperatureSelect register={register} control={control} name="temperatures" />
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{region.id ? <Button onClick={() => handleDelete(region.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteRegion")}</Button> : undefined}
				<Button type="submit" variant="contained">{region.id ? t("updateRegion") : t("createRegion")}</Button>
			</Box>
		</form>
	);
};

export const RegionPage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, region, isLoading } = useRegions(useGetRegion({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}

	return <RegionForm save={save} remove={remove} region={region} />;
};
