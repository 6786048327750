import { Query, UILogic } from "@aptus/frontend-core";
import { AutoCompleteSelectProps, getDropdownOptions } from "components/form/autoCompleteSelect/autoCompleteSelect";
import { useRegions, useTranslations } from "domains";
import { Country } from "hooks/countries/models/country";
import { Region } from "hooks/regions/models/region";
import { RegionsAPIDTO } from "hooks/regions/regionMapper";
import { RegionFilterDTO } from "models/schema";
import { useGetRegions } from "./models/getRegions";

export interface RegionSelectProps extends Omit<AutoCompleteSelectProps, "dropdownProps"> {
	country?: string;
	defaultValue?: string;
}

type Result = AutoCompleteSelectProps;

interface QueryProps {
	countryId?: Country["id"];
	regionId?: Region["id"];
}

const useGetFilteredRegions: Query<QueryProps, RegionsAPIDTO> = ({ countryId, regionId }) => {
	const filter: RegionFilterDTO = {
		OR: [
			{
				archived: { equals: false },
				...countryId ? { country: { id: { equals: countryId } } } : {},
			},
			...regionId ? [{ id: { equals: regionId } }] : [],
		],
	};

	return useGetRegions({ variables: { filter } });
};

export const useRegionSelect: UILogic<RegionSelectProps, Result> = (props) => {
	const { t } = useTranslations();
	const { regions, isLoading } = useRegions(useGetFilteredRegions({
		countryId: props.country,
		regionId: props.defaultValue,
	}));

	const options = {
		loading: isLoading,
		options: getDropdownOptions(regions),
	};

	return {
		...props,
		dropdownProps: options,
		noOptionsText: t("noOptions"),
	};
};
