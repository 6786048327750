import { createGlobalStyle } from "styled-components";

/**
 * Default look for all the standard HTML elements.
 */

const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html {
		font-size: 16px; /* 1rem = 2 x 8pt grid units. */
	}
`;

export default GlobalStyle;
