import styled from "styled-components";

export const RequestsFilterBarStyle = styled.div`
	padding: 30px;
	background-color: white;
`;

export const FilterBarHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const VisibleFilters = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 20px 0;
`;

export const ToggleFilters = styled.div<{ visible?: boolean }>`
	border-top: 1px solid lightgray;
	width: 100%;
	padding-top: 20px;

	display: ${(props) => (props.visible ? "block" : "none")};
`;
