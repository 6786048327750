import { Database } from "mocks/db";

export const pupulateConversationsCollection = (db: Database): void => {
	const users = db.user.getAll();
	db.conversation.create({
		id: "withMessages-id",
		owner: users[1],
		messages: [
			{
				__typename: "ConversationMessage",
				content: "This is a message",
				createdAt: new Date().toISOString(),
				owner: users[2],
			},
			{
				__typename: "ConversationMessage",
				content: "Another reply",
				createdAt: new Date().toISOString(),
				owner: users[1],
			},
			{
				__typename: "ConversationMessage",
				content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et lorem quis nibh ultrices tristique. Curabitur elementum erat urna, eu porttitor leo tempor id. Donec sit amet euismod felis. Sed ac pellentesque tortor. Curabitur at suscipit justo. Pellentesque erat mauris, auctor ac ex vitae, eleifend ultrices enim. Cras ullamcorper eros id odio consectetur sodales. Quisque aliquet varius lectus ac egestas. Mauris rutrum mattis aliquet. Curabitur quis arcu vel diam mattis cursus eget at nisi.",
				createdAt: new Date().toISOString(),
				owner: users[2],
			},
		],
	});
};
