/* eslint-disable */
import * as Types from '../../../models/schema';

import { AdviceRequestFragment_BuyingAdviceRequest_DTO, AdviceRequestFragment_ScanAdviceRequest_DTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import { AdviceRequestFragmentDTO } from '../../../graphql/models/fragments';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAdviceRequestVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.Scalars['AdviceRequestUpdateInput'];
}>;


export type UpdateAdviceRequestDTO = (
  { __typename?: 'Mutation' }
  & { updateOneAdviceRequest: (
    { __typename?: 'BuyingAdviceRequest' }
    & AdviceRequestFragment_BuyingAdviceRequest_DTO
  ) | (
    { __typename?: 'ScanAdviceRequest' }
    & AdviceRequestFragment_ScanAdviceRequest_DTO
  ) }
);


export const UpdateAdviceRequestDocumentDTO = gql`
    mutation updateAdviceRequest($id: String!, $data: AdviceRequestUpdateInput!) {
  updateOneAdviceRequest(id: $id, data: $data) {
    ...adviceRequestFragment
  }
}
    ${AdviceRequestFragmentDTO}`;
export type UpdateAdviceRequestMutationFnDTO = Apollo.MutationFunction<UpdateAdviceRequestDTO, UpdateAdviceRequestVariablesDTO>;

/**
 * __useUpdateAdviceRequest__
 *
 * To run a mutation, you first call `useUpdateAdviceRequest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdviceRequest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdviceRequest, { data, loading, error }] = useUpdateAdviceRequest({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAdviceRequest(baseOptions?: Apollo.MutationHookOptions<UpdateAdviceRequestDTO, UpdateAdviceRequestVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdviceRequestDTO, UpdateAdviceRequestVariablesDTO>(UpdateAdviceRequestDocumentDTO, options);
      }
export type UpdateAdviceRequestHookResult = ReturnType<typeof useUpdateAdviceRequest>;
export type UpdateAdviceRequestMutationResultDTO = Apollo.MutationResult<UpdateAdviceRequestDTO>;
export type UpdateAdviceRequestMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateAdviceRequestDTO, UpdateAdviceRequestVariablesDTO>;