/* eslint-disable */
import * as Types from '../../../../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetClassificationVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetClassificationDTO = (
  { __typename?: 'Query' }
  & { classification: (
    { __typename?: 'Classification' }
    & Pick<Types.ClassificationDTO, 'id' | 'name' | 'type' | 'archived'>
  ) }
);


export const GetClassificationDocumentDTO = gql`
    query getClassification($id: String!) {
  classification(id: $id) {
    id
    name
    type
    archived
  }
}
    `;

/**
 * __useGetClassification__
 *
 * To run a query within a React component, call `useGetClassification` and pass it any options that fit your needs.
 * When your component renders, `useGetClassification` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassification({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassification(baseOptions: Apollo.QueryHookOptions<GetClassificationDTO, GetClassificationVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassificationDTO, GetClassificationVariablesDTO>(GetClassificationDocumentDTO, options);
      }
export function useGetClassificationLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetClassificationDTO, GetClassificationVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassificationDTO, GetClassificationVariablesDTO>(GetClassificationDocumentDTO, options);
        }
export type GetClassificationHookResult = ReturnType<typeof useGetClassification>;
export type GetClassificationLazyQueryDTOHookResult = ReturnType<typeof useGetClassificationLazyQueryDTO>;
export type GetClassificationQueryResultDTO = Apollo.QueryResult<GetClassificationDTO, GetClassificationVariablesDTO>;