import { UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import React from "react";

interface Result {
	search?: string;
	language?: string | null;
	status?: string | null;
	createdOn?: Date | null;
	requestType?: string[];
	showSecondFilterBar: boolean,
	toggleSecondFilterBar: () => void;
	onSearch: (value: string | null) => void;
	onSelectLanguage: (value: string | null) => void;
	onSelectStatus: (value: string | null) => void;
	onSelectDate: (value?: Date | null) => void;
	onSelectType: (value: string[]) => void;
}

export const useRequestsFilterBar: UILogic<{}, Result> = () => {
	const { currentQueryString, updateQueryString } = useRoutes();
	const [secondFilterBar, toggleSecondFilterBar] = React.useState(false);

	const onSearch: Result["onSearch"] = (value) => {
		updateQueryString({ search: value });
	};

	const onSelectLanguage: Result["onSelectLanguage"] = (value) => {
		updateQueryString({ language: value });
	};

	const onSelectStatus: Result["onSelectStatus"] = (value) => {
		updateQueryString({ status: value });
	};

	const onSelectDate: Result["onSelectDate"] = (value) => {
		updateQueryString({ date: value ? value.toISOString() : null });
	};

	const onSelectType: Result["onSelectType"] = (value) => {
		updateQueryString({ type: value });
	};

	return {
		search: currentQueryString.search,
		language: currentQueryString.language,
		status: currentQueryString.status,
		createdOn: currentQueryString.date ? new Date(currentQueryString.date) : null,
		// eslint-disable-next-line no-nested-ternary
		requestType: Array.isArray(currentQueryString.type)
			? currentQueryString.type
			: (currentQueryString.type ? [currentQueryString.type] : []),
		showSecondFilterBar: secondFilterBar,
		toggleSecondFilterBar: () => toggleSecondFilterBar(!secondFilterBar),
		onSearch,
		onSelectLanguage,
		onSelectStatus,
		onSelectDate,
		onSelectType,
	};
};
