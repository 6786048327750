import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { GrapeDTO, QueryDTO, WineTypeDTO } from "models/schema";
import { CreateGrapeMutationFnDTO } from "./models/createGrape";
import { DeleteGrapeMutationFnDTO } from "./models/deleteGrape";
import { Grape } from "./models/grape";
import { UpdateGrapeMutationFnDTO } from "./models/updateGrape";
import { CreateGrapeMutation, DeleteGrapeMutation, GrapesAPI, UpdateGrapeMutation } from "./useGrapesUseCases";

export type GrapesAPIDTO = ApolloAPI<QueryDTO, "grapes" | "grape">;

interface Mapper {
	toAPI: ToAPI<GrapesAPIDTO, GrapesAPI>;
	toUpdateMutation: ToInternal<UpdateGrapeMutationFnDTO, UpdateGrapeMutation>;
	toCreateMutation: ToInternal<CreateGrapeMutationFnDTO, CreateGrapeMutation>;
	toDeleteMutation: ToInternal<DeleteGrapeMutationFnDTO, DeleteGrapeMutation>
	toGrape: ToInternal<DeepPartial<GrapeDTO>, Grape>;
}

export class GrapeMapper implements Mapper {
	public toGrape: Mapper["toGrape"] = (grape) => ({
		id: grape.id || "",
		name: grape.name || "",
		mainGrape: grape.mainGrape?.id,
		temperatures: (grape.temperatures || []).map((item) => ({ type: item.type || WineTypeDTO.RedDTO, temperature: item.temperature })),
		archived: grape.archived || false,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: GrapesAPIDTO["data"]): DeepPartial<GrapeDTO[]> => {
			if (data?.grape) {
				return [data.grape];
			}

			if (data?.grapes) {
				return data.grapes;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toGrape),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateGrapeMutationFnDTO, UpdateGrapeMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						name: input.name,
						temperatures: input.temperatures,
						archived: input.archived || false,
						mainGrape: input.mainGrape,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneGrape ? this.toGrape(output.data.updateOneGrape) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateGrapeMutationFnDTO, CreateGrapeMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						name: input.name,
						temperatures: input.temperatures,
						mainGrape: input.mainGrape,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneGrape ? this.toGrape(output.data.createOneGrape) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteGrapeMutationFnDTO, DeleteGrapeMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getGrapes"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: !!output?.data?.updateOneGrape.id }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
