import styled from "styled-components";

export const AddressItem = styled.div`
	p {
		:first-child {
			font-size: 13px;
			opacity: 0.5;
		}
	}

	margin-bottom: 10px;
`;
