import styled from "styled-components";

export const HeaderContainer = styled.div`
	padding: 30px;
	background-color: white;
`;

export const ContentStyle = styled.div`
	padding: 30px;
	display: flex;
`;
