import { UILogic } from "@aptus/frontend-core";
import { User } from "hooks/users/models/user";
import { SaveUserInput } from "hooks/users/models/userInput";
import { UseFormTypes } from "pages/databasePage/components/databaseContent/recordForm/forms/useFormTypes";
import { useForm } from "react-hook-form";

export interface EmailFormProps {
	formValues: Partial<User>
}

interface Props {
	initialValues: EmailFormProps["formValues"];
	submit: (values: SaveUserInput) => void;
}

type Result = UseFormTypes<EmailFormProps["formValues"]>;

export const useEmailForm: UILogic<Props, Result> = ({ initialValues, submit }) => {
	const { register, handleSubmit, control, formState: { errors } } = useForm<EmailFormProps["formValues"]>({ defaultValues: initialValues });

	const handleFormSubmit = (values: EmailFormProps["formValues"]): void => {
		if (!values.id) {
			return;
		}

		submit({
			id: values.id,
			...values,
		});
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		errors,
	};
};
