import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import event from "event";
import { t } from "i18next";
import { Wine } from "./models/wine";
import { CreateWineInput, SaveWineInput, UpdateWineInput } from "./models/wineInput";

export type WinesAPI = InternalAPI<Wine[]>;

export type UpdateWineMutation = Mutation<UpdateWineInput, Wine>;
export type CreateWineMutation = Mutation<CreateWineInput, Wine>;

interface Props {
	API: WinesAPI;
	defaultWine: Wine;
	updateMutation: UpdateWineMutation;
	createMutation: CreateWineMutation;
}

interface Result {
	wine?: Wine;
	wines: Wine[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveWineInput) => Promise<Wine | undefined>;
}

export const useWinesUseCases: UseCases<Props, Result> = ({ API, defaultWine, updateMutation, createMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveWineInput): input is UpdateWineInput => !!input.id;

	const save: Result["save"] = async (input) => {
		let result: Wine | undefined;

		try {
			setIsLoading(true);
			if (isUpdate(input)) {
				result = await updateMutation(input);
				event.emit("mutationSucceeded", t("wineHasBeenSuccessfullyUpdated"));
			} else {
				result = await createMutation(input);
				event.emit("mutationSucceeded", t("wineHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}

		return result;
	};

	return {
		wine: API.data[0] || defaultWine,
		wines: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
	};
};
