import { UIComponent } from "@aptus/frontend-core";
import { useMainGrapes, useRoutes, useTranslations } from "domains";
import { Button, FormControl, TextField, Select, InputLabel, MenuItem, Box, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import { WineStyleDTO } from "models/schema";
import { MultiLanguage } from "components/form/multilanguage/MultiLanguage";
import BasePage from "pages/basePage/basePage";
import Loader from "components/loader/loader";
import { MainGrape } from "hooks/mainGrapes/models/mainGrape";
import { SaveMainGrapeInput } from "hooks/mainGrapes/models/mainGrapeInput";
import { useGetMainGrape } from "./models/getMainGrape";
import { useMainGrapeForm } from "./useMainGrapeForm";

interface Props {
	mainGrape: MainGrape;
	save: (input: SaveMainGrapeInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const MainGrapeForm: UIComponent<Props> = ({ mainGrape, save, remove }) => {
	const { t } = useTranslations();
	const { control, register, handleFormSubmit, handleDelete, errors } = useMainGrapeForm({ initialValues: mainGrape, submit: save, remove });
	return (
		<form onSubmit={handleFormSubmit}>
			<FormControl {...register("name", { required: { value: true, message: "This is required." } })} fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField error={!!errors?.name} helperText={errors?.name?.message} fullWidth id="name" name="name" label={t("grapeForm.name")} variant="outlined" />
			</FormControl>
			<FormControl error={!!errors?.wineStyle} sx={{ mb: 2 }} fullWidth>
				<InputLabel id="wine_style">{t("wineStyle")}</InputLabel>
				<Controller
					control={control}
					name="wineStyle"
					rules={{ required: { value: true, message: "This is required." } }}
					render={({ field: { onChange, value } }) => (
						<Select
							value={value}
							onChange={(e) => onChange(e.target.value)}
							labelId="wine_style"
							error={errors?.wineStyle}
							label={t("wineStyle")}
							variant="outlined"
							name="wineStyle"
						>
							{Object.values(WineStyleDTO).map((key) => <MenuItem key={key} value={key}>{t(`wineStyle.${key}`)}</MenuItem>)}
						</Select>
					)}
				/>
				{errors?.wineStyle?.message ? <FormHelperText>{errors?.wineStyle?.message}</FormHelperText> : null}
			</FormControl>
			<MultiLanguage register={register} languages={["nl", "en", "fr"]}>
				<TextField sx={{ mb: 2 }} fullWidth id="temperatureDescriptions" rows={5} multiline label={t("mainGrapeForm.temperatureDescription")} variant="outlined" />
				<TextField sx={{ mb: 2 }} fullWidth rows={5} multiline id="foodDescriptions" label={t("mainGrapeForm.foodDescription")} variant="outlined" />
			</MultiLanguage>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{mainGrape.id ? <Button onClick={() => handleDelete(mainGrape.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteMainGrape")}</Button> : undefined}
				<Button type="submit" variant="contained">{mainGrape.id ? t("updateMainGrape") : t("createMainGrape")}</Button>
			</Box>
		</form>
	);
};

export const MainGrapePage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, mainGrape, isLoading } = useMainGrapes(useGetMainGrape({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}
	return <MainGrapeForm save={save} remove={remove} mainGrape={mainGrape} />;
};
