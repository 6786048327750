import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslations } from "domains";
import { WineTypeDTO } from "models/schema";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Result, useWineAndTemperatureSelect, WineAndTemperatureSelectProps, WineTypeAndTemperature } from "./useWineAndTemperatureSelect";
import { MainGrapeSelect } from "../mainGrapeSelect/mainGrapeSelect";

const Layout: UIComponent<Result> = ({ onSelectWine, availableWines, onAddWine, fields, register, name, onRemoveWine, selectedWine, control, showMainGrapeField }) => {
	const { t } = useTranslations();

	return (
		<>
			<h4>{t("addWineType")}</h4>
			<Box sx={{ display: "flex", marginBottom: 2, marginTop: 1 }}>
				<FormControl fullWidth sx={{ mr: 2 }} variant="filled">
					<Select disabled={!availableWines.length} onChange={(e) => onSelectWine(e.target.value as WineTypeDTO)} variant="outlined" name="wineType">
						{availableWines.map((wine) => <MenuItem value={wine}>{t(wine)}</MenuItem>)}
					</Select>
				</FormControl>
				<Button variant="contained" disabled={!availableWines.length || !selectedWine} onClick={onAddWine}><AddIcon /></Button>
			</Box>
			{fields.length ? <h4>{t("selectedWines")}</h4> : null}
			{(fields as unknown as WineTypeAndTemperature[]).map((field: WineTypeAndTemperature, index) => (
				<Box sx={{ display: "flex", marginBottom: 2, marginTop: 2 }}>
					<FormControl {...register(`${name}.${index}.temperature`)} fullWidth sx={{ mr: 2 }} variant="filled">
						<TextField variant="outlined" name={`${name}.${index}.temperature`} type="number" label={t(`${field.type}Temperature`)} />
					</FormControl>
					{showMainGrapeField ? <MainGrapeSelect control={control} name={`${name}.${index}.mainGrape`} label={t("mainGrape")} /> : null}
					<Button sx={{ ml: 2 }} color="error" variant="contained" onClick={() => onRemoveWine(index)}><DeleteIcon /></Button>
				</Box>
			))}
		</>
	);
};

export const WineAndTemperatureSelect: UIComponent<WineAndTemperatureSelectProps> = (props) => (
	<Layout {...useWineAndTemperatureSelect(props)} />
);
