/* eslint-disable */
import * as Types from '../../../models/schema';

import { ClassificationFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateClassificationVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.ClassificationUpdateInputDTO>;
}>;


export type UpdateClassificationDTO = (
  { __typename?: 'Mutation' }
  & { updateOneClassification: (
    { __typename?: 'Classification' }
    & ClassificationFragmentDTO
  ) }
);


export const UpdateClassificationDocumentDTO = gql`
    mutation updateClassification($id: String!, $data: ClassificationUpdateInput) {
  updateOneClassification(id: $id, data: $data) {
    ...classificationFragment
  }
}
    ${ClassificationFragmentDTO}`;
export type UpdateClassificationMutationFnDTO = Apollo.MutationFunction<UpdateClassificationDTO, UpdateClassificationVariablesDTO>;

/**
 * __useUpdateClassification__
 *
 * To run a mutation, you first call `useUpdateClassification` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassification` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassification, { data, loading, error }] = useUpdateClassification({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClassification(baseOptions?: Apollo.MutationHookOptions<UpdateClassificationDTO, UpdateClassificationVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassificationDTO, UpdateClassificationVariablesDTO>(UpdateClassificationDocumentDTO, options);
      }
export type UpdateClassificationHookResult = ReturnType<typeof useUpdateClassification>;
export type UpdateClassificationMutationResultDTO = Apollo.MutationResult<UpdateClassificationDTO>;
export type UpdateClassificationMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateClassificationDTO, UpdateClassificationVariablesDTO>;