import { UIComponent, UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import { useMemo } from "react";
import { Tables } from "../../databaseFilterBar/useDatabaseFilterBar";
import { CountryPage } from "./forms/countryForm/countryForm";
import { DomainPage } from "./forms/domainForm/domainForm";
import { ClassificationPage } from "./forms/classificationForm/classificationForm";
import { RegionPage } from "./forms/regionForm/regionForm";
import { CorrectionPage } from "./forms/correctionForm/correctionForm";
import { AocPage } from "./forms/aocForm/aocForm";
import { ExceptionPage } from "./forms/exceptionForm/exceptionForm";
import { GrapePage } from "./forms/grapeForm/grapeForm";
import { FaqPage } from "./forms/faqForm/faqForm";
import { FoodPairingPage } from "./forms/foodPairingForm/foodPairingForm";
import { MainGrapePage } from "./forms/mainGrapeForm/mainGrapeForm";

interface Result {
	component?: UIComponent<{}>;
}

export const useRecordForm: UILogic<{}, Result> = () => {
	const { currentQueryString: { table, create, selected } } = useRoutes();

	const component = useMemo(() => {
		switch (table) {
			case Tables.Country:
				return CountryPage;
			case Tables.Domain:
				return DomainPage;
			case Tables.Classification:
				return ClassificationPage;
			case Tables.Region:
				return RegionPage;
			case Tables.Correction:
				return CorrectionPage;
			case Tables.AOC:
				return AocPage;
			case Tables.Exception:
				return ExceptionPage;
			case Tables.Grape:
				return GrapePage;
			case Tables.FAQ:
				return FaqPage;
			case Tables.FoodPairing:
				return FoodPairingPage;
			case Tables.MainGrape:
				return MainGrapePage;
			default:
				return undefined;
		}
	}, [table, selected, create]);

	return {
		component,
	};
};
