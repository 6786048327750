import { Query, UILogic } from "@aptus/frontend-core";
import { AutoCompleteSelectProps, getDropdownOptions } from "components/form/autoCompleteSelect/autoCompleteSelect";
import { useDomains, useTranslations } from "domains";
import { Aoc } from "hooks/aocs/models/aoc";
import { Country } from "hooks/countries/models/country";
import { DomainsAPIDTO } from "hooks/domains/domainMapper";
import { Domain } from "hooks/domains/models/domain";
import { Region } from "hooks/regions/models/region";
import { DomainFilterDTO } from "models/schema";
import { useGetDomains } from "./models/getDomains";

export interface DomainSelectProps extends Omit<AutoCompleteSelectProps, "dropdownProps"> {
	country?: string;
	region?: string;
	aoc?: string;
	defaultValue?: string;
}

type Result = AutoCompleteSelectProps;

interface QueryProps {
	countryId?: Country["id"];
	regionId?: Region["id"];
	aocId?: Aoc["id"];
	domainId?: Domain["id"];
}

const getFilter = ({ countryId, regionId, aocId, domainId }: QueryProps): DomainFilterDTO => {
	if (domainId) return { id: { equals: domainId } };

	return {
		archived: { equals: false },
		country: { id: { equals: countryId } },
		...regionId ? { region: { id: { equals: regionId } } } : {},
		...aocId ? { aoc: { id: { equals: aocId } } } : {},
	};
};

const useGetFilteredDomains: Query<QueryProps, DomainsAPIDTO> = ({ countryId, ...rest }) => (
	useGetDomains({ variables: { filter: getFilter({ countryId, ...rest }) }, skip: !countryId })
);

export const useDomainSelect: UILogic<DomainSelectProps, Result> = (props) => {
	const { t } = useTranslations();
	const { domains, isLoading } = useDomains(useGetFilteredDomains({
		countryId: props.country,
		regionId: props.region,
		aocId: props.aoc,
		domainId: props.defaultValue,
	}));

	const options = {
		loading: isLoading,
		options: getDropdownOptions(domains),
	};

	return {
		...props,
		dropdownProps: options,
		noOptionsText: !props.country ? t("beSureToPickACountry") : t("noOptions"),
	};
};
