import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { ClassificationDTO, QueryDTO } from "models/schema";
import { Classification } from "./models/classification";
import { CreateClassificationMutationFnDTO } from "./models/createClassification";
import { DeleteClassificationMutationFnDTO } from "./models/deleteClassification";
import { UpdateClassificationMutationFnDTO } from "./models/updateClassification";
import { ClassificationsAPI, CreateClassificationMutation, DeleteClassificationMutation, UpdateClassificationMutation } from "./useClassificationsUseCases";

export type ClassificationsAPIDTO = ApolloAPI<QueryDTO, "classification" | "classifications">;

interface Mapper {
	toAPI: ToAPI<ClassificationsAPIDTO, ClassificationsAPI>;
	toUpdateMutation: ToInternal<UpdateClassificationMutationFnDTO, UpdateClassificationMutation>;
	toCreateMutation: ToInternal<CreateClassificationMutationFnDTO, CreateClassificationMutation>;
	toDeleteMutation: ToInternal<DeleteClassificationMutationFnDTO, DeleteClassificationMutation>;
	toClassification: ToInternal<DeepPartial<ClassificationDTO>, Classification>;
}

export class ClassificationMapper implements Mapper {
	public toClassification: Mapper["toClassification"] = (classification) => ({
		id: classification.id || "",
		name: classification.name || "",
		type: classification.type || "",
		archived: classification.archived || false,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ClassificationsAPIDTO["data"]): DeepPartial<ClassificationDTO[]> => {
			if (data?.classification) {
				return [data.classification];
			}

			if (data?.classifications) {
				return data.classifications;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toClassification),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateClassificationMutationFnDTO, UpdateClassificationMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						name: input.name,
						type: input.type,
						archived: input.archived,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneClassification ? this.toClassification(output.data.updateOneClassification) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateClassificationMutationFnDTO, CreateClassificationMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						name: input.name,
						type: input.type,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneClassification ? this.toClassification(output.data.createOneClassification) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteClassificationMutationFnDTO, DeleteClassificationMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getClassifications"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: !!output?.data?.updateOneClassification.id }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
