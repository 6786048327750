/* eslint-disable */
import * as Types from '../../../models/schema';

import { AocFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateAocVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.AocUpdateInputDTO>;
}>;


export type UpdateAocDTO = (
  { __typename?: 'Mutation' }
  & { updateOneAoc: (
    { __typename?: 'Aoc' }
    & AocFragmentDTO
  ) }
);


export const UpdateAocDocumentDTO = gql`
    mutation updateAoc($id: String!, $data: AocUpdateInput) {
  updateOneAoc(id: $id, data: $data) {
    ...aocFragment
  }
}
    ${AocFragmentDTO}`;
export type UpdateAocMutationFnDTO = Apollo.MutationFunction<UpdateAocDTO, UpdateAocVariablesDTO>;

/**
 * __useUpdateAoc__
 *
 * To run a mutation, you first call `useUpdateAoc` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAoc` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAoc, { data, loading, error }] = useUpdateAoc({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAoc(baseOptions?: Apollo.MutationHookOptions<UpdateAocDTO, UpdateAocVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAocDTO, UpdateAocVariablesDTO>(UpdateAocDocumentDTO, options);
      }
export type UpdateAocHookResult = ReturnType<typeof useUpdateAoc>;
export type UpdateAocMutationResultDTO = Apollo.MutationResult<UpdateAocDTO>;
export type UpdateAocMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateAocDTO, UpdateAocVariablesDTO>;