import { UILogic } from "@aptus/frontend-core";
import { User } from "hooks/users/models/user";
import { SaveUserInput } from "hooks/users/models/userInput";
import { UseFormTypes } from "pages/databasePage/components/databaseContent/recordForm/forms/useFormTypes";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export interface ProfileFormProps {
	formValues: Partial<User>
}

interface Props {
	initialValues: ProfileFormProps["formValues"];
	submit: (values: SaveUserInput) => void;
}

type Result = UseFormTypes<ProfileFormProps["formValues"]>;

export const useProfileForm: UILogic<Props, Result> = ({ initialValues, submit }) => {
	const { register, handleSubmit, reset, control, formState: { errors } } = useForm<ProfileFormProps["formValues"]>({ defaultValues: initialValues });

	useEffect(() => {
		reset(initialValues);
	}, [initialValues]);

	const handleFormSubmit = (values: ProfileFormProps["formValues"]): void => {
		if (!values.id) {
			return;
		}

		submit({
			id: values.id,
			...values,
		});
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		errors,
	};
};
