import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { QueryDTO, ConversationDTO, ConversationStatusDTO, LanguageDTO } from "models/schema";
import { Conversation } from "./models/conversation";
import { CreateConversationMessageMutationFnDTO } from "./models/createConversationMessage";
import { UpdateConversationMutationFnDTO } from "./models/updateConversation";
import { ChatMutation, ConversationsAPI, UpdateConversationMutation } from "./useConversationsUseCases";

export type ConversationsAPIDTO = ApolloAPI<QueryDTO, "conversation" | "conversations" | "conversationsCount" | "profile">;

interface Mapper {
	toAPI: ToAPI<ConversationsAPIDTO, ConversationsAPI>;
	toUpdateMutation: ToInternal<UpdateConversationMutationFnDTO, UpdateConversationMutation>;
	toChatMutation: ToInternal<CreateConversationMessageMutationFnDTO, ChatMutation>
	toConversation: ToInternal<DeepPartial<ConversationDTO>, Conversation>;
}

export class ConversationMapper implements Mapper {
	public toConversation: Mapper["toConversation"] = (conversation) => {
		const lastMessage = (conversation.messages && conversation.messages.length && conversation.messages[conversation.messages.length - 1]) || null;
		const ownerId = conversation.owner?.id;
		return {
			id: conversation.id || "",
			new: lastMessage?.owner?.id === conversation.owner?.id,
			messages: (conversation.messages || []).map((m) => ({
				content: m.content || "",
				createdAt: m.createdAt ? new Date(m.createdAt) : new Date(),
				isOwner: m.owner?.id === ownerId,
				initials: `${m.owner?.givenName?.[0]}${m.owner?.familyName?.[0]}`,
			})),
			status: conversation.status || ConversationStatusDTO.NewDTO,
			language: conversation.owner?.language || LanguageDTO.EnDTO,
			client: conversation.owner ? `${conversation.owner?.givenName} ${conversation.owner?.familyName}` : "Unknown",
			lastMessage: new Date(lastMessage?.createdAt || 0) || new Date(),
		};
	};

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ConversationsAPIDTO["data"]): DeepPartial<ConversationDTO[] | number> => {
			if (data?.conversation) {
				return [data.conversation];
			}

			if (data?.conversations) {
				return data.conversations;
			}

			if (data?.conversationsCount) {
				return data.conversationsCount || 0;
			}

			return [];
		};

		const data = extractDTOs(api?.data);

		return {
			...api,
			isLoading: api?.loading || false,
			count: api?.data?.conversationsCount,
			data: Array.isArray(data) ? data.map(this.toConversation) : data,
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateConversationMutationFnDTO, UpdateConversationMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						status: input.status,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneConversation ? this.toConversation(output.data.updateOneConversation) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toChatMutation: Mapper["toChatMutation"] = (mutation) => {
		const map: IOMap<CreateConversationMessageMutationFnDTO, ChatMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						content: input.content,
						conversation: input.id,
					},
				},
				refetchQueries: ["getConversation"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ((output.data?.createOneConversationMessage ? this.toConversation(output.data.createOneConversationMessage) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
