/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteFoodVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteFoodDTO = (
  { __typename?: 'Mutation' }
  & { deleteOneFood: (
    { __typename?: 'Success' }
    & Pick<Types.SuccessDTO, 'success'>
  ) }
);


export const DeleteFoodDocumentDTO = gql`
    mutation deleteFood($id: String!) {
  deleteOneFood(id: $id) {
    success
  }
}
    `;
export type DeleteFoodMutationFnDTO = Apollo.MutationFunction<DeleteFoodDTO, DeleteFoodVariablesDTO>;

/**
 * __useDeleteFood__
 *
 * To run a mutation, you first call `useDeleteFood` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFood` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFood, { data, loading, error }] = useDeleteFood({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFood(baseOptions?: Apollo.MutationHookOptions<DeleteFoodDTO, DeleteFoodVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFoodDTO, DeleteFoodVariablesDTO>(DeleteFoodDocumentDTO, options);
      }
export type DeleteFoodHookResult = ReturnType<typeof useDeleteFood>;
export type DeleteFoodMutationResultDTO = Apollo.MutationResult<DeleteFoodDTO>;
export type DeleteFoodMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteFoodDTO, DeleteFoodVariablesDTO>;