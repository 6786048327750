import styled from "styled-components";

export const ChatWindow = styled.div`
	height: 70vh;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	padding: 30px;
`;

export const ChatItem = styled.div<{ isOwner: boolean }>`
	display: flex;
	align-self: flex-start;

	${(props) => !props.isOwner && `
		align-self: flex-end;
		flex-direction: row-reverse;
	`}

	max-width: 80%;

	align-items: flex-end;
	margin-bottom: 20px;
`;

export const ChatAvatar = styled.div`
	height: 50px;
	width: 50px;
	background-color: #095B76;
	color: white;
	padding: 12px;
	border-radius: 50%;
	text-align: center;
`;

export const ChatMessage = styled.div<{ isOwner: boolean, initials: string }>`
	padding: 10px;
	background-color: #F5F5F5;
	margin-left: 10px;
	
	.date {
		font-size: 10px;
		opacity: 0.5;
		display: block;
		text-align: end;
		margin-top: 4px;
	}

	${(props) => !props.isOwner && `
		background-color: #095B76;
		color: white;
		margin-right: 10px;
		margin-left: 0;
	`}
`;
