/* eslint-disable */
import * as Types from '../../../models/schema';

import { FaqFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateFaqVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.FaqUpdateInputDTO>;
}>;


export type UpdateFaqDTO = (
  { __typename?: 'Mutation' }
  & { updateOneFaq: (
    { __typename?: 'Faq' }
    & FaqFragmentDTO
  ) }
);


export const UpdateFaqDocumentDTO = gql`
    mutation updateFaq($id: String!, $data: FaqUpdateInput) {
  updateOneFaq(id: $id, data: $data) {
    ...faqFragment
  }
}
    ${FaqFragmentDTO}`;
export type UpdateFaqMutationFnDTO = Apollo.MutationFunction<UpdateFaqDTO, UpdateFaqVariablesDTO>;

/**
 * __useUpdateFaq__
 *
 * To run a mutation, you first call `useUpdateFaq` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaq` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaq, { data, loading, error }] = useUpdateFaq({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFaq(baseOptions?: Apollo.MutationHookOptions<UpdateFaqDTO, UpdateFaqVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFaqDTO, UpdateFaqVariablesDTO>(UpdateFaqDocumentDTO, options);
      }
export type UpdateFaqHookResult = ReturnType<typeof useUpdateFaq>;
export type UpdateFaqMutationResultDTO = Apollo.MutationResult<UpdateFaqDTO>;
export type UpdateFaqMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateFaqDTO, UpdateFaqVariablesDTO>;