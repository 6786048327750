import { useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import event from "event";
import qs from "query-string";
import { UseCases } from "@aptus/frontend-core";
import { SortOrderDTO } from "models/schema";
import { Routes } from "./models/Routes";

interface Result {
	routes: Routes;
	routeNames: Routes;
	currentUrl: string;
	params: Record<string, string>;
	currentQueryString: Filters;
	goToLogin: () => void;
	goToHome: () => void;
	goToRequestDetail: (id: string, type: string) => void;
	goToDetail: (id: string) => void;
	goToConversationDetail: (id: string) => void;
	goToDatabase: () => void;
	updateQueryString: (values: { [key: string]: string | null | boolean | string[] }) => void;
}

interface Filters {
	table?: string;
	search?: string;
	selected?: string;
	create?: boolean;
	language?: string;
	status?: string;
	date?: string;
	type?: string[];
	page?: string;
	pageSize?: string;
	sort?: SortOrderDTO;
	sortField?: string;
}

export const useRoutesUseCases: UseCases<undefined, Result> = () => {
	const params: Result["params"] = useParams();
	const { pathname, search } = useLocation();
	const history = useHistory();

	const routes: Result["routes"] = {
		home: "/",
		requestDetail: "/detail/:type/:id",
		detail: "/detail",
		login: "/signin",
		database: "/database",
		users: "/users",
		profile: "/profile",
		sommelierSuggestions: "/suggestions",
		conversations: "/conversations",
		conversationDetail: "/conversation/:id",
	};

	const routeNames: Result["routes"] = {
		home: "requests",
		requestDetail: "requestDetail",
		detail: "adviceRequestsDetail",
		login: "signIn",
		database: "database",
		users: "users",
		profile: "profile",
		sommelierSuggestions: "sommelierSuggestions",
		conversations: "conversations",
		conversationDetail: "conversationDetail",
	};

	const goToHome: Result["goToHome"] = () => {
		history.push(routes.home);
	};

	const goToLogin: Result["goToLogin"] = () => {
		history.push(routes.login);
	};

	const goToDetail: Result["goToDetail"] = (id: string) => {
		history.push(`${routes.detail}/${id}`);
	};

	const goToRequestDetail: Result["goToRequestDetail"] = (id: string, type: string) => {
		history.push(routes.requestDetail.replace(":id", id).replace(":type", type));
	};

	const goToConversationDetail: Result["goToConversationDetail"] = (id: string) => {
		history.push(routes.conversationDetail.replace(":id", id));
	};

	const goToDatabase: Result["goToDatabase"] = () => {
		history.push(routes.database);
	};

	const currentFilter: Filters = useMemo(() => qs.parse(search, { parseBooleans: true, arrayFormat: "comma" }), [search]);

	const updateQueryString = (values: { [key: string]: string | null | boolean | string[] }) => {
		history.push({
			search: qs.stringify({ ...currentFilter, ...values }, { arrayFormat: "comma" }),
		});
	};

	return {
		routes,
		routeNames,
		currentUrl: pathname,
		params,
		currentQueryString: currentFilter,
		goToHome,
		goToLogin,
		goToDetail,
		goToDatabase,
		goToRequestDetail,
		goToConversationDetail,
		updateQueryString,
	};
};

export const useRoutesEvents = (routes: ReturnType<UseCases<undefined, Result>>): void => {
	useEffect(() => {
		event.on("userLoggedIn", routes.goToHome);
		event.on("userLoggedOut", routes.goToLogin);

		return () => {
			event.removeListener("userLoggedIn", routes.goToHome);
			event.removeListener("userLoggedOut", routes.goToLogin);
		};
	}, []);
};
