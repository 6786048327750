import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { ExceptionDTO, QueryDTO, WineTypeDTO } from "models/schema";
import { CreateExceptionMutationFnDTO } from "./models/createException";
import { DeleteExceptionMutationFnDTO } from "./models/deleteException";
import { Exception } from "./models/exception";
import { UpdateExceptionMutationFnDTO } from "./models/updateException";
import { CreateExceptionMutation, DeleteExceptionMutation, ExceptionsAPI, UpdateExceptionMutation } from "./useExceptionsUseCases";

export type ExceptionsAPIDTO = ApolloAPI<QueryDTO, "exceptions" | "exception">;

interface Mapper {
	toAPI: ToAPI<ExceptionsAPIDTO, ExceptionsAPI>;
	toUpdateMutation: ToInternal<UpdateExceptionMutationFnDTO, UpdateExceptionMutation>;
	toCreateMutation: ToInternal<CreateExceptionMutationFnDTO, CreateExceptionMutation>;
	toDeleteMutation: ToInternal<DeleteExceptionMutationFnDTO, DeleteExceptionMutation>
	toException: ToInternal<DeepPartial<ExceptionDTO>, Exception>;
}

export class ExceptionMapper implements Mapper {
	public toException: Mapper["toException"] = (exception) => ({
		id: exception.id || "",
		text: exception.text || "",
		aoc: exception.aoc?.id,
		country: exception.country?.id,
		region: exception.region?.id,
		grape: exception.grape?.id,
		domain: exception.domain?.id,
		temperatures: (exception.temperatures || []).map((item) => ({ type: item.type || WineTypeDTO.RedDTO, temperature: item.temperature })),
		archived: exception.archived || false,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ExceptionsAPIDTO["data"]): DeepPartial<ExceptionDTO[]> => {
			if (data?.exception) {
				return [data.exception];
			}

			if (data?.exceptions) {
				return data.exceptions;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toException),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateExceptionMutationFnDTO, UpdateExceptionMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						text: input.text,
						aoc: input.aoc,
						country: input.country,
						region: input.region,
						grape: input.grape,
						domain: input.domain,
						temperatures: input.temperatures,
						archived: input.archived || false,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneException ? this.toException(output.data.updateOneException) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateExceptionMutationFnDTO, CreateExceptionMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						text: input.text,
						aoc: input.aoc,
						country: input.country,
						region: input.region,
						grape: input.grape,
						domain: input.domain,
						temperatures: input.temperatures,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneException ? this.toException(output.data.createOneException) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteExceptionMutationFnDTO, DeleteExceptionMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getExceptions"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: output?.data?.deleteOneException.success || false }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
