/* eslint-disable */
import * as Types from '../../../models/schema';

import { RegionFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateRegionVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data?: Types.Maybe<Types.RegionUpdateInputDTO>;
}>;


export type UpdateRegionDTO = (
  { __typename?: 'Mutation' }
  & { updateOneRegion: (
    { __typename?: 'Region' }
    & RegionFragmentDTO
  ) }
);


export const UpdateRegionDocumentDTO = gql`
    mutation updateRegion($id: String!, $data: RegionUpdateInput) {
  updateOneRegion(id: $id, data: $data) {
    ...regionFragment
  }
}
    ${RegionFragmentDTO}`;
export type UpdateRegionMutationFnDTO = Apollo.MutationFunction<UpdateRegionDTO, UpdateRegionVariablesDTO>;

/**
 * __useUpdateRegion__
 *
 * To run a mutation, you first call `useUpdateRegion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegion, { data, loading, error }] = useUpdateRegion({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRegion(baseOptions?: Apollo.MutationHookOptions<UpdateRegionDTO, UpdateRegionVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegionDTO, UpdateRegionVariablesDTO>(UpdateRegionDocumentDTO, options);
      }
export type UpdateRegionHookResult = ReturnType<typeof useUpdateRegion>;
export type UpdateRegionMutationResultDTO = Apollo.MutationResult<UpdateRegionDTO>;
export type UpdateRegionMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateRegionDTO, UpdateRegionVariablesDTO>;