/* eslint-disable */
import * as Types from '../../../../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFaqVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetFaqDTO = (
  { __typename?: 'Query' }
  & { faq: (
    { __typename?: 'Faq' }
    & Pick<Types.FaqDTO, 'id'>
    & { titles?: Types.Maybe<Array<(
      { __typename?: 'MultiLanguageText' }
      & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
    )>>, descriptions?: Types.Maybe<Array<(
      { __typename?: 'MultiLanguageText' }
      & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
    )>> }
  ) }
);


export const GetFaqDocumentDTO = gql`
    query getFaq($id: String!) {
  faq(id: $id) {
    id
    titles {
      language
      text
    }
    descriptions {
      language
      text
    }
  }
}
    `;

/**
 * __useGetFaq__
 *
 * To run a query within a React component, call `useGetFaq` and pass it any options that fit your needs.
 * When your component renders, `useGetFaq` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaq({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFaq(baseOptions: Apollo.QueryHookOptions<GetFaqDTO, GetFaqVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaqDTO, GetFaqVariablesDTO>(GetFaqDocumentDTO, options);
      }
export function useGetFaqLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetFaqDTO, GetFaqVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaqDTO, GetFaqVariablesDTO>(GetFaqDocumentDTO, options);
        }
export type GetFaqHookResult = ReturnType<typeof useGetFaq>;
export type GetFaqLazyQueryDTOHookResult = ReturnType<typeof useGetFaqLazyQueryDTO>;
export type GetFaqQueryResultDTO = Apollo.QueryResult<GetFaqDTO, GetFaqVariablesDTO>;