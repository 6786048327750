/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type DeleteCountryVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteCountryDTO = (
  { __typename?: 'Mutation' }
  & { updateOneCountry: (
    { __typename?: 'Country' }
    & Pick<Types.CountryDTO, 'id'>
  ) }
);


export const DeleteCountryDocumentDTO = gql`
    mutation deleteCountry($id: String!) {
  updateOneCountry(id: $id, data: {archived: true}) {
    id
  }
}
    `;
export type DeleteCountryMutationFnDTO = Apollo.MutationFunction<DeleteCountryDTO, DeleteCountryVariablesDTO>;

/**
 * __useDeleteCountry__
 *
 * To run a mutation, you first call `useDeleteCountry` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCountry` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCountry, { data, loading, error }] = useDeleteCountry({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCountry(baseOptions?: Apollo.MutationHookOptions<DeleteCountryDTO, DeleteCountryVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCountryDTO, DeleteCountryVariablesDTO>(DeleteCountryDocumentDTO, options);
      }
export type DeleteCountryHookResult = ReturnType<typeof useDeleteCountry>;
export type DeleteCountryMutationResultDTO = Apollo.MutationResult<DeleteCountryDTO>;
export type DeleteCountryMutationOptionsDTO = Apollo.BaseMutationOptions<DeleteCountryDTO, DeleteCountryVariablesDTO>;