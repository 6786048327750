/* eslint-disable */
import * as Types from '../../models/schema';

import { gql } from '@apollo/client';
export type WineDescriptionFragmentDTO = (
  { __typename?: 'WineDescription' }
  & Pick<Types.WineDescriptionDTO, 'grapeText' | 'aocText' | 'regionText' | 'countryText' | 'domainText' | 'classificationText'>
  & { grape?: Types.Maybe<(
    { __typename?: 'Grape' }
    & Pick<Types.GrapeDTO, 'id' | 'name'>
    & { temperatures?: Types.Maybe<Array<(
      { __typename?: 'TypeTemperature' }
      & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  )>, aoc?: Types.Maybe<(
    { __typename?: 'Aoc' }
    & Pick<Types.AocDTO, 'id' | 'name'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Types.CountryDTO, 'id'>
    ), temperatures?: Types.Maybe<Array<(
      { __typename?: 'AocTypeTemperature' }
      & Pick<Types.AocTypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  )>, region?: Types.Maybe<(
    { __typename?: 'Region' }
    & Pick<Types.RegionDTO, 'id' | 'name'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Types.CountryDTO, 'id'>
    ), temperatures?: Types.Maybe<Array<(
      { __typename?: 'TypeTemperature' }
      & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  )>, country?: Types.Maybe<(
    { __typename?: 'Country' }
    & Pick<Types.CountryDTO, 'id' | 'name'>
    & { temperatures?: Types.Maybe<Array<(
      { __typename?: 'TypeTemperature' }
      & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
    )>> }
  )>, domain?: Types.Maybe<(
    { __typename?: 'Domain' }
    & Pick<Types.DomainDTO, 'id' | 'name'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Types.CountryDTO, 'id'>
    ) }
  )>, classification?: Types.Maybe<(
    { __typename?: 'Classification' }
    & Pick<Types.ClassificationDTO, 'id' | 'name'>
  )> }
);

export type WineFragmentDTO = (
  { __typename?: 'Wine' }
  & Pick<Types.WineDTO, 'id' | 'type' | 'archived' | 'year' | 'idealTemp' | 'customTemp' | 'amount' | 'image' | 'rating' | 'comment'>
  & { description: (
    { __typename?: 'WineDescription' }
    & WineDescriptionFragmentDTO
  ) }
);

export type AdviceRequestFragment_BuyingAdviceRequest_DTO = (
  { __typename?: 'BuyingAdviceRequest' }
  & Pick<Types.BuyingAdviceRequestDTO, 'id' | 'status' | 'createdAt' | 'type'>
  & { wine: (
    { __typename?: 'Wine' }
    & WineFragmentDTO
  ), deliveryAddress: (
    { __typename?: 'DeliveryAddress' }
    & Pick<Types.DeliveryAddressDTO, 'givenName' | 'familyName' | 'phoneNumber' | 'email'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Types.AddressDTO, 'street' | 'houseNumber' | 'postalCode' | 'city' | 'country'>
    ) }
  ), owner: (
    { __typename?: 'User' }
    & Pick<Types.UserDTO, 'id' | 'givenName' | 'familyName' | 'email' | 'language'>
  ) }
);

export type AdviceRequestFragment_ScanAdviceRequest_DTO = (
  { __typename?: 'ScanAdviceRequest' }
  & Pick<Types.ScanAdviceRequestDTO, 'comment' | 'internalComment' | 'id' | 'status' | 'createdAt' | 'type'>
  & { scan: (
    { __typename?: 'Scan' }
    & Pick<Types.ScanDTO, 'id' | 'image' | 'used' | 'wineType'>
    & { advices?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'ScanAdvice' }
      & Pick<Types.ScanAdviceDTO, 'idealTemp' | 'year'>
      & { description?: Types.Maybe<(
        { __typename?: 'WineDescription' }
        & WineDescriptionFragmentDTO
      )> }
    )>>> }
  ), wineResult?: Types.Maybe<(
    { __typename?: 'Wine' }
    & WineFragmentDTO
  )>, owner: (
    { __typename?: 'User' }
    & Pick<Types.UserDTO, 'id' | 'givenName' | 'familyName' | 'email' | 'language'>
  ) }
);

export type AdviceRequestFragmentDTO = AdviceRequestFragment_BuyingAdviceRequest_DTO | AdviceRequestFragment_ScanAdviceRequest_DTO;

export type UserFragmentDTO = (
  { __typename?: 'User' }
  & Pick<Types.UserDTO, 'id' | 'email' | 'language' | 'givenName' | 'familyName' | 'company' | 'business' | 'country' | 'tempUnit' | 'roles'>
);

export type CountryFragmentDTO = (
  { __typename?: 'Country' }
  & Pick<Types.CountryDTO, 'id' | 'name' | 'archived'>
  & { temperatures?: Types.Maybe<Array<(
    { __typename?: 'TypeTemperature' }
    & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
  )>> }
);

export type DomainFragmentDTO = (
  { __typename?: 'Domain' }
  & Pick<Types.DomainDTO, 'id' | 'name' | 'archived'>
  & { aoc?: Types.Maybe<(
    { __typename?: 'Aoc' }
    & Pick<Types.AocDTO, 'id'>
  )>, region?: Types.Maybe<(
    { __typename?: 'Region' }
    & Pick<Types.RegionDTO, 'id'>
  )>, country: (
    { __typename?: 'Country' }
    & Pick<Types.CountryDTO, 'id'>
  ) }
);

export type ClassificationFragmentDTO = (
  { __typename?: 'Classification' }
  & Pick<Types.ClassificationDTO, 'id' | 'name' | 'type' | 'archived'>
);

export type RegionFragmentDTO = (
  { __typename?: 'Region' }
  & Pick<Types.RegionDTO, 'id' | 'name' | 'archived'>
  & { country: (
    { __typename?: 'Country' }
    & Pick<Types.CountryDTO, 'id'>
  ), temperatures?: Types.Maybe<Array<(
    { __typename?: 'TypeTemperature' }
    & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
  )>> }
);

export type CorrectionFragmentDTO = (
  { __typename?: 'Correction' }
  & Pick<Types.CorrectionDTO, 'id' | 'originalText' | 'replacement'>
);

export type AocFragmentDTO = (
  { __typename?: 'Aoc' }
  & Pick<Types.AocDTO, 'id' | 'name' | 'archived'>
  & { region?: Types.Maybe<(
    { __typename?: 'Region' }
    & Pick<Types.RegionDTO, 'id'>
  )>, country: (
    { __typename?: 'Country' }
    & Pick<Types.CountryDTO, 'id'>
  ), temperatures?: Types.Maybe<Array<(
    { __typename?: 'AocTypeTemperature' }
    & Pick<Types.AocTypeTemperatureDTO, 'type' | 'temperature'>
    & { mainGrape?: Types.Maybe<(
      { __typename?: 'MainGrape' }
      & Pick<Types.MainGrapeDTO, 'id'>
    )>, temperatureDescriptions?: Types.Maybe<Array<(
      { __typename?: 'MultiLanguageText' }
      & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
    )>> }
  )>> }
);

export type ExceptionFragmentDTO = (
  { __typename?: 'Exception' }
  & Pick<Types.ExceptionDTO, 'id' | 'text' | 'archived'>
  & { aoc?: Types.Maybe<(
    { __typename?: 'Aoc' }
    & Pick<Types.AocDTO, 'id'>
  )>, country?: Types.Maybe<(
    { __typename?: 'Country' }
    & Pick<Types.CountryDTO, 'id'>
  )>, region?: Types.Maybe<(
    { __typename?: 'Region' }
    & Pick<Types.RegionDTO, 'id'>
  )>, grape?: Types.Maybe<(
    { __typename?: 'Grape' }
    & Pick<Types.GrapeDTO, 'id'>
  )>, domain?: Types.Maybe<(
    { __typename?: 'Domain' }
    & Pick<Types.DomainDTO, 'id'>
  )>, temperatures?: Types.Maybe<Array<(
    { __typename?: 'TypeTemperature' }
    & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
  )>> }
);

export type GrapeFragmentDTO = (
  { __typename?: 'Grape' }
  & Pick<Types.GrapeDTO, 'id' | 'name' | 'archived'>
  & { temperatures?: Types.Maybe<Array<(
    { __typename?: 'TypeTemperature' }
    & Pick<Types.TypeTemperatureDTO, 'type' | 'temperature'>
  )>> }
);

export type MainGrapeFragmentDTO = (
  { __typename?: 'MainGrape' }
  & Pick<Types.MainGrapeDTO, 'id' | 'name' | 'wineStyle' | 'archived'>
  & { temperatureDescriptions?: Types.Maybe<Array<(
    { __typename?: 'MultiLanguageText' }
    & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
  )>>, foodDescriptions?: Types.Maybe<Array<(
    { __typename?: 'MultiLanguageText' }
    & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
  )>> }
);

export type FaqFragmentDTO = (
  { __typename?: 'Faq' }
  & Pick<Types.FaqDTO, 'id'>
  & { titles?: Types.Maybe<Array<(
    { __typename?: 'MultiLanguageText' }
    & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
  )>>, descriptions?: Types.Maybe<Array<(
    { __typename?: 'MultiLanguageText' }
    & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
  )>> }
);

export type FoodFragmentDTO = (
  { __typename?: 'Food' }
  & Pick<Types.FoodDTO, 'id' | 'course' | 'kitchen' | 'ingredients' | 'wineStyle' | 'title' | 'description'>
  & { titles?: Types.Maybe<Array<(
    { __typename?: 'MultiLanguageText' }
    & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
  )>>, descriptions?: Types.Maybe<Array<(
    { __typename?: 'MultiLanguageText' }
    & Pick<Types.MultiLanguageTextDTO, 'language' | 'text'>
  )>> }
);

export type SuggestionFragment_DomainSuggestion_DTO = (
  { __typename?: 'DomainSuggestion' }
  & Pick<Types.DomainSuggestionDTO, 'id' | 'createdAt' | 'type' | 'status' | 'title'>
  & { owner: (
    { __typename?: 'User' }
    & Pick<Types.UserDTO, 'id'>
  ), source: (
    { __typename?: 'AdviceRequestSuggestionSource' }
    & Pick<Types.AdviceRequestSuggestionSourceDTO, 'type'>
    & { adviceRequest: (
      { __typename?: 'BuyingAdviceRequest' }
      & AdviceRequestFragment_BuyingAdviceRequest_DTO
    ) | (
      { __typename?: 'ScanAdviceRequest' }
      & AdviceRequestFragment_ScanAdviceRequest_DTO
    ) }
  ) | (
    { __typename?: 'ConversationSuggestionSource' }
    & Pick<Types.ConversationSuggestionSourceDTO, 'type'>
    & { conversation: (
      { __typename?: 'Conversation' }
      & Pick<Types.ConversationDTO, 'id'>
    ) }
  ) }
);

export type SuggestionFragment_FaqSuggestion_DTO = (
  { __typename?: 'FaqSuggestion' }
  & Pick<Types.FaqSuggestionDTO, 'id' | 'createdAt' | 'type' | 'status' | 'title' | 'description'>
  & { owner: (
    { __typename?: 'User' }
    & Pick<Types.UserDTO, 'id'>
  ), source: (
    { __typename?: 'AdviceRequestSuggestionSource' }
    & Pick<Types.AdviceRequestSuggestionSourceDTO, 'type'>
    & { adviceRequest: (
      { __typename?: 'BuyingAdviceRequest' }
      & AdviceRequestFragment_BuyingAdviceRequest_DTO
    ) | (
      { __typename?: 'ScanAdviceRequest' }
      & AdviceRequestFragment_ScanAdviceRequest_DTO
    ) }
  ) | (
    { __typename?: 'ConversationSuggestionSource' }
    & Pick<Types.ConversationSuggestionSourceDTO, 'type'>
    & { conversation: (
      { __typename?: 'Conversation' }
      & Pick<Types.ConversationDTO, 'id'>
    ) }
  ) }
);

export type SuggestionFragmentDTO = SuggestionFragment_DomainSuggestion_DTO | SuggestionFragment_FaqSuggestion_DTO;

export const UserFragmentDTO = gql`
    fragment userFragment on User {
  id
  email
  language
  givenName
  familyName
  company
  business
  country
  tempUnit
  roles
}
    `;
export const CountryFragmentDTO = gql`
    fragment countryFragment on Country {
  id
  name
  temperatures {
    type
    temperature
  }
  archived
}
    `;
export const DomainFragmentDTO = gql`
    fragment domainFragment on Domain {
  id
  name
  aoc {
    id
  }
  region {
    id
  }
  country {
    id
  }
  archived
}
    `;
export const ClassificationFragmentDTO = gql`
    fragment classificationFragment on Classification {
  id
  name
  type
  archived
}
    `;
export const RegionFragmentDTO = gql`
    fragment regionFragment on Region {
  id
  name
  country {
    id
  }
  temperatures {
    type
    temperature
  }
  archived
}
    `;
export const CorrectionFragmentDTO = gql`
    fragment correctionFragment on Correction {
  id
  originalText
  replacement
}
    `;
export const AocFragmentDTO = gql`
    fragment aocFragment on Aoc {
  id
  name
  region {
    id
  }
  country {
    id
  }
  temperatures {
    type
    temperature
    mainGrape {
      id
    }
    temperatureDescriptions {
      language
      text
    }
  }
  archived
}
    `;
export const ExceptionFragmentDTO = gql`
    fragment exceptionFragment on Exception {
  id
  text
  aoc {
    id
  }
  country {
    id
  }
  region {
    id
  }
  grape {
    id
  }
  domain {
    id
  }
  temperatures {
    type
    temperature
  }
  archived
}
    `;
export const GrapeFragmentDTO = gql`
    fragment grapeFragment on Grape {
  id
  name
  temperatures {
    type
    temperature
  }
  archived
}
    `;
export const MainGrapeFragmentDTO = gql`
    fragment mainGrapeFragment on MainGrape {
  id
  name
  wineStyle
  archived
  temperatureDescriptions {
    language
    text
  }
  foodDescriptions {
    language
    text
  }
}
    `;
export const FaqFragmentDTO = gql`
    fragment faqFragment on Faq {
  id
  titles {
    language
    text
  }
  descriptions {
    language
    text
  }
}
    `;
export const FoodFragmentDTO = gql`
    fragment foodFragment on Food {
  id
  course
  kitchen
  ingredients
  wineStyle
  title
  titles {
    language
    text
  }
  description
  descriptions {
    language
    text
  }
}
    `;
export const WineDescriptionFragmentDTO = gql`
    fragment wineDescriptionFragment on WineDescription {
  grape {
    id
    name
    temperatures {
      type
      temperature
    }
  }
  grapeText
  aoc {
    id
    name
    country {
      id
    }
    temperatures {
      type
      temperature
    }
  }
  aocText
  region {
    id
    name
    country {
      id
    }
    temperatures {
      type
      temperature
    }
  }
  regionText
  country {
    id
    name
    temperatures {
      type
      temperature
    }
  }
  countryText
  domain {
    id
    name
    country {
      id
    }
  }
  domainText
  classification {
    id
    name
  }
  classificationText
}
    `;
export const WineFragmentDTO = gql`
    fragment wineFragment on Wine {
  id
  description {
    ...wineDescriptionFragment
  }
  type
  archived
  year
  idealTemp
  customTemp
  amount
  image
  rating
  comment
}
    ${WineDescriptionFragmentDTO}`;
export const AdviceRequestFragmentDTO = gql`
    fragment adviceRequestFragment on AdviceRequest {
  id
  status
  createdAt
  type
  owner {
    id
    givenName
    familyName
    email
    language
  }
  ... on ScanAdviceRequest {
    comment
    internalComment
    scan {
      id
      image
      used
      wineType
      advices {
        idealTemp
        year
        description {
          ...wineDescriptionFragment
        }
      }
    }
    wineResult {
      ...wineFragment
    }
  }
  ... on BuyingAdviceRequest {
    wine {
      ...wineFragment
    }
    deliveryAddress {
      givenName
      familyName
      address {
        street
        houseNumber
        postalCode
        city
        country
      }
      phoneNumber
      email
    }
  }
}
    ${WineDescriptionFragmentDTO}
${WineFragmentDTO}`;
export const SuggestionFragmentDTO = gql`
    fragment suggestionFragment on Suggestion {
  source {
    ... on ConversationSuggestionSource {
      type
      conversation {
        id
      }
    }
    ... on AdviceRequestSuggestionSource {
      type
      adviceRequest {
        ...adviceRequestFragment
      }
    }
  }
  ... on DomainSuggestion {
    id
    createdAt
    type
    status
    owner {
      id
    }
    title
  }
  ... on FaqSuggestion {
    id
    createdAt
    type
    status
    owner {
      id
    }
    title
    description
  }
}
    ${AdviceRequestFragmentDTO}`;