import { UILogic } from "@aptus/frontend-core";
import { WineTypeDTO } from "models/schema";
import React from "react";
import { Control, FieldArrayWithId, useFieldArray, UseFormRegister } from "react-hook-form";

export interface WineAndTemperatureSelectProps {
	name: string;
	showMainGrapeField?: boolean;
	control: Control<any, any>
	register: UseFormRegister<any>;
}

export interface WineTypeAndTemperature {
	type: WineTypeDTO;
	temperature?: number;
}

export interface Result extends WineAndTemperatureSelectProps {
	availableWines: WineTypeDTO[];
	fields: FieldArrayWithId<WineTypeAndTemperature>[];
	selectedWine?: WineTypeDTO;
	onSelectWine: (wine: WineTypeDTO) => void;
	onAddWine: () => void;
	onRemoveWine: (index: number) => void;
}

export const useWineAndTemperatureSelect: UILogic<WineAndTemperatureSelectProps, Result> = (props) => {
	const [selectedWine, setSelectedWine] = React.useState<WineTypeDTO>();

	const { fields, append, remove } = useFieldArray({
		control: props.control,
		name: props.name,
	});

	const availableWines = React.useMemo(
		() => [WineTypeDTO.RedDTO, WineTypeDTO.WhiteDTO, WineTypeDTO.PinkDTO, WineTypeDTO.SparklingDTO].filter((item) => !(fields as unknown as WineTypeAndTemperature[]).map((f) => f.type).includes(item)),
		[fields],
	);
	const onSelectWine: Result["onSelectWine"] = (wine) => {
		setSelectedWine(wine);
	};

	const onAddWine: Result["onAddWine"] = () => {
		append({
			type: selectedWine,
			temperature: undefined,
		});

		setSelectedWine(undefined);
	};

	const onRemoveWine: Result["onRemoveWine"] = (index) => {
		remove(index);
	};

	return {
		...props,
		onSelectWine,
		onAddWine,
		onRemoveWine,
		selectedWine,
		availableWines,
		fields,
	};
};
