import { UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";
import { Country } from "hooks/countries/models/country";
import { SaveCountryInput } from "hooks/countries/models/countryInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UseFormTypes } from "../useFormTypes";

interface Props {
	initialValues?: Country;
	submit: (values: SaveCountryInput) => void;
	remove: (id: string) => void;
}

interface Result extends UseFormTypes<Country> {
	handleDelete: (id: string) => void;
}

export const useCountryForm: UILogic<Props, Result> = ({ initialValues, submit, remove }) => {
	const { currentQueryString: { selected, create }, updateQueryString } = useRoutes();

	const defaultValues = {
		...initialValues,
		id: selected,
	};

	const { register, handleSubmit, control, reset, formState: { errors } } = useForm<Country>({ defaultValues });

	useEffect(() => {
		reset(defaultValues);
	}, [create, initialValues]);

	const handleFormSubmit = (values: Country): void => {
		submit({
			...values,
			temperatures: values.temperatures.map((temp) => ({ ...temp, temperature: temp.temperature ? Number(temp.temperature) : undefined })),
		});
	};

	const handleDelete = (id: string) => {
		remove(id);
		updateQueryString({ selected: null, create: true });
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		handleDelete,
		errors,
	};
};
