import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { MainGrape } from "./models/mainGrape";
import { CreateMainGrapeInput, DeleteMainGrapeInput, SaveMainGrapeInput, UpdateMainGrapeInput } from "./models/mainGrapeInput";

export type MainGrapesAPI = InternalAPI<MainGrape[]>;

export type UpdateMainGrapeMutation = Mutation<UpdateMainGrapeInput, MainGrape>;
export type CreateMainGrapeMutation = Mutation<CreateMainGrapeInput, MainGrape>;
export type DeleteMainGrapeMutation = Mutation<DeleteMainGrapeInput, Success>;

interface Props {
	API: MainGrapesAPI;
	defaultMainGrape: MainGrape;
	updateMutation: UpdateMainGrapeMutation;
	createMutation: CreateMainGrapeMutation;
	deleteMutation: DeleteMainGrapeMutation;
}
interface Result {
	mainGrape: MainGrape;
	mainGrapes: MainGrape[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveMainGrapeInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useMainGrapesUseCases: UseCases<Props, Result> = ({ API, defaultMainGrape, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveMainGrapeInput): input is UpdateMainGrapeInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("mainGrapeHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("mainGrapeHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("mainGrapeHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		mainGrape: API.data[0] || defaultMainGrape,
		mainGrapes: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
