/* eslint-disable */
import * as Types from '../../../../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAocByIdVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetAocByIdDTO = (
  { __typename?: 'Query' }
  & { aoc: (
    { __typename?: 'Aoc' }
    & Pick<Types.AocDTO, 'id'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Types.CountryDTO, 'id'>
    ), region?: Types.Maybe<(
      { __typename?: 'Region' }
      & Pick<Types.RegionDTO, 'id'>
    )> }
  ) }
);


export const GetAocByIdDocumentDTO = gql`
    query getAocById($id: String!) {
  aoc(id: $id) {
    id
    country {
      id
    }
    region {
      id
    }
  }
}
    `;

/**
 * __useGetAocById__
 *
 * To run a query within a React component, call `useGetAocById` and pass it any options that fit your needs.
 * When your component renders, `useGetAocById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAocById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAocById(baseOptions: Apollo.QueryHookOptions<GetAocByIdDTO, GetAocByIdVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAocByIdDTO, GetAocByIdVariablesDTO>(GetAocByIdDocumentDTO, options);
      }
export function useGetAocByIdLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetAocByIdDTO, GetAocByIdVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAocByIdDTO, GetAocByIdVariablesDTO>(GetAocByIdDocumentDTO, options);
        }
export type GetAocByIdHookResult = ReturnType<typeof useGetAocById>;
export type GetAocByIdLazyQueryDTOHookResult = ReturnType<typeof useGetAocByIdLazyQueryDTO>;
export type GetAocByIdQueryResultDTO = Apollo.QueryResult<GetAocByIdDTO, GetAocByIdVariablesDTO>;