import { DeepPartial, IOMap, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { FaqDTO, LanguageDTO, QueryDTO } from "models/schema";
import { toAPIMultiLanguage, toFormMultiLanguage } from "utils/MLtoFMLI";
import { CreateFaqMutationFnDTO } from "./models/createFaq";
import { DeleteFaqMutationFnDTO } from "./models/deleteFaq";
import { Faq } from "./models/faq";
import { UpdateFaqMutationFnDTO } from "./models/updateFaq";
import { CreateFaqMutation, DeleteFaqMutation, FaqsAPI, UpdateFaqMutation } from "./useFaqsUseCases";

export type FaqsAPIDTO = ApolloAPI<QueryDTO, "faqs" | "faq">;

interface Mapper {
	toAPI: ToAPI<FaqsAPIDTO, FaqsAPI>;
	toUpdateMutation: ToInternal<UpdateFaqMutationFnDTO, UpdateFaqMutation>;
	toCreateMutation: ToInternal<CreateFaqMutationFnDTO, CreateFaqMutation>;
	toDeleteMutation: ToInternal<DeleteFaqMutationFnDTO, DeleteFaqMutation>;
	toFaq: ToInternal<DeepPartial<FaqDTO>, Faq>;
}

export class FaqMapper implements Mapper {
	public toFaq: Mapper["toFaq"] = (faq) => ({
		id: faq.id || "",
		titles: toFormMultiLanguage(
			(faq.titles || []).map((t) => ({ language: t.language || LanguageDTO.EnDTO, text: t.text || "" })),
		),
		descriptions: toFormMultiLanguage(
			(faq.descriptions || []).map((d) => ({ language: d.language || LanguageDTO.EnDTO, text: d.text || "" })),
		),
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: FaqsAPIDTO["data"]): DeepPartial<FaqDTO[]> => {
			if (data?.faq) {
				return [data.faq];
			}

			if (data?.faqs) {
				return data.faqs;
			}

			return [];
		};

		return {
			...api,
			isLoading: api?.loading || false,
			data: extractDTOs(api?.data).map(this.toFaq),
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateFaqMutationFnDTO, UpdateFaqMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						titles: toAPIMultiLanguage(input.titles),
						descriptions: toAPIMultiLanguage(input.descriptions),
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneFaq ? this.toFaq(output.data.updateOneFaq) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateFaqMutationFnDTO, CreateFaqMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						titles: toAPIMultiLanguage(input.titles),
						descriptions: toAPIMultiLanguage(input.descriptions),
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneFaq ? this.toFaq(output.data.createOneFaq) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	public toDeleteMutation: Mapper["toDeleteMutation"] = (mutation) => {
		const map: IOMap<DeleteFaqMutationFnDTO, DeleteFaqMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
				},
				refetchQueries: ["getFaqs"],
				awaitRefetchQueries: true,
			}),
			toOutput: (output) => ({ success: output?.data?.deleteOneFaq.success || false }),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
