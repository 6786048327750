/* eslint-disable */
import * as Types from '../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetConversationVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetConversationDTO = (
  { __typename?: 'Query' }
  & { conversation: (
    { __typename?: 'Conversation' }
    & Pick<Types.ConversationDTO, 'id' | 'status'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<Types.UserDTO, 'id' | 'givenName' | 'familyName'>
    ), messages: Array<(
      { __typename?: 'ConversationMessage' }
      & Pick<Types.ConversationMessageDTO, 'content' | 'createdAt'>
      & { owner: (
        { __typename?: 'User' }
        & Pick<Types.UserDTO, 'id' | 'givenName' | 'familyName'>
      ) }
    )> }
  ) }
);


export const GetConversationDocumentDTO = gql`
    query getConversation($id: String!) {
  conversation(id: $id) {
    id
    owner {
      id
      givenName
      familyName
    }
    status
    messages {
      content
      createdAt
      owner {
        id
        givenName
        familyName
      }
    }
  }
}
    `;

/**
 * __useGetConversation__
 *
 * To run a query within a React component, call `useGetConversation` and pass it any options that fit your needs.
 * When your component renders, `useGetConversation` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConversation(baseOptions: Apollo.QueryHookOptions<GetConversationDTO, GetConversationVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationDTO, GetConversationVariablesDTO>(GetConversationDocumentDTO, options);
      }
export function useGetConversationLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationDTO, GetConversationVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationDTO, GetConversationVariablesDTO>(GetConversationDocumentDTO, options);
        }
export type GetConversationHookResult = ReturnType<typeof useGetConversation>;
export type GetConversationLazyQueryDTOHookResult = ReturnType<typeof useGetConversationLazyQueryDTO>;
export type GetConversationQueryResultDTO = Apollo.QueryResult<GetConversationDTO, GetConversationVariablesDTO>;