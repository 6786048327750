import { UIComponent } from "@aptus/frontend-core";
import { Button, FormControl, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { MainGrapeSelect } from "components/form/items/mainGrapeSelect/mainGrapeSelect";
import { WineAndTemperatureSelect } from "components/form/items/wineAndTemperatureSelect/wineAndTemperatureSelect";
import Loader from "components/loader/loader";
import { useGrapes, useRoutes, useTranslations } from "domains";
import { Grape } from "hooks/grapes/models/grape";
import { SaveGrapeInput } from "hooks/grapes/models/grapeInput";
import BasePage from "pages/basePage/basePage";
import { useGetGrape } from "./models/getGrape";
import { useGrapeForm } from "./useGrapeForm";

interface Props {
	grape: Grape;
	save: (input: SaveGrapeInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

const GrapeForm: UIComponent<Props> = ({ grape, remove, save }) => {
	const { register, handleFormSubmit, control, handleDelete, errors, selectedMainGrape } = useGrapeForm({ initialValues: grape, submit: save, remove });
	const { t } = useTranslations();

	return (
		<form onSubmit={handleFormSubmit}>
			<h2>{t(`grape.${grape.id ? "edit" : "add"}`)}</h2>
			<FormControl {...register("name", { required: { value: true, message: "This is required." } })} fullWidth sx={{ mb: 2, mr: 2 }}>
				<TextField
					error={!!errors?.name}
					helperText={errors?.name?.message}
					fullWidth
					id="name"
					name="name"
					label={t("grapeForm.name")}
					variant="outlined"
				/>
			</FormControl>
			<MainGrapeSelect
				name="mainGrape"
				label="Main grape"
				control={control}
				noOptionsText="No main grapes"
				defaultValue={selectedMainGrape}
			/>
			<WineAndTemperatureSelect register={register} control={control} name="temperatures" />
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				{grape.id ? <Button onClick={() => handleDelete(grape.id)} color="error" variant="outlined" sx={{ marginRight: 2 }}>{t("deleteGrape")}</Button> : undefined}
				<Button type="submit" variant="contained">{grape.id ? t("updateGrape") : t("createGrape")}</Button>
			</Box>
		</form>
	);
};

export const GrapePage: UIComponent = () => {
	const { currentQueryString } = useRoutes();
	const { save, remove, grape, isLoading } = useGrapes(useGetGrape({ variables: { id: currentQueryString.selected! }, skip: !currentQueryString.selected }));

	if (isLoading) {
		return (
			<BasePage>
				<Loader />
			</BasePage>
		);
	}
	return <GrapeForm save={save} remove={remove} grape={grape} />;
};
