import { UIComponent } from "@aptus/frontend-core";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { formatDistance } from "date-fns";
import { Conversation } from "hooks/conversations/models/conversation";
import { ContentBox } from "pages/conversationDetailPage/conversationDetailPageStyle";
import SendIcon from "@mui/icons-material/Send";
import { useConversations, useTranslations } from "domains";
import { useRef } from "react";
import { ChatWindow, ChatMessage, ChatItem, ChatAvatar } from "./ChatStyle";

interface ChatProps {
	conversation: Conversation;
}

export const Chat: UIComponent<ChatProps> = ({ conversation }) => {
	const { t } = useTranslations();
	const chatRef = useRef<HTMLTextAreaElement>();
	const { sendMessage, isSending } = useConversations();

	return (
		<ContentBox>
			<h4>
				{t("conversationWith")}
				{" "}
				{conversation.client}
			</h4>
			<ChatWindow>
				{conversation.messages.map((m) => (
					<ChatItem key={`${m.createdAt}`} isOwner={m.isOwner}>
						<ChatAvatar>{m.initials}</ChatAvatar>
						<ChatMessage isOwner={m.isOwner} initials={m.initials}>
							{m.content}
							<span className="date">
								{formatDistance(m.createdAt, new Date(), { addSuffix: true })}
							</span>
						</ChatMessage>
					</ChatItem>
				))}
				<OutlinedInput
					inputRef={chatRef}
					id="chat"
					maxRows={6}
					multiline
					style={{ width: "100%" }}
					label=""
					disabled={isSending}
					endAdornment={(
						<InputAdornment style={{ alignSelf: "flex-end", padding: 10 }} position="end">
							<IconButton
								aria-label="Send message"
								disabled={isSending}
								style={{ padding: 10 }}
								edge="end"
								onClick={() => {
									if (chatRef.current?.value) {
										sendMessage({
											id: conversation.id,
											content: chatRef.current.value,
										});

										chatRef.current.value = "";
									}
								}}
							>
								<SendIcon />
							</IconButton>
						</InputAdornment>
					)}
				/>
			</ChatWindow>
		</ContentBox>
	);
};
