import { ContentStyle } from "components/styles";
import styled from "styled-components";

export const ProfilePageStyle = styled(ContentStyle)`
	height: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;

	form {
		background-color: white;
		padding: 20px;
		margin-bottom: 20px;

		h2 {
			margin-bottom: 20px;
		}
	}
`;
