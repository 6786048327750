import { Domain } from "@aptus/frontend-core";
import AdviceRequestMapper, { AdviceRequestsAPIDTO } from "hooks/adviceRequests/adviceRequestsMapper";
import { useUpdateAdviceRequest } from "hooks/adviceRequests/models/updateAdviceRequest";
import { useAdviceRequestsUseCases } from "hooks/adviceRequests/useAdviceRequestsUseCases";
import { AocMapper, AocsAPIDTO } from "hooks/aocs/aocMapper";
import { useCreateAoc } from "hooks/aocs/models/createAoc";
import { useDeleteAoc } from "hooks/aocs/models/deleteAoc";
import { useUpdateAoc } from "hooks/aocs/models/updateAoc";
import { useAocsUseCases } from "hooks/aocs/useAocsUseCases";
import { ClassificationMapper, ClassificationsAPIDTO } from "hooks/classifications/classificationMapper";
import { useCreateClassification } from "hooks/classifications/models/createClassification";
import { useDeleteClassification } from "hooks/classifications/models/deleteClassification";
import { useUpdateClassification } from "hooks/classifications/models/updateClassification";
import { useClassificationsUseCases } from "hooks/classifications/useClassificationsUseCases";
import { CorrectionMapper, CorrectionsAPIDTO } from "hooks/corrections/correctionMapper";
import { useCreateCorrection } from "hooks/corrections/models/createCorrection";
import { useDeleteCorrection } from "hooks/corrections/models/deleteCorrection";
import { useUpdateCorrection } from "hooks/corrections/models/updateCorrection";
import { useCorrectionsUseCases } from "hooks/corrections/useCorrectionsUseCases";
import { CountriesAPIDTO, CountryMapper } from "hooks/countries/countryMapper";
import { useCreateCountry } from "hooks/countries/models/createCountry";
import { useDeleteCountry } from "hooks/countries/models/deleteCountry";
import { useUpdateCountry } from "hooks/countries/models/updateCountry";
import { useCountriesUseCases } from "hooks/countries/useCountriesUseCases";
import { DomainMapper, DomainsAPIDTO } from "hooks/domains/domainMapper";
import { useCreateDomain } from "hooks/domains/models/createDomain";
import { useDeleteDomain } from "hooks/domains/models/deleteDomain";
import { useUpdateDomain } from "hooks/domains/models/updateDomain";
import { useDomainsUseCases } from "hooks/domains/useDomainsUseCases";
import { ExceptionMapper, ExceptionsAPIDTO } from "hooks/exceptions/exceptionMapper";
import { useCreateException } from "hooks/exceptions/models/createException";
import { useDeleteException } from "hooks/exceptions/models/deleteException";
import { useUpdateException } from "hooks/exceptions/models/updateException";
import { useExceptionsUseCases } from "hooks/exceptions/useExceptionsUseCases";
import { FaqMapper, FaqsAPIDTO } from "hooks/faqs/faqMapper";
import { useCreateFaq } from "hooks/faqs/models/createFaq";
import { useDeleteFaq } from "hooks/faqs/models/deleteFaq";
import { useUpdateFaq } from "hooks/faqs/models/updateFaq";
import { useFaqsUseCases } from "hooks/faqs/useFaqsUseCases";
import { FoodMapper, FoodsAPIDTO } from "hooks/foods/foodMapper";
import { useCreateFood } from "hooks/foods/models/createFood";
import { useDeleteFood } from "hooks/foods/models/deleteFood";
import { useUpdateFood } from "hooks/foods/models/updateFood";
import { useFoodsUseCases } from "hooks/foods/useFoodsUseCases";
import { GrapeMapper, GrapesAPIDTO } from "hooks/grapes/grapeMapper";
import { useCreateGrape } from "hooks/grapes/models/createGrape";
import { useDeleteGrape } from "hooks/grapes/models/deleteGrape";
import { useUpdateGrape } from "hooks/grapes/models/updateGrape";
import { useGrapesUseCases } from "hooks/grapes/useGrapesUseCases";
import { MainGrapeMapper, MainGrapesAPIDTO } from "hooks/mainGrapes/mainGrapeMapper";
import { useCreateMainGrape } from "hooks/mainGrapes/models/createMainGrape";
import { useDeleteMainGrape } from "hooks/mainGrapes/models/deleteMainGrape";
import { useUpdateMainGrape } from "hooks/mainGrapes/models/updateMainGrape";
import { useMainGrapesUseCases } from "hooks/mainGrapes/useMainGrapesUseCases";
import { useNotificationsUseCases } from "hooks/notifications/useNotificationsUseCases";
import { useProfileUseCases } from "hooks/profile/useProfileUseCases";
import { useCreateRegion } from "hooks/regions/models/createRegion";
import { useDeleteRegion } from "hooks/regions/models/deleteRegion";
import { useUpdateRegion } from "hooks/regions/models/updateRegion";
import { RegionMapper, RegionsAPIDTO } from "hooks/regions/regionMapper";
import { useRegionsUseCases } from "hooks/regions/useRegionsUseCases";
import { useRoutesUseCases } from "hooks/routes/useRoutes";
import { useCreateSuggestion } from "hooks/suggestions/models/createSuggestion";
import { useUpdateSuggestion } from "hooks/suggestions/models/updateSuggestion";
import { SuggestionMapper, SuggestionsAPIDTO } from "hooks/suggestions/suggestionMapper";
import { useSuggestionsUseCases } from "hooks/suggestions/useSuggestionsUseCases";
import { useTranslationsUseCases } from "hooks/translations/useTranslationsUseCases";
import { useCreateWine } from "hooks/wines/models/createWine";
import { useUpdateWine } from "hooks/wines/models/updateWine";
import { useWinesUseCases } from "hooks/wines/useWinesUseCases";
import { WineMapper, WinesAPIDTO } from "hooks/wines/wineMapper";
import { useUsersUseCases } from "hooks/users/useUsersUseCases";
import { UserMapper, UsersAPIDTO } from "hooks/users/userMapper";
import { useCreateUser } from "hooks/users/models/createUser";
import { useDeleteUser } from "hooks/users/models/deleteUser";
import { useUpdateUser } from "hooks/users/models/updateUser";
import { useDeleteSuggestion } from "hooks/suggestions/models/deleteSuggestion";
import { useConversationsUseCases } from "hooks/conversations/useConversationsUseCases";
import { ConversationMapper, ConversationsAPIDTO } from "hooks/conversations/conversationMapper";
import { useUpdateConversation } from "hooks/conversations/models/updateConversation";
import { useCreateConversationMessage } from "hooks/conversations/models/createConversationMessage";

export const useTranslations: Domain<undefined, typeof useTranslationsUseCases> = useTranslationsUseCases;
export const useRoutes: Domain<undefined, typeof useRoutesUseCases> = useRoutesUseCases;
export const useProfile: Domain<undefined, typeof useProfileUseCases> = useProfileUseCases;
export const useNotifications: Domain<undefined, typeof useNotificationsUseCases> = useNotificationsUseCases;

export const useCountries: Domain<CountriesAPIDTO, typeof useCountriesUseCases> = (API) => {
	const map = new CountryMapper();
	const [updateCountry] = useUpdateCountry();
	const [createCountry] = useCreateCountry();
	const [deleteCountry] = useDeleteCountry();

	return useCountriesUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateCountry),
		createMutation: map.toCreateMutation(createCountry),
		deleteMutation: map.toDeleteMutation(deleteCountry),
		defaultCountry: map.toCountry({}),
	});
};

export const useDomains: Domain<DomainsAPIDTO, typeof useDomainsUseCases> = (API) => {
	const map = new DomainMapper();
	const [updateDomain] = useUpdateDomain();
	const [createDomain] = useCreateDomain();
	const [deleteDomain] = useDeleteDomain();

	return useDomainsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateDomain),
		createMutation: map.toCreateMutation(createDomain),
		deleteMutation: map.toDeleteMutation(deleteDomain),
		defaultDomain: map.toDomain({}),
	});
};

export const useClassifications: Domain<ClassificationsAPIDTO, typeof useClassificationsUseCases> = (API) => {
	const map = new ClassificationMapper();
	const [updateClassification] = useUpdateClassification();
	const [createClassification] = useCreateClassification();
	const [deleteClassification] = useDeleteClassification();

	return useClassificationsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateClassification),
		createMutation: map.toCreateMutation(createClassification),
		deleteMutation: map.toDeleteMutation(deleteClassification),
		defaultClassification: map.toClassification({}),
	});
};

export const useRegions: Domain<RegionsAPIDTO, typeof useRegionsUseCases> = (API) => {
	const map = new RegionMapper();
	const [updateRegion] = useUpdateRegion();
	const [createRegion] = useCreateRegion();
	const [deleteRegion] = useDeleteRegion();

	return useRegionsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateRegion),
		createMutation: map.toCreateMutation(createRegion),
		deleteMutation: map.toDeleteMutation(deleteRegion),
		defaultRegion: map.toRegion({}),
	});
};

export const useCorrections: Domain<CorrectionsAPIDTO, typeof useCorrectionsUseCases> = (API) => {
	const map = new CorrectionMapper();
	const [updateCorrection] = useUpdateCorrection();
	const [createCorrection] = useCreateCorrection();
	const [deleteCorrection] = useDeleteCorrection();

	return useCorrectionsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateCorrection),
		createMutation: map.toCreateMutation(createCorrection),
		deleteMutation: map.toDeleteMutation(deleteCorrection),
		defaultCorrection: map.toCorrection({}),
	});
};

export const useAocs: Domain<AocsAPIDTO, typeof useAocsUseCases> = (API) => {
	const map = new AocMapper();
	const [updateAoc] = useUpdateAoc();
	const [createAoc] = useCreateAoc();
	const [deleteAoc] = useDeleteAoc();

	return useAocsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateAoc),
		createMutation: map.toCreateMutation(createAoc),
		deleteMutation: map.toDeleteMutation(deleteAoc),
		defaultAoc: map.toAoc({}),
	});
};

export const useExceptions: Domain<ExceptionsAPIDTO, typeof useExceptionsUseCases> = (API) => {
	const map = new ExceptionMapper();
	const [updateException] = useUpdateException();
	const [createException] = useCreateException();
	const [deleteException] = useDeleteException();

	return useExceptionsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateException),
		createMutation: map.toCreateMutation(createException),
		deleteMutation: map.toDeleteMutation(deleteException),
		defaultException: map.toException({}),
	});
};

export const useGrapes: Domain<GrapesAPIDTO, typeof useGrapesUseCases> = (API) => {
	const map = new GrapeMapper();
	const [updateGrape] = useUpdateGrape();
	const [createGrape] = useCreateGrape();
	const [deleteGrape] = useDeleteGrape();

	return useGrapesUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateGrape),
		createMutation: map.toCreateMutation(createGrape),
		deleteMutation: map.toDeleteMutation(deleteGrape),
		defaultGrape: map.toGrape({}),
	});
};

export const useMainGrapes: Domain<MainGrapesAPIDTO, typeof useMainGrapesUseCases> = (API) => {
	const map = new MainGrapeMapper();
	const [updateMainGrape] = useUpdateMainGrape();
	const [createMainGrape] = useCreateMainGrape();
	const [deleteMainGrape] = useDeleteMainGrape();

	return useMainGrapesUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateMainGrape),
		createMutation: map.toCreateMutation(createMainGrape),
		deleteMutation: map.toDeleteMutation(deleteMainGrape),
		defaultMainGrape: map.toMainGrape({}),
	});
};

export const useFaqs: Domain<FaqsAPIDTO, typeof useFaqsUseCases> = (API) => {
	const map = new FaqMapper();
	const [updateFaq] = useUpdateFaq();
	const [createFaq] = useCreateFaq();
	const [deleteFaq] = useDeleteFaq();

	return useFaqsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateFaq),
		createMutation: map.toCreateMutation(createFaq),
		deleteMutation: map.toDeleteMutation(deleteFaq),
		defaultFaq: map.toFaq({}),
	});
};

export const useFoods: Domain<FoodsAPIDTO, typeof useFoodsUseCases> = (API) => {
	const map = new FoodMapper();
	const [updateFood] = useUpdateFood();
	const [createFood] = useCreateFood();
	const [deleteFood] = useDeleteFood();

	return useFoodsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateFood),
		createMutation: map.toCreateMutation(createFood),
		deleteMutation: map.toDeleteMutation(deleteFood),
		defaultFood: map.toFood({}),
	});
};

export const useWines: Domain<WinesAPIDTO, typeof useWinesUseCases> = (API) => {
	const map = new WineMapper();
	const [updateWine] = useUpdateWine();
	const [createWine] = useCreateWine();

	return useWinesUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateWine),
		createMutation: map.toCreateMutation(createWine),
		defaultWine: map.toWine({}),
	});
};

export const useAdviceRequests: Domain<AdviceRequestsAPIDTO, typeof useAdviceRequestsUseCases> = (API) => {
	const map = new AdviceRequestMapper();
	const [updateAdviceRequest] = useUpdateAdviceRequest();

	return useAdviceRequestsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateAdviceRequest),
		defaultAdviceRequest: map.toAdviceRequest({}),
	});
};

export const useSuggestions: Domain<SuggestionsAPIDTO, typeof useSuggestionsUseCases> = (API) => {
	const map = new SuggestionMapper();
	const [updateSuggestion] = useUpdateSuggestion();
	const [createSuggestion] = useCreateSuggestion();
	const [deleteSuggestion] = useDeleteSuggestion();

	return useSuggestionsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateSuggestion),
		createMutation: map.toCreateMutation(createSuggestion),
		deleteMutation: map.toDeleteMutation(deleteSuggestion),
		defaultSuggestion: map.toSuggestion({}),
	});
};

export const useUsers: Domain<UsersAPIDTO, typeof useUsersUseCases> = (API) => {
	const map = new UserMapper();
	const [updateUser] = useUpdateUser();
	const [createUser] = useCreateUser();
	const [deleteUser] = useDeleteUser();

	return useUsersUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateUser),
		createMutation: map.toCreateMutation(createUser),
		deleteMutation: map.toDeleteMutation(deleteUser),
		defaultUser: map.toUser({}),
	});
};

export const useConversations: Domain<ConversationsAPIDTO, typeof useConversationsUseCases> = (API) => {
	const map = new ConversationMapper();
	const [updateConversation] = useUpdateConversation();
	const [chatMutation] = useCreateConversationMessage();

	return useConversationsUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateConversation),
		chatMutation: map.toChatMutation(chatMutation),
		defaultConversation: map.toConversation({}),
	});
};
