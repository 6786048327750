import { Database } from "mocks/db";

export const populateExceptionCollection = (db: Database): void => {
	const grapes = db.grape.findMany({});
	const domains = db.domain.findMany({});
	const countries = db.country.findMany({});

	db.exception.create({
		text: "Grand cru 2.0",
		region: domains[0].region,
		country: domains[0].region?.country,
		domain: domains[0],
		grape: grapes[0],
		aoc: domains[0].aoc,
	});

	db.exception.create({
		country: countries[1],
		grape: grapes[1],
	});

	db.exception.create({
		region: domains[1].region,
		country: domains[1].region?.country,
		domain: domains[1],
	});

	db.exception.create({});
	db.exception.create({});
	db.exception.create({});
	db.exception.create({});
	db.exception.create({});
	db.exception.create({});
};
