/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetCorrectionListVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  paging?: Types.Maybe<Types.PagingDTO>;
  sort?: Types.Maybe<Array<Types.Maybe<Types.SortDTO>> | Types.Maybe<Types.SortDTO>>;
}>;


export type GetCorrectionListDTO = (
  { __typename?: 'Query' }
  & { corrections: Array<(
    { __typename?: 'Correction' }
    & Pick<Types.CorrectionDTO, 'id'>
    & { name: Types.CorrectionDTO['originalText'] }
  )> }
);


export const GetCorrectionListDocumentDTO = gql`
    query getCorrectionList($search: String, $paging: Paging, $sort: [Sort]) {
  corrections(
    filter: {OR: [{originalText: {contains: $search}}, {replacement: {contains: $search}}]}
    paging: $paging
    sort: $sort
  ) {
    id
    name: originalText
  }
}
    `;

/**
 * __useGetCorrectionList__
 *
 * To run a query within a React component, call `useGetCorrectionList` and pass it any options that fit your needs.
 * When your component renders, `useGetCorrectionList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorrectionList({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCorrectionList(baseOptions?: Apollo.QueryHookOptions<GetCorrectionListDTO, GetCorrectionListVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCorrectionListDTO, GetCorrectionListVariablesDTO>(GetCorrectionListDocumentDTO, options);
      }
export function useGetCorrectionListLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetCorrectionListDTO, GetCorrectionListVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCorrectionListDTO, GetCorrectionListVariablesDTO>(GetCorrectionListDocumentDTO, options);
        }
export type GetCorrectionListHookResult = ReturnType<typeof useGetCorrectionList>;
export type GetCorrectionListLazyQueryDTOHookResult = ReturnType<typeof useGetCorrectionListLazyQueryDTO>;
export type GetCorrectionListQueryResultDTO = Apollo.QueryResult<GetCorrectionListDTO, GetCorrectionListVariablesDTO>;