/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAocsCountVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetAocsCountDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'aocsCount'>
);


export const GetAocsCountDocumentDTO = gql`
    query getAocsCount($search: String) {
  aocsCount(filter: {archived: {equals: false}, name: {contains: $search}})
}
    `;

/**
 * __useGetAocsCount__
 *
 * To run a query within a React component, call `useGetAocsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetAocsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAocsCount({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAocsCount(baseOptions?: Apollo.QueryHookOptions<GetAocsCountDTO, GetAocsCountVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAocsCountDTO, GetAocsCountVariablesDTO>(GetAocsCountDocumentDTO, options);
      }
export function useGetAocsCountLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetAocsCountDTO, GetAocsCountVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAocsCountDTO, GetAocsCountVariablesDTO>(GetAocsCountDocumentDTO, options);
        }
export type GetAocsCountHookResult = ReturnType<typeof useGetAocsCount>;
export type GetAocsCountLazyQueryDTOHookResult = ReturnType<typeof useGetAocsCountLazyQueryDTO>;
export type GetAocsCountQueryResultDTO = Apollo.QueryResult<GetAocsCountDTO, GetAocsCountVariablesDTO>;