/* eslint-disable */
import * as Types from '../../../../../models/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetGrapeListVariablesDTO = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  paging?: Types.Maybe<Types.PagingDTO>;
}>;


export type GetGrapeListDTO = (
  { __typename?: 'Query' }
  & { grapes: Array<(
    { __typename?: 'Grape' }
    & Pick<Types.GrapeDTO, 'id' | 'name'>
  )> }
);


export const GetGrapeListDocumentDTO = gql`
    query getGrapeList($search: String, $paging: Paging) {
  grapes(
    filter: {archived: {equals: false}, name: {contains: $search}}
    paging: $paging
    sort: [{field: "name", order: ASC}]
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetGrapeList__
 *
 * To run a query within a React component, call `useGetGrapeList` and pass it any options that fit your needs.
 * When your component renders, `useGetGrapeList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrapeList({
 *   variables: {
 *      search: // value for 'search'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetGrapeList(baseOptions?: Apollo.QueryHookOptions<GetGrapeListDTO, GetGrapeListVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGrapeListDTO, GetGrapeListVariablesDTO>(GetGrapeListDocumentDTO, options);
      }
export function useGetGrapeListLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetGrapeListDTO, GetGrapeListVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGrapeListDTO, GetGrapeListVariablesDTO>(GetGrapeListDocumentDTO, options);
        }
export type GetGrapeListHookResult = ReturnType<typeof useGetGrapeList>;
export type GetGrapeListLazyQueryDTOHookResult = ReturnType<typeof useGetGrapeListLazyQueryDTO>;
export type GetGrapeListQueryResultDTO = Apollo.QueryResult<GetGrapeListDTO, GetGrapeListVariablesDTO>;