/* eslint-disable no-tabs */
import { ApolloProvider } from "@apollo/client";
import Notification from "components/notification/notification";
import { FunctionComponent, StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";

import "i18n";
import themeOptions from "style/theme";
import { ThemeProvider } from "@mui/material";
import { useNotifications } from "domains";
import { createRoot } from "react-dom/client";
import worker from "mocks/worker";
import client from "./client";
import reportWebVitals from "./reportWebVitals";
import Routes from "./Routes";
import GlobalStyle from "./style/global";

if (process.env.REACT_APP_ENVIRONMENT === "local") {
	worker.start({
		onUnhandledRequest: "bypass",
	});
}

const App: FunctionComponent = () => {
	const { notification } = useNotifications();

	return (
		<StrictMode>
			<GlobalStyle />
			<ApolloProvider client={client}>
				<ThemeProvider theme={themeOptions}>
					<BrowserRouter>
						<Routes />
						{notification && (<Notification {...notification} />)}
					</BrowserRouter>
				</ThemeProvider>
			</ApolloProvider>
		</StrictMode>
	);
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
