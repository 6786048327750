import { UseCases } from "@aptus/frontend-core";
import { useTranslation } from "react-i18next";
import {
	Translate, TranslationKey, Translations,
} from "./models/translate";
import en from "./en.json";

interface Result {
	translations: Translations<TranslationKey>;
	errorTranslations: Translations<string>;
	t: Translate;
}

export const useTranslationsUseCases: UseCases<undefined, Result> = () => {
	const { t } = useTranslation<TranslationKey>();

	const translations: Result["translations"] = en;

	const errorTranslations: Result["errorTranslations"] = {
		unknownError: t("somethingWentWrong"),
		...translations,
	};

	return {
		translations,
		errorTranslations,
		t,
	};
};
