/* eslint-disable */
import * as Types from '../../../../../../../../models/schema';

import { FoodFragmentDTO } from '../../../../../../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFoodVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetFoodDTO = (
  { __typename?: 'Query' }
  & { food: (
    { __typename?: 'Food' }
    & FoodFragmentDTO
  ) }
);


export const GetFoodDocumentDTO = gql`
    query getFood($id: String!) {
  food(id: $id) {
    ...foodFragment
  }
}
    ${FoodFragmentDTO}`;

/**
 * __useGetFood__
 *
 * To run a query within a React component, call `useGetFood` and pass it any options that fit your needs.
 * When your component renders, `useGetFood` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFood({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFood(baseOptions: Apollo.QueryHookOptions<GetFoodDTO, GetFoodVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFoodDTO, GetFoodVariablesDTO>(GetFoodDocumentDTO, options);
      }
export function useGetFoodLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetFoodDTO, GetFoodVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFoodDTO, GetFoodVariablesDTO>(GetFoodDocumentDTO, options);
        }
export type GetFoodHookResult = ReturnType<typeof useGetFood>;
export type GetFoodLazyQueryDTOHookResult = ReturnType<typeof useGetFoodLazyQueryDTO>;
export type GetFoodQueryResultDTO = Apollo.QueryResult<GetFoodDTO, GetFoodVariablesDTO>;