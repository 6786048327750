import { UILogic } from "@aptus/frontend-core";
import { useAocs, useRoutes } from "domains";
import { Aoc } from "hooks/aocs/models/aoc";
import { Country } from "hooks/countries/models/country";
import { Domain } from "hooks/domains/models/domain";
import { SaveDomainInput } from "hooks/domains/models/domainInput";
import { Region } from "hooks/regions/models/region";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UseFormTypes } from "../useFormTypes";
import { useGetAocById } from "./models/getAocById";

interface Props {
	initialValues?: Domain;
	submit: (values: SaveDomainInput) => void;
	remove: (id: string) => void;
}

interface Result extends UseFormTypes<Domain> {
	handleDelete: (id: string) => void;
	selectedCountry?: Country["id"];
	selectedRegion?: Region["id"];
	selectedAoc?: Aoc["id"];
	isDisabled?: boolean;
}

export const useDomainForm: UILogic<Props, Result> = ({ initialValues, remove, submit }) => {
	const { currentQueryString: { selected, create }, updateQueryString } = useRoutes();
	const [aocId, setAocId] = useState(initialValues?.aoc);

	const defaultValues = {
		...initialValues,
		id: selected,
	};

	const { register, handleSubmit, control, reset, watch, setValue, formState: { errors } } = useForm<Domain>({ defaultValues });

	const country = watch("country");
	const region = watch("region");

	const { aoc } = useAocs(useGetAocById({ variables: { id: aocId || "" }, skip: !aocId }));

	useEffect(() => {
		setValue("region", undefined);
		if (!aoc) setValue("aoc", undefined);
	}, [country]);

	useEffect(() => {
		if (watch("aoc") && watch("region") !== aoc.region) setValue("region", aoc.region);
		if (watch("aoc") && watch("country") !== aoc.country) setValue("country", aoc.country || "");
	}, [aoc]);

	useEffect(() => {
		if (watch("aoc") !== aocId) {
			setAocId(watch("aoc"));
		}

		// if (!watch("aoc")) {
		// setValue("region", undefined);
		// setValue("country", "");
		// }
	}, [watch("aoc")]);

	useEffect(() => {
		reset(defaultValues);
	}, [create, initialValues]);

	const handleFormSubmit = (values: SaveDomainInput): void => {
		submit(values);
	};

	const handleDelete = (id: string) => {
		remove(id);
		updateQueryString({ selected: null, create: true });
	};

	return {
		register,
		control,
		handleFormSubmit: handleSubmit(handleFormSubmit),
		handleDelete,
		selectedCountry: country,
		selectedRegion: region,
		selectedAoc: aoc.id,
		errors,
		isDisabled: !!watch("aoc"),
	};
};
