import { UIComponent } from "@aptus/frontend-core";
import { Card } from "@mui/material";
import { useRoutes } from "domains";
import { DatabaseContentStyle } from "./databaseContentStyle";
import { RecordForm } from "./recordForm/recordForm";
import { RecordList } from "./recordList/recordList";
import { useDatabaseContent } from "./useDatabaseContent";

export const DatabaseContent: UIComponent<{}> = () => {
	const { data, loadMore, count } = useDatabaseContent({});
	const { currentQueryString: { table } } = useRoutes();

	if (!table) {
		return null;
	}

	return (
		<DatabaseContentStyle>
			<Card style={{ flex: 1, marginRight: "20px" }} variant="outlined">
				<RecordList data={data} loadMore={loadMore} count={count} />
			</Card>
			<Card style={{ flex: 3 }} variant="outlined">
				<RecordForm />
			</Card>
		</DatabaseContentStyle>
	);
};
