/* eslint-disable */
import * as Types from '../../../models/schema';

import { WineFragmentDTO } from '../../../graphql/models/fragments';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateWineVariablesDTO = Types.Exact<{
  data: Types.WineCreateInputDTO;
}>;


export type CreateWineDTO = (
  { __typename?: 'Mutation' }
  & { createOneWine: (
    { __typename?: 'Wine' }
    & WineFragmentDTO
  ) }
);


export const CreateWineDocumentDTO = gql`
    mutation createWine($data: WineCreateInput!) {
  createOneWine(data: $data) {
    ...wineFragment
  }
}
    ${WineFragmentDTO}`;
export type CreateWineMutationFnDTO = Apollo.MutationFunction<CreateWineDTO, CreateWineVariablesDTO>;

/**
 * __useCreateWine__
 *
 * To run a mutation, you first call `useCreateWine` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWine` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWine, { data, loading, error }] = useCreateWine({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWine(baseOptions?: Apollo.MutationHookOptions<CreateWineDTO, CreateWineVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWineDTO, CreateWineVariablesDTO>(CreateWineDocumentDTO, options);
      }
export type CreateWineHookResult = ReturnType<typeof useCreateWine>;
export type CreateWineMutationResultDTO = Apollo.MutationResult<CreateWineDTO>;
export type CreateWineMutationOptionsDTO = Apollo.BaseMutationOptions<CreateWineDTO, CreateWineVariablesDTO>;