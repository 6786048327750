import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import event from "event";
import { Success } from "hooks/countries/models/country";
import { t } from "i18next";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Region } from "./models/region";
import { CreateRegionInput, DeleteRegionInput, SaveRegionInput, UpdateRegionInput } from "./models/regionInput";

export type RegionsAPI = InternalAPI<Region[]>;

export type UpdateRegionMutation = Mutation<UpdateRegionInput, Region>;
export type CreateRegionMutation = Mutation<CreateRegionInput, Region>;
export type DeleteRegionMutation = Mutation<DeleteRegionInput, Success>;

interface Props {
	API: RegionsAPI;
	defaultRegion: Region;
	updateMutation: UpdateRegionMutation;
	createMutation: CreateRegionMutation;
	deleteMutation: DeleteRegionMutation;
}

interface Result {
	region: Region;
	regions: Region[];
	isLoading: boolean;
	error?: Error;
	save: (input: SaveRegionInput) => Promise<void>;
	remove: (id: string) => Promise<void>;
}

export const useRegionsUseCases: UseCases<Props, Result> = ({ API, defaultRegion, updateMutation, createMutation, deleteMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);
	const isUpdate = (input: SaveRegionInput): input is UpdateRegionInput => !!input.id;

	const save: Result["save"] = async (input) => {
		setIsLoading(true);
		try {
			if (isUpdate(input)) {
				await updateMutation(input);
				event.emit("mutationSucceeded", t("regionHasBeenSuccessfullyUpdated"));
			} else {
				await createMutation(input);
				event.emit("mutationSucceeded", t("regionHasBeenSuccessfullyCreated"));
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	const remove = async (id: string) => {
		try {
			await deleteMutation({ id });
			event.emit("mutationSucceeded", t("regionHasBeenSuccessfullyDeleted"));
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
	};

	return {
		region: API.data[0] || defaultRegion,
		regions: API.data || [],
		isLoading: API.isLoading || isLoading,
		save,
		remove,
	};
};
