import { UIComponent, UILogic } from "@aptus/frontend-core";
import { Redirect, RouteProps as OriginalRouteProps } from "react-router";

interface PublicRouteProps extends OriginalRouteProps {
	isAuthorized?: never;
	redirectTo?: never;
}

interface PrivateRouteProps extends OriginalRouteProps {
	isAuthorized: boolean;
	redirectTo: string;
}

export type RouteProps = PublicRouteProps | PrivateRouteProps;
type Props = RouteProps;

interface Result {
	render?: OriginalRouteProps["render"];
	component?: OriginalRouteProps["component"];
	children?: OriginalRouteProps["children"];
}

const useRoute: UILogic<Props, Result> = ({ isAuthorized, redirectTo, ...props }) => {
	const RedirectComponent: UIComponent = () => <Redirect to={redirectTo} />;
	const isPrivate: boolean = isAuthorized === undefined || redirectTo === undefined;

	let component: Result["component"] = isPrivate ? props.component : undefined;
	let render: Result["render"] = isPrivate ? props.render : undefined;
	let children: Result["children"] = isPrivate ? props.children : undefined;

	if (!isPrivate) {
		component = isAuthorized ? props.component : RedirectComponent;
		render = isAuthorized ? props.render : () => <RedirectComponent />;
		children = isAuthorized ? props.children : <RedirectComponent />;
	}

	return {
		render,
		component,
		children,
	};
};

export default useRoute;
