import { UIComponent } from "@aptus/frontend-core";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Alert, Button, FormControl } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useTranslations } from "domains";
import event from "event";
import { FormProps } from "models/formProps";
import { useEffect, useState } from "react";

import sx from "./resetPasswordAnonymouslyStyle";
import { useResetPasswordAnonymouslyForm } from "./useResetPasswordAnonymouslyForm";

type Props = FormProps<{ email: string }>;

/* Translate */

const ResetPasswordAnonymouslyForm: UIComponent<Props> = ({ submit }) => {
	const { handleFormSubmit, register } = useResetPasswordAnonymouslyForm({ submit });
	const { t } = useTranslations();

	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const onPasswordResetMailSent = () => {
		setSuccess(true);
	};

	const onPasswordResetFailed = () => {
		setSuccess(false);
		setError(true);
	};

	useEffect(() => {
		event.on("passwordResetMailSent", onPasswordResetMailSent);
		event.on("passwordResetFailed", onPasswordResetFailed);

		return () => {
			event.removeListener("passwordResetMailSent", onPasswordResetMailSent);
			event.removeListener("passwordResetFailed", onPasswordResetFailed);
		};
	}, []);

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box sx={sx.paper}>
				<Avatar sx={sx.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					{t("resetPasswordAnonymously.title")}
				</Typography>
				<Typography mt={2} component="h1" textAlign="center" variant="body2">
					{t("resetPasswordAnonymously.description")}
				</Typography>
				<form style={{ marginTop: 20 }} onSubmit={handleFormSubmit}>
					<FormControl {...register("email")} fullWidth sx={{ mb: 2 }} variant="filled">
						<TextField autoComplete="current-email" required type="email" variant="outlined" name="email" label={t("email")} />
					</FormControl>
					<Button type="submit" fullWidth variant="contained" color="primary" sx={sx.submit}>
						{t("resetPasswordAnonymously.submit")}
					</Button>

					{success && <Alert severity="success">{t("resetPasswordAnonymously.success")}</Alert>}
					{error && <Alert severity="error">{t("resetPasswordAnonymously.failed")}</Alert>}
				</form>
			</Box>
		</Container>
	);
};

export default ResetPasswordAnonymouslyForm;
