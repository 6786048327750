import { UILogic } from "@aptus/frontend-core";
import { useRoutes } from "domains";

export interface PaginationResult {
	skip: number;
	pageSize: number;
	page: number;
	onChangePage: (p: number) => void;
}

type Props = {};

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 0;

export const usePagination: UILogic<Props, PaginationResult> = () => {
	const { updateQueryString, currentQueryString: { page, pageSize } } = useRoutes();

	const skip = Number(page || DEFAULT_PAGE) * (Number(pageSize) || DEFAULT_PAGE_SIZE);

	const onChangePage: PaginationResult["onChangePage"] = (p) => {
		updateQueryString({ page: String(p) });
	};

	return {
		skip,
		pageSize: Number(pageSize) || DEFAULT_PAGE_SIZE,
		page: Number(page) || 0,
		onChangePage,
	};
};
