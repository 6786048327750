import { UILogic } from "@aptus/frontend-core";
import { AdviceRequest, BuyingAdviceRequest } from "hooks/adviceRequests/models/adviceRequest";
import { UpdateAdviceRequestInput } from "hooks/adviceRequests/models/adviceRequestInput";
import { WineTypeDTO } from "models/schema";
import { LabelDataFormValues } from "../scanForm/useScanForm";

interface Props {
	buyingAdviceRequest: AdviceRequest & BuyingAdviceRequest;
	save: (input: Partial<UpdateAdviceRequestInput>) => Promise<void>;
}

interface DataValues {
	owner?: AdviceRequest["owner"];
	createdAt?: Date;
	id?: string;
	status?: AdviceRequest["status"];
	type?: AdviceRequest["type"];
	image?: AdviceRequest["image"];
	address: {
		firstName: string;
		lastName: string;
		address: string;
		phoneNumber: string;
		email: string;
	};
}

interface Result {
	labelDataFormValues: LabelDataFormValues;
	dataValues: DataValues;
	onMarkAsHandled: () => Promise<void>;
}

export const useBuyingForm: UILogic<Props, Result> = ({ buyingAdviceRequest: initialValues, save }) => {
	const labelDataFormValues: LabelDataFormValues = {
		id: initialValues.wine?.id,
		year: initialValues?.wine?.year,
		wineType: initialValues?.wine?.type || WineTypeDTO.RedDTO,
		overwriteTemperature: !!initialValues?.wine?.customTemperature,
		idealTemp: initialValues?.wine?.customTemperature,
		grape: initialValues?.wine?.descriptions.grape?.id,
		aoc: initialValues?.wine?.descriptions.aoc?.id,
		region: initialValues?.wine?.descriptions.region?.id,
		country: initialValues?.wine?.descriptions.country?.id,
		domain: initialValues?.wine?.descriptions.domainId,
		domainNotFound: !!initialValues?.wine?.descriptions.customDomain,
		customDomain: initialValues?.wine?.descriptions.customDomain,
		classification: initialValues?.wine?.descriptions.classificationId,
	};

	const dataValues: DataValues = {
		owner: initialValues?.owner,
		createdAt: initialValues?.createdAt ? new Date(initialValues?.createdAt) : undefined,
		id: initialValues?.id,
		status: initialValues?.status,
		type: initialValues?.type,
		image: initialValues?.image,
		address: {
			firstName: initialValues?.deliveryAddress?.givenName || "",
			lastName: initialValues?.deliveryAddress?.familyName || "",
			address: `${initialValues?.deliveryAddress?.address.street} ${initialValues?.deliveryAddress?.address.houseNumber}, ${initialValues?.deliveryAddress?.address.postalCode} ${initialValues?.deliveryAddress?.address.city}, ${initialValues?.deliveryAddress?.address.country}`,
			phoneNumber: initialValues?.deliveryAddress?.phoneNumber || "",
			email: initialValues?.deliveryAddress?.email || "",
		},
	};

	const onMarkAsHandled = async () => {
		await save({ id: initialValues.id, wineResult: initialValues.wine?.id });
	};

	return {
		dataValues,
		labelDataFormValues,
		onMarkAsHandled,
	};
};
