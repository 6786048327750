import { Query, UILogic } from "@aptus/frontend-core";
import { AutoCompleteSelectProps, getDropdownOptions } from "components/form/autoCompleteSelect/autoCompleteSelect";
import { useCountries } from "domains";
import { CountriesAPIDTO } from "hooks/countries/countryMapper";
import { Country } from "hooks/countries/models/country";
import { CountryFilterDTO } from "models/schema";
import { useGetCountries } from "./models/getCountries";

export interface CountrySelectProps extends Omit<AutoCompleteSelectProps, "dropdownProps"> {
	defaultValue?: string;
}

type Result = AutoCompleteSelectProps;

interface QueryProps {
	id?: Country["id"];
}

const useGetFilteredCountries: Query<QueryProps, CountriesAPIDTO> = ({ id }) => {
	const filter: CountryFilterDTO = {
		OR: [
			{ archived: { equals: false } },
			...id ? [{ id: { equals: id } }] : [],
		],
	};

	return useGetCountries({ variables: { filter } });
};

export const useCountrySelect: UILogic<CountrySelectProps, Result> = (props) => {
	const { countries, isLoading } = useCountries(useGetFilteredCountries({ id: props.defaultValue }));

	const options = {
		loading: isLoading,
		options: getDropdownOptions(countries),
	};

	return {
		...props,
		dropdownProps: options,
	};
};
